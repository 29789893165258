import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class TableFilter extends React.Component {
  static propTypes = {
    /** Data used to populate filter dropdown/checkbox */
    filterData: PropTypes.array.isRequired,
    /** Data selected to be filtered against dropdown/checkbox */
    filterList: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger filter update */
    onFilterUpdate: PropTypes.func,
    /** Callback to trigger filter reset */
    onFilterRest: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
  };

  state = {
    iconActive: null,

    searchText: this.props.searchText || null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
  }

  toggleActions = () => {
    this.setState((prevState) => ({ actionsOpen: !prevState.actionsOpen }));
  };

  setActiveIcon = (iconName) => {
    this.setState(() => ({
      iconActive: iconName,
    }));
  };

  getActiveIcon = (iconName) => {
    return this.state.iconActive !== iconName ? 'icon' : 'iconActive';
  };

  handleCheckboxChange = (index, value, column) => {
    this.props.onFilterUpdate(index, value, column, 'checkbox');
  };

  handleDropdownChange = (event, index, column) => {
    const labelFilterAll = this.props.options.textLabels.filter.all;
    const value = event.target.value === labelFilterAll ? '' : event.target.value;
    this.props.onFilterUpdate(index, value, column, 'dropdown');
  };

  handleMultiselectChange = (index, value, column) => {
    this.props.onFilterUpdate(index, value, column, 'multiselect');
  };

  handleTextFieldChange = (event, index, column) => {
    this.props.onFilterUpdate(index, event.target.value, column, 'textField');
  };

  handleCustomChange = (value, index, column) => {
    this.props.onFilterUpdate(index, value, column.name, column.filterType);
  };

  renderCheckbox(column, index) {
    const { filterData, filterList } = this.props;

    return (
      <div className="filter__checkboxList" key={index}>
        <div className="formGroup">
          <span className="filter__checkboxListTitle">{column.label}</span>

          {filterData[index].map((filterValue, filterIndex) => (
            <div
              className="FormControlLabel"
              key={filterIndex}
              classes={{
                root: 'filter__checkboxFormControl',
                label: 'filter__checkboxFormControlLabell',
              }}
              control={
                <Checkbox
                  className="filter__checkboxIcon"
                  onChange={this.handleCheckboxChange.bind(null, index, filterValue, column.name)}
                  checked={filterList[index].indexOf(filterValue) >= 0 ? true : false}
                  classes={{
                    root: 'filter__checkbox',
                    checked: 'filter__checked',
                  }}
                  value={filterValue != null ? filterValue.toString() : ''}
                />
              }
              label={filterValue}
            />
          ))}
        </div>
      </div>
    );
  }

  renderSelect(column, index) {
    const { filterData, filterList, options } = this.props;
    const textLabels = options.textLabels.filter;

    return (
      <React.Fragment key={index}>
        <div className="filter__formControl" key={index}>
          <Select disableUnderline className="filter__formSelect" value={filterList[index].toString() || textLabels.all} name={column.name} onChange={(event) => this.handleDropdownChange(event, index, column.name)} input={<Input name={column.name} id={column.name} />}>
            <MenuItem value={textLabels.all} key={0}>
              {column.name}
            </MenuItem>
            {filterData[index].map((filterValue, filterIndex) => (
              <MenuItem value={filterValue} key={filterIndex + 1}>
                {filterValue != null ? filterValue.toString() : ''}
              </MenuItem>
            ))}
          </Select>
        </div>
      </React.Fragment>
    );
  }

  renderTextField(column, index) {
    const { filterList } = this.props;

    return (
      <div className="filter__formControl">
        <div className="filter__formSelect" key={index}>
          <TextField placeholder={column.label} value={filterList[index].toString() || ''} onChange={(event) => this.handleTextFieldChange(event, index, column.name)} />
        </div>
      </div>
    );
  }

  renderMultiselect(column, index) {
    const { filterData, filterList } = this.props;

    return (
      <div className="filter__formControl" key={index}>
        <Select multiple className="filter__formSelect" value={filterList[index] || []} renderValue={(selected) => selected.join(', ')} name={column.name} onChange={(event) => this.handleMultiselectChange(index, event.target.value, column.name)} input={<Input name={column.name} id={column.name} />}>
          <MenuItem value={column.label} key={0}>
            {column.label}
          </MenuItem>
          {filterData[index].map((filterValue, filterIndex) => (
            <MenuItem value={filterValue} key={filterIndex + 1}>
              <Checkbox checked={filterList[index].indexOf(filterValue) >= 0 ? true : false} value={filterValue != null ? filterValue.toString() : ''} className="filter__checkboxIcon" classes={{ root: 'filter__checkbox', checked: 'filter__checked' }} />
              <div className="ListItemText">{filterValue}</div>
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderCustomField(column, index) {
    const { filterList, options } = this.props;
    const display = (column.filterOptions && column.filterOptions.display) || (options.filterOptions && options.filterOptions.display);

    if (!display) {
      console.error('Property "display" is required when using custom filter type.');
      return;
    }

    return <FormControl key={index}>{display(filterList, this.handleCustomChange, index, column)}</FormControl>;
  }

  render() {
    const { columns, options, onFilterReset } = this.props;
    const textLabels = options.textLabels.filter;
  
    return (
      <React.Fragment>
        {columns.map((column, index) => {
          if (column.filter) {
            const filterType = column.filterType || options.filterType;
            const key = `column-${index}`;
  
            return (
              <React.Fragment key={key}>
                {filterType === 'checkbox' && this.renderCheckbox(column, index)}
                {filterType === 'multiselect' && this.renderMultiselect(column, index)}
                {filterType === 'textField' && this.renderTextField(column, index)}
                {filterType === 'custom' && this.renderCustomField(column, index)}
                {filterType === 'select' && this.renderSelect(column, index)}
              </React.Fragment>
            );
          }
          return null; 
        })}
        <div className="filter__formControl no-padding background--transparent">
          <button className="btnColouredSmall" aria-label={textLabels.reset} onClick={onFilterReset}>
            {textLabels.reset}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

TableFilter.propTypes = {
  columns: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

export default TableFilter;
