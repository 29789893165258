import React, { Component } from "react";
import {
  ERROR_TO_NUMBERS_RECEIVED,
  ERROR_TO_NUMBERS_SAVED,
  fetchToNumbersConfig,
  saveToNumbersConfig,
  TO_NUMBERS_SAVED
} from "../../../actions/gsm";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS
} from "../../../actions/snackbar";
import { connect } from "react-redux";
import CardAlternative from "../../Card/CardAlternative";
import VisibilityToggle from "../../VisibilityToggle/VisibilityToggle";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Button } from "@operata/ui-components";
import Grid from "@material-ui/core/Grid";

let Numbers = class Numbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };

    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    await this.props.fetchToNumbersConfig();
    this.setInitialState(this.props.data);
    this.setState({ loaded: true });
  }

  setConfigInState(region, number) {
    let keyValuePairs = {};
    keyValuePairs[region] = number;
    this.setState(keyValuePairs);
  }

  setInitialState(data) {
    let keyValuePairs = {};
    data.map(field => {
      keyValuePairs[field.region] = field.number;
    });

    this.setState(keyValuePairs);
  }

  handleSave() {
    this.props.data.map(field => {
      if (
        this.state[field.region].length > 15 ||
        this.state[field.region].length < 10
      ) {
        this.props.showSnackbarMessage(
          SNACKBAR_ERROR,
          field.region + ` could not be set. Check the number length`
        );
        return;
      }
      this.props.saveToNumbersConfig({
        region: field.region,
        number: this.state[field.region]
      });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status === TO_NUMBERS_SAVED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Config has been set successfully."
      );
    }

    if (nextProps.status === ERROR_TO_NUMBERS_SAVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while setting the Config."
      );
    }

    if (nextProps.status === ERROR_TO_NUMBERS_RECEIVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving Configs."
      );
    }

    return prevState;
  }

  render() {
    const fields = this.props.data;
    let showSaveButton = false;
    return (
      <Grid container item xs={8} direction={"column"} spacing={2}>
        <Grid item xs={12}>
          <CardAlternative title="Connect numbers">
            <VisibilityToggle>
              Set the dial number for connect regions
            </VisibilityToggle>
          </CardAlternative>
        </Grid>
        <Grid container item spacing={2}>
          {fields.map((field, i) => {
            if (this.state.loaded) {
              const value = this.state[field.region];
              showSaveButton = showSaveButton || (value && value.length > 0);
              return (
                <Grid item xs={6}>
                  <TextField
                    key={field.region}
                    region={i}
                    label={field.region}
                    name={field.region}
                    className="textfield"
                    variant="outlined"
                    value={value}
                    onChange={event =>
                      this.setConfigInState(field.region, event.target.value)
                    }
                  />
                </Grid>
              );
            }
          })}
        </Grid>

        <div className="form__control form__control--buttons">
          {showSaveButton && (
            <Button
              asyncButton={true}
              buttonText={"Save"}
              className={"btnSolid btnSolid-no-margin"}
              onClick={this.handleSave}
            />
          )}
        </div>
      </Grid>
    );
  }
};

Numbers.propTypes = {
  data: PropTypes.array,
  fetchToNumbersConfig: PropTypes.func,
  saveToNumbersConfig: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string
};

const mapDispatchToProps = {
  saveToNumbersConfig,
  fetchToNumbersConfig,
  showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.gsm.numbers,
  loading: state.gsm.loading,
  status: state.gsm.status
});

Numbers = connect(
  mapStateToProps,
  mapDispatchToProps
)(Numbers);

export default Numbers;