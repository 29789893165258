import React from "react";
import PropTypes from "prop-types";

const NetworkBrowserDetails = props => {
    const browserName = props.browser && props.browser.name;
    const browserVersion = props.browser && props.browser.version;
    const browserData = props.browser && (browserName+ ' ' + browserVersion);
    const noData = "No data found"

    return (
        <div className="outerDiv-browserDetails">
            <div className="rowDiv-browserDetails">
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">ISP</span>
                    <span className="span-values-browserDetails">{props.isp || noData}</span>
                </div>
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">Browser</span>
                    <span className="span-values-browserDetails">{browserData || noData}</span>
                </div>
            </div>
            <div className="rowDiv-browserDetails">
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">LAN IP</span>
                    <span className="span-values-browserDetails">{props.lanIP || noData}</span>
                </div>
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">Country</span>
                    <span className="span-values-browserDetails">{props.country || noData}</span>
                </div>
            </div>
            <div className="rowDiv-browserDetails">
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">WAN IP</span>
                    <span className="span-values-browserDetails">{props.wanIP || noData}</span>
                </div>
                <div className="innerDev-browserDetails">
                    <span className="span-labels-browserDetails">Region</span>
                    <span className="span-values-browserDetails">{props.region || noData}</span>
                </div>
            </div>
        </div>
    )

}

NetworkBrowserDetails.propTypes = {
    isp: PropTypes.string,
    browser: PropTypes.object,
    lanIP: PropTypes.any,
    country: PropTypes.string,
    wanIP: PropTypes.any,
    region: PropTypes.string
}

export default NetworkBrowserDetails;