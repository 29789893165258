import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import intervalToDuration from 'date-fns/intervalToDuration';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import { Tabs, LinkTab, TabContainer } from '@operata/ui-components';
import NetworkPerformance from './NetworkPerformance';
import CustomerExperience from './CustomerExperience';
import AgentExperience from './AgentExperience';
import ServiceAvailability from './ServiceAvailability';
import { fetchJobsAverages } from '../../../actions/gsm';
import { useDispatch, useSelector } from 'react-redux';
import { getTimestampCriteria } from '../../../utils/datatables';
import InsightsScore from './InsightsScore';

const InsightsDetails = ({ filters, logs, initialTab = 0 }) => {
  const dispatch = useDispatch();
  const { currentPeriodStats, prevPeriodStats } = useSelector((state) => state.gsm);
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(
    () => {
      const timeCriteria = getTimestampCriteria(filters.timestamp);
      const regionsCriteria = {
        connectRegions: filters.connectRegions,
        agentRegions: filters.agentRegions,
      };
      const currentPeriodCriteria = Object.assign({}, timeCriteria, regionsCriteria);
      const prevPeriodCriteria = Object.assign({}, getPreviousPeriodTimestampCriteria(timeCriteria), regionsCriteria);

      dispatch(fetchJobsAverages(currentPeriodCriteria, prevPeriodCriteria));
    },
    [filters]
  );

  const getPreviousPeriodTimestampCriteria = (current) => {
    let fromTime;

    const lastXRegex = /now(-\d+[mhdwMy])/;
    const toDateRegex = /now\/([mhdwMy])/;
    if (lastXRegex.test(current.fromTime)) {
      const period = current.fromTime.match(lastXRegex)[1];
      fromTime = `${current.fromTime}${period}`;
    } else if (toDateRegex.test(current.fromTime)) {
      const period = current.fromTime.match(toDateRegex)[1];
      fromTime = `${current.fromTime}-1${period}`;
    } else {
      const duration = intervalToDuration({
        start: new Date(current.fromTime),
        end: new Date(current.toTime),
      });
      const newFrom = sub(new Date(current.fromTime), duration);
      fromTime = `${format(newFrom, "yyyy-MM-dd'T'HH:mm:ss")}`;
    }

    return {
      fromTime,
      toTime: current.fromTime,
      timezone: current.timezone,
    };
  };

  const handleTab = (event, tab) => {
    setSelectedTab(tab);
    //
  };

  return (
    <div className="insights__wrapper">
      <Tabs selectedTab={selectedTab} onChange={handleTab}>
        <LinkTab href="page1" icon={<InsightsScore value={currentPeriodStats ? currentPeriodStats.completedPercentage : 0} prevValue={prevPeriodStats ? prevPeriodStats.completedPercentage : 0} title="Telephony" description="Telephony is..." unit={'%'} />} />
        <LinkTab href="page2" icon={<InsightsScore title="CX Score" value={currentPeriodStats ? currentPeriodStats.average.cxScore : 0} prevValue={prevPeriodStats ? prevPeriodStats.average.cxScore : 0} description="CX Score is..." />} />
        <LinkTab href="page3" icon={<InsightsScore title="AX Score" value={currentPeriodStats ? currentPeriodStats.average.axScore : 0} prevValue={prevPeriodStats ? prevPeriodStats.average.axScore : 0} description="AX Score is..." />} />
        <LinkTab href="page4" icon={<InsightsScore title="Network Performance" value={currentPeriodStats ? currentPeriodStats.average.networkScore : 0} prevValue={prevPeriodStats ? prevPeriodStats.average.networkScore : 0} description="Network Performance is..." />} />
      </Tabs>
      <TabContainer selectedTab={selectedTab} index={0}>
        {selectedTab === 0 && <ServiceAvailability logs={logs} />}
      </TabContainer>
      <TabContainer selectedTab={selectedTab} index={1}>
        {selectedTab === 1 && <CustomerExperience logs={logs} />}
      </TabContainer>
      <TabContainer selectedTab={selectedTab} index={2}>
        {selectedTab === 2 && <AgentExperience logs={logs} />}
      </TabContainer>
      <TabContainer selectedTab={selectedTab} index={3}>
        {selectedTab === 3 && <NetworkPerformance logs={logs} />}
      </TabContainer>
    </div>
  );
};

export default InsightsDetails;

InsightsDetails.propTypes = {
  filters: PropTypes.object,
  initialTab: PropTypes.number,
  logs: PropTypes.array,
};
