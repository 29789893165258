import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { validate, asyncValidate } from "./validation";
import OpTextField from "../Generic/OpTextField";
import { fetchProfile, updateUserProfile } from "../../actions/users";
import { USER_UPDATED, ERROR_USER_CREATED } from "../../actions/users";
import {
  showSnackbarMessage,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR
} from "../../actions/snackbar";

import { auth } from "../../auth/Auth";
import PropTypes from "prop-types";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(
      this
    );
  }

  componentDidMount() {
    let id = auth.getUserProfile().id;
    if (id && id > 0) {
      this.props.fetchProfile();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === USER_UPDATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "User profile updated successfully."
      );
    } else if (nextProps.status === ERROR_USER_CREATED) {
      this.props.showSnackbarMessage(SNACKBAR_ERROR, nextProps.message);
    }
  }

  render() {
    let { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <div className="grid__container">
          <form
            className="grid__column grid__column--four"
            onSubmit={handleSubmit(this.props.updateUserProfile)}
          >
            <div className="form__control" style={{ justifyContent: "center" }}>
              <img alt={auth.getUserProfile().name} src={auth.getPicture()} />
            </div>
            <div className="form__control">
              <OpTextField
                className="textfield"
                name="name"
                label="Name"
                type="text"
                readOnly={true}
              />
            </div>

            <div className="form__control">
              <OpTextField
                className="textfield"
                name="email"
                label="Email"
                type="text"
                readOnly={true}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  fetchProfile: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string,
  message: PropTypes.string,
  handleSubmit: PropTypes.func,
  updateUserProfile: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool
};

const mapDispatchToProps = {
  fetchProfile: fetchProfile,
  updateUserProfile: updateUserProfile,
  showSnackbarMessage: showSnackbarMessage
};

export const mapStateToProps = state => ({
  initialValues: state.user.data,
  loading: state.user.loading,
  status: state.user.status,
  message: state.user.message
});

// eslint-disable-next-line no-class-assign
UserProfile = reduxForm({
  // eslint-disable-line no-class-assign
  form: "UserProfileForm",
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
  enableReinitialize: true
})(UserProfile);

// eslint-disable-next-line no-class-assign
UserProfile = connect(
  // eslint-disable-line no-class-assign
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

export default UserProfile;
