import React from "react";
import TestContext from "../TestContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import {
  AGENT_TYPE_STEP,
  HUMAN_AGENT,
  VIRTUAL_AGENT
} from "../../../../../constants/heartbeat";

export class StepAgentType extends React.Component {
  render() {
    return (
      <TestContext.Consumer>
        {({
          agentType,
          setAgentType,
          error_agentType,
          errorMessage_agentType
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="agentType-helper">
                      {errorMessage_agentType
                        ? errorMessage_agentType
                        : AGENT_TYPE_STEP}
                    </InputLabel>
                    <Select
                      name="agentType"
                      value={agentType}
                      variant="outlined"
                      id="outlined-required"
                      error={error_agentType}
                      onChange={event => setAgentType(event.target.value)}
                      input={
                        <OutlinedInput name="agentType" id="agentType-helper" />
                      }
                    >
                      <MenuItem value={HUMAN_AGENT}>Human</MenuItem>
                      <MenuItem value={VIRTUAL_AGENT}>Virtual</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          );
        }}
      </TestContext.Consumer>
    );
  }
}

export default StepAgentType;
