import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { auth } from "../../auth/Auth";
import {
  ActionStack,
  Button,
  Card,
  Carousel,
  Insight,
  Modal,
  Page,
  Section,
  Stack,
  TaskStack,
  Typography
} from "@operata/adagio";
import CanvasDashboard from "../CanvasDashboard/CanvasDashboard";
import {
  BarChartRounded,
  ChevronRightRounded,
  SchoolOutlined,
  Close,
  ChatBubbleOutline,
  PlayCircleOutline,
  OpenInNew
} from "@mui/icons-material";
import { fetchGroupDashboardDetails } from "../../actions/groups";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./index.scss";
import { useFeatureFlags } from "../../FeatureFlags";
import Garvis from "../Garvis";
import { activeBISession } from "../../actions/auth";
import "../Iframe/Iframe.scss";

let Home = ({ userProfile }) => {
  const dispatch = useDispatch();
  const { charts } = useSelector(state => state.homepage);
  const biAuthSuccessful = useSelector(
    state => state.canvas.biAuthSuccessful,
    shallowEqual
  );
  const selectedTimeFilter = "7d";
  const [isModalOpen, setModalOpen] = useState(false);

  const intervalRef = useRef(null);
  const attemptsRef = useRef(0);

  const [modalContent, setModalContent] = useState({
    header: "",
    content: ""
  });

  const currentGroupId = auth.getCurrentGroupId();
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  useEffect(
    () => {
      dispatch(fetchGroupDashboardDetails());

      intervalRef.current = setInterval(() => {
        attemptsRef.current += 1;
        dispatch(activeBISession());
      }, 1000 * 2);

      return () => {
        clearInterval(intervalRef.current);
      };
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (biAuthSuccessful || attemptsRef.current > 10) {
        clearInterval(intervalRef.current);
      }
    },
    [biAuthSuccessful]
  );

  const handleNavigate = url => {
    history.push(url);
  };

  const handleExternalNavigate = url => {
    window.open(url, "_blank");
  };

  const openModal = (header, content) => {
    setModalContent({ header, content });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const memorisedCanvasDashboard = useMemo(
    () => (
      <CanvasDashboard
        sliceId={charts?.["call_volume_v2"]?.toString()}
        key={"callVolumeV2"}
        timeFilter={selectedTimeFilter}
      />
    ),
    [charts, selectedTimeFilter]
  );

  return (
    <Page.Layout>
      <Page.SectionWithAside>
        <Page.SectionWithAside.Section>
          <Stack direction="col" gap="medium">
            {featureFlags.garvis && <Garvis />}
            <Section>
              <Section.Header title="Calls by Minimum Network Quality (7 Days)" />
              <Section.Content>
                <Insight>
                  <Insight.Chart>{memorisedCanvasDashboard}</Insight.Chart>
                  <Insight.Content>
                    <Stack direction="column" gap="8">
                      <Typography>
                        <p>
                          Unexpected peaks and troughs in call volume and
                          network quality can be indicators of operational or
                          technical issues.
                        </p>
                      </Typography>
                    </Stack>
                  </Insight.Content>
                  <Insight.Actions>
                    <ActionStack>
                      <ActionStack.Item
                        description="Explore Playbook 1 - Agent & Technology Overview"
                        icon={<BarChartRounded />}
                        actionIcon={<ChevronRightRounded />}
                        onClick={() =>
                          handleNavigate(`canvas/default_${currentGroupId}`)
                        }
                      />
                      <ActionStack.Item
                        description="Learn how trends in technical and operational performance are a signpost for deeper investigation."
                        icon={<SchoolOutlined />}
                        actionIcon={<OpenInNew />}
                        onClick={() =>
                          handleExternalNavigate(
                            "https://help.operata.com/en/articles/6230376-playbook-1-agent-and-technology-overview"
                          )
                        }
                      />
                    </ActionStack>
                  </Insight.Actions>
                </Insight>
              </Section.Content>
            </Section>

            <Section>
              <Section.Header title="Agent Reported Issues (7 Days)" />
              <Section.Content>
                <Insight>
                  <Insight.Chart>
                    {biAuthSuccessful && (
                      <CanvasDashboard
                        sliceId={charts?.[
                          "agent_reported_issues_v2"
                        ]?.toString()}
                        key={"agentReportedIssuesV2"}
                        timeFilter={selectedTimeFilter}
                      />
                    )}
                  </Insight.Chart>
                  <Insight.Content>
                    <Stack direction="column" gap="8">
                      <Typography>
                        <p>
                          Agent Reported Issues are great indicators of how well
                          your service is performing, Agent Reported Issues
                          provide insights into both individual agent issues
                          that need focus and trends that highlight more serious
                          problems.
                        </p>
                      </Typography>
                    </Stack>
                  </Insight.Content>
                  <Insight.Actions>
                    <ActionStack>
                      <ActionStack.Item
                        description="Explore Playbook 7 - Agent Reported Issue Investigation"
                        icon={<BarChartRounded />}
                        actionIcon={<ChevronRightRounded />}
                        onClick={() =>
                          handleNavigate(
                            `canvas/${currentGroupId}_Playbook7-AgentReportedIssueInvestigation`
                          )
                        }
                      />
                      <ActionStack.Item
                        description="Learn the likely cause of individual Agent Reported Issues and trends indicating broader problems."
                        icon={<SchoolOutlined />}
                        actionIcon={<OpenInNew />}
                        onClick={() =>
                          handleExternalNavigate(
                            "https://help.operata.com/en/articles/6431953-playbook-7-agent-reported-issue-investigation"
                          )
                        }
                      />
                    </ActionStack>
                  </Insight.Actions>
                </Insight>
              </Section.Content>
            </Section>

            <Section>
              <Section.Header title="Softphone Errors (7 Days)" />
              <Section.Content>
                <Insight>
                  <Insight.Chart>
                    {biAuthSuccessful && (
                      <CanvasDashboard
                        sliceId={charts?.["softphone_errors_v2"]?.toString()}
                        key={"softphoneErrorsV2"}
                        timeFilter={selectedTimeFilter}
                      />
                    )}
                  </Insight.Chart>
                  <Insight.Content>
                    <Stack direction="column" gap="8">
                      <Typography>
                        <p>
                          Softphone errors are generated whenever a contact is
                          unable to be connected to an agent, these are good
                          indicators of underlying problems with network
                          connectivity, machine performance, application
                          problems or even CCaaS issues.
                        </p>
                      </Typography>
                    </Stack>
                  </Insight.Content>
                  <Insight.Actions>
                    <ActionStack>
                      <ActionStack.Item
                        description="Explore Playbook 5 - Errors and Logs"
                        icon={<BarChartRounded />}
                        actionIcon={<ChevronRightRounded />}
                        onClick={() =>
                          handleNavigate(
                            `canvas/${currentGroupId}_Playbook5-SoftphoneErrorsandLogs`
                          )
                        }
                      />
                      <ActionStack.Item
                        description="Learn how softphone errors point to both individual agent issues or broader service problems."
                        icon={<SchoolOutlined />}
                        actionIcon={<OpenInNew />}
                        onClick={() =>
                          handleExternalNavigate(
                            "https://help.operata.com/en/articles/6230389-playbook-5-errors-and-logs"
                          )
                        }
                      />
                    </ActionStack>
                  </Insight.Actions>
                </Insight>
              </Section.Content>
            </Section>

            <Section>
              <Section.Header title="Agent Alerts (7 Days)" />
              <Section.Content>
                <Insight>
                  <Insight.Chart>
                    {biAuthSuccessful && (
                      <CanvasDashboard
                        sliceId={charts?.["agent_notifications_v2"]?.toString()}
                        key={"agentNotificationsV2"}
                        timeFilter={selectedTimeFilter}
                      />
                    )}
                  </Insight.Chart>
                  <Insight.Content>
                    <Stack direction="column" gap="8">
                      <Typography>
                        <p>
                          AX Copilot notifies agents where there are Customer
                          Experience issues, for example they are not using a
                          headset, calls ended while on hold or where network/PC
                          issues may have caused problems.
                        </p>
                      </Typography>
                    </Stack>
                  </Insight.Content>
                  <Insight.Actions>
                    <ActionStack>
                      <ActionStack.Item
                        description="Explore Playbook 2 - Events / Alerts"
                        icon={<BarChartRounded />}
                        actionIcon={<ChevronRightRounded />}
                        onClick={() =>
                          handleNavigate(
                            `canvas/${currentGroupId}_Playbook2-AgentInsights`
                          )
                        }
                      />
                      <ActionStack.Item
                        description="Learn how agent Event trends can focus both training and technology improvements."
                        icon={<SchoolOutlined />}
                        actionIcon={<OpenInNew />}
                        onClick={() =>
                          handleExternalNavigate(
                            "https://help.operata.com/en/articles/6230378-playbook-2-agent-insights"
                          )
                        }
                      />
                    </ActionStack>
                  </Insight.Actions>
                </Insight>
              </Section.Content>
            </Section>
          </Stack>
        </Page.SectionWithAside.Section>

        <Page.SectionWithAside.Aside>
          <Stack direction="col" gap="medium">
            <Section>
              <Section.Content>
                <Typography>
                  <h3>Hi {userProfile.name}</h3>
                  <p>
                    Contact us to enable our new AX Copilot features, more
                    details here.{" "}
                    <a href="https://help.operata.com/en/articles/8936224-ax-copilot">
                      AX Copilot | Help Center
                    </a>
                  </p>
                </Typography>
              </Section.Content>
              <Section.Footer>
                <Stack direction="row" gap="8">
                  <Button
                    className="support"
                    type="secondary"
                    size="medium"
                    onClick={() =>
                      window.open(
                        "https://operata.typeform.com/to/drBxt3f3",
                        "_blank"
                      )
                    }
                  >
                    <Button.Icon>
                      <ChatBubbleOutline />{" "}
                    </Button.Icon>
                    <Button.Content>Share your feedback</Button.Content>
                  </Button>
                </Stack>
              </Section.Footer>
            </Section>
            <Section>
              <Section.Header
                title="Explore Operata"
                description="Learn the basics, and access handy tips & resources"
              />
              <Section.Content>
                <TaskStack>
                  <TaskStack.Item
                    title="User guides"
                    description="How to use Operata and explore the data elements we collect in detail."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate(
                        "https://help.operata.com/en/collections/2146543-user-guides"
                      )
                    }
                  />
                  <TaskStack.Item
                    title="FAQs"
                    description="Commonly asked questions, hints and tips to get the most out of Operata."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate(
                        "https://help.operata.com/en/collections/1988587-faq"
                      )
                    }
                  />
                  <TaskStack.Item
                    title="Learn about agent investigation"
                    description="Find out how you can analyse agent technical performance and activity by to troubleshoot issues."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate(
                        "https://help.operata.com/en/articles/6230386-playbook-4-agent-investigation"
                      )
                    }
                  />
                  <TaskStack.Item
                    title="Agent reported issues"
                    description="Explore agent reports on service performance issues, and surface specific areas needing improvement."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate(
                        "https://help.operata.com/en/articles/6431953-playbook-7-agent-reported-issue-investigation"
                      )
                    }
                  />
                  <TaskStack.Item
                    title="Setup a workflow"
                    description="Connect Operata to your systems. Send an email, push a webHook, generate an EventBridge notification or even send a Slack message."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate(
                        "https://help.operata.com/en/articles/3721528-workflow"
                      )
                    }
                  />
                  <TaskStack.Item
                    title="Help Center"
                    description="Check out our articles with frequently asked questions, tips and tricks."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate("https://help.operata.com/en/")
                    }
                  />
                  <TaskStack.Item
                    title="Operata Documentation"
                    description="Build upon your Operata Knowledge by reading our documentation."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate("https://docs.operata.com/docs")
                    }
                  />
                  <TaskStack.Item
                    title="Recent Articles"
                    description="Read more on our latest improvements, product releases and contact center insights over on our Blog."
                    icon={<OpenInNew />}
                    onClick={() =>
                      handleExternalNavigate("https://operata.com/blog")
                    }
                  />
                </TaskStack>
              </Section.Content>
            </Section>
            <Section>
              <Section.Header
                title="Video Resources"
                description="Learn more about how to use Operata to its full potential"
              />
              <Section.Content>
                <Carousel>
                  <Card
                    onClick={() =>
                      openModal(
                        "1.1 Basic Navigation",
                        <iframe
                          src="https://player.vimeo.com/video/827908960?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="1.1 Basic Navigation"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/1-1.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="1.1 Basic Navigation" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "1.3 Workflow",
                        <iframe
                          src="https://player.vimeo.com/video/827906577?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="1.3 Workflow"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/1-3.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="1.3 Workflow" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "1.4 History",
                        <iframe
                          src="https://player.vimeo.com/video/830469041?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="1.4 History"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/1-4.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="1.4 History" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "2.0 Canvas - Basic Navigation",
                        <iframe
                          src="https://player.vimeo.com/video/837820929?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="2.0 Canvas - Basic Navigation"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/2-0.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="2.0 Canvas - Basic Navigation" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "2.1 - Agent and Technology Overview",
                        <iframe
                          src="https://player.vimeo.com/video/837822498?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="2.1 - Agent and Technology Overview"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/2-1.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="2.1 - Agent and Technology Overview" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "3.1 Heartbeat explained",
                        <iframe
                          src="https://player.vimeo.com/video/830457520?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="3.1 Heartbeat explained"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/3-1.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="3.1 Heartbeat explained" />
                  </Card>

                  <Card
                    onClick={() =>
                      openModal(
                        "4.0 Simulate",
                        <iframe
                          src="https://player.vimeo.com/video/830935947?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          width="960"
                          height="540"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="4.0 Simulate"
                        />
                      )
                    }
                  >
                    <Card.Thumbnail
                      image="https://static.operata.io/operata-assets/video-training-thumbnails/4-0.png"
                      icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                    />
                    <Card.Content title="4.0 Simulate" />
                  </Card>
                </Carousel>
              </Section.Content>
            </Section>
          </Stack>
        </Page.SectionWithAside.Aside>
      </Page.SectionWithAside>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size="medium"
        closeIcon={<Close />}
      >
        <Modal.Header>{modalContent.header}</Modal.Header>
        <Modal.Content>{modalContent.content}</Modal.Content>
      </Modal>
    </Page.Layout>
  );
};

Home.propTypes = {
  selectedTimeFilter: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired
};

Home.defaultProps = {
  selectedTimeFilter: "7d"
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  userProfile: state.auth.profile
});

// eslint-disable-next-line no-class-assign
Home = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

// eslint-disable-next-line no-class-assign
Home = withRouter(Home);

export default Home;
