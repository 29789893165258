import {
  AGENT_CCP_LOGS_RECEIVED,
  ERROR_AGENT_CCP_LOGS_RETRIEVAL
} from "../actions/agent";

const agent = (
  state = {
    loading: false,
    ccpLogs: [],
    ccpLogsRecCount: 0
  },
  action
) => {
  switch (action.type) {
    case AGENT_CCP_LOGS_RECEIVED:
      return Object.assign({}, state, {
        ccpLogs: action.logs,
        ccpLogsRecCount: action.recordCount,
        loading: false,
        status: AGENT_CCP_LOGS_RECEIVED
      });
    case ERROR_AGENT_CCP_LOGS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_AGENT_CCP_LOGS_RETRIEVAL
      });
    default:
      return state;
  }
};

export default agent;
