import {
  USER_RECEIVED,
  USER_CREATED,
  USER_UPDATED,
  ERROR_USER_CREATED,
  RESET_USER_FORM_STATE
} from "../actions/users";

const users = (state = { loading: false }, action) => {
  switch (action.type) {
    case USER_RECEIVED: {
      let user = action.user;
      user["group"] = user.groups[0].name;
      return Object.assign({}, state, {
        data: user,
        loading: false,
        status: USER_RECEIVED
      });
    }
    case USER_CREATED:
      return Object.assign({}, state, {
        data: action.user,
        loading: false,
        status: USER_CREATED
      });
    case ERROR_USER_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_USER_CREATED,
        message: action.message
      });
    case RESET_USER_FORM_STATE:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_USER_FORM_STATE
      });
    case USER_UPDATED:
      return Object.assign({}, state, {
        data: action.user,
        loading: false,
        status: USER_UPDATED
      });
    default:
      return Object.assign({}, state);
  }
};

export default users;
