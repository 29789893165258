import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Filter, MUIDataTable } from '@operata/ui-components';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const InsightsTable = ({ logs, tags = [], status = [], columns, dataToColumn }) => {
  const [filters, setFilters] = useState({});

  const handleFilterUpdate = (index, value, name) => {
    setFilters((prevFilters) => {
      return Object.assign({}, prevFilters, {
        [name]: value,
      });
    });
  };

  const removeCountFromFilterName = (filter) => {
    const split = filter.split(' ');
    split.pop();
    return split.join(' ');
  };

  const getFilteredData = () => {
    if (filters.options && filters.options.length > 0) {
      const filterOptionsWithoutCounts = filters.options.map(removeCountFromFilterName);

      const matchesWithTags = logs.filter((data) => {
        return data.tags && data.tags.filter((tag) => filterOptionsWithoutCounts.indexOf(tag) >= 0).length > 0;
      });
      const matchesWithStatus = logs.filter((data) => filterOptionsWithoutCounts.indexOf(data.status) >= 0);

      return matchesWithTags.concat(matchesWithStatus);
    }
    return logs;
  };

  const getTagsCount = (tag) => {
    return logs.filter((data) => data.tags && data.tags.indexOf(tag) >= 0).length;
  };

  const getStatusCount = (status) => {
    return logs.filter((data) => data.status === status).length;
  };

  const getFilterOptions = () => {
    const tagFilters = tags.map((tag) => {
      const count = getTagsCount(tag);
      return `${tag} (${count})`;
    });
    const statusFilters = status.map((s) => {
      const count = getStatusCount(s);
      return `${s} (${count})`;
    });
    console.log('Status Filters', statusFilters);
    return tagFilters.concat(statusFilters);
  };

  const resultOptions = {
    filter: false,
    search: false,
    selectableRows: 'none',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 50],
    expandableRows: true,
    renderExpandableRow: (rowData) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>Data: {JSON.stringify(rowData)}</TableCell>
        </TableRow>
      );
    },
  };

  return (
    <div className="insights__filtertables">
      <Filter
        filters={[
          {
            type: 'checkbox',
            label: 'Options',
            name: 'options',
            options: getFilterOptions(),
          },
        ]}
        onFilterUpdate={handleFilterUpdate}
        showReset={false}
      />

      <MUIDataTable data={getFilteredData().map(dataToColumn)} columns={columns} options={resultOptions} />
    </div>
  );
};

InsightsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dataToColumn: PropTypes.func,
  globalFilters: PropTypes.object,
  logs: PropTypes.array,
  status: PropTypes.array,
  tags: PropTypes.array,
};

InsightsTable.displayName = 'InsightsTable';

export default InsightsTable;
