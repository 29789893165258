import { axiosProxy } from "../axios/AxiosProxy";
import axios from "axios";
import { SHOW_PROGRESS } from "./progress";

export const CAMPAIGNS_RECEIVED = "CAMPAIGNS_RECEIVED";
export const CAMPAIGN_RECEIVED = "CAMPAIGN_RECEIVED";
export const CAMPAIGNS_RUNS_RECEIVED = "CAMPAIGNS_RUNS_RECEIVED";
export const CAMPAIGNS_RUN_RECEIVED = "CAMPAIGNS_RUN_RECEIVED";
export const CAMPAIGNS_CALL_LOG_RECEIVED = "CAMPAIGNS_CALL_LOG_RECEIVED";
export const CAMPAIGN_TOGGLED = "CAMPAIGN_TOGGLED";
export const CAMPAIGN_STOPPED = "CAMPAIGN_STOPPED";
export const CAMPAIGN_CREATED = "CAMPAIGN_CREATED";
export const ERROR_CAMPAIGN_CREATED = "ERROR_CAMPAIGN_CREATED";
export const CAMPAIGN_STARTED = "CAMPAIGN_STARTED";
export const ERROR_CAMPAIGN_STARTED = "ERROR_CAMPAIGN_STARTED";
export const RESET_CAMPAIGN_PAGE_STATE = "RESET_CAMPAIGN_PAGE_STATE";
export const CAMPAIGN_UPDATED = "CAMPAIGN_UPDATED";
export const CAMPAIGN_REMOVED = "CAMPAIGN_REMOVED";
export const ERROR_CAMPAIGN_REMOVED = "ERROR_CAMPAIGN_REMOVED";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const NEW_CAMPAIGN = "NEW_CAMPAIGN";

export const openNewCampaign = () => ({
  type: NEW_CAMPAIGN
});

export const openEditCampaign = data => ({
  type: EDIT_CAMPAIGN,
  data: data
});

export const campaignsCampaignRequested = () => ({
  type: SHOW_PROGRESS
});

export const campaignsReceived = campaigns => ({
  type: CAMPAIGNS_RECEIVED,
  campaigns: campaigns
});

export const campaignReceived = campaign => ({
  type: CAMPAIGN_RECEIVED,
  campaign: campaign
});

export const campaignsRunsReceived = campaignRuns => ({
  type: CAMPAIGNS_RUNS_RECEIVED,
  campaignRuns: campaignRuns
});

export const campaignsRunReceived = campaignRun => ({
  type: CAMPAIGNS_RUN_RECEIVED,
  campaignRun: campaignRun
});

export const callLogReceived = (callLog, combinedRunData) => ({
  type: CAMPAIGNS_CALL_LOG_RECEIVED,
  callLog: callLog,
  campaignRun: combinedRunData
});

export const campaignStopped = (campaignId, runId) => ({
  type: CAMPAIGN_STOPPED,
  campaignId,
  runId
});

export const campaignCreated = campaign => ({
  type: CAMPAIGN_CREATED,
  campaign: campaign
});

export const errorCampaignCreated = message => ({
  type: ERROR_CAMPAIGN_CREATED,
  message: message
});

export const campaignStarted = run => ({
  type: CAMPAIGN_STARTED,
  run: run
});

export const errorCampaignStarted = message => ({
  type: ERROR_CAMPAIGN_STARTED,
  message: message
});

export const resetState = () => ({
  type: RESET_CAMPAIGN_PAGE_STATE
});

export const campaignUpdated = campaign => ({
  type: CAMPAIGN_UPDATED,
  campaign: campaign
});

export const campaignRemoved = id => ({
  type: CAMPAIGN_REMOVED,
  id: id
});

export const errorCampaignRemoved = () => ({
  type: ERROR_CAMPAIGN_REMOVED
});

//redux-thunk
export const resetCampaignsPageState = () => {
  return dispatch => {
    dispatch(resetState());
  };
};

export const editCampaign = data => {
  return dispatch => {
    console.log("dispatching edit campaign ..");
    dispatch(openEditCampaign(data));
  };
};

export const newCampaign = () => {
  return dispatch => {
    console.log("dispatching new campaign ..");
    dispatch(openNewCampaign());
  };
};

export const fetchCampaigns = () => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    dispatch(campaignsCampaignRequested());
    return getCampaigns().then(resp => {
      console.log("dispatching campaignsReceived..");
      dispatch(campaignsReceived(resp.data));
    });
  };
};

export const fetchCampaign = campaignId => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    dispatch(campaignsCampaignRequested());
    return getCampaign(campaignId).then(resp => {
      console.log("dispatching campaignReceived..");

      let campaign = resp.data;
      if (campaign && campaign.DataURL) {
        const csvURL = campaign.DataURL.URL;
        getCSV(csvURL)
          .then(csvResp => {
            campaign["data"] = csvToJSON(csvResp.data);
            dispatch(campaignReceived(campaign));
          })
          .catch(() => {
            dispatch(campaignReceived(campaign));
          });
      } else {
        dispatch(campaignReceived(campaign));
      }
    });
  };
};

const csvToJSON = csv => {
  const lines = csv.split("\n");
  const result = [];
  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    if (!lines[i]) continue;
    const obj = {};
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }
  return result;
};

export const fetchCampaignRuns = campaignId => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    dispatch(campaignsCampaignRequested());
    return getCampaignRuns(campaignId).then(resp => {
      console.log("dispatching campaignRunsReceived..");
      dispatch(campaignsRunsReceived(resp.data));
    });
  };
};

export const fetchCampaignRun = (campaignId, campaignRunId) => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    //dispatch(campaignsCampaignRequested());
    return getCampaignRun(campaignId, campaignRunId).then(resp => {
      const run = resp.data;
      return getCampaign(campaignId).then(resp => {
        let retObj = mergeCampaignAndRunData(resp, run);
        dispatch(campaignsRunReceived(retObj));
        console.log("dispatching campaignRunsReceived..");
      });
    });
  };
};

export const fetchCallLog = (campaignId, runId) => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    //dispatch(campaignsCampaignRequested());
    return getCampaignRun(campaignId, runId).then(resp => {
      const run = resp.data;
      return getCampaign(campaignId).then(resp => {
        console.log("dispatching campaignRunsReceived..");
        let combinedRunData = mergeCampaignAndRunData(resp, run);
        return getCallLog(campaignId, runId).then(resp => {
          console.log("dispatching call log received..");
          dispatch(callLogReceived(resp.data, combinedRunData));
        });
      });
    });
  };
};

export const deleteCampaign = campaignId => {
  return dispatch => {
    console.log("dispatching campaigns action requested..");
    dispatch(campaignsCampaignRequested());
    return deleteCampaignHandler(campaignId)
      .then(() => {
        console.log("dispatching delete campaign..");
        dispatch(campaignRemoved(campaignId));
      })
      .catch(() => {
        dispatch(
          errorCampaignRemoved("An error occurred while deleting the campaign")
        );
        dispatch(resetState());
      });
  };
};

export const stopCampaign = (campaignId, runId) => {
  return dispatch => {
    console.log("dispatching stop campaign action..");
    dispatch(campaignsCampaignRequested());
    return stopCampaignHandler(campaignId, runId).then(() => {
      console.log("dispatching stop campaign complete..");
      dispatch(campaignStopped(campaignId, runId));
    });
  };
};

const getCampaign = campaignId => {
  return axiosProxy.getInstance().get("/campaigns/" + campaignId);
};

const getCSV = url => {
  return axios.get(url);
};

const getCampaigns = () => {
  return axiosProxy.getInstance().get("/campaigns");
};

const getCallLog = (campaignId, runId) => {
  return axiosProxy
    .getInstance()
    .get("/campaigns/" + campaignId + "/runs/" + runId + "/graph");
};

const getCampaignRuns = campaignId => {
  return axiosProxy.getInstance().get("/campaigns/" + campaignId + "/runs");
};

const getCampaignRun = (campaignId, runId) => {
  return axiosProxy
    .getInstance()
    .get("/campaigns/" + campaignId + "/runs/" + runId);
};

const deleteCampaignHandler = campaignId => {
  return axiosProxy.getInstance().delete("/campaigns/" + campaignId);
};

export const createOrUpdateCampaign = campaign => {
  return dispatch => {
    console.log(
      "dispatching campaign campaign requested..",
      JSON.stringify(campaign)
    );
    dispatch(campaignsCampaignRequested());

    console.log("create campaign");
    return createOrUpdateCampaignHandler(campaign)
      .then(resp => {
        console.log("dispatching campaign created..");
        dispatch(campaignCreated(resp.data));
      })
      .catch(error => {
        if (error.response && error.response.status == 400) {
          dispatch(errorCampaignCreated(error.response.data));
          dispatch(resetState());
        } else {
          dispatch(
            errorCampaignCreated(
              "An error occurred while creating the campaign"
            )
          );
          dispatch(resetState());
        }
      });
  };
};

const createOrUpdateCampaignHandler = campaign => {
  return axiosProxy.getInstance().post("/campaigns", campaign, {
    responseType: "text/plain",
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const startCampaign = run => {
  return dispatch => {
    console.log("dispatching start campaign requested..", JSON.stringify(run));
    dispatch(campaignsCampaignRequested());

    console.log("start campaign");
    return startCampaignHandler(run)
      .then(resp => {
        console.log("dispatching campaign started..");
        dispatch(campaignStarted(resp.data));
      })
      .catch(() => {
        dispatch(
          errorCampaignStarted("An error occurred while creating the campaign")
        );
        dispatch(resetState());
      });
  };
};

const startCampaignHandler = run => {
  return axiosProxy
    .getInstance()
    .post("/campaigns/" + run.campaignId + "/runs/start", run);
};

const stopCampaignHandler = (campaignId, runId) => {
  return axiosProxy
    .getInstance()
    .delete("/campaigns/" + campaignId + "/runs/" + runId);
};
function mergeCampaignAndRunData(resp, run) {
  let retObj = resp.data;
  retObj["runDescription"] = run.description;
  retObj["runTags"] = run.tags;
  retObj["createdOn"] = run.createdOn;
  retObj["isComplete"] = run.isComplete;
  return retObj;
}
