import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchMarketplaceData } from "../../actions/billing";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./style.scss";

let Marketplace = ({
    fetchMarketplaceData,
    marketplaceData,
  }) => {

    useEffect(
        () => {
          fetchMarketplaceData();
        },
        [fetchMarketplaceData]
      );

    const marketplaceMinutes = (minuteData) => {
        return minuteData.map((data, i) => {
            return (
                    <tr key={"minutes"+i}>
                        <td>{data.month}</td>
                        <td>{data.minutes}</td>
                    </tr>
            )
        } )

    } 
      
    return (
            <>
                {marketplaceData.map ((data, i) => {
                    return (
                    <Accordion key={"customer"+i} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <table>
                            <thead>
                                <tr>
                                    <td>Customer Name</td>
                                    <td>Customer ID</td>
                                    <td>Customer Code</td>
                                    <td>Included Minutes</td>
                                    <td>Billing Minutes Type</td>
                                    <td>Subscription start</td>
                                    <td>Subscription end</td>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.customerName}</td>
                                        <td>{data.customerID}</td>
                                        <td>{data.customerCode}</td>
                                        <td>{data.includedAgentMins}</td>
                                        <td>{data.billingMinutesType}</td>
                                        <td>{data.subscriptionStart}</td>
                                        <td>{data.subscriptionEnd}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </AccordionSummary>
                        <AccordionDetails>
                            <table>
                                <thead>
                                    <tr>
                                    <td>Month</td>
                                    <td>Billed Minutes</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {marketplaceMinutes(data.monthMinutes)}
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>)
                })
                }
            </>
        );
  };
  Marketplace.propTypes = {
    fetchMarketplaceData: PropTypes.func,
    marketplaceData: PropTypes.array,
    loading: PropTypes.bool.isRequired
  };
  
  const mapDispatchToProps = {
      fetchMarketplaceData: fetchMarketplaceData
  };
  
  const mapStateToProps = state => ({
      marketplaceData: state.billing.marketplaceData,
      loading: state.progress.loading
  });
  
  // eslint-disable-next-line no-class-assign
  Marketplace = connect(
    mapStateToProps,
    mapDispatchToProps
  )(Marketplace);
  
  export default Marketplace;