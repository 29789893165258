import React from "react";
import MUIDataTable from "../Datatables";
import Modal from "../Modal/Modal";
import CampaignCreateWizard from "./CreateCampaign/CampaignCreateWizard";
import Pagination from "../Pagination/Pagination";
import Chip from "../Chips/Chip";
import { connect } from "react-redux";
import "./Campaigns.scss";
import "../ActionBar/ActionBar.scss";
import {
  fetchCampaigns,
  deleteCampaign,
  editCampaign,
  CAMPAIGN_CREATED,
  CAMPAIGN_REMOVED,
  EDIT_CAMPAIGN,
  NEW_CAMPAIGN,
  newCampaign,
  resetCampaignsPageState,
  CAMPAIGN_STARTED,
  ERROR_CAMPAIGN_STARTED,
  ERROR_CAMPAIGN_REMOVED
} from "../../actions/campaign";
import { showConfirmationDialog } from "../../actions/dialog";
import {
  showSnackbarMessage,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR
} from "../../actions/snackbar";
import { AVAILABLE_CAMPAIGN_TYPES } from "./CreateCampaign/WizardConstants";
import { NavLink, withRouter } from "react-router-dom";
import RouteModal from "../Modal/RouteModal";
import CampaignRunWizard from "./RunCampaign/CampaignRunWizard";
import { formatDate, sortDate } from "../../utils/date";
import PropTypes from "prop-types";
import { prefixedPath } from "../../utils/deeplinks";

const TYPE_COLUMN = 1;
export const NAME_COLUMN = 2;
export const LAST_RUN_COLUMN = 5;

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelOpen: false,
      modalTitle: ""
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleNewCampaign = this.handleNewCampaign.bind(this);
    this.handleCampaignToggle = this.handleCampaignToggle.bind(this);
    this.handleCancelRemoveCampaign = this.handleCancelRemoveCampaign.bind(
      this
    );
    this.handleRemoveCampaign = this.handleRemoveCampaign.bind(this);
    this.handleEditCampaign = this.handleEditCampaign.bind(this);
    this.componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(
      this
    );
  }

  componentDidMount() {
    this.props.fetchCampaigns();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;
    if (nextProps.status === CAMPAIGN_CREATED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Campaign created successfully.");
      this.setState({ modelOpen: false });
      this.props.fetchCampaigns();
    } else if (nextProps.status === CAMPAIGN_STARTED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Campaign started successfully.");
      this.props.history.push(
        prefixedPath(
          "/campaign/view/" + nextProps.run.campaignId + "/" + nextProps.run.id
        )
      );
    } else if (nextProps.status === ERROR_CAMPAIGN_STARTED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while starting the Campaign."
      );
      this.setState({ modelOpen: false });
    } else if (nextProps.status === ERROR_CAMPAIGN_REMOVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while deleting the Campaign."
      );
    } else if (nextProps.status === CAMPAIGN_REMOVED) {
      this.props.fetchCampaigns();
    } else if (nextProps.status === EDIT_CAMPAIGN) {
      this.setState({ modelOpen: true, modalTitle: "Edit Campaign" });
    } else if (nextProps.status === NEW_CAMPAIGN) {
      this.setState({ modelOpen: true, modalTitle: "Create New Campaign" });
    }
  }

  handleCampaignToggle(watchId, enabled) {
    let { toggleCampaign, revertToggle, showConfirmationDialog } = this.props;
    let dialogTitlePrefix = enabled ? "Disable" : "Enable";
    showConfirmationDialog(
      dialogTitlePrefix + " Campaign",
      "Are you sure you want to " +
        dialogTitlePrefix.toLowerCase() +
        " this action?",
      toggleCampaign,
      [watchId, enabled],
      revertToggle,
      []
    );
  }

  handleCancelRemoveCampaign() {
    //do nothing
  }

  handleRemoveCampaign(event, campaignId) {
    let { deleteCampaign, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Delete Campaign",
      "Are you sure you want to delete this Campaign?",
      deleteCampaign,
      [campaignId],
      this.handleCancelRemoveCampaign,
      []
    );

    event.preventDefault();
  }

  handleEditCampaign(event, data) {
    let { editCampaign } = this.props;
    editCampaign(data);
    event.preventDefault();
  }

  handleNewCampaign() {
    this.props.newCampaign();
  }

  handleCloseModal() {
    this.setState({ modelOpen: false });
    this.props.resetCampaignsPageState();
  }

  render() {
    const columns = [
      {
        name: "ID",
        label: "ID",
        options: {
          display: false,
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Type",
        label: "Type",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return (
              <div className="campaign-journey">
                <span className="campaign-journey__icon">
                  <img src={value} alt="icon" />
                </span>
              </div>
            );
          }
        }
      },
      {
        name: "Name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return (
              <div className="campaign-description">
                <div className="textfield-bold">{value.name}</div>
                <div className="textfield-description">{value.description}</div>
                {/* <div className="textfield-comment">{executeOn}</div>  */}
              </div>
            );
          }
        }
      },
      {
        name: "Tags",
        label: "Tags",
        options: {
          filter: true,
          customFilterListRender: value => `Tag: ${value}`,
          sort: true,
          customBodyRender: tags => {
            let chips = [];
            if (tags) {
              tags.forEach(tag => {
                chips.push(<Chip label={tag} key={tag} />);
              });
            }
            return chips;
          }
        }
      },
      {
        name: "Volume",
        label: "Volume",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Last Run",
        label: "Last Run",
        options: {
          filter: false,
          sort: true,
          sortDirection: "desc",
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Runs",
        label: "Runs",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Actions",
        options: {
          filter: false,
          download: false,
          customBodyRender: item => {
            return (
              <React.Fragment>
                <NavLink
                  className="textfield-bold actions__link"
                  to={this.props.match.url + "/start/" + item.id}
                  data={item}
                >
                  Run
                </NavLink>

                <span
                  className="textfield-bold actions__link"
                  onClick={event => {
                    this.handleEditCampaign(event, item);
                  }}
                >
                  Copy
                </span>

                {item.runCount > 0 && (
                  <NavLink
                    className="textfield-bold actions__link"
                    to={"/campaign/history/" + item.id}
                  >
                    History
                  </NavLink>
                )}

                {item.runCount <= 0 && (
                  <span
                    className="textfield-bold actions__link"
                    onClick={event => {
                      this.handleRemoveCampaign(event, item.id);
                    }}
                  >
                    Delete
                  </span>
                )}
              </React.Fragment>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: true,
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      searchText: "",
      customSearch: (searchText, row) => {
        const campaignName = row[NAME_COLUMN].name.toLowerCase();
        return campaignName.indexOf(searchText.toLowerCase()) >= 0;
      },
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          const aData =
            a.data[colIndex] === null || typeof a.data[colIndex] === "undefined"
              ? ""
              : a.data[colIndex];
          const bData =
            b.data[colIndex] === null || typeof b.data[colIndex] === "undefined"
              ? ""
              : b.data[colIndex];

          if (colIndex == NAME_COLUMN) {
            return (
              aData.name.localeCompare(bData.name) * (order === "asc" ? 1 : -1)
            );
          }
          if (colIndex == LAST_RUN_COLUMN) {
            return sortDate(aData, bData) * (order === "desc" ? 1 : -1);
          }
          return (
            (typeof aData.localeCompare === "function"
              ? aData.localeCompare(bData)
              : aData - bData) * (order === "asc" ? 1 : -1)
          );
        });
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const header = buildHead(columns);
        const body = data.map(item => {
          item.data[TYPE_COLUMN] = item.data[item.data.length - 1].type;
          item.data[NAME_COLUMN] = item.data[NAME_COLUMN].name;
          return item;
        });
        return `${header}${buildBody(body)}`.trim();
      }
    };

    return (
      <React.Fragment>
        <Modal
          className="button button--right"
          buttonText={"Create Campaign"}
          onButtonClick={this.handleNewCampaign}
          title={this.state.modalTitle}
          isModalOpen={this.state.modelOpen}
          onCloseModal={this.handleCloseModal}
          disableBackdropClick={true}
        >
          <CampaignCreateWizard
            handleClose={this.handleClose}
            open={this.state.open}
            data={this.props.campaignData}
          />
        </Modal>

        <MUIDataTable
          data={this.props.data.map(item => {
            return [
              item.id,
              AVAILABLE_CAMPAIGN_TYPES[item.type].icon,
              { name: item.name, description: item.description },
              item.tags,
              item.totalCalls,
              formatDate(item.lastExecutionTime),
              item.runCount,
              item
            ];
          })}
          columns={columns}
          options={options}
        />
        <RouteModal
          title={"Run Campaign"}
          size={"large"}
          {...this.props}
          path={`${this.props.match.url}/start/:campaignId`}
        >
          <CampaignRunWizard {...this.props} />
        </RouteModal>
      </React.Fragment>
    );
  }
}

Campaigns.propTypes = {
  fetchCampaigns: PropTypes.func,
  deleteCampaign: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  editCampaign: PropTypes.func,
  newCampaign: PropTypes.func,
  resetCampaignsPageState: PropTypes.func,
  toggleCampaign: PropTypes.func,
  revertToggle: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  status: PropTypes.string,
  campaignData: PropTypes.object,
  run: PropTypes.object,
  history: PropTypes.array,
  match: PropTypes.object
};

const mapDispatchToProps = {
  fetchCampaigns,
  deleteCampaign,
  showConfirmationDialog,
  showSnackbarMessage,
  editCampaign,
  newCampaign,
  resetCampaignsPageState
};

const mapStateToProps = state => ({
  data: state.campaign.data,
  loading: state.campaign.loading,
  status: state.campaign.status,
  campaignData: state.campaign.campaignData,
  random: state.campaign.random,
  run: state.campaign.run
});

// eslint-disable-next-line no-class-assign
Campaigns = connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);

export default withRouter(Campaigns);
