import React, { Component } from 'react';
import CampaignViewStatsFailureGraph from './CampaignViewStatsFailureGraph';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '../Chips/Chip';
import { getRunStatus } from './dataUtil';

class CampaignViewStats extends Component {
  render() {
    const { failedStats, averages, campaignRun } = this.props;
    const runStatus = getRunStatus(campaignRun.canceledAt, campaignRun.isComplete);
    return (
      <React.Fragment>
        <div className="campaigns__grid">
          <List>
            <ListItem>
              <b>Run Status</b>
            </ListItem>
            <ListItem>
              <Chip label={runStatus} key={runStatus} />
            </ListItem>
            <ListItem>
              <b>Averages</b>
            </ListItem>
            <ListItem>
              <ListItemText primary="Average Post-Dial Delay" secondary={averages.averagePostDialDelay + ' Second(s)'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Average Time to Answer" secondary={averages.averageTimeToAnswer + ' Second(s)'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Average Call Duration" secondary={averages.averageCallDuration + ' Second(s)'} />
            </ListItem>
          </List>

          <List>
            <ListItem>
              <b>Call Statuses</b>
            </ListItem>
            {failedStats.map((item) => {
              return (
                <ListItem>
                  <ListItemText primary={item.status} secondary={item.calls} />
                </ListItem>
              );
            })}
          </List>
          <List>
            <ListItem>
              <CampaignViewStatsFailureGraph failedStats={failedStats} />
            </ListItem>
          </List>
        </div>
      </React.Fragment>
    );
  }
}

export default CampaignViewStats;
