import React from "react";
import * as PropTypes from "prop-types";

function AudioLatency(props) {
  let latencies = {};

  props.audioLatency.map(item => {
    latencies[item.recordingType] = item;
  });

  return (
    <div className="audio__quality__visualiser__wrapper">
      <div className="audio__quality__visualiser__inner__wrapper">
        <div className="audio__quality__audioLatency__wrapper">
          <div className="audio__quality__audioLatency__wrapper__inner">
            <div className="audioLatency__inner__column">
              <div className="title__circle avatar-icon" />
              Customer
            </div>

            <div className="audioLatency__inner__column">
              <svg
                width="465px"
                height="11px"
                viewBox="0 0 465 11"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <rect id="path-1" x="125" y="644" width="1232" height="149" />
                  <filter
                    x="-0.5%"
                    y="-3.7%"
                    width="101.1%"
                    height="108.7%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                  >
                    <feOffset
                      dx="0"
                      dy="1"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="2"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0.0823529412   0 0 0 0 0.133333333   0 0 0 0 0.196078431  0 0 0 0.08 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    />
                  </filter>
                </defs>
                <g
                  id="Operata-Review"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Logs-detail-Audio"
                    transform="translate(-219.000000, -684.000000)"
                  >
                    <g
                      id="Rectangle_409-2-Copy-2"
                      transform="translate(741.000000, 718.500000) rotate(180.000000) translate(-741.000000, -718.500000) "
                    >
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                      />
                      <use fill="#FFFFFF" fillRule="evenodd" />
                    </g>
                    <rect
                      id="bg"
                      fill="#FFFFFF"
                      opacity="0"
                      x="661"
                      y="670"
                      width="80"
                      height="80"
                    />
                    <path
                      id="Line-2-Copy"
                      d="M674.24514,684.759192 L683.25,689.249459 L674.25487,693.759187 L674.25,689.759 L228.75,690.24 L228.75486,694.240808 L219.75,689.750541 L228.74513,685.240813 L228.749,689.24 L674.249,688.759 L674.24514,684.759192 Z"
                      fill="#333752"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </svg>
              <div className="stat__circle">
                {latencies["echo"] &&
                latencies["connect"] &&
                latencies["echo"].averageLatencyMs &&
                latencies["connect"].averageLatencyMs
                  ? (
                      latencies["echo"].averageLatencyMs -
                      latencies["connect"].averageLatencyMs
                    ).toFixed(2) + "ms"
                  : "Unavailable"}
              </div>

              <svg
                width="465px"
                height="11px"
                viewBox="0 0 465 11"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <rect id="path-1" x="125" y="644" width="1232" height="149" />
                  <filter
                    x="-0.5%"
                    y="-3.7%"
                    width="101.1%"
                    height="108.7%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                  >
                    <feOffset
                      dx="0"
                      dy="1"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="2"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0.0823529412   0 0 0 0 0.133333333   0 0 0 0 0.196078431  0 0 0 0.08 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    />
                  </filter>
                </defs>
                <g
                  id="Operata-Review"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Logs-detail-Audio"
                    transform="translate(-219.000000, -684.000000)"
                  >
                    <g
                      id="Rectangle_409-2-Copy-2"
                      transform="translate(741.000000, 718.500000) rotate(180.000000) translate(-741.000000, -718.500000) "
                    >
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                      />
                      <use fill="#FFFFFF" fillRule="evenodd" />
                    </g>
                    <rect
                      id="bg"
                      fill="#FFFFFF"
                      opacity="0"
                      x="661"
                      y="670"
                      width="80"
                      height="80"
                    />
                    <path
                      id="Line-2-Copy"
                      d="M674.24514,684.759192 L683.25,689.249459 L674.25487,693.759187 L674.25,689.759 L228.75,690.24 L228.75486,694.240808 L219.75,689.750541 L228.74513,685.240813 L228.749,689.24 L674.249,688.759 L674.24514,684.759192 Z"
                      fill="#333752"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div className="audioLatency__inner__column">
              <div className="title__circle phone-icon" />
              CCaaS
            </div>

            <div className="audioLatency__inner__column">
              <svg
                width="465px"
                height="11px"
                viewBox="0 0 465 11"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <rect id="path-1" x="125" y="644" width="1232" height="149" />
                  <filter
                    x="-0.5%"
                    y="-3.7%"
                    width="101.1%"
                    height="108.7%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                  >
                    <feOffset
                      dx="0"
                      dy="1"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="2"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0.0823529412   0 0 0 0 0.133333333   0 0 0 0 0.196078431  0 0 0 0.08 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    />
                  </filter>
                </defs>
                <g
                  id="Operata-Review"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Logs-detail-Audio"
                    transform="translate(-219.000000, -684.000000)"
                  >
                    <g
                      id="Rectangle_409-2-Copy-2"
                      transform="translate(741.000000, 718.500000) rotate(180.000000) translate(-741.000000, -718.500000) "
                    >
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                      />
                      <use fill="#FFFFFF" fillRule="evenodd" />
                    </g>
                    <rect
                      id="bg"
                      fill="#FFFFFF"
                      opacity="0"
                      x="661"
                      y="670"
                      width="80"
                      height="80"
                    />
                    <path
                      id="Line-2-Copy"
                      d="M674.24514,684.759192 L683.25,689.249459 L674.25487,693.759187 L674.25,689.759 L228.75,690.24 L228.75486,694.240808 L219.75,689.750541 L228.74513,685.240813 L228.749,689.24 L674.249,688.759 L674.24514,684.759192 Z"
                      fill="#333752"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </svg>

              <div className="stat__circle">
                {latencies["connect"] && latencies["connect"].averageLatencyMs
                  ? latencies["connect"].averageLatencyMs.toFixed(2) + "ms"
                  : "Unavailable"}
              </div>

              <svg
                width="465px"
                height="11px"
                viewBox="0 0 465 11"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <rect id="path-1" x="125" y="644" width="1232" height="149" />
                  <filter
                    x="-0.5%"
                    y="-3.7%"
                    width="101.1%"
                    height="108.7%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                  >
                    <feOffset
                      dx="0"
                      dy="1"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="2"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0.0823529412   0 0 0 0 0.133333333   0 0 0 0 0.196078431  0 0 0 0.08 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    />
                  </filter>
                </defs>
                <g
                  id="Operata-Review"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Logs-detail-Audio"
                    transform="translate(-219.000000, -684.000000)"
                  >
                    <g
                      id="Rectangle_409-2-Copy-2"
                      transform="translate(741.000000, 718.500000) rotate(180.000000) translate(-741.000000, -718.500000) "
                    >
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                      />
                      <use fill="#FFFFFF" fillRule="evenodd" />
                    </g>
                    <rect
                      id="bg"
                      fill="#FFFFFF"
                      opacity="0"
                      x="661"
                      y="670"
                      width="80"
                      height="80"
                    />
                    <path
                      id="Line-2-Copy"
                      d="M674.24514,684.759192 L683.25,689.249459 L674.25487,693.759187 L674.25,689.759 L228.75,690.24 L228.75486,694.240808 L219.75,689.750541 L228.74513,685.240813 L228.749,689.24 L674.249,688.759 L674.24514,684.759192 Z"
                      fill="#333752"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div className="audioLatency__inner__column">
              <div className="title__circle person-icon" />
              Agent
            </div>
          </div>

          <div className="echo__latency__wrapper">
            <div className="stat__circle">
              Echo Latency{" "}
              {latencies["echo"] && latencies["echo"].averageLatencyMs
                ? latencies["echo"].averageLatencyMs.toFixed(2) + "ms"
                : "Unavailable"}
            </div>

            <svg
              width="1205px"
              height="10px"
              viewBox="0 0 1205 10"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Line 2</title>
              <g
                id="Operata-Review"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Logs-detail-Audio"
                  transform="translate(-145.000000, -809.000000)"
                  fill="#333752"
                  fillRule="nonzero"
                >
                  <path
                    id="Line-2"
                    d="M1341,809.5 L1350,814 L1341,818.5 L1341,814.5 L154,814.5 L154,818.5 L145,814 L154,809.5 L154,813.5 L1341,813.5 L1341,809.5 Z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

AudioLatency.propTypes = {
  audioLatency: PropTypes.array
};

export default AudioLatency;
