import React from "react";
import classNames from "classnames";
import { CloseIcon } from "@operata/adagio";
import PropTypes from "prop-types";
import "./style.scss";

const ErrorState = ({ width, height = 24, children }) => {
  let errorClass = classNames("error-state", {
    "error-state--highlight": false
  });

  var errorStyle = {
    height,
    width
  };

  return (
    <div className={errorClass} style={errorStyle}>
      <div className="error-state__icon">
        <CloseIcon />
      </div>
      <div className="error-state__message">
        <p>{children}</p>
      </div>
    </div>
  );
};

ErrorState.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node
};

export default ErrorState;
