import Dygraph from "dygraphs";
import Crosshair from "../crosshair.js";
import { highlightHelper } from "./Helpers/HighlightHelper.js";
import { legendFormatter } from "./Helpers/legendFormatter.js";

const GraphJitter = ({ call, insightHighlights, graphOptions }) => {
  if (!call?.call) return;
  const jitter = call?.call?.map(d => [new Date(d.timestamp), d.jitter]);

  return new Dygraph("graph-jitter", jitter, {
    labels: ["timestamp", "jitter"],
    labelsDiv: "graph-jitter-legend",
    axisLabelWidth: graphOptions.op_yAxisLabelWidth,
    axes: {
      x: {
        drawGrid: false,
        drawAxis: true
      },
      y: {
        drawGrid: true,
        drawAxis: true,
        gridLineColor: "rgba(0,0,0,0.3)"
      }
    },
    xRangePad: graphOptions.op_xRangePad,
    fillGraph: false,
    drawAxesAtZero: true,
    labelsUTC: true,
    legend: "always",
    legendFormatter: legendFormatter,
    colors: ["#008080"],
    underlayCallback: insightHighlights
      ? (canvas, area, g) => {
          highlightHelper(canvas, area, g, insightHighlights);
        }
      : null,
    plugins: [new Crosshair({ direction: "vertical" })]
  });
};

export default GraphJitter;
