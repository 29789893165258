import React from 'react';
import './Audio.scss';
import * as PropTypes from "prop-types";

class AudioPlayer extends React.Component {
  pi = Math.PI;
  doublePi = this.pi * 2;
  arcOffset = -this.pi / 2;
  animTime = 200;
  loaderTime = 1800;

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      borderColor: '#c86dd7',
      playedColor: '#3023ae',
      backgroundColor: 'white',
      iconColor: '#3023ae',
      borderWidth: 2,
      size: 48,
      class: 'circle-audio-player is-loading',
    };

    for (var property in this.state) {
      this.state[property] = props[property] || this.state[property];
    }

    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
  }

  handleOnMouseDown() {
    if (this.state.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    this.audio.play();
  }

  pause() {
    this.audio.pause();
  }

  _draw(progress) {
    // common settings
    if (isNaN(progress)) {
      progress = this.audio.currentTime / this.audio.duration || 0;
    }

    // clear existing
    this._ctx.clearRect(0, 0, this.state.size, this.state.size);

    // draw bg
    this._ctx.beginPath();
    this._ctx.arc(this._halfSize, this._halfSize, this._halfSize - this.state.borderWidth / 2, 0, this.doublePi);
    this._ctx.closePath();
    this._ctx.fillStyle = this.state.backgroundColor;
    this._ctx.fill();

    // draw border
    // our active path is already the full circle, so just stroke it
    this._ctx.lineWidth = this.state.borderWidth;
    this._ctx.strokeStyle = this.state.borderColor;
    this._ctx.stroke();

    // play progress
    if (progress > 0) {
      this._ctx.beginPath();
      this._ctx.arc(
        this._halfSize,
        this._halfSize,
        this._halfSize - this.state.borderWidth / 2,
        this.arcOffset,
        this.arcOffset + this.doublePi * progress
      );
      this._ctx.strokeStyle = this.state.playedColor;
      this._ctx.stroke();
    }

    // icons
    this._ctx.fillStyle = this.state.iconColor;
    if (this.loading) {
      var loaderOffset =
        -Math.cos(((new Date().getTime() % this.loaderTime) / this.loaderTime) * this.pi) * this.doublePi -
        this.pi / 3 -
        this.pi / 2;
      this._ctx.beginPath();
      this._ctx.arc(this._halfSize, this._halfSize, this._halfSize / 3, loaderOffset, loaderOffset + (this.pi / 3) * 2);
      this._ctx.strokeStyle = this.state.iconColor;
      this._ctx.stroke();
    } else {
      this._ctx.beginPath();
      var icon = (this._animationProps && this._animationProps.current) || this._icons.play;
      for (var i = 0; i < icon.length; i++) {
        this._ctx.moveTo(icon[i][0][0], icon[i][0][1]);

        for (var j = 1; j < icon[i].length; j++) {
          this._ctx.lineTo(icon[i][j][0], icon[i][j][1]);
        }
      }

      // this._ctx.closePath();
      this._ctx.fill();
      // stroke to fill in for retina
      this._ctx.strokeStyle = this.state.iconColor;
      this._ctx.lineWidth = 1;
      this._ctx.lineJoin = 'miter';
      this._ctx.stroke();
    }
  }

  _setState(state) {
    this.setState({ playing: false });
    this.loading = false;
    if (state === 'playing') {
      this.setState({ playing: true });
      this._animateIcon('pause', 'play');
    } else if (state === 'loading') {
      this.loading = true;
    } else if (state !== 'loading') {
      this._animateIcon('play', 'pause');
    } else {
      this._animateIcon('play', null);
    }

    this.setState({ class: 'circle-audio-player is-' + state });
    this.draw();
  }

  // public methods
  draw() {
    this._forceDraw = true;
  }

  setSize(size) {
    this.size = size;
   // we do this a lot. it's not heavy, but why repeat?
    
    this.canvasRef.current.width = size * this.pixelRatio;
    this.canvasRef.current.height = size * this.pixelRatio;
    this.canvasRef.current.style.width = size + "px";
    this.canvasRef.current.style.height = size + "px";
    this._ctx.setTransform(this.pixelRatio, 0, 0, this.pixelRatio, 0, 0);

    this._halfSize = size / 2;
    
    // set icon paths
    var iconSize = this.size / 3;
    var pauseGap = iconSize / 10;
    var playLeft = Math.cos((this.pi / 3) * 2) * (iconSize / 2) + this._halfSize;
    var playRight = iconSize / 2 + this._halfSize;
    var playHalf = (playRight - playLeft) / 2 + playLeft;
    var top = this._halfSize - Math.sin((this.pi / 3) * 2) * (iconSize / 2);
    var bottom = this.size - top;
    var pauseLeft = this._halfSize - iconSize / 3;
    var pauseRight = this.size - pauseLeft;
    
    this._icons = {
      play: [
        [
          [playLeft, top],
          [playHalf, (this._halfSize - top) / 2 + top],
          [playHalf, (this._halfSize - top) / 2 + this._halfSize],
          [playLeft, bottom],
          [playLeft, top],
          [playHalf, (this._halfSize - top) / 2 + top],
        ],
        [
          [playHalf, (this._halfSize - top) / 2 + top],
          [playRight, this._halfSize],
          [playRight, this._halfSize],
          [playHalf, (this._halfSize - top) / 2 + this._halfSize],
          [playHalf, (this._halfSize - top) / 2 + top],
          [playRight, this._halfSize],
        ],
      ],
      pause: [
        [
          [pauseLeft, top + pauseGap],
          [this._halfSize - pauseGap, top + pauseGap],
          [this._halfSize - pauseGap, bottom - pauseGap],
          [pauseLeft, bottom - pauseGap],
          [pauseLeft, top + pauseGap],
          [this._halfSize - pauseGap, top + pauseGap],
        ],
        [
          [this._halfSize + pauseGap, top + pauseGap],
          [pauseRight, top + pauseGap],
          [pauseRight, bottom - pauseGap],
          [this._halfSize + pauseGap, bottom - pauseGap],
          [this._halfSize + pauseGap, top + pauseGap],
          [pauseRight, top + pauseGap],
        ],
      ],
    };

    if (this._animationProps && this._animationProps.current) {
      this._animateIcon(this._animationProps.to);
    }
    if (!this.state.playing) {
      this.draw();
    }
  }

  setAudio(audioUrl) {
    this.audio = new Audio(audioUrl);
    this._setState('loading');

    this.audio.addEventListener(
      'canplaythrough',
      function() {
        this._setState('paused');
      }.bind(this)
    );
    this.audio.addEventListener(
      'play',
      function() {
        this._setState('playing');
      }.bind(this)
    );
    this.audio.addEventListener(
      'pause',
      function() {
        // reset when finished
        if (this.audio.currentTime === this.audio.duration) {
          this.audio.currentTime = 0;
        }
        this._setState('paused');
      }.bind(this)
    );
  }

  getDevicePixelRatio(ctx) {
      let dpr = window.devicePixelRatio || 1;
      let bsr =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1;

    return dpr / bsr;
  }

  componentDidMount() {
    this._ctx = this.canvasRef.current.getContext('2d');
    this.pixelRatio = this.getDevicePixelRatio(this._ctx);

    // set up initial stuff
    this.setAudio(this.props.src);
    this.setSize(this.state.size);

    // redraw loop
    (function cAPAnimationLoop(now) {
      // check if we need to update anything
      if (this.animating) {
        this._updateAnimations(now);
      }
      if (this._forceDraw || this.state.playing || this.animating || this.loading) {
        this._draw();
        this._forceDraw = false;
      }

      requestAnimationFrame(cAPAnimationLoop.bind(this));
    }.call(this, new Date().getTime()));
  }

  // private methods
  _animateIcon(to, from) {
    // define a few things the first time
    this._animationProps = {
      animStart: null,
      from: from,
      to: to,
    };
    if (from) {
      this.animating = true;
    } else {
      this._animationProps.current = this._icons[to].slice();
      this.draw();
    }
  }

  _updateAnimations(now) {
    this._animationProps.animStart = this._animationProps.animStart || now;
    var deltaTime = now - this._animationProps.animStart;
    var perc = 1 - Math.cos(((deltaTime / this.animTime) * this.pi) / 2);
    if (deltaTime >= this.animTime) {
      this.animating = false;
      perc = 1;
      this._animationProps.current = this._icons[this._animationProps.to].slice();
      this.draw();
    } else {
      var from = this._icons[this._animationProps.from];
      var current = [];
      for (var i = 0; i < from.length; i++) {
        current.push([]);
        for (var j = 0; j < from[i].length; j++) {
          current[i].push([]);
          var to = this._icons[this._animationProps.to][i][j];
          current[i][j][0] = from[i][j][0] + (to[0] - from[i][j][0]) * perc;
          current[i][j][1] = from[i][j][1] + (to[1] - from[i][j][1]) * perc;
        }
      }
      this._animationProps.current = current;
    }
  }

  render() {
    return (
      <React.Fragment>
        <canvas ref={this.canvasRef} className={this.state.class} onMouseDown={this.handleOnMouseDown} />
      </React.Fragment>
    );
  }
}

AudioPlayer.propTypes = {
  src: PropTypes.string,
}

export default AudioPlayer;
