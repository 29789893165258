import PropTypes from "prop-types";
import React from "react";
import Chip from "../../Chips/Chip";
import InsightsTable from "./InsightsTable";
import TelephonySneakPeek from "./SneakPeek/TelephonySneakPeek";
import { NavLink } from "react-router-dom";
import { TELEPHONY_TAGS, getTagsWithoutCategory } from "../../../constants/gsm";
import { getStatusLevelFromTags, createStatusLevelText } from "./utils";

const SERVICE_AVAILABILITY_STATUSES = [
  "Completed",
  "Busy",
  "No Answer",
  "Failed",
  "Call Disconnected Prematurely"
];

const ServiceAvailability = ({ logs, filterInsights, props }) => {
  let sortDirectionForCols = {};
  sortDirectionForCols[filterInsights.sortDirection.telephony.colIndex] =
    filterInsights.sortDirection.telephony.order;

  const columns = [
    {
      name: "jobId",
      label: "Job ID",
      options: {
        display: filterInsights.showColumns.telephony[0].display,
        sortDirection: sortDirectionForCols[0] ? sortDirectionForCols[0] : null
      }
    },
    {
      name: "toNumber",
      label: "To Number",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: job => {
          return (
            <NavLink
              className="textfield-bold actions__link"
              to={`/gsm/heartbeat/jobs/${job.jobId}/report`}
            >
              <span className="insights__link">{job.toNumber}</span>
            </NavLink>
          );
        },
        display: filterInsights.showColumns.telephony[1].display,
        sortDirection: sortDirectionForCols[1] ? sortDirectionForCols[1] : null
      }
    },
    {
      name: "carrier",
      label: "Carrier",
      options: {
        display: filterInsights.showColumns.telephony[2].display,
        sortDirection: sortDirectionForCols[2] ? sortDirectionForCols[2] : null
      }
    },
    {
      name: "audioLatency",
      label: "Audio Latency",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: ([tags, status]) => {
          const statusLevel = getStatusLevelFromTags(
            status,
            tags,
            TELEPHONY_TAGS["High Audio Latency"],
            "Normal"
          );

          return createStatusLevelText(statusLevel);
        },
        display: filterInsights.showColumns.telephony[3].display,
        sortDirection: sortDirectionForCols[3] ? sortDirectionForCols[3] : null
      }
    },
    {
      name: "audioQuality",
      label: "Audio Quality (IN/OUT)",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: ([tags, status]) => {
          const inboundQualityStatusLevel = getStatusLevelFromTags(
            status,
            tags,
            TELEPHONY_TAGS["Poor Audio Quality"].filter(tag =>
              tag.includes("Inbound")
            )
          );
          const outboundQualityStatusLevel = getStatusLevelFromTags(
            status,
            tags,
            TELEPHONY_TAGS["Poor Audio Quality"].filter(tag =>
              tag.includes("Outbound")
            )
          );

          return (
            <span>
              {createStatusLevelText(inboundQualityStatusLevel)}
              {"/"}
              {createStatusLevelText(outboundQualityStatusLevel)}
            </span>
          );
        },
        display: filterInsights.showColumns.telephony[4].display,
        sortDirection: sortDirectionForCols[4] ? sortDirectionForCols[4] : null
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: filterInsights.showColumns.telephony[5].display,
        sortDirection: sortDirectionForCols[5] ? sortDirectionForCols[5] : null
      }
    },
    {
      name: "tags",
      label: "Insights",
      options: {
        customBodyRender: tags => {
          let chips = [];
          if (tags) {
            tags.forEach(tag => {
              chips.push(<Chip label={tag} key={tag} />);
            });
          }
          return chips;
        },
        display: filterInsights.showColumns.telephony[6].display,
        sortDirection: sortDirectionForCols[6] ? sortDirectionForCols[6] : null
      }
    }
  ];

  return (
    <InsightsTable
      logs={logs}
      tags={TELEPHONY_TAGS}
      status={SERVICE_AVAILABILITY_STATUSES}
      columns={columns}
      dataToColumn={result => {
        return [
          result.jobId,
          result,
          result.carrier,
          [result.tags, result.status],
          [result.tags, result.status],
          result.status,
          result.tags
            ? result.tags.filter(tag =>
                getTagsWithoutCategory(TELEPHONY_TAGS).includes(tag)
              )
            : []
        ];
      }}
      InsightsSneakPeek={TelephonySneakPeek}
      filterInsights={filterInsights}
      props={props}
    />
  );
};

ServiceAvailability.propTypes = {
  logs: PropTypes.array,
  filterInsights: PropTypes.object,
  props: PropTypes.object
};

export default ServiceAvailability;
