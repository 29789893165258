import _ from 'lodash';

const SECOND_IN_MILLS = 1000;
const RUN_STATUS_COMPLETE = 'Complete';
const RUN_STATUS_CANCELLED = 'Cancelled';
const RUN_STATUS_IN_PROGRESS = 'In Progress';

const convertToTimeInMills = (item, field) => {
  if (item[field]) {
    item[field] = new Date(item[field]).getTime();
  }
};

const isCallInProgress = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.inProgressAt, item.callTerminatedTime);
};

const isUnsuccessfulCall = (thisSecond, item) => {
  return (
    withInTimeRange(thisSecond, item.busyAt, item.busyAt) ||
    withInTimeRange(thisSecond, item.terminatedPrematurelyAt, item.terminatedPrematurelyAt) ||
    withInTimeRange(thisSecond, item.canceledAt, item.canceledAt) ||
    withInTimeRange(thisSecond, item.noAnswerAt, item.noAnswerAt) ||
    withInTimeRange(thisSecond, item.failedAt, item.failedAt)
  );
};

const isBusyCall = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.busyAt, item.busyAt);
};

const isPrematureCall = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.terminatedPrematurelyAt, item.terminatedPrematurelyAt);
};

const isCancelledCall = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.canceledAt, item.canceledAt);
};

const isNoAnswerCall = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.noAnswerAt, item.noAnswerAt);
};

const isFailedCall = (thisSecond, item) => {
  return withInTimeRange(thisSecond, item.failedAt, item.failedAt);
};

const withInTimeRange = (thisSecond, fromTime, toTime) => {
  return fromTime && toTime && thisSecond >= fromTime && thisSecond <= toTime;
};

const setMinAndMaxTime = (maxTime, minTime, time) => {
  if (maxTime < time) {
    maxTime = time;
  }
  if (minTime > time) {
    minTime = time;
  }
  return { maxTime, minTime };
};

export const prepareGraphData = (campaignRunData, callLog) => {
  const { callsPerSecond, callDuration, totalCalls, isComplete, canceledAt } = campaignRunData;

  const runStatus = getRunStatus(canceledAt, isComplete);

  let { maxTime, minTime, callData, totalCallDuration, totalTimeToAnswer, totalPostDialDelay } = prepareCallData(
    callLog
  );

  let loadTestMaxDuration = calculateTestDuration(callDuration, totalCalls, callsPerSecond, maxTime, minTime);

  var {
    totalUnsuccessfulCalls,
    persecondStats,
    totalBusyCalls,
    totalCancelledCalls,
    totalNoAnswerCalls,
    totalFailedCalls,
    totalPrematureCalls,
  } = calculatePerSecondStats(
    loadTestMaxDuration,
    totalCalls,
    callsPerSecond,
    callDuration,
    minTime,
    callData,
    runStatus
  );

  const totalSuccessfulCalls = callLog.length - totalUnsuccessfulCalls;
  const totalPendingCalls = totalCalls - callLog.length;
  const averageCallDuration = totalCallDuration / totalSuccessfulCalls / SECOND_IN_MILLS;
  const averageTimeToAnswer = totalTimeToAnswer / totalSuccessfulCalls / SECOND_IN_MILLS;
  const averagePostDialDelay = totalPostDialDelay / totalSuccessfulCalls / SECOND_IN_MILLS;

  return {
    persecondStats,
    summaryStats: [
      { status: 'Pending', calls: totalPendingCalls, variety: 'Calls' },
      { status: 'Failed', calls: totalUnsuccessfulCalls, variety: 'Calls' },
      { status: 'Successful', calls: totalSuccessfulCalls, variety: 'Calls' },
    ],
    failedStats: [
      { status: 'Successful', calls: totalSuccessfulCalls, color: 'green' },
      { status: 'Busy', calls: totalBusyCalls, color: 'blue' },
      { status: 'Cancelled', calls: totalCancelledCalls, color: 'orange' },
      { status: 'No Answer', calls: totalNoAnswerCalls, color: 'purple' },
      { status: 'Failed', calls: totalFailedCalls, color: 'red' },
      { status: 'Premature', calls: totalPrematureCalls, color: 'yellow' },
      { status: 'Pending', calls: totalPendingCalls, color: 'grey' },
    ],
    averages: {
      averageCallDuration,
      averageTimeToAnswer,
      averagePostDialDelay,
    },

    loadTestMaxDuration,
  };
};

function calculatePerSecondStats(
  loadTestMaxDuration,
  totalCalls,
  callsPerSecond,
  callDuration,
  minTime,
  callData,
  runStatus
) {
  let totalUnsuccessfulCalls = 0;
  let totalBusyCalls = 0;
  let totalPrematureCalls = 0;
  let totalCancelledCalls = 0;
  let totalNoAnswerCalls = 0;
  let totalFailedCalls = 0;

  const persecondStats = _.range(0, loadTestMaxDuration, SECOND_IN_MILLS).map(second => {
    const currentSecond = second / SECOND_IN_MILLS;
    let idealCalls = _.min([totalCalls, (currentSecond + 1) * callsPerSecond]);
    if (currentSecond >= callDuration) {
      idealCalls -= (currentSecond - callDuration + 1) * callsPerSecond;
      idealCalls = _.max([0, idealCalls]);
    }
    let realCalls = null;
    let unsuccessfulCalls = 0;
    let thisSecond = minTime + second;
    callData.map(item => {
      if (item.callTerminatedTime) {
        if (runStatus !== RUN_STATUS_IN_PROGRESS && !realCalls) {
          realCalls = 0;
        }
        if (isCallInProgress(thisSecond, item)) {
          if (!realCalls) {
            realCalls = 0;
          }
          realCalls++;
        }
        if (isUnsuccessfulCall(thisSecond, item)) {
          unsuccessfulCalls++;
          totalUnsuccessfulCalls++;
        }
        if (isBusyCall(thisSecond, item)) {
          totalBusyCalls++;
        }
        if (isPrematureCall(thisSecond, item)) {
          totalPrematureCalls++;
        }
        if (isCancelledCall(thisSecond, item)) {
          totalCancelledCalls++;
        }
        if (isNoAnswerCall(thisSecond, item)) {
          totalNoAnswerCalls++;
        }
        if (isFailedCall(thisSecond, item)) {
          totalFailedCalls++;
        }
      }
      return null;
    });

    return {
      second: currentSecond,
      realCalls,
      idealCalls,
      unsuccessfulCalls,
    };
  });

  return {
    totalUnsuccessfulCalls,
    persecondStats,
    totalBusyCalls,
    totalCancelledCalls,
    totalNoAnswerCalls,
    totalFailedCalls,
    totalPrematureCalls,
  };
}

function prepareCallData(callLog) {
  let minTime = 9999999999999;
  let maxTime = 0;
  let totalCallDuration = 0;
  let totalTimeToAnswer = 0;
  let totalPostDialDelay = 0;
  let callData = callLog.map(item => {
    item['liveCall'] = true;
    let callTerminatedTime =
      item.completedAt ||
      item.noAnswerAt ||
      item.canceledAt ||
      item.terminatedPrematurelyAt ||
      item.busyAt ||
      item.failedAt;
    if (!callTerminatedTime) {
      item.callTerminatedTime = new Date().getTime();
    } else {
      item.callTerminatedTime = new Date(callTerminatedTime).getTime();
      item['liveCall'] = false;
    }
    transformTimstampsInCallrecord(item);
    ({ maxTime, minTime } = setMinAndMaxTime(maxTime, minTime, item.callTerminatedTime));
    if (item.inProgressAt) {
      ({ maxTime, minTime } = setMinAndMaxTime(maxTime, minTime, item.inProgressAt));
    }
    if (item.callTerminatedTime && item.inProgressAt) {
      const duration = item.callTerminatedTime - item.inProgressAt;
      item.callDuration = duration / SECOND_IN_MILLS;
      totalCallDuration += duration;
    }
    if (item.ringingAt && item.inProgressAt) {
      totalTimeToAnswer += item.inProgressAt - item.ringingAt;
    }
    if (item.initiatedAt && item.ringingAt) {
      totalPostDialDelay += item.ringingAt - item.initiatedAt;
    }
    return item;
  });
  return { maxTime, minTime, callData, totalCallDuration, totalTimeToAnswer, totalPostDialDelay };
}

function transformTimstampsInCallrecord(item) {
  convertToTimeInMills(item, 'initiatedAt');
  convertToTimeInMills(item, 'inProgressAt');
  convertToTimeInMills(item, 'completedAt');
  convertToTimeInMills(item, 'ringingAt');
  convertToTimeInMills(item, 'noAnswerAt');
  convertToTimeInMills(item, 'canceledAt');
  convertToTimeInMills(item, 'terminatedPrematurelyAt');
  convertToTimeInMills(item, 'busyAt');
  convertToTimeInMills(item, 'failedAt');
}

function calculateTestDuration(callDuration, totalCalls, callsPerSecond, maxTime, minTime) {
  let loadTestMaxDuration = (callDuration + totalCalls / callsPerSecond) * SECOND_IN_MILLS;
  if (maxTime > 0) {
    const actualTestDuration = maxTime - minTime + SECOND_IN_MILLS * 2;
    if (loadTestMaxDuration < actualTestDuration) {
      loadTestMaxDuration = actualTestDuration;
    }
  }
  return loadTestMaxDuration;
}

export const getRunStatus = (cancelledAt, isComplete) => {
  if (cancelledAt) {
    return RUN_STATUS_CANCELLED;
  } else if (isComplete) {
    return RUN_STATUS_COMPLETE;
  } else {
    return RUN_STATUS_IN_PROGRESS;
  }
};
