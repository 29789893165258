import React from "react";
import { FEATURES, featureToggles } from "../../auth/FearureToggles";
import {
  auth,
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER
} from "../../auth/Auth";

import {
  ExpandMoreRounded,
  CheckCircleOutlineRounded
} from "@material-ui/icons";
import { SWITCH_GROUP_SUCCESSFUL } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchConfig } from "../../actions/config";
import { openGroupSwitch } from "../../actions/groups";

import { expandSidebar, collapseSidebar } from "../../actions/page";

import { withFeatureFlagsConsumer } from "../../FeatureFlags";
import { Sidebar } from "@operata/adagio";
import initials from "./initials";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      config: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.authStatus === SWITCH_GROUP_SUCCESSFUL) {
      const { currentGroupId } = nextProps.userProfile;
      window.location.replace("/" + currentGroupId + "/dashboard");
    } else if (nextProps.config && nextProps.config.key) {
      this.setState({
        config: {
          ...this.state.config,
          [nextProps.config.key]: nextProps.config.value
        }
      });
    }
  }

  componentDidMount() {
    this.props.fetchConfig("HEARTBEAT_ENABLED", "boolean");
    this.props.fetchConfig("BI_ENABLED", "boolean");
  }

  handleLogout(event) {
    auth.logout();
    event.preventDefault();
  }

  render() {
    let { groups } = this.props.userProfile;
    const authCurrentGroupId = auth.getCurrentGroupId();

    groups = groups.map(group => ({ ...group, icon: initials(group.name) }));

    const currentGroup = groups.find(({ id }) => id === authCurrentGroupId);
    const profilePicture = auth.getPicture();

    const profile = {
      ...this.props.userProfile,
      currentGroupId: authCurrentGroupId,
      currentGroup,
      picture: profilePicture
    };

    this.props.ldClient && this.props.ldClient.setStreaming();
    this.props.ldClient &&
      this.props.ldClient.identify({
        kind: "multi",
        user: {
          key: profile.id,
          name: profile.name,
          email: profile.email
        },
        organization: {
          key: profile.currentGroup?.id,
          name: profile.currentGroup?.name
        }
      });

    profile.icon = <img alt={profile.name} src={profilePicture} width="100%" />;

    return (
      <Sidebar.Layout>
        <Sidebar.Logo
          isExpanded={this.props.sidebarExpanded}
          expandSidebar={this.props.expandSidebar}
          collapseSidebar={this.props.collapseSidebar}
        />
        <Sidebar.Dropdown.Layout
          isExpanded={this.props.sidebarExpanded}
          currentGroup={currentGroup}
          selectGroup={({ id }) => auth.switchGroup(id)}
          groups={groups}
          expandIcon={<ExpandMoreRounded />}
          selectedIcon={<CheckCircleOutlineRounded />}
        />
        <Sidebar.Navigation isExpanded={this.props.sidebarExpanded}>
          <Sidebar.Navigation.Menu>
            <Sidebar.Navigation.MenuItem
              isExpanded={this.props.sidebarExpanded}
              exact
              path={`/${authCurrentGroupId}/dashboard`}
              icon={<Sidebar.HomeIcon />}
              name="Home"
            />

            {this.state.config["BI_ENABLED"] === "true" &&
              auth.hasPermission([
                USER_ROLE_USER,
                USER_ROLE_ADMIN,
                USER_ROLE_SUPER
              ]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  exact
                  path={`/${authCurrentGroupId}/canvas`}
                  icon={<Sidebar.PlaybooksIcon />}
                  name="Canvas"
                />
              )}

            {this.props &&
              this.props.flags.embeddedCanvasApi &&
              auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/playbooks`}
                  icon={<Sidebar.PlaybooksIcon />}
                  name="Playbooks"
                />
              )}
          </Sidebar.Navigation.Menu>

          <Sidebar.Navigation.Menu>
            <Sidebar.Navigation.MenuTitle
              isExpanded={this.props.sidebarExpanded}
            >
              Explorer
            </Sidebar.Navigation.MenuTitle>
            <Sidebar.Navigation.MenuItem
              isExpanded={this.props.sidebarExpanded}
              path={`/${authCurrentGroupId}/explorer/calls`}
              icon={<Sidebar.SearchCallIcon />}
              name="Calls and Logs"
            />
          </Sidebar.Navigation.Menu>

          {this.state.config["HEARTBEAT_ENABLED"] === "true" &&
            auth.hasPermission([
              USER_ROLE_SUPER,
              USER_ROLE_ADMIN,
              USER_ROLE_USER
            ]) && (
              <Sidebar.Navigation.Menu>
                <Sidebar.Navigation.MenuTitle
                  isExpanded={this.props.sidebarExpanded}
                >
                  Heartbeat
                </Sidebar.Navigation.MenuTitle>
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  exact
                  path={`/${authCurrentGroupId}/gsm/heartbeat/insights`}
                  icon={<Sidebar.HeartbeatIcon />}
                  name="Heartbeat"
                />
                {auth.hasPermission([USER_ROLE_SUPER, USER_ROLE_ADMIN]) && (
                  <Sidebar.Navigation.MenuItem
                    isExpanded={this.props.sidebarExpanded}
                    exact
                    path={`/${authCurrentGroupId}/gsm/heartbeat`}
                    icon={<Sidebar.ExperimentIcon />}
                    name="Tests"
                  />
                )}

                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/gsm/heartbeat/logs`}
                  icon={<Sidebar.ReportIcon />}
                  name="Logs"
                />
              </Sidebar.Navigation.Menu>
            )}

          {auth.hasPermission([
            USER_ROLE_USER,
            USER_ROLE_ADMIN,
            USER_ROLE_SUPER
          ]) && (
            <Sidebar.Navigation.Menu>
              <Sidebar.Navigation.MenuTitle
                isExpanded={this.props.sidebarExpanded}
              >
                Tools
              </Sidebar.Navigation.MenuTitle>

              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/workflows`}
                  icon={<Sidebar.WorkflowIcon />}
                  name="Workflow"
                />
              )}

              {featureToggles.isFeatureEnabled(FEATURES.SIMULATE_MODULE) &&
                auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                  <Sidebar.Navigation.MenuItem
                    isExpanded={this.props.sidebarExpanded}
                    path={`/${authCurrentGroupId}/campaigns`}
                    icon={<Sidebar.SimulateIcon />}
                    name="Simulate"
                  />
                )}

              {auth.hasPermission([
                USER_ROLE_USER,
                USER_ROLE_ADMIN,
                USER_ROLE_SUPER
              ]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/history/lists`}
                  icon={<Sidebar.HistoryIcon />}
                  name="History"
                />
              )}
            </Sidebar.Navigation.Menu>
          )}

          {this.props.sidebarExpanded && (
            <Sidebar.Navigation.Menu pinToBottom>
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/users`}
                  icon={<Sidebar.TeamIcon />}
                  name="Teammates"
                />
              )}
              <Sidebar.Navigation.MenuItem
                isExpanded={this.props.sidebarExpanded}
                path={`/${authCurrentGroupId}/configs`}
                icon={<Sidebar.SettingsIcon />}
                name="Group Settings"
              />
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/extutil`}
                  icon={<Sidebar.CoverageIcon />}
                  name="Coverage"
                />
              )}
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/billing`}
                  icon={<Sidebar.UsageIcon />}
                  name="Usage"
                />
              )}
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/groups`}
                  icon={<Sidebar.GroupIcon />}
                  name="Groups"
                />
              )}
            </Sidebar.Navigation.Menu>
          )}
        </Sidebar.Navigation>
        <Sidebar.StickyMenu.Layout>
          <Sidebar.StickyMenu.Profile
            isExpanded={this.props.sidebarExpanded}
            profile={profile}
            handleLogout={this.handleLogout}
            expandIcon={<ExpandMoreRounded />}
          />
        </Sidebar.StickyMenu.Layout>
      </Sidebar.Layout>
    );
  }
}

MainSidebar.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
  openGroupSwitch: PropTypes.func.isRequired,
  expandSidebar: PropTypes.func.isRequired,
  collapseSidebar: PropTypes.func.isRequired,
  authStatus: PropTypes.string,
  userProfile: PropTypes.object,
  config: PropTypes.object,
  sidebarExpanded: PropTypes.bool,
  flags: PropTypes.object,
  ldClient: PropTypes.object
};

const mapDispatchToProps = {
  fetchConfig,
  openGroupSwitch,
  expandSidebar,
  collapseSidebar
};

const mapStateToProps = state => ({
  authStatus: state.auth.switchGroupStatus,
  userProfile: state.auth.profile,
  config: state.config.config,
  sidebarExpanded: state.page.sidebarExpanded
});

// eslint-disable-next-line no-class-assign
MainSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSidebar);

// eslint-disable-next-line no-class-assign
MainSidebar = withFeatureFlagsConsumer()(MainSidebar);

export default MainSidebar;
