import React from "react";
import PropTypes from "prop-types";

import { ButtonRefactored, InputSelect } from "@operata/adagio";

import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconDown from "@material-ui/icons/KeyboardArrowDown";

import "./style.scss";

const TablePagination = ({ pageSize, setPageSize, page, setPage, count }) => (
  <div className="table-pagination">
    <div className="table-pagination__rows">
      <div className="table-pagination__label">Rows per page:</div>
      <InputSelect
        icon={<IconDown />}
        selectedValue={pageSize}
        setSelectedValue={pageSize => {
          setPageSize(pageSize);
          setPage(0);
        }}
      >
        <InputSelect.Option value="10" />
        <InputSelect.Option value="20" />
        <InputSelect.Option value="50" />
        <InputSelect.Option value="100" />
      </InputSelect>
    </div>

    <div className="table-pagination__actions">
      <div className="table-pagination__label">
        {pageSize * page + 1}-{Math.min(pageSize * (page + 1), count)} of{" "}
        {count}
      </div>

      <ButtonRefactored
        type="secondary"
        icon={<ArrowLeft />}
        tooltip={{
          label: "Previous",
          position: "top"
        }}
        disabled={page === 0}
        onClick={() => page !== 0 && setPage(page - 1)}
      />
      <ButtonRefactored
        type="secondary"
        icon={<ArrowRight />}
        tooltip={{
          label: "Next",
          position: "top"
        }}
        disabled={(page + 1) * pageSize >= count}
        onClick={() => page * pageSize < count && setPage(page + 1)}
      />
    </div>
  </div>
);

TablePagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
};

export default TablePagination;
