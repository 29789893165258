import React, { Component } from "react";
import MUIDataTable from "../Datatables";
import { connect } from "react-redux";
import { fetchUsers, removeUser, USER_REMOVED } from "../../actions/users";
import { showConfirmationDialog } from "../../actions/dialog";
import { showSnackbarMessage, SNACKBAR_SUCCESS } from "../../actions/snackbar";
import Pagination from "../Pagination/Pagination";
import { Chip } from "@material-ui/core";
import "./users.scss";
import PropTypes from "prop-types";

class UserList extends Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  static getDerivedStateFromProps(nextProps) {
    const { showSnackbarMessage, status, fetchUsers } = nextProps;
    if (status === USER_REMOVED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Teammate has been deleted successfully."
      );
      fetchUsers();
    }

    return null;
  }

  handleCancelRemoveUser = () => {
    //do nothing
  };

  handleRemoveUser = userId => {
    let { removeUser, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Remove Teammate",
      "Are you sure you want to remove this Teammate?",
      removeUser,
      [userId],
      this.handleCancelRemoveUser,
      []
    );
  };

  render() {
    const columns = [
      {
        name: "Name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          download: false,
          customBodyRender: value => {
            const avatar = "https://via.placeholder.com/150.png/09f/fff";
            const { picture, name, email } = value;

            return (
              <div className="user">
                <span className="avatar">
                  <img alt={name} src={picture} />
                  <img src={avatar} alt="avatar" />
                </span>
                <span className="textfield-bold">{name}</span>
                <span className="textfield-description">{email}</span>
              </div>
            );
          }
        }
      },
      {
        name: "Name",
        filter: true,
        options: {
          showColumn: false,
          viewColumns: false
        }
      },
      {
        name: "Email",
        filter: true,
        options: {
          showColumn: false,
          viewColumns: false
        }
      },
      {
        name: "Group",
        options: {
          filter: false
        }
      },
      {
        name: "Role",
        label: "Role",
        options: {
          filter: true,
          //customFilterListRender: value => `Tag: ${value}`,
          sort: true,
          customBodyRender: role => {
            let chips = [];
            chips.push(<Chip label={role} key={role} />);
            return chips;
          }
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          viewColumns: false,
          download: false,
          customBodyRender: value => {
            return (
              <React.Fragment>
                <a className="textfield-bold" href={"/admin/user/" + value}>
                  Edit
                </a>

                <span
                  className="textfield-bold actions__link"
                  onClick={() => {
                    this.handleRemoveUser(value);
                  }}
                >
                  Delete
                </span>
              </React.Fragment>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: "none",
      filter: true,
      fixedHeader: false,
      responsive: "stacked",
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      }
    };

    return (
      <MUIDataTable
        data={this.props.data.map(item => {
          return [
            {
              picture: item.picture,
              name: item.name,
              email: item.email,
              toString: () => {
                return item.name + " " + item.email;
              }
            },
            item.name,
            item.email,
            item.groups.map(item => item.name).join(", "),
            item.role,
            item.id
          ];
        })}
        columns={columns}
        options={options}
      />
    );
  }
}

UserList.propTypes = {
  fetchUsers: PropTypes.func,
  status: PropTypes.string,
  showSnackbarMessage: PropTypes.func,
  removeUser: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  data: PropTypes.array
};

const mapDispatchToProps = {
  fetchUsers: fetchUsers,
  removeUser: removeUser,
  showConfirmationDialog: showConfirmationDialog,
  showSnackbarMessage: showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.users.data,
  loading: state.users.loading,
  status: state.users.status
});

// eslint-disable-next-line no-class-assign
UserList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);

export default UserList;
