import React from 'react';
import Button from '../Button/Button';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

class VisibilityToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visibility: false };
  }

  render() {
    const { visibility } = this.state;
    return (
      <React.Fragment>
        <Button
          className="trigger__info"
          onClick={() => {
            this.setState((prevState) => ({ visibility: !prevState.visibility }));
          }}
        />

        <CSSTransition in={visibility} timeout={800} classNames="fade" unmountOnExit>
          <div className="fade">{this.state.visibility && <p className={this.props.className}>{this.props.children}</p>}</div>
        </CSSTransition>
      </React.Fragment>
    );
  }
}

VisibilityToggle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default VisibilityToggle;
