export function highlightHelper(canvas, area, g, insights) {
    canvas.fillStyle = "rgba(255, 0, 0, 0.05)";

    if (insights) {
      Object.keys(insights).forEach((k)=>{
        insights[k].forEach((highlight)=>{
          let canvas_left_x = g.toDomXCoord(Date.parse(highlight.start));
          let canvas_right_x = g.toDomXCoord(Date.parse(highlight.end));
          let canvas_width = canvas_right_x - canvas_left_x;
          canvas.fillRect(canvas_left_x, area.y, canvas_width, area.h);
        })
      })
    }
}