import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Campaigns.scss";
import "../ActionBar/ActionBar.scss";
import {
  fetchCallLog,
  fetchCampaignRun,
  CAMPAIGNS_CALL_LOG_RECEIVED,
  stopCampaign,
  CAMPAIGN_STOPPED
} from "../../actions/campaign";
import { showConfirmationDialog } from "../../actions/dialog";
import { showSnackbarMessage, SNACKBAR_SUCCESS } from "../../actions/snackbar";
import { updateDynamicBreadcrumb } from "../../actions/breadcrumb";
import Button from "../Button/Button";
import CampaignGraph from "./CampaignGraph";
import Tabs from "@material-ui/core/Tabs";
import LinkTab from "../Tabs/LinkTab";
import TabContainer from "../Tabs/TabContainer";
import CallLog from "./CallLog";
import CampaignViewDetails from "./CampaignViewDetails";
import { prepareGraphData } from "./dataUtil";
import CampaignViewStats from "./CampaignViewStats";
import CampaignViewStatsOverallGraph from "./CampaignViewStatsOverallGraph";

class CampaignView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelOpen: false,
      modalTitle: "",
      tab: 0,
      live: false
    };

    this.handleStopCampaign = this.handleStopCampaign.bind(this);
  }

  componentDidMount() {
    this.page = 0;
    const runId = this.props.match.params.runId;
    const campaignId = this.props.match.params.campaignId;

    if (!runId || !campaignId) {
      return;
    }

    this.props.fetchCallLog(campaignId, runId);

    this.fetchCallLogInterval = setInterval(() => {
      this.props.fetchCallLog(campaignId, runId);
    }, 2000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { campaignRun, data } = nextProps;
    let graphData = null;

    if (campaignRun) {
      this.props.updateDynamicBreadcrumb({
        campaignId: campaignRun && campaignRun.id,
        campaignName: campaignRun.name,
        runDescription: campaignRun.runDescription
      });
    }

    if (
      nextProps.status === CAMPAIGNS_CALL_LOG_RECEIVED &&
      campaignRun &&
      data
    ) {
      graphData = prepareGraphData(campaignRun, data);
      this.setState({ graphData });
    }

    if (campaignRun && campaignRun.isComplete) {
      console.log("polling stopped");
      this.setState({ live: false });
      if (this.state.graphData) {
        clearInterval(this.fetchCallLogInterval);
      }
    } else if (campaignRun && !campaignRun.isComplete) {
      this.setState({ live: true });
    }

    if (nextProps.status === CAMPAIGN_STOPPED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "A request has been sent to stop this Campaign Run."
      );
    }
  }

  componentWillUnmount() {
    if (this.fetchCallLogInterval) {
      clearInterval(this.fetchCallLogInterval);
    }
  }

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  cancelHandleStopCampaign = () => {};

  handleStopCampaign = () => {
    const runId = this.props.match.params.runId;
    const campaignId = this.props.match.params.campaignId;
    const { stopCampaign, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Cancel/Stop Campaign Run",
      "Are you sure you want to stop this Campaign Run?",
      stopCampaign,
      [campaignId, runId],
      this.cancelHandleStopCampaign,
      []
    );
  };

  render() {
    const { tab, graphData } = this.state;

    return (
      <React.Fragment>
        {this.state.live && (
          <Button
            onClick={() => {
              this.handleStopCampaign();
            }}
            className={"button button--right icon--aws-log"}
            buttonText={"Stop"}
            hidePlus={true}
          />
        )}

        <div className="tabs__wrapper">
          <Tabs className="tabs__links" value={tab} onChange={this.handleTab}>
            <LinkTab label="Stats" href="page1" />
            <LinkTab label="Call Log" href="page2" />
            <LinkTab label="Run Details" href="page3" />
          </Tabs>

          {tab === 0 && (
            <TabContainer>
              {graphData && (
                <React.Fragment>
                  <CampaignGraph
                    persecondStats={graphData.persecondStats}
                    showLegend={true}
                  />
                  <CampaignViewStatsOverallGraph
                    summaryStats={graphData.summaryStats}
                  />
                  <CampaignViewStats
                    campaignRun={this.props.campaignRun}
                    summaryStats={graphData.summaryStats}
                    failedStats={graphData.failedStats}
                    averages={graphData.averages}
                  />
                </React.Fragment>
              )}
            </TabContainer>
          )}

          {tab === 1 && (
            <TabContainer>
              <CallLog callLog={this.props.data} />
            </TabContainer>
          )}

          {tab === 2 && (
            <TabContainer>
              <CampaignViewDetails campaignRun={this.props.campaignRun} />
            </TabContainer>
          )}
        </div>
      </React.Fragment>
    );
  }
}

CampaignView.propTypes = {
  data: PropTypes.object,
  campaignRun: PropTypes.object,
  showSnackbarMessage: PropTypes.func,
  stopCampaign: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  status: PropTypes.string,
  match: PropTypes.object,
  fetchCallLog: PropTypes.object,
  updateDynamicBreadcrumb: PropTypes.func
};

const mapDispatchToProps = {
  fetchCallLog,
  showConfirmationDialog,
  showSnackbarMessage,
  fetchCampaignRun,
  stopCampaign,
  updateDynamicBreadcrumb
};

const mapStateToProps = state => ({
  data: state.campaign.callLog,
  loading: state.campaign.loading,
  status: state.campaign.status,
  campaignRun: state.campaign.campaignRun,
  random: state.campaign.random
});

// eslint-disable-next-line no-class-assign
CampaignView = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignView);

export default CampaignView;
