import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  downloadLogsFetched,
  fetchAgentCCPLogs,
  getAgentCCPLogs,
  showProgress
} from "../../actions/agent";
import {
  ButtonRefactored as Button,
  InputSearch,
  InputSelect,
  Skeleton,
  Table,
  TableFilter
} from "@operata/adagio";
import DateTimePicker from "../DateTime/Wrapper";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import TablePagination from "../Billing/TablePagination";
import PropTypes from "prop-types";
import Close from "@material-ui/icons/FileCopyOutlined";
import moment from "moment-timezone";
import { sortData, useDebounce } from "../../utils/datatables";

export const getSearchCriteria = (timeRange, agent, level, page, pageSize) => {
  timeRange = timeRange || ["Last 4 hours", "now-4h", "now"];
  const [, fromTime, toTime] = timeRange;

  const criteria = {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
    size: pageSize,
    from: page * pageSize
  };

  if (agent) {
    criteria.agent = agent;
  }

  if (level) {
    criteria.level = level;
  }

  return criteria;
};

const AgentLogs = ({ logs, recordCount, loading }) => {
  const logLevels = [
    "TRACE",
    "DEBUG",
    "INFO",
    "LOG",
    "WARN",
    "ERROR",
    "CRITICAL"
  ];

  const [agentFilter, setAgentFilter] = useState("");
  const [logLevelFilter, setLogLevelFilter] = useState(null);
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortKey, setSortKey] = useState("time");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedRange, setSelectedRange] = useState("Last 4 hours");
  const [isResetting, setIsResetting] = useState(false);

  const debouncedAgentFilter = useDebounce(agentFilter, 300, isResetting);
  const debouncedLogLevelFilter = useDebounce(logLevelFilter, 300, isResetting);

  function resetFilters() {
    setIsResetting(true);
    setAgentFilter("");
    setLogLevelFilter(null);
    setPage(0);
    setSelectedRange("Last 4 hours");
    setTimeRange(["Last 4 hours", "now-4h", "now"]);
    setTimeout(() => {
      setIsResetting(false);
    }, 0);
  }

  const handleSortClick = key => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const handleLogDownload = async () => {
    dispatch(showProgress());

    let logs = [];
    let page = 0;
    let batchSize = 1000; // download in batches of 1000
    const results = await getAgentCCPLogs(
      getSearchCriteria(timeRange, agentFilter, logLevelFilter, page, batchSize)
    );
    logs.push(...results.data);

    createJSONDownload(logs);

    dispatch(downloadLogsFetched());
  };

  const createJSONDownload = logs => {
    const blob = new Blob([JSON.stringify(logs)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "agent-logs.json";
    a.click();
    a.remove();
  };

  useEffect(
    () => {
      if (isResetting) return;
      dispatch(
        fetchAgentCCPLogs(
          getSearchCriteria(
            timeRange,
            agentFilter,
            logLevelFilter,
            page,
            pageSize
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      timeRange,
      page,
      pageSize,
      debouncedAgentFilter,
      debouncedLogLevelFilter,
      isResetting
    ]
  );

  let filteredLogs = logs || [];

  filteredLogs = debouncedAgentFilter
    ? filteredLogs.filter(log =>
        log.agent.toLowerCase().includes(debouncedAgentFilter.toLowerCase())
      )
    : filteredLogs;

  filteredLogs = logLevelFilter
    ? filteredLogs.filter(log =>
        log.level.toLowerCase().includes(logLevelFilter.toLowerCase())
      )
    : filteredLogs;

  const sortedLogs = filteredLogs.sort(sortData(sortKey, sortOrder));

  return (
    <>
      <TableFilter
        filters={
          <>
            <span
              onClick={setPickerOpen.bind(null, !pickerOpen)}
              className="time__link time__results"
            >
              {timeRange?.[0] === "Custom Range"
                ? `${timeRange[1].format(
                    "DD-MM-YYYY HH:mm"
                  )} - ${timeRange[2].format("DD-MM-YYYY HH:mm")}`
                : timeRange?.[0] || "Last 4 hours"}
            </span>

            <InputSearch
              value={agentFilter}
              placeholder="Agent"
              iconAfter={<Close />}
              onChange={event => setAgentFilter(event.target.value)}
              onClear={() => {
                setAgentFilter("");
                setPage(0);
              }}
              icon={false}
            />
            <InputSelect
              placeholder="Log Level"
              selectedValue={logLevelFilter}
              onChange={event => setLogLevelFilter(event.target.value)}
              setSelectedValue={logsFilter => {
                setLogLevelFilter(logsFilter);
                setPage(0);
              }}
            >
              {logLevels.map((logLevel, index) => (
                <InputSelect.Option key={index} value={logLevel} />
              ))}
            </InputSelect>
            <Button
              size="medium"
              type="secondary"
              disabled={!timeRange && !logLevelFilter && !agentFilter}
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>
          </>
        }
        actions={
          <Button
            type="secondary"
            size="medium"
            disabled={!filteredLogs.length}
            iconAfter={<Table.DownloadIcon />}
            onClick={handleLogDownload}
          >
            Download JSON
          </Button>
        }
      />
      <div
        style={{
          position: "relative",
          marginTop: "-50px",
          marginBottom: "66px"
        }}
      >
        <DateTimePicker
          rightAlign={false}
          dateRangeSelected={(range, start, end) => {
            if (range === "Custom Range") {
              setTimeRange([range, start, end]);
            } else {
              const timeRange = getRanges(new Date())[range];
              setTimeRange([range, timeRange[4], timeRange[5]]);
            }
            setSelectedRange(range); // Update the selected range state
            setPickerOpen(false);
          }}
          pickerOpen={pickerOpen ? "open" : ""}
          setPickerOpen={po => setPickerOpen(!!po)}
          currentRange={null}
          currentStartTime={timeRange?.[1]}
          currentEndTime={timeRange?.[2]}
          selectedRange={selectedRange}
        />
      </div>
      <Table>
        <thead>
          <Table.Row>
            <Table.Header
              width={240}
              align="left"
              sortable
              sortIcon={
                sortOrder === "desc" ? (
                  <Table.SortDescendingIcon fontSize="inherit" />
                ) : (
                  <Table.SortAscendingIcon fontSize="inherit" />
                )
              }
              onSortClick={() => handleSortClick("time")}
            >
              Timestamp
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "agent" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("agent")}
            >
              Agent
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "level" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("level")}
            >
              Log Level
            </Table.Header>
            <Table.Header width="auto">Text</Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : sortedLogs.length ? (
            sortedLogs.map((log, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <div>
                    {log.time ? (
                      moment(log.time).format("DD-MM-YYYY")
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                  <div>
                    {log.time ? (
                      moment(log.time).format("HH:mm:ss")
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>{log.agent}</Table.Cell>
                <Table.Cell>{log.level}</Table.Cell>
                <Table.Cell>{log.text}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={4}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        count={recordCount || 0}
      />
    </>
  );
};

AgentLogs.propTypes = {
  fetchAgentCCPLogs: PropTypes.func,
  logs: PropTypes.object,
  recordCount: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  fetchAgentCCPLogs
};

const mapStateToProps = state => ({
  logs: state.agent.ccpLogs || [],
  recordCount: state.agent.ccpLogsRecCount,
  loading: state.progress.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentLogs);
