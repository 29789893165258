import React from "react";
import "./Canvas.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { auth } from "../../auth/Auth";

let Canvas = props => {
  let nextPage = props.next.replace("@@group_id@@", auth.getCurrentGroupId());

  const location = window.location.href;

  const substrs = location.split("/");
  const index = substrs.length - 1;

  let locationSubstr = substrs[index];
  if (substrs.length > 1 && locationSubstr == "") {
    locationSubstr = substrs[index - 1];
  }
  if (locationSubstr != "" && locationSubstr != "canvas") {
    const nextStr = nextPage.substring(0, nextPage.lastIndexOf("/"));
    nextPage = nextStr + "/" + locationSubstr;
  }

  return (
    <div
      className={"iframe__wrapper superset__iframe"}
      dangerouslySetInnerHTML={{
        __html:
          `<iframe id="dashboard_iframe" title="canvas" src=` +
          process.env.REACT_APP_BI_URL.replace(
            ".operata.io",
            auth.getRegionalUrlPrefix() + ".operata.io"
          ) +
          nextPage +
          ` height="600" width="800" content="upgrade-insecure-requests"></iframe>`
      }}
    />
  );
};

export const mapStateToProps = state => ({
  next: state.reports.next
});

const mapDispatchToProps = {};

// eslint-disable-next-line no-class-assign
Canvas = connect(
  mapStateToProps,
  mapDispatchToProps
)(Canvas);

Canvas.propTypes = {
  next: PropTypes.string
};

export default withRouter(Canvas);
