import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import './Pagination.scss';
class Pagination extends React.Component {
  render() {
    return (
      <tbody>
        <tr>
          <TablePagination
            className="pagination__wrapper"
            count={this.props.count}
            page={this.props.page}
            rowsPerPage={this.props.rowsPerPage}
            onChangePage={this.props.onChangePage}
            onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            rowsPerPageOptions={this.props.rowsPerPageOptions}
          />
        </tr>
      </tbody>
    );
  }
}

export default Pagination;
