import React, { Component } from 'react';
import Modal from './Modal';
import { Route } from 'react-router-dom';
import _ from 'lodash'

class RouteModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.props.history.push(this.props.match.url);
  };

  render() {

    return (
      <Route
        path={this.props.path}
        render={(routeProps) => {
          
          const mergedChildenWithRouteProps = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, routeProps);
          });
          
          console.log(`merged children`, mergedChildenWithRouteProps)

          return (
            <React.Fragment>
              <Modal
                className="hidden"
                title={this.props.title}
                isModalOpen={true}
                onCloseModal={this.handleClose}
                modalSize={this.props.size}
              >
                <div className="grid__container">
                  <div className="grid__column grid__column--twelve">{mergedChildenWithRouteProps}</div>
                </div>
              </Modal>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

export default RouteModal;
