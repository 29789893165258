import {
  WORKFLOWS_RECEIVED,
  WORKFLOW_TOGGLED,
  WORKFLOW_CANCELLED,
  WORKFLOW_CREATED,
  ERROR_WORKFLOW_CREATED,
  WORKFLOW_REMOVED,
  EDIT_WORKFLOW,
  NEW_WORKFLOW,
  WORKFLOW_ACTIONS_HISTORY_RECEIVED,
  ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL,
  WORKFLOW_UPDATED
} from "../actions/workflow";

const actions = (state = { loading: false, data: [], history: [] }, action) => {
  switch (action.type) {
    case WORKFLOWS_RECEIVED:
      return Object.assign({}, state, {
        data: action.actions,
        loading: false,
        status: WORKFLOWS_RECEIVED
      });
    case WORKFLOW_ACTIONS_HISTORY_RECEIVED:
      return Object.assign({}, state, {
        history: action.history,
        loading: false,
        status: WORKFLOW_ACTIONS_HISTORY_RECEIVED
      });
    case ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL
      });
    case WORKFLOW_TOGGLED:
      return Object.assign({}, state, {
        data: state.data.map(watcher => {
          if (watcher.id === action.watcherId) {
            return Object.assign({}, watcher, { enabled: action.enabled });
          } else {
            return watcher;
          }
        })
      });
    case WORKFLOW_CANCELLED:
      return Object.assign({}, state, {
        loading: false,
        status: WORKFLOW_CANCELLED
      });
    case WORKFLOW_REMOVED:
      return Object.assign({}, state, {
        loading: false,
        status: WORKFLOW_REMOVED
      });
    case WORKFLOW_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: WORKFLOW_CREATED
      });
    case WORKFLOW_UPDATED:
      return Object.assign({}, state, {
        loading: false,
        status: WORKFLOW_UPDATED
      });
    case NEW_WORKFLOW:
      return Object.assign({}, state, {
        loading: false,
        status: NEW_WORKFLOW,
        workflowData: []
      });
    case EDIT_WORKFLOW:
      return Object.assign({}, state, {
        loading: false,
        status: EDIT_WORKFLOW,
        workflowData: action.data,
        random: Math.random() * 10000
      });
    case ERROR_WORKFLOW_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_WORKFLOW_CREATED,
        random: Math.random() * 10000
      };
    default:
      return state;
  }
};

export default actions;
