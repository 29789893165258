import React from "react";
import "./Header.scss";

import Breadcrumb from "../Breadcrumb/Breadcrumb";

function Header() {
  return (
    <div className="header__wrapper">
      <Breadcrumb />
    </div>
  );
}

export default Header;
