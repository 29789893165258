import React from "react";
import moment from "moment";
import _ from "lodash";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";
import { connect } from "react-redux";
import AudioPlayer from "../../Audio/AudioPlayer";
import { ERROR_LOGS_RETRIEVAL, fetchLogs } from "../../../actions/gsm";
import { NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Time, MUIDataTable } from "@operata/ui-components";
import { getTimestampCriteria } from "../../../utils/datatables";
import Chip from "../../Chips/Chip";

let Logs = class Logs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDisplayTexts: [""]
    };
  }

  componentDidMount() {
    this.props.fetchLogs(getTimestampCriteria("time:(from:now-7d,to:now)"));
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;

    if (nextProps.status === ERROR_LOGS_RETRIEVAL) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving gsm."
      );
    }
  }

  getFilterDateRange() {
    if (this.props.data.length) {
      return _.uniqBy(
        this.props.data.map(result => {
          return moment(result.createdOn).format("DD-MM-YYYY");
        })
      );
    }
  }

  render() {
    const resultColumns = [
      {
        name: "timestamp",
        label: "Timestamp",
        options: {
          sortDirection: "desc",
          filter: true,
          filterType: "custom",
          customFilterListRender: value => {
            if (value.length > 0) {
              return this.state.filterDisplayTexts[0];
            } else {
              return "";
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let selectedRange = "Last 7 days";
              let selectedRangeDisplay = "Last 7 days";
              let filterDisplay = [];

              return (
                <Time
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterDisplay[index] = selectedRangeDisplay;
                    filterList[index] = dateRange;
                    this.setState({ filterDisplayTexts: filterDisplay });
                    onChange(filterList[index], index, column);
                  }}
                  selectedRange={selectedRange}
                  selectedRangeDisplay={selectedRangeDisplay}
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
            logic: () => {
              return false;
            }
          },
          customBodyRender: createdOn => {
            return moment(createdOn).format("DD-MM-YYYY HH:mm:ss");
          }
        }
      },
      {
        name: "Job ID",
        label: "Job ID",
        options: {
          filter: true,
          customFilterListRender: value => `Job ID: ${value}`,
          sort: false,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Connect Region",
        label: "Connect Region",
        options: {
          filter: true,
          customFilterListRender: value => `Connect Region: ${value}`,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Agent Region",
        label: "Agent Region",
        options: {
          filter: true,
          customFilterListRender: value => `Agent Region: ${value}`,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "CX Score",
        label: "CX Score",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "AX Score",
        label: "AX Score",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Network Score",
        label: "Network Score",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Tags",
        label: "Tags",
        options: {
          filter: true,
          customFilterListRender: value => `Tags: ${value}`,
          sort: false,
          customBodyRender: tags => {
            let chips = [];
            if (tags) {
              tags.forEach(tag => {
                chips.push(<Chip label={tag} key={tag} />);
              });
            }
            return chips;
          }
        }
      },
      {
        name: "Carrier",
        label: "Carrier",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Status",
        label: "Status",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Preview",
        options: {
          filter: false,
          customBodyRender: fileName => {
            if (fileName)
              return <AudioPlayer src={fileName} size={35} borderWidth={2} />;
          }
        }
      },
      {
        name: "Actions",
        options: {
          filter: false,
          search: false,
          sort: false,
          customBodyRender: jobId => {
            return (
              <React.Fragment>
                {jobId ? (
                  <NavLink
                    className="textfield-bold actions__link"
                    to={"/gsm/jobs/" + jobId + "/report"}
                  >
                    Call Report
                  </NavLink>
                ) : (
                  <div />
                )}
              </React.Fragment>
            );
          }
        }
      }
    ];

    const resultOptions = {
      filter: true,
      search: true,
      selectableRows: "none",
      onFilterChange: (column, filterList) => {
        if (column === "timestamp") {
          this.props.fetchLogs(getTimestampCriteria(filterList[0]));
        }
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          data={this.props.data.map(result => {
            let jobIdUpdated =
              result.status === "Completed" ? result.jobId : undefined;
            return [
              result.createdOn,
              result.jobId,
              result.connectRegion,
              result.agentRegion,
              result.cxScore,
              result.axScore,
              result.networkScore,
              result.tags,
              result.carrier,
              result.status,
              result.preview,
              jobIdUpdated
            ];
          })}
          columns={resultColumns}
          options={resultOptions}
        />
      </React.Fragment>
    );
  }
};

Logs.propTypes = {
  fetchLogs: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string,
  data: PropTypes.array
};

const mapDispatchToProps = {
  fetchLogs: fetchLogs,
  showSnackbarMessage: showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.gsm.logs,
  loading: state.gsm.loading,
  status: state.gsm.status
});

Logs = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logs);

export default Logs;
