export const zoomHelper = {
    activate: function(g) {
    // Save the initial y-axis range for later.
    const initialValueRange = g.getOption('valueRange');
    return {
        dblclick: e => {
            e.dygraph.updateOptions({
                dateWindow: null,  // zoom all the way out
                valueRange: initialValueRange  // zoom to a specific y-axis range.
            });
            e.preventDefault();  // prevent the default zoom out action.
        }
        }
    }
}