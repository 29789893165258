import React from "react";
import "./Toggle.scss";
class Toggle extends React.Component {
  static defaultProps = {
    onText: "On",
    offText: "Off"
  };

  constructor(props) {
    super(props);
    this.state = { checked: this.props.checked };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState(
      prevState => ({
        checked: !prevState.checked
      }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.checked);
        }
      }
    );
  }

  renderText() {
    if (this.props.text) {
      return <span>{this.props.text}</span>;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.checked });
  }
  render() {
    return (
      <div className="toggle__wrapper">
        <input
          className="toggle__input"
          type="checkbox"
          disabled={this.props.disabled}
          checked={this.state.checked}
          onChange={this.handleChange}
          data-text-on={this.props.onText}
          data-text-off={this.props.offText}
        />
        {this.renderText()}
      </div>
    );
  }
}
export default Toggle;
