import React from "react";
import WorkflowContext from "../WorkflowContext";
import ActionConfigWebHook from "./ActionConfigWebHook";
import ActionConfigAgentNotification from "./ActionConfigAgentNotification";
import ActionConfigEmail from "./ActionConfigEmail";
import ActionConfigCreateEvent from "./ActionConfigCreateEvent";
import * as PropTypes from "prop-types";
import { ActionCondition } from "./ActionCondition";
import ActionConfigSlack from "./ActionConfigSlack";

class StepActionConfig extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
    this.currentTriggerConditionObjects = [];
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAddAction(action) {
    let actions = this.context.actions;
    if (actions.length <= 0) {
      actions.push(action);
    } else {
      actions[0] = action;
    }
  }

  getCurrentActionObject() {
    return this.currentActionObject;
  }

  getCurrentTriggerConditionObjects() {
    return this.currentTriggerConditionObjects;
  }

  render() {
    let prevState =
      this.context.actions.length > 0 ? this.context.actions[0] : {};

    return (
      <WorkflowContext.Consumer>
        {({
          trigger,
          action,
          triggerThreshold,
          setTriggerThreshold,
          availableTriggers
        }) => (
          <React.Fragment>
            <div className="grid__container">
              <div className="grid__column grid__column--four">
                {availableTriggers[trigger].triggerConditions &&
                  availableTriggers[trigger].triggerConditions.map(
                    (condition, i) => (
                      <ActionCondition
                        key={"action_condition_" + i}
                        field={condition.field}
                        description={condition.thresholdDesc}
                        default={condition.defaultThreshold}
                        handleAddCondition={setTriggerThreshold}
                        value={triggerThreshold[condition.field]}
                        required={condition.required}
                        type={condition.type}
                        options={condition.options}
                        onRef={ref =>
                          this.currentTriggerConditionObjects.push(ref)
                        }
                      />
                    )
                  )}

                {action === "invoke-webhook" && (
                  <ActionConfigWebHook
                    handleAddAction={this.handleAddAction}
                    actionType={action}
                    onRef={ref => (this.currentActionObject = ref)}
                    prevState={prevState}
                  />
                )}
                {action === "agent-assist-notification" && (
                  <ActionConfigAgentNotification
                    handleAddAction={this.handleAddAction}
                    actionType={action}
                    onRef={ref => (this.currentActionObject = ref)}
                    prevState={prevState}
                  />
                )}
                {action === "send-email" && (
                  <ActionConfigEmail
                    handleAddAction={this.handleAddAction}
                    actionType={action}
                    onRef={ref => (this.currentActionObject = ref)}
                    prevState={prevState}
                    legacy={!availableTriggers[trigger].watchdogWatcher}
                  />
                )}
                {action === "eventbridge" && (
                  <ActionConfigCreateEvent
                    handleAddAction={this.handleAddAction}
                    actionType={action}
                    onRef={ref => (this.currentActionObject = ref)}
                    prevState={prevState}
                    eventSources={this.props.eventSources}
                    handleAddCondition={setTriggerThreshold}
                  />
                )}
                {action === "send-to-slack" && (
                  <ActionConfigSlack
                    handleAddAction={this.handleAddAction}
                    actionType={action}
                    onRef={ref => (this.currentActionObject = ref)}
                    prevState={prevState}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </WorkflowContext.Consumer>
    );
  }
}

StepActionConfig.propTypes = {
  onRef: PropTypes.any,
  eventSources: PropTypes.array
};

StepActionConfig.contextType = WorkflowContext;

export default StepActionConfig;
