import CardAlternative from "../../../Card/CardAlternative";
import React from "react";
import * as PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { findAudioLatencyResult } from "../../utils";
import _ from "lodash";
import { mapSipResponseCodeToMessage } from "./utils/sip";

function Telephony(props) {
  const {
    jobStats,
    calculateDegradation,
    customerToConnectReference,
    insights
  } = props;

  let connect_to_dialler;
  let dialler_to_connect;
  let agent_to_connect;
  if (jobStats.polqaScores) {
    connect_to_dialler = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "connect_to_dialler"
    );
    agent_to_connect = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "agent_to_connect"
    );
    dialler_to_connect = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "dialler_to_connect"
    );
  }

  let echo_latency;
  let connect_latency;
  if (jobStats.audioLatency) {
    echo_latency = findAudioLatencyResult(jobStats.audioLatency, "echo");
    connect_latency = findAudioLatencyResult(jobStats.audioLatency, "connect");
  }

  return (
    <>
      <CardAlternative title={"Telephony"} />

      <div className="campaigns__grid">
        <List>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Quality"
              secondary={
                dialler_to_connect && _.has(dialler_to_connect, "score")
                  ? dialler_to_connect.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Quality"
              secondary={
                connect_to_dialler && _.has(connect_to_dialler, "score")
                  ? connect_to_dialler.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Loss"
              secondary={
                dialler_to_connect && _.has(dialler_to_connect, "score")
                  ? calculateDegradation(
                      customerToConnectReference,
                      dialler_to_connect.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Loss"
              secondary={
                connect_to_dialler &&
                agent_to_connect &&
                _.has(connect_to_dialler, "score") &&
                _.has(agent_to_connect, "score")
                  ? calculateDegradation(
                      agent_to_connect.score,
                      connect_to_dialler.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary="Source Number"
              secondary={
                insights && _.has(insights, "summary.from.caller")
                  ? insights.summary.from.caller
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Dialled Number"
              secondary={
                insights && _.has(insights, "summary.to.callee")
                  ? insights.summary.to.callee
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Answer Point DID"
              secondary={
                _.has(jobStats, "contactTraceSummary.SystemEndpoint.Address")
                  ? jobStats.contactTraceSummary.SystemEndpoint.Address
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Carrier"
              secondary={
                insights && insights.summary?.to.carrier
                  ? insights.summary.to.carrier
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="PSTN Latency"
              secondary={
                echo_latency &&
                connect_latency &&
                _.has(echo_latency, "averageLatencyMs") &&
                _.has(connect_latency, "averageLatencyMs")
                  ? echo_latency.averageLatencyMs -
                    connect_latency.averageLatencyMs +
                    " ms"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Post Dial Delay"
              secondary={
                insights && _.has(insights, "summary.properties.pdd_ms")
                  ? insights.summary.properties.pdd_ms + " ms"
                  : "N/A"
              }
            />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary="SIP Response"
              secondary={
                insights &&
                _.has(insights, "summary.properties.last_sip_response_num")
                  ? mapSipResponseCodeToMessage(
                      insights.summary.properties.last_sip_response_num
                    )
                  : "N/A"
              }
            />
          </ListItem>
        </List>
      </div>
    </>
  );
}

Telephony.propTypes = {
  jobStats: PropTypes.object,
  calculateDegradation: PropTypes.func,
  customerToConnectReference: PropTypes.number,
  insights: PropTypes.object
};

export default Telephony;
