import React, { Component } from 'react';
import vegaEmbed from 'vega-embed';
import { connect } from 'react-redux';

const EXPECTED_CALLS_LINE_COLOUR = '#c86dd7';
const ACTUAL_CALLS_LINE_COLOUR = '#3023ae';

class CampaignGraph extends Component {
  componentDidMount() {
    this.showGraph();
  }

  componentDidUpdate() {
    this.showGraph();
  }

  showGraph() {
    let spec = this._spec();
    spec.width = this.props.width ? this.props.width : this.props.windowWidth - 40;

    vegaEmbed(this.refs.chartContainer, spec, { tooltip: { theme: 'dark' }, actions: false })
      .then(function(result) {})
      .catch(console.error);
  }

  updateDimensions = () => {
    this.setState({ graphWidth: this.getMainContentWidth() });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return <div ref="chartContainer" className="graph__wrapper" />;
  }

  _spec() {
    const retJson = {
      $schema: 'https://vega.github.io/schema/vega/v5.json',
      width: 'container',
      height: 400,
      padding: 0,

      data: [
        {
          name: 'table',
          values: this.props.persecondStats,
        },
        {
          name: 'legend',
          values: {
            expected: 'Expected calls',
            actual: 'Actual calls',
          },
        },
      ],

      scales: [
        {
          name: 'x',
          type: 'linear',
          range: 'width',
          domain: { data: 'table', field: 'second' },
          round: true,
          nice: true,
        },
        {
          name: 'ideal',
          type: 'linear',
          range: 'height',
          zero: true,
          domain: { data: 'table', fields: ['idealCalls', 'realCalls'] },
          round: true,
          nice: true,
        },
        {
          name: 'color',
          type: 'ordinal',
          domain: { data: 'legend', fields: ['expected', 'actual'] },
          range: [EXPECTED_CALLS_LINE_COLOUR, ACTUAL_CALLS_LINE_COLOUR],
        },
      ],

      axes: [{ orient: 'bottom', scale: 'x', title: 'Seconds', grid: true }, { orient: 'left', scale: 'ideal', title: 'Number of Calls', grid: true }],

      legends: [
        {
          title: 'Legend',
          orient: 'top-right',
          fill: 'color',
          titleOpacity: this.props.showLegend ? 1 : 0,
          symbolOpacity: this.props.showLegend ? 1 : 0,
          labelOpacity: this.props.showLegend ? 1 : 0,
        },
      ],

      marks: [
        {
          type: 'group',
          from: {
            facet: {
              name: 'series',
              data: 'table',
              groupby: 'c',
            },
          },
          marks: [
            {
              type: 'line',
              from: { data: 'table' },
              encode: {
                enter: {
                  x: { scale: 'x', field: 'second', axis: { tickMinStep: 1 } },
                  y: { scale: 'ideal', field: 'idealCalls', axis: { tickMinStep: 1 } },
                  stroke: { value: EXPECTED_CALLS_LINE_COLOUR },
                  strokeWidth: { value: 1 },
                },
              },
            },
            {
              type: 'line',
              from: { data: 'table' },
              encode: {
                update: {
                  x: { scale: 'x', field: 'second', axis: { tickMinStep: 1 } },
                  y: { scale: 'ideal', field: 'realCalls', axis: { tickMinStep: 1 } },

                  // strokeWidth: [
                  //   {
                  //     test: 'datum.realCalls == null',
                  //     value: 20,
                  //   }
                  // ],
                  stroke: [
                    {
                      test: 'datum.realCalls',
                      value: ACTUAL_CALLS_LINE_COLOUR,
                    },
                    {
                      test: 'datum.realCalls',
                      value: 2,
                    },
                  ],
                  defined: { signal: 'datum.realCalls  !== null' },
                  tooltip: { signal: '{Ideal: datum.idealCalls, Actual: datum.realCalls}' },
                },
              },
            },
            {
              name: 'points_on_line',
              from: { data: 'series' },
              type: 'symbol',
              encode: {
                update: {
                  defined: { signal: 'datum.realCalls  !== null' },
                  fill: [
                    {
                      test: 'datum.realCalls  !== null',
                      value: '#00000',
                    },
                  ],
                  size: { value: 10 },
                  stroke: [
                    {
                      test: 'datum.realCalls  !== null',
                      value: ACTUAL_CALLS_LINE_COLOUR,
                    },
                  ],
                  strokeWidth: { value: 0.5 },
                  x: { scale: 'x', field: 'second', axis: { tickMinStep: 1 } },
                  y: { scale: 'ideal', field: 'realCalls', axis: { tickMinStep: 1 } },
                  tooltip: { signal: '{Ideal: datum.idealCalls, Actual: datum.realCalls}' },
                },
              },
            },
            {
              name: 'failed_points',
              from: { data: 'series' },
              type: 'symbol',
              encode: {
                update: {
                  fill: [
                    {
                      test: 'datum.unsuccessfulCalls > 0',
                      value: 'red',
                    },
                  ],
                  size: { value: 40 },
                  stroke: [
                    {
                      test: 'datum.unsuccessfulCalls > 0',
                      value: '#00000',
                    },
                  ],
                  strokeWidth: { value: 1.0 },
                  x: { scale: 'x', field: 'second' },
                  y: { scale: 'ideal', field: 'unsuccessfulCalls' },
                  tooltip: { signal: '{Failed: datum.unsuccessfulCalls}' },
                },
              },
            },
          ],
        },
      ],
    };

    return retJson;
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
});

CampaignGraph = connect(
  mapStateToProps,
  null
)(CampaignGraph);

export default CampaignGraph;
