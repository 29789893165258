import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { validate } from './ActionConfigAgentNotificationValidation';
import { OutlinedInput, FormControl, InputLabel } from '@material-ui/core';

class ActionConfigAgentNotification extends React.Component {
  constructor(props) {
    super();

    this.state = {
      actionId: '',
      type: props.actionType,
      messageType: '',
      actionButton: '',
      body: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    let { prevState } = this.props;
    if (prevState.type === this.state.type) {
      this.setState(prevState);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.handleAddAction(this.state);
      }
    );
  }

  handleAddAction() {
    let errors = validate(this.state, ['messageType', 'actionButton', 'body'], this);
    if (!errors['hasErrors']) {
      this.state.actionId = 'Agent_Assist_Notification';
      this.state.displayText = 'Agent Notification';
    }

    this.props.handleAddAction(this.state);
    return errors;
  }

  render() {
    return (
      <React.Fragment>
        <div className="form__control">
          <FormControl variant="outlined">
            <InputLabel htmlFor="messageType-helper">
              {this.state.errorMessage_messageType ? this.state.errorMessage_messageType : 'Message Type'}
            </InputLabel>

            <Select
              name="messageType"
              error={this.state.error_messageType}
              variant="outlined"
              value={this.state.messageType}
              onChange={event => this.handleChange(event)}
              input={<OutlinedInput name="messageType" id="messageType-helper" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'network-issue'}>Network Issue</MenuItem>
              <MenuItem value={'audio-issue'}>Audio Issue</MenuItem>
              <MenuItem value={'headset-audio-delay'}>Audio Delay</MenuItem>
              <MenuItem value={'headset-crosstalk'}>Crosstalk</MenuItem>
              <MenuItem value={'headset-background-noise'}>Background Noise</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="form__control">
          <FormControl variant="outlined">
            <InputLabel htmlFor="actionButton-helper">
              {this.state.errorMessage_actionButton ? this.state.errorMessage_actionButton : 'Action Buttons'}
            </InputLabel>
            <Select
              name="actionButton"
              error={this.state.error_actionButton}
              variant="outlined"
              value={this.state.actionButton}
              onChange={event => this.handleChange(event)}
              input={<OutlinedInput name="actionButton" id="actionButton-helper" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'thumbs-up-or-down'}>Thumbs Up/Down</MenuItem>
              <MenuItem value={'yes-or-no'}>Yes/No</MenuItem>
              <MenuItem value={'no-buttons'}>No Buttons</MenuItem>
              <MenuItem value={'run-network-test'}>Run Network Test</MenuItem>
              <MenuItem value={'link'}>Link</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="form__control">
          <TextField
            error={this.state.error_body}
            label={this.state.errorMessage_body ? this.state.errorMessage_body : 'Body'}
            id="outlined-required"
            name="body"
            className="textField"
            onChange={event => this.handleChange(event)}
            variant="outlined"
            multiline={true}
            rows="3"
            value={this.state.body}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ActionConfigAgentNotification;
