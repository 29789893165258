import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { TimelineChart } from "@operata/ui-components";

const SignallingEvents = props => {
  let softphoneEventTimestamps = {};

  //excluding muted and unmuted timestamps
  if (props.softphoneEvents) {
    for (let key in props.softphoneEvents) {
      if (key === "ended") {
        softphoneEventTimestamps[key] = props.softphoneEvents[key].timestamp;
      } else if (key !== "muted" && key !== "unmuted" && key !== "ended") {
        softphoneEventTimestamps[key] = props.softphoneEvents[key];
      }
    }
  }

  let seriesEventsData = [];

  for (let key in softphoneEventTimestamps) {
    seriesEventsData.push({
      x: Date.UTC(
        new Date(softphoneEventTimestamps[key]).getFullYear(),
        new Date(softphoneEventTimestamps[key]).getMonth(),
        new Date(softphoneEventTimestamps[key]).getDate(),
        new Date(softphoneEventTimestamps[key]).getHours(),
        new Date(softphoneEventTimestamps[key]).getMinutes(),
        new Date(softphoneEventTimestamps[key]).getSeconds()
      ),
      name: "connection",
      label: key.concat(
        " at ".concat(
          moment(softphoneEventTimestamps[key]).format("DD-MM-YYYY HH:mm:ss")
        )
      ),
      description: key.concat(
        " at ".concat(
          moment(softphoneEventTimestamps[key]).format("DD-MM-YYYY HH:mm:ss")
        )
      )
    });
  }

  let eventsData = {
    title: "",
    data: [
      {
        data: seriesEventsData
      }
    ]
  };

  return <TimelineChart {...eventsData} />;
};

SignallingEvents.propTypes = {
  softphoneEvents: PropTypes.object
};
export default SignallingEvents;
