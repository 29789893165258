import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MUIDataTable } from "@operata/ui-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import uuidAPIKey from "uuid-apikey";
import ClipboardJS from "clipboard";
import { showConfirmationDialog } from "../../actions/dialog";
import "./Configs.scss";
import { formatDate } from "../../utils/date";

let ApiTokenConfig = class ApiTokenConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newTokenLabel: "",
      labelError: false
    };

    this.createNewAPIToken = this.createNewAPIToken.bind(this);
    this.handleTooltipHide = this.handleTooltipHide.bind(this);

    this.setupClipboard();
  }

  setupClipboard() {
    // instantiate clipboard
    const clipboard = new ClipboardJS(".apiToken__masked");

    clipboard.on("success", e => {
      this.setState({
        copied: e.text
      });
    });
  }

  handleTooltipHide() {
    this.setState({
      copied: ""
    });
  }

  createNewAPIToken() {
    const label = this.state.newTokenLabel;
    if (label.trim() === "") {
      this.setState({
        labelError: true
      });
      return;
    }
    const { apiKey } = uuidAPIKey.create({ noDashes: true });
    this.props.onAdd({
      label,
      token: apiKey,
      createdOn: new Date(Date.now()).toISOString()
    });
    this.setState({
      newTokenLabel: ""
    });
  }

  handleRevokeAPIToken(item) {
    let { onRevoke, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Revoke API token",
      "Revoking this token will cause any applications using this token to stop working. Are you sure you want to revoke this token?",
      onRevoke,
      [item],
      () => {},
      []
    );
  }

  render() {
    const { values, editable } = this.props;
    const { newTokenLabel, labelError, copied } = this.state;

    let columns = [
      {
        name: "Label"
      },
      {
        name: "Token",
        options: {
          customBodyRender: value => {
            return (
              <Tooltip
                placement="right"
                title="Copied"
                open={copied === value}
                arrow
              >
                <span
                  className="apiToken__masked"
                  data-clipboard-text={value}
                  onMouseLeave={this.handleTooltipHide}
                >
                  {value}
                </span>
              </Tooltip>
            );
          }
        }
      },
      {
        name: "Last Accessed"
      },
      {
        name: "Created On"
      }
    ];
    if (editable) {
      columns.push({
        name: "Actions",
        options: {
          filter: false,
          customBodyRender: item => {
            return (
              <div className="group__links">
                {
                  <React.Fragment>
                    <span
                      className="textfield-bold actions__link"
                      onClick={() => {
                        this.handleRevokeAPIToken(item);
                      }}
                    >
                      Revoke
                    </span>
                  </React.Fragment>
                }
              </div>
            );
          }
        }
      });
    }

    const options = {
      selectableRows: "none",
      filter: false,
      filterType: "dropdown",
      fixedHeader: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      pagination: false
    };

    return (
      <Grid container>
        {editable && (
          <Grid container spacing={5} justify="flex-end">
            <Grid item xs={8}>
              <TextField
                required
                error={labelError}
                label="Label"
                name="label"
                className="textfield"
                variant="outlined"
                onChange={event =>
                  this.setState({
                    newTokenLabel: event.target.value,
                    labelError: false
                  })
                }
                value={newTokenLabel}
              />
            </Grid>
            <Grid item xs={4}>
              <div>
                <button className={"button"} onClick={this.createNewAPIToken}>
                  <span className="button__circle">+</span>
                  New API Token
                </button>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <MUIDataTable
              data={values.map(value => {
                return [
                  value.label,
                  value.token,
                  value.lastAccessed ? formatDate(value.lastAccessed) : "Never",
                  value.createdOn ? formatDate(value.createdOn) : "",
                  value
                ];
              })}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

ApiTokenConfig.propTypes = {
  values: PropTypes.array,
  onAdd: PropTypes.func,
  onRevoke: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  editable: PropTypes.bool
};

const mapDispatchToProps = {
  showConfirmationDialog: showConfirmationDialog
};

const mapStateToProps = () => ({});

ApiTokenConfig = connect(
  // eslint-disable-line no-class-assign
  mapStateToProps,
  mapDispatchToProps
)(ApiTokenConfig);

export default ApiTokenConfig;
