import { auth } from "../auth/Auth";

export const getLocalStateObject = () => {
  if (localStorage.getItem("state")) {
    try {
      return JSON.parse(localStorage.getItem("state"));
    } catch (e) {
      console.log(`error occurred while parsing local state object `, e);
    }
  }

  return null;
};

export const getGroupIdFromRequestedURL = () => {
  const currentRequestPath = getCurrentURLPath();
  if (currentRequestPath) {
    try {
      const urlParts = currentRequestPath.split("/");
      if (urlParts.length > 1) {
        return urlParts[1];
      }
    } catch (e) {
      console.log(`couldn't get groupId from the requested url`, e);
    }
  }

  return null;
};

export const getCurrentURLPath = () => {
  return window.location.href.replace(window.location.origin, "");
};

export const prefixedPath = path => {
  return "/" + auth.getCurrentGroupId() + path;
};
