import React from "react";
import MUIDataTable from "../Datatables";
import Modal from "../Modal/Modal";
import Wizard from "./Wizard";
import Pagination from "../Pagination/Pagination";
import Chip from "../Chips/Chip";
import { connect } from "react-redux";
import "./Actions.scss";
import "../ActionBar/ActionBar.scss";
import PropTypes from "prop-types";
import {
  fetchActions,
  toggleAction,
  revertToggle,
  deleteWorkflow,
  editWorkflow,
  WORKFLOW_CANCELLED,
  WORKFLOW_CREATED,
  WORKFLOW_REMOVED,
  EDIT_WORKFLOW,
  NEW_WORKFLOW,
  newWorkflow,
  WORKFLOW_UPDATED
} from "../../actions/workflow";
import { showConfirmationDialog } from "../../actions/dialog";
import { showSnackbarMessage, SNACKBAR_SUCCESS } from "../../actions/snackbar";
import Toggle from "../Toggle/Toggle";
import {
  AVAILABLE_APPLICATIONS,
  AVAILABLE_TRIGGERS,
  AVAILABLE_ACTIONS
} from "./WizardConstants";

class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalTitle: ""
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleActionToggle = this.handleActionToggle.bind(this);
    this.handleRemoveWorkflow = this.handleRemoveWorkflow.bind(this);
    this.handleCancelRemoveWorkflow = this.handleCancelRemoveWorkflow.bind(
      this
    );
    this.handleEditWorkflow = this.handleEditWorkflow.bind(this);
    this.handleNewWorkflow = this.handleNewWorkflow.bind(this);
    this.componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(
      this
    );
  }

  componentDidMount() {
    this.props.fetchActions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;
    if (nextProps.status === WORKFLOW_CREATED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Workflow created successfully.");
      this.setState({ modalOpen: false });
      this.props.fetchActions();
    } else if (nextProps.status === WORKFLOW_UPDATED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Workflow updated successfully.");
      this.setState({ modalOpen: false });
      this.props.fetchActions();
    } else if (
      nextProps.status === WORKFLOW_CANCELLED ||
      nextProps.status === WORKFLOW_REMOVED
    ) {
      this.props.fetchActions();
    } else if (nextProps.status === EDIT_WORKFLOW) {
      this.setState({ modalOpen: true, modalTitle: "Edit Workflow" });
    } else if (nextProps.status === NEW_WORKFLOW) {
      this.setState({ modalOpen: true, modalTitle: "Create New Workflow" });
    }
  }

  handleActionToggle(watchId, enabled, watchdogWatcher) {
    let { toggleAction, revertToggle, showConfirmationDialog } = this.props;
    let dialogTitlePrefix = enabled ? "Disable" : "Enable";
    showConfirmationDialog(
      dialogTitlePrefix + " Action",
      "Are you sure you want to " +
        dialogTitlePrefix.toLowerCase() +
        " this action?",
      toggleAction,
      [watchId, enabled, watchdogWatcher],
      revertToggle,
      []
    );
  }

  handleCancelRemoveWorkflow() {
    //do nothing
  }

  handleRemoveWorkflow(event, watcherId, watchdogWatcher) {
    let { deleteWorkflow, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Delete Workflow",
      "Are you sure you want to delete this Workflow?",
      deleteWorkflow,
      [watcherId, watchdogWatcher],
      this.handleCancelRemoveWorkflow,
      []
    );
    event.preventDefault();
  }

  handleEditWorkflow(event, data) {
    let { editWorkflow } = this.props;
    editWorkflow(data);
    event.preventDefault();
  }

  handleNewWorkflow() {
    this.props.newWorkflow();
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
    this.props.revertToggle();
  }

  render() {
    const columns = [
      {
        name: "Workflow",
        label: "Workflow",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            const { application, action, trigger } = value;

            return (
              <div className="workflow-journey">
                <span className="workflow-journey__icon">
                  <img src={application} alt="icon" />
                </span>
                <span className="workflow-journey__icon">
                  <img src={trigger} alt="icon" />
                </span>
                <span className="workflow-journey__icon">
                  <img src={action} alt="icon" />
                </span>
              </div>
            );
          }
        }
      },
      {
        name: "Description",
        label: "Description",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            const { name, description, executeOn } = value;

            return (
              <div className="workflow-description">
                <div className="textfield-bold">{description}</div>
                <div className="textfield-description">{name}</div>
                <div className="textfield-comment">{executeOn}</div>
              </div>
            );
          }
        }
      },
      {
        name: "Trigger",
        label: "Trigger Type",
        options: {
          filter: true,
          customFilterListRender: value => `Trigger: ${value}`,
          sort: true,
          customBodyRender: value => {
            return <span className={`textfield`}>{value}</span>;
          }
        }
      },
      {
        name: "Tags",
        label: "Tags",
        options: {
          filter: true,
          customFilterListRender: value => `Tag: ${value}`,
          sort: true,
          customBodyRender: tags => {
            let chips = [];
            if (tags) {
              tags.forEach(tag => {
                chips.push(<Chip label={tag} key={tag} />);
              });
            }
            return chips;
          }
        }
      },
      {
        name: "Enabled",
        label: "Status",
        options: {
          filter: true,
          customFilterListRender: value => `Enabled: ${value}`,
          filterOptions: {
            names: ["On", "Off"],
            logic(value, filterVal) {
              return !value.enabled === (filterVal[0] === "On");
            }
          },
          sort: true,
          customBodyRender: value => {
            return (
              <Toggle
                onText="On"
                offText="Off"
                checked={value.enabled}
                onChange={() =>
                  this.handleActionToggle(
                    value.watchId,
                    value.enabled,
                    value.watchdogWatcher
                  )
                }
              />
            );
          }
        }
      },
      {
        name: "Actions",
        options: {
          filter: false,
          customBodyRender: item => {
            return (
              <React.Fragment>
                <span
                  className="textfield-bold actions__link"
                  onClick={event => {
                    this.handleEditWorkflow(event, item);
                  }}
                >
                  Edit
                </span>

                <span
                  className="textfield-bold actions__link"
                  onClick={event => {
                    this.handleRemoveWorkflow(
                      event,
                      item.id,
                      AVAILABLE_TRIGGERS[item.type].watchdogWatcher || false
                    );
                  }}
                >
                  Delete
                </span>
              </React.Fragment>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: true,
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      searchText: " ",
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      }
    };

    return (
      <React.Fragment>
        <Modal
          className="button button--right"
          buttonText={"Create Workflow"}
          onButtonClick={this.handleNewWorkflow}
          title={this.state.modalTitle}
          isModalOpen={this.state.modalOpen}
          onCloseModal={this.handleCloseModal}
          disableBackdropClick={true}
        >
          <Wizard
            handleClose={this.handleClose}
            open={this.state.modalOpen}
            data={this.props.workflowData}
          />
        </Modal>

        <MUIDataTable
          data={this.props.data.map(item => {
            return [
              {
                application: AVAILABLE_APPLICATIONS[item.application]?.icon,
                trigger: AVAILABLE_TRIGGERS[item.type]?.icon,
                action: AVAILABLE_ACTIONS[item.actions[0].type]?.icon
              },
              {
                name: item.name,
                description: item.description,
                executeOn: item.status
              },
              AVAILABLE_TRIGGERS[item.type]?.displayText,
              item.tags,
              {
                watchId: item.id,
                enabled: item.enabled,
                watchdogWatcher:
                  AVAILABLE_TRIGGERS[item.type]?.watchdogWatcher || false
              },
              item
            ];
          })}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    );
  }
}

Actions.propTypes = {
  status: PropTypes.string,
  fetchActions: PropTypes.func,
  toggleAction: PropTypes.func,
  revertToggle: PropTypes.func,
  deleteWorkflow: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  editWorkflow: PropTypes.func,
  newWorkflow: PropTypes.func,
  workflowData: PropTypes.array,
  data: PropTypes.array
};

const mapDispatchToProps = {
  fetchActions: fetchActions,
  toggleAction: toggleAction,
  revertToggle: revertToggle,
  deleteWorkflow: deleteWorkflow,
  showConfirmationDialog: showConfirmationDialog,
  showSnackbarMessage: showSnackbarMessage,
  editWorkflow: editWorkflow,
  newWorkflow: newWorkflow
};

const mapStateToProps = state => ({
  data: state.workflow.data,
  loading: state.workflow.loading,
  status: state.workflow.status,
  workflowData: state.workflow.workflowData,
  random: state.workflow.random
});

// eslint-disable-next-line no-class-assign
Actions = connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);

export default Actions;
