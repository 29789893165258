import React from "react";
import { Button } from "@operata/ui-components";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import MonitorContext from "./MonitorContext";
import {
  createMonitor,
  ERROR_MONITOR_CREATED,
  MONITOR_CREATED
} from "../../../../actions/gsm";
import { connect } from "react-redux";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS
} from "../../../../actions/snackbar";
import { validate } from "./validation";
import "../../../Wizard/Wizard.scss";
import StepDescription from "./WizardContent/StepDescription";
import StepConnectRegions from "./WizardContent/StepConnectRegions";
import StepAgentRegions from "./WizardContent/StepAgentRegions";
import StepSchedule from "./WizardContent/StepSchedule";
import PropTypes from "prop-types";

function getSteps() {
  return ["Description", "Connect Regions", "Agent Regions", "Schedule"];
}

let CreateMonitorWizard = class CreateMonitorWizard extends React.Component {
  constructor(props) {
    super(props);

    this.getStepContent = this.getStepContent.bind(this);

    this.state = {
      activeStep: 0,

      description: "",
      setDescription: this.setDescription,

      initConnectRegions: {
        "North Virginia": false,
        Oregon: false,
        Singapore: false,
        Sydney: false,
        Tokyo: false,
        Frankfurt: false,
        London: false
      },
      connectRegions: [],
      setConnectRegions: this.setConnectRegions,

      initAgentRegions: {
        "North Virginia": false,
        Oregon: false,
        Singapore: false,
        Sydney: false,
        Tokyo: false,
        Frankfurt: false,
        London: false
      },
      agentRegions: [],
      setAgentRegions: this.setAgentRegions,

      schedule: "",
      setSchedule: this.setSchedule
    };
  }

  setDescription = description => {
    this.setState({ description });
  };

  setConnectRegions = value => {
    let connectRegions = this.state.connectRegions;
    let initConnectRegions = this.state.initConnectRegions;
    if (connectRegions.includes(value)) {
      connectRegions.splice(connectRegions.indexOf(value), 1);
      initConnectRegions[value] = false;
    } else {
      connectRegions.push(value);
      initConnectRegions[value] = true;
    }
    this.setState({ connectRegions, initConnectRegions });
  };

  setAgentRegions = value => {
    let agentRegions = this.state.agentRegions;
    let initAgentRegions = this.state.initAgentRegions;
    if (agentRegions.includes(value)) {
      agentRegions.splice(agentRegions.indexOf(value), 1);
      initAgentRegions[value] = false;
    } else {
      agentRegions.push(value);
      initAgentRegions[value] = true;
    }
    this.setState({ agentRegions, initAgentRegions });
  };

  setSchedule = schedule => {
    this.setState({ schedule });
  };

  handleSave = () => {
    let { createMonitor } = this.props;
    let { monitor } = {};
    if (this.state.schedule === "0m") {
      monitor = {
        description: this.state.description,
        connectRegions: this.state.connectRegions,
        agentRegions: this.state.agentRegions
      };
    } else {
      monitor = {
        description: this.state.description,
        connectRegions: this.state.connectRegions,
        agentRegions: this.state.agentRegions,
        schedule: this.state.schedule
      };
    }

    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      createMonitor(monitor);
    } else {
      this.showValidationError();
    }
  };

  static getDerivedStateFromProps(nextProps) {
    let { showSnackbarMessage } = nextProps;

    if (nextProps.status === ERROR_MONITOR_CREATED) {
      const { errorMsg } = nextProps;
      showSnackbarMessage(SNACKBAR_ERROR, errorMsg);
      return null;
    }
    if (nextProps.status === MONITOR_CREATED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "A new monitor has been created successfully."
      );
      return {
        activeStep: 0,
        description: "",
        initConnectRegions: {
          "North Virginia": false,
          Oregon: false,
          Singapore: false,
          Sydney: false,
          Tokyo: false,
          Frankfurt: false,
          London: false
        },
        connectRegions: [],
        initAgentRegions: {
          "North Virginia": false,
          Oregon: false,
          Singapore: false,
          Sydney: false,
          Tokyo: false,
          Frankfurt: false,
          London: false
        },
        agentRegions: [],
        schedule: ""
      };
    }

    return null;
  }

  showValidationError() {
    let { showSnackbarMessage } = this.props;
    const steps = getSteps();
    switch (this.state.activeStep) {
      case steps.indexOf("Description"):
        showSnackbarMessage(SNACKBAR_ERROR, "Please enter a Description.");
        return;
      case steps.indexOf("Connect Regions"):
        showSnackbarMessage(SNACKBAR_ERROR, "Please select Connect Region(s).");
        return;
      case steps.indexOf("Agent Regions"):
        showSnackbarMessage(SNACKBAR_ERROR, "Please select Agent Region(s).");
        return;
      case steps.indexOf("Schedule"):
        showSnackbarMessage(SNACKBAR_ERROR, "Please select a Schedule.");
        return;
    }
  }

  validateCurrentStep() {
    let errors = {};
    const steps = getSteps();
    switch (this.state.activeStep) {
      case steps.indexOf("Description"):
        return (errors = validate(this.state, ["description"], this));
      case steps.indexOf("Connect Regions"):
        if (this.state.connectRegions.length < 1) {
          return (errors = {
            connectRegion: "Required (Connect Region)",
            hasErrors: true
          });
        } else {
          return {};
        }
      case steps.indexOf("Agent Regions"):
        if (this.state.agentRegions.length < 1) {
          return (errors = {
            agentRegion: "Required (Agent Region)",
            hasErrors: true
          });
        } else {
          return {};
        }
      case steps.indexOf("Schedule"):
        return (errors = validate(this.state, ["schedule"], this));
    }

    return errors;
  }

  getStepContent(stepIndex) {
    const steps = getSteps();

    switch (stepIndex) {
      case steps.indexOf("Description"):
        return <StepDescription />;
      case steps.indexOf("Connect Regions"):
        return <StepConnectRegions />;
      case steps.indexOf("Agent Regions"):
        return <StepAgentRegions />;
      case steps.indexOf("Schedule"):
        return <StepSchedule />;
      default:
        return "Unknown step";
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      this.setState({
        activeStep: activeStep + 1
      });
    } else {
      this.showValidationError();
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  render() {
    const steps = getSteps();

    const { activeStep } = this.state;
    const displayPrev = this.state.activeStep > 0;
    const displayNext = this.state.activeStep < steps.length - 1;
    const displaySave = this.state.activeStep === steps.length - 1;

    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} className="wizard__progress">
          {steps.map((label, index) => {
            const labelProps = {};
            if (index === activeStep) {
              labelProps.icon = (
                <div className="step step__active">{label}</div>
              );
            }
            if (index > activeStep) {
              labelProps.icon = (
                <div className="step step__future">{label}</div>
              );
            }
            if (index < activeStep) {
              labelProps.icon = <div className="step step__past">{label}</div>;
            }
            return (
              <Step key={label}>
                <StepLabel icon={index + 1} {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        <div className="wizard__content">
          <MonitorContext.Provider value={this.state}>
            {this.getStepContent(this.state.activeStep)}
          </MonitorContext.Provider>
        </div>

        <div className="wizard__btn-container">
          {displayPrev && (
            <Button
              onClick={this.handleBack}
              buttonText={"Previous"}
              className={"btnColoured"}
            />
          )}
          {displayNext && (
            <Button
              className={"btnSolid"}
              buttonText={"Next"}
              onClick={this.handleNext}
            />
          )}
          {displaySave && (
            <Button
              asyncButton={true}
              buttonText={"Create"}
              className={"btnSolid"}
              onClick={this.handleSave}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
};

CreateMonitorWizard.propTypes = {
  createMonitor: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string,
  errorMsg: PropTypes.string
};

const mapDispatchToProps = {
  createMonitor: createMonitor,
  showSnackbarMessage: showSnackbarMessage
};

const mapStateToProps = state => ({
  status: state.gsm.status,
  errorMsg: state.gsm.errorMsg,
  data: state.gsm.monitorData
});

CreateMonitorWizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMonitorWizard);

export default CreateMonitorWizard;
