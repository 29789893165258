import HighchartsReact from "highcharts-react-official";
import React from "react";
import StatusModal from "./StatusModal";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import customWorld from "@highcharts/map-collection/custom/world.geo.json";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
highchartsMap(Highcharts);

const modalOptions = {
    isError: false,
    title: "",
    cxTag: "",
    axTag: "",
    serviceAvailabilityTag: ""
}

function setModalOptions(e) {
    modalOptions.title = `CX Failures in ` + e.point.keyword;
}

if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
}

let StatusMap = class StatusMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: null,
            modalOpen: false
        };

        this.state.mapOptions = {
            chart: {
                map: 'custom/world',
                backgroundColor: "transparent"
            },
            title: {
                text: ''
            },
            legend: {
                title: {
                    text: 'Population density per km²',
                    style: {
                        color: ( // theme
                            Highcharts.defaultOptions &&
                            Highcharts.defaultOptions.legend &&
                            Highcharts.defaultOptions.legend.title &&
                            Highcharts.defaultOptions.legend.title.style &&
                            Highcharts.defaultOptions.legend.title.style.color
                        ) || 'black'
                    }
                }
            },
            mapNavigation: {
                enabled: true,
            },
            colors: [
                "#c86dd7",
                "#3023ae"
            ],
            colorAxis: {
                dataClasses: [
                    {
                        from: 1,
                        color: "#c86dd7",
                        name: ""
                    },
                    {
                        from: 2,
                        color: "#3023ae",
                        name: ""
                    }
                ]
            },
            series: [
                {
                    // Use the gb-all map with no data as a basemap
                    name: "Basemap",
                    mapData: customWorld,
                    borderColor: "#A0A0A0",
                    data: customWorld.features.map((feature, i) => {
                        return [feature.properties['hc-key'], (i%2)+1]
                    }),
                    showInLegend: false,
                    enableMouseTracking: false
                },
                {
                    // Specify points using lat/lon
                    type: 'mapbubble',
                    name: 'AWS Region',
                    allowPointSelect: true,
                    color: '#4cae4c',
                    colorAxis: {

                    },
                    data:
                        [
                            { z: 4, keyword: "Sydney", lat: -33.86888, lon: 151.2093 },
                            { z: 4, keyword: "Mumbai", lat: 19.0760, lon: 72.8777 },
                            { z: 4, keyword: "Tokyo", lat: 35.6762, lon: 139.6503 },
                            { z: 4, keyword: "US West", lat: 36.7783, lon: -119.4179 },
                            { z: 4, keyword: "US East", lat: 40.7128, lon: -74.0060 },
                            { z: 4, keyword: "Singapore", lat: 1.3521, lon: 103.8198 },
                            { z: 4, keyword: "Frankfurt", lat: 50.1109, lon: 8.6821 },
                            { z: 4, keyword: "London", lat: 51.5074, lon: -0.1278 },
                        ],
                    cursor: 'pointer',
                    point: {
                        events: {
                            select:() => {
                                console.log(this);
                                this.setState({modalOpen: true});
                            },
                            click: function(e){
                                console.log(this);
                                setModalOptions(e);
                            }
                        }

                    },
                    tooltip: {
                        pointFormat: '{point.keyword}',
                    },
                }
            ]
        };
    }

    setModalOpen = () => {
        this.setState({ modalOpen: true });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    render(){
        return(
            <div className="statusMap__wrapper">
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"mapChart"}
                    options={this.state.mapOptions}
                />
                {this.state.modalOpen && <div className="statusPopup">
                    <div className="statusModal__header">
                        <div className="statusModal__header__alert"><ReportProblemIcon/></div>
                        <div className="statusModal__header__title">{modalOptions.title}</div>
                        <div className="statusModal__header__close" onClick={this.onCloseModal} >Close</div>
                    </div>
                    <StatusModal />
                </div>}
            </div>
        );
    }
}

export default StatusMap;
