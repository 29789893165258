import React, { useEffect } from "react";
import { synchronize } from "../synchronizer";
import GraphMos from "../Graphs/_GraphMos";
import GraphPackets from "../Graphs/_GraphPackets";
import GraphPacketsLost from "../Graphs/_GraphPacketsLost";
import GraphRtt from "../Graphs/_GraphRtt";
import GraphJitter from "../Graphs/_GraphJitter";
import GraphAudio from "../Graphs/_GraphAudio";
import GraphCpu from "../Graphs/_GraphCpu";
import GraphHoldmute from "../Graphs/_GraphHoldMute";
import GraphAgentLogs from "../Graphs/_GraphAgentLogs";
import PropTypes from "prop-types";
import { Stack, Skeleton, Heading } from "@operata/adagio";
import ErrorState from "./ErrorState";

const TabCharts = ({ call, hasResolved }) => {
  const graphContainerStyle = {
    marginTop: "0.5rem",
    width: "100%"
  };
  const graphOptions = {
    op_yAxisLabelWidth: 50,
    op_xRangePad: 10
  };

  useEffect(
    () => {
      let isMounted = true;

      const insightHighlights = call?.summary?.insights
        ? Object.keys(call.summary.insights).reduce((acc, key) => {
            if (key !== "tags" && key !== "count") {
              acc[key] = call.summary.insights[key];
            }
            return acc;
          }, {})
        : null;

      const initializeGraphs = () => {
        try {
          const graphs = [
            GraphAgentLogs({ call, graphOptions }),
            GraphCpu({ call, graphOptions }),
            GraphHoldmute({ call, graphOptions }),
            GraphPackets({ call, insightHighlights, graphOptions }),
            GraphPacketsLost({ call, insightHighlights, graphOptions }),
            GraphMos({ call, insightHighlights, graphOptions }),
            GraphRtt({ call, insightHighlights, graphOptions }),
            GraphJitter({ call, insightHighlights, graphOptions }),
            GraphAudio({ call, insightHighlights, graphOptions })
          ];
          if (graphs.length > 0 && isMounted) {
            synchronize(...graphs, {
              range: false,
              selection: true,
              zoom: true
            });
          }
        } catch (err) {
          // Do nothing
        }
      };

      initializeGraphs();

      return () => {
        isMounted = false;
      };
    },
    [call, graphOptions]
  );

  return (
    <>
      <Stack padding="medium" gap="large" block>
        <Stack direction="column" gap="8" block>
          <Heading level={4}>Logs</Heading>
          {!call?.logs &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.logs &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.logs && (
            <div className="graph-container" style={graphContainerStyle}>
              <div
                id="graph-agentlogs"
                className="dygraphChart"
                style={{ height: 150 }}
              />
              <div
                id="graph-agentlogs-legend"
                className="dygraphCustomLegend"
              />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>Agent Status</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div
                id="graph-holdmute"
                className="dygraphChart"
                style={{ height: 150 }}
              />
              <div id="graph-holdmute-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>Audio Levels</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-audio" className="dygraphChart" />
              <div id="graph-audio-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>Packet Count</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-packets" className="dygraphChart" />
              <div id="graph-packets-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>Packets Lost Count</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-packets-lost" className="dygraphChart" />
              <div
                id="graph-packets-lost-legend"
                className="dygraphCustomLegend"
              />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>MOS</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-mos" className="dygraphChart" />
              <div id="graph-mos-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>RTT (ms)</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-rtt" className="dygraphChart" />
              <div id="graph-rtt-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>Jitter (ms)</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-jitter" className="dygraphChart" />
              <div id="graph-jitter-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>

        <Stack direction="column" gap="8" block>
          <Heading level={4}>CPU Available</Heading>
          {!call?.call &&
            !hasResolved && <Skeleton height={160} width="100%" loading />}
          {!call?.call &&
            hasResolved && <ErrorState>Unable to retrieve data</ErrorState>}
          {call?.call && (
            <div className="graph-container" style={graphContainerStyle}>
              <div id="graph-cpu" className="dygraphChart" />
              <div id="graph-cpu-legend" className="dygraphCustomLegend" />
            </div>
          )}
        </Stack>
      </Stack>
    </>
  );
};

TabCharts.propTypes = {
  call: PropTypes.object,
  hasResolved: PropTypes.bool
};

export default TabCharts;
