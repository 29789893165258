import { axiosProxy } from "../axios/AxiosProxy";
import { auth } from "../auth/Auth";
import { SHOW_PROGRESS } from "./progress";
export const WORKFLOWS_RECEIVED = "WORKFLOWS_RECEIVED";
export const WORKFLOW_TOGGLED = "WORKFLOW_TOGGLED";
export const WORKFLOW_CANCELLED = "WORKFLOW_CANCELLED";
export const WORKFLOW_CREATED = "WORKFLOW_CREATED";
export const ERROR_WORKFLOW_CREATED = "ERROR_WORKFLOW_CREATED";
export const RESET_ERROR_WORKFLOW_CREATED = "RESET_ERROR_WORKFLOW_CREATED";
export const WORKFLOW_UPDATED = "WORKFLOW_UPDATED";
export const WORKFLOW_REMOVED = "WORKFLOW_REMOVED";
export const EDIT_WORKFLOW = "EDIT_WORKFLOW";
export const NEW_WORKFLOW = "NEW_WORKFLOW";
export const WORKFLOW_ACTIONS_HISTORY_RECEIVED =
  "WORKFLOW_ACTIONS_HISTORY_RECEIVED";
export const ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL =
  "ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL";

export const openNewWorkflow = () => ({
  type: NEW_WORKFLOW
});

export const openEditWorkflow = data => ({
  type: EDIT_WORKFLOW,
  data: data
});

export const actionsActionRequested = () => ({
  type: SHOW_PROGRESS
});

export const actionsReceived = actions => ({
  type: WORKFLOWS_RECEIVED,
  actions: actions
});

export const actionsHistoryReceived = history => ({
  type: WORKFLOW_ACTIONS_HISTORY_RECEIVED,
  history: history
});

export const actionToggled = (watcherId, enabled) => ({
  type: WORKFLOW_TOGGLED,
  watcherId: watcherId,
  enabled: enabled
});

export const cancelToggled = () => ({
  type: WORKFLOW_CANCELLED
});

export const workflowCreated = workflow => ({
  type: WORKFLOW_CREATED,
  workflow: workflow
});

export const errorWorkflowCreated = message => ({
  type: ERROR_WORKFLOW_CREATED,
  message: message
});

export const resetErrorWorkflowCreated = () => ({
  type: RESET_ERROR_WORKFLOW_CREATED
});

export const errorActionHistoryRetrieval = () => ({
  type: ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL
});

export const workflowUpdated = workflow => ({
  type: WORKFLOW_UPDATED,
  workflow: workflow
});

export const workflowRemoved = id => ({
  type: WORKFLOW_REMOVED,
  id: id
});

//redux-thunk
export const editWorkflow = data => {
  return dispatch => {
    console.log("dispatching edit workflow ..");
    dispatch(openEditWorkflow(data));
  };
};

export const newWorkflow = () => {
  return dispatch => {
    console.log("dispatching new workflow ..");
    dispatch(openNewWorkflow());
  };
};

export const fetchActionHistory = criteria => {
  return dispatch => {
    console.log("dispatching actions action requested..");
    dispatch(actionsActionRequested());
    return getActionHistory(criteria)
      .then(resp => {
        console.log("dispatching actionHistoryReceived..");
        dispatch(actionsHistoryReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorActionHistoryRetrieval());
      });
  };
};

export const fetchActions = () => {
  return dispatch => {
    console.log("dispatching actions action requested..");
    dispatch(actionsActionRequested());

    Promise.all([getActions(), getWatchdogWatchers()]).then(
      ([actions, watchers]) => {
        const transformedToNewStructure = actions.data.map(action => {
          return {
            id: action._id,
            enabled: action._source.status.state.active,
            application: action._source.metadata.application,
            type: action._source.metadata.trigger,
            actions: action._source.metadata.actions,
            name: action._source.metadata.name,
            description: action._source.metadata.workflowName,
            status:
              action._source.status.execution_state === undefined
                ? ""
                : action._source.status.execution_state,
            triggerThreshold: action._source.metadata.triggerThreshold,
            tags: action._source.metadata.tags
          };
        });
        const combined = [...transformedToNewStructure, ...watchers.data];

        console.log("dispatching actionsReceived..");
        dispatch(actionsReceived(combined));
      }
    );
  };
};

export const revertToggle = () => {
  return dispatch => {
    console.log("dispatching action cancelled..");
    dispatch(cancelToggled());
  };
};

export const toggleAction = (watcherId, enabled, watchdogWatcher) => {
  return dispatch => {
    console.log("dispatching actions action requested..");
    dispatch(actionsActionRequested());
    if (!enabled) {
      return activateWatcher(watcherId, watchdogWatcher).then(() => {
        console.log("dispatching watcher activation..");
        dispatch(actionToggled(watcherId, !enabled));
      });
    } else {
      return deactivateWatcher(watcherId, watchdogWatcher).then(() => {
        console.log("dispatching watcher deactivation..");
        dispatch(actionToggled(watcherId, !enabled));
      });
    }
  };
};

export const deleteWorkflow = (watcherId, watchdogWatcher) => {
  return dispatch => {
    console.log("dispatching actions action requested..");
    dispatch(actionsActionRequested());
    return deleteWorkflowHandler(watcherId, watchdogWatcher).then(() => {
      console.log("dispatching delete workflow..");
      dispatch(workflowRemoved(watcherId));
    });
  };
};

const getActionHistory = criteria => {
  return axiosProxy
    .getInstance()
    .get(
      "/groups/" +
        auth.getCurrentGroupId() +
        "/watchers/actions/history?criteria=" +
        JSON.stringify(criteria)
    );
};

const getActions = () => {
  return new Promise(resolve => {
    axiosProxy
      .getInstance()
      .get("/groups/" + auth.getCurrentGroupId() + "/watchers")
      .then(resolve)
      .catch(() => resolve({ data: [] }));
  });
};

const getWatchdogWatchers = () => {
  return axiosProxy.getInstance().get("/watchdog/watcher/list");
};

const deleteWorkflowHandler = (watcherId, watchdogWatcher) => {
  let url = "/groups/" + auth.getCurrentGroupId() + "/watchers/" + watcherId;
  if (watchdogWatcher) {
    url = `/watchdog/watcher/${watcherId}`;
  }

  return axiosProxy.getInstance().delete(url);
};

const activateWatcher = (watcherId, watchdogWatcher) => {
  let url =
    "/groups/" +
    auth.getCurrentGroupId() +
    "/watchers/" +
    watcherId +
    "/activate";
  if (watchdogWatcher) {
    url = `/watchdog/watcher/${watcherId}/activate`;
  }

  return axiosProxy.getInstance().put(url, {});
};

const deactivateWatcher = (watcherId, watchdogWatcher) => {
  let url =
    "/groups/" +
    auth.getCurrentGroupId() +
    "/watchers/" +
    watcherId +
    "/deactivate";
  if (watchdogWatcher) {
    url = `/watchdog/watcher/${watcherId}/deactivate`;
  }

  return axiosProxy.getInstance().put(url, {});
};

export const createOrUpdateWorkflow = (workflow, useWatchdog = false) => {
  return dispatch => {
    console.log(
      "dispatching workflow action requested..",
      JSON.stringify(workflow)
    );
    dispatch(actionsActionRequested());

    let workflowAction;
    if (useWatchdog) {
      workflowAction = createOrUpdateWatchdogWatcher(workflow);
    } else {
      workflowAction = createOrUpdateWorkflowHandler(workflow);
    }

    if (workflow.workflowId && workflow.workflowId.length > 0) {
      console.log("put workflow");
      return workflowAction
        .then(resp => {
          console.log("dispatching workflow updated..");
          dispatch(workflowUpdated(resp.data));
        })
        .catch(() => {
          dispatch(
            errorWorkflowCreated(
              "An error occurred while creating the workflow"
            )
          );
          dispatch(resetErrorWorkflowCreated());
        });
    } else {
      console.log("create workflow");
      return workflowAction
        .then(resp => {
          console.log("dispatching workflow created..");
          dispatch(workflowCreated(resp.data));
        })
        .catch(() => {
          dispatch(
            errorWorkflowCreated(
              "An error occurred while creating the workflow"
            )
          );
          dispatch(resetErrorWorkflowCreated());
        });
    }
  };
};

const createOrUpdateWorkflowHandler = workflow => {
  return axiosProxy
    .getInstance()
    .put("/groups/" + auth.getCurrentGroupId() + "/watchers", workflow);
};

const createOrUpdateWatchdogWatcher = workflow => {
  const watcher = {
    application: workflow.application,
    type: workflow.trigger,
    name: workflow.workflowName,
    tags: workflow.tags,
    triggerThreshold: workflow.triggerThreshold,
    actions: workflow.actions
  };

  if (
    workflow.workflowId &&
    workflow.workflowId !== "-1" &&
    workflow.workflowId.length > 0
  ) {
    return axiosProxy
      .getInstance()
      .put(`/watchdog/watcher/${workflow.workflowId}`, watcher);
  }

  return axiosProxy.getInstance().post("/watchdog/watcher", watcher);
};
