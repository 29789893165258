import React from 'react';
import CampaignContext from '../CampaignContext';
import { prepareGraphData } from '../../dataUtil';
import CampaignGraph from '../../CampaignGraph';

export default class StepVisualise extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({ callsPerSecond, callDuration, totalCalls }) => {
          const graphData = prepareGraphData({ callsPerSecond, callDuration, totalCalls }, []);
          return <CampaignGraph persecondStats={graphData.persecondStats} width={800} showLegend={false}/>;
        }}
      </CampaignContext.Consumer>
    );
  }
}
