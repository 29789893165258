import React, { Component } from "react";
import IdleTimer from "react-idle-timer";

import { ConfirmationDialog } from "@operata/ui-components";
import SnackbarMessage from "./Snackbar/SnackbarMessage";
import Main from "./Main/Main";
import "./App.scss";
import { auth } from "../auth/Auth";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Callback from "./Callback/Callback";
import Fallback from "./Fallback/Fallback";
import { AUTH_SUCCESSFUL, AUTH_UNSUCCESSFUL } from "../actions/auth";
import {
  closeDialog,
  DIALOG_OPENED,
  showConfirmationDialog
} from "../actions/dialog";
import PropTypes from "prop-types";
import NotFound from "./NotFound/NotFound";
import AccountActivated from "./User/AccountActivated";

import { withFeatureFlagsProvider } from "../FeatureFlags";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  hidePreloader() {
    document.getElementById("preloaderView").style.display = "none";
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    const { status, dialogStatus, showConfirmationDialog } = this.props;

    if (status === AUTH_SUCCESSFUL) {
      this.setState({
        idleTimeout: auth.tokenExpirationSecs * 1000
      });
      this.idleTimer.start();
    }

    if (status === AUTH_UNSUCCESSFUL && dialogStatus !== DIALOG_OPENED) {
      showConfirmationDialog(
        "Login Unsuccessful",
        "Oops...looks like that Login doesn't exist.",
        auth.logout,
        [],
        null,
        []
      );
    }
  }

  componentDidMount() {
    if (window.location.href.indexOf("subscription") < 0) {
      document.title = "Operata Dashboard";
    }

    if (!auth.isPublicRoute()) {
      if (localStorage.getItem("isLoggedIn") === "true") {
        auth.renewSession();
      } else {
        auth.login();
      }
    }
    this.hidePreloader();
  }

  handleOnIdle() {
    console.log("user is idle, logging off");
    auth.logout();
  }

  render() {
    const {
      dialogStatus,
      dialogTitle,
      dialogMessage,
      dialogOKCallback,
      dialogOKCallbackArgs,
      dialogCancelCallback,
      dialogCancelCallbackArgs
    } = this.props;

    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={this.state.idleTimeout}
          onIdle={this.handleOnIdle}
          startOnMount={false}
          startManually={true}
        />

        <Router>
          <Switch>
            <Route path="/activated" component={AccountActivated} />
            <Route path="/callback" component={Callback} />
            <Route path="/fallback" component={Fallback} />
          </Switch>
          {this.props.status === AUTH_SUCCESSFUL &&
            this.props.profile && (
              <Switch>
                <Route path={"/" + auth.getCurrentGroupId()} component={Main} />
                <Route component={NotFound} />
              </Switch>
            )}
        </Router>

        <ConfirmationDialog
          title={dialogTitle}
          message={dialogMessage}
          onCancel={dialogCancelCallback}
          onCancelArgs={dialogCancelCallbackArgs}
          onOK={dialogOKCallback}
          onOKArgs={dialogOKCallbackArgs}
          dialogOpen={dialogStatus === DIALOG_OPENED}
          onCloseDialog={this.props.closeDialog}
        />
        <SnackbarMessage />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  showConfirmationDialog: showConfirmationDialog,
  closeDialog: closeDialog
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  sessionExpired: state.auth.sessionExpired,
  status: state.auth.status,

  dialogStatus: state.dialog.status,
  dialogTitle: state.dialog.title,
  dialogMessage: state.dialog.message,
  dialogOKCallback: state.dialog.okCallback,
  dialogOKCallbackArgs: state.dialog.okCallbackArgs,
  dialogCancelCallback: state.dialog.cancelCallback,
  dialogCancelCallbackArgs: state.dialog.cancelCallbackArgs
});

App.propTypes = {
  profile: PropTypes.object,
  status: PropTypes.string,
  showConfirmationDialog: PropTypes.func,
  closeDialog: PropTypes.func,
  auth: PropTypes.any,
  dialogStatus: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogMessage: PropTypes.string,
  dialogOKCallback: PropTypes.func,
  dialogOKCallbackArgs: PropTypes.array,
  dialogCancelCallback: PropTypes.func,
  dialogCancelCallbackArgs: PropTypes.array
};

// eslint-disable-next-line no-class-assign
App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

// eslint-disable-next-line no-class-assign
App = withFeatureFlagsProvider(App);

export default App;
