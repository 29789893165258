import _ from "lodash";
import { validate as uuidValidate } from "uuid";

export const validateEmailField = (field, values, errors, component) => {
  if (
    values[field] &&
    !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values[field])
  ) {
    errors[field] = "Invalid email address";
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

export const validatePhoneNumberField = (field, values, errors, component) => {
  if (values[field] && !/\+\d{9,12}/.test(values[field])) {
    errors[field] = "Invalid phone number";
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

export const validateUUID = (field, values, errors, component) => {
  if (values[field] && !uuidValidate(values[field])) {
    errors[field] = "Invalid ID";
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

export const validateRequireField = (
  requiredFields,
  values,
  errors,
  component
) => {
  requiredFields.forEach(field => {
    if (!values[field] || values[field].length === 0) {
      errors[field] = "Required (" + _.startCase(field) + ")";
    }

    updateComponentState(component, errors, field);
    setHasErrors(errors);
  });
};

export const validatePassword = (passwordField, values, errors, component) => {
  let restrictionSatisfied = 0;
  const restrictions = [/[0-9]/, /[a-z]/, /[A-Z]/, /[!@#$%^&*]/];
  restrictions.forEach(restriction => {
    if (restriction.test(values[passwordField])) {
      restrictionSatisfied++;
    }
  });

  if (values[passwordField]) {
    if (values[passwordField].length < 8) {
      errors[passwordField] = "Password should be at least 8 characters long";
    } else if (restrictionSatisfied < 3) {
      errors[passwordField] =
        "Password should have at least 3 out of digits [0-9], lowercase letters [a-z], uppercase letters [A-Z], and special characters [!@#$%^&*]";
    }
  }
  updateComponentState(component, errors, passwordField);
  setHasErrors(errors);
};

export const validatePasswordConfirmation = (
  passwordField,
  confirmationField,
  values,
  errors,
  component
) => {
  if (
    values[passwordField] &&
    values[passwordField] !== values[confirmationField]
  ) {
    errors[confirmationField] = "Password confirmation doesn't match password";
  }
  updateComponentState(component, errors, confirmationField);
  setHasErrors(errors);
};

export const validateJSON = (jsonField, values, errors, component) => {
  let json = values[jsonField];
  try {
    JSON.parse(json);
  } catch (e) {
    errors[jsonField] = "Invalid JSON";
  }

  updateComponentState(component, errors, jsonField);
  setHasErrors(errors);
};

export const validateAlphanumericId = (field, values, errors, component) => {
  let value = values[field];

  if (/[^a-zA-Z0-9_]/.test(value)) {
    errors[field] = field + " has invalid characters";
  }

  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

export const validateURL = (urlField, values, errors, component) => {
  let href = values[urlField];
  let urlParts = {};

  if (href.indexOf("http://") === 0 || href.indexOf("https://") === 0) {
    urlParts = getUrlPartsInternal(href);
  }

  if (
    !(
      urlParts["protocol"] &&
      urlParts["hostname"] &&
      urlParts["protocol"].length > 0 &&
      urlParts["hostname"].length > 0
    )
  ) {
    errors[urlField] = "Invalid URL";
  }

  updateComponentState(component, errors, urlField);
  setHasErrors(errors);
};

export const getUrlParts = href => {
  return getUrlPartsInternal(href);
};

function getUrlPartsInternal(href) {
  let anchor = document.createElement("a");
  anchor.href = href;
  return anchor;
}

export const setHasErrors = errors => {
  delete errors["hasErrors"];
  if (Object.keys(errors).length) {
    errors["hasErrors"] = true;
  } else {
    errors["hasErrors"] = false;
  }
};

export const updateComponentState = (component, errors, field) => {
  if (component) {
    let errorObj = {};
    if (errors[field] && errors[field].length > 0) {
      errorObj["error_" + field] = true;
      errorObj["errorMessage_" + field] = errors[field];
    } else {
      errorObj["error_" + field] = false;
      errorObj["errorMessage_" + field] = "";
    }
    component.setState(errorObj);
  }
};

export const validateNumeric = (field, values, errors, component) => {
  if (isNaN(parseFloat(values[field])) || !isFinite(values[field])) {
    errors[field] = "Invalid number";
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

export const validateCCP = (field, values, errors, component) => {
  const val = values[field];
  let ccpUrlParts = null;

  if (val) {
    errors[field] = "Invalid CCP.";
    ccpUrlParts = val.match(
      /(https:\/\/)(.*)(.awsapps.com\/|.connect.aws\/)(connect\/|)(ccp-v2$|ccp$)/
    );
  }

  if (ccpUrlParts !== null) {
    const protocol = ccpUrlParts[1];
    const instanceName = ccpUrlParts[2];
    const domain = ccpUrlParts[3];
    const path = ccpUrlParts[4];
    const ccp = ccpUrlParts[5];

    if (
      !(
        !protocol ||
        protocol !== "https://" ||
        !instanceName ||
        !domain ||
        !(
          (domain === ".awsapps.com/" && path === "connect/") ||
          (domain === ".connect.aws/" && path === "") ||
          (domain === ".connect.aws/" && path === "connect/")
        ) ||
        !ccp ||
        !(ccp === "ccp" || ccp === "ccp-v2")
      )
    ) {
      delete errors[field];
    }
  }

  internal.updateComponentState(component, errors, field);
  internal.setHasErrors(errors);
};

export const internal = {
  updateComponentState,
  setHasErrors
};
