import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";

export const AUDIT_LOGS_RECEIVED = "AUDIT_LOGS_RECEIVED";
export const DOWNLOAD_AUDIT_LOGS_FETCHED = "DOWNLOAD_AUDIT_LOGS_FETCHED";
export const ERROR_AUDIT_LOGS_RETRIEVAL = "ERROR_AUDIT_LOGS_RETRIEVAL";

export const showProgress = () => ({
  type: SHOW_PROGRESS
});

export const downloadAuditLogsFetched = () => ({
  type: DOWNLOAD_AUDIT_LOGS_FETCHED
});

export const auditLogsReceived = (logs, recordCount) => ({
  type: AUDIT_LOGS_RECEIVED,
  logs: logs,
  recordCount: recordCount
});

export const errorAuditLogsRetrieval = () => ({
  type: ERROR_AUDIT_LOGS_RETRIEVAL
});

export const fetchAuditLogs = criteria => {
  return dispatch => {
    dispatch(showProgress());
    return getAuditLogs(criteria)
      .then(resp => {
        const recCount = resp.headers["recordcount"];
        dispatch(auditLogsReceived(resp.data, recCount));
      })
      .catch(() => {
        dispatch(errorAuditLogsRetrieval());
      });
  };
};

export const getAuditLogs = criteria => {
  return axiosProxy
    .getInstance()
    .get("/auditLogs" + "?criteria=" + JSON.stringify(criteria));
};
