import { UPDATE_FILTER } from "../actions/location";

const reports = (
  state = {
    next: process.env.REACT_APP_BI_WELCOME_PAGE
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FILTER:
      return Object.assign({}, state, action.payload, {
        reduxPath: action.reduxPath,
        status: UPDATE_FILTER
      });
    default:
      return state;
  }
};

export default reports;
