import React from 'react';
import MonitorContext from '../MonitorContext';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export class StepConnectRegions extends React.Component {
    render() {
        return (
            <MonitorContext.Consumer>
                {({
                      initConnectRegions,
                      setConnectRegions,

                  }) => {
                    return (
                        <div className="grid__container workflow__summary">
                            <div className="grid__column grid__column--four">

                                {
                                    Object.keys(initConnectRegions).map((region) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={initConnectRegions[region]}
                                            onChange={event => setConnectRegions(event.target.value)}
                                            name="connectRegions"
                                            value={region}
                                        />}
                                    label={region}
                                />
                                ))}

                            </div>
                        </div>
                    );
                }}
            </MonitorContext.Consumer>
        );
    }
}

export default StepConnectRegions;
