import React from 'react';
import TextField from '@material-ui/core/TextField';
import MonitorContext from '../MonitorContext';

export class StepDescription extends React.Component {
    render() {
        return (
            <MonitorContext.Consumer>
                {({
                      description,
                      setDescription,
                      error_description,
                      errorMessage_description
                  }) => {
                    return (
                        <div className="grid__container workflow__summary">
                            <div className="grid__column grid__column--four">

                                <div className="workflow__input">
                                    <TextField
                                        error={error_description}
                                        label={errorMessage_description ? errorMessage_description : 'Monitor Description'}
                                        id="outlined-required"
                                        name="description"
                                        className="textField"
                                        onChange={event => setDescription(event.target.value)}
                                        variant="outlined"
                                        multiline={true}
                                        rows="3"
                                        value={description}
                                    />
                                </div>

                            </div>
                        </div>
                    );
                }}
            </MonitorContext.Consumer>
        );
    }
}

export default StepDescription;
