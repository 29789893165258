import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import UserList from "../User/UserList";
import "./Tabs.scss";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function LinkTab(props) {
  return (
    <Tab component="a" onClick={event => event.preventDefault()} {...props} />
  );
}

class NavTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;

    return (
      <div className="tabs__wrapper">
        <Tabs
          className="tabs__links"
          value={value}
          onChange={this.handleChange}
        >
          <LinkTab label="TeamMates" href="page1" />
        </Tabs>

        {value === 0 && (
          <TabContainer>
            <UserList />
          </TabContainer>
        )}
      </div>
    );
  }
}

export default NavTabs;
