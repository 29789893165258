import axios, { AxiosInstance } from "axios";
import { auth } from "../auth/Auth";
import {getAPIUrl} from "../utils/common";

class AxiosProxy {
  private axiosInstance: AxiosInstance | undefined;
  private regionInstances: { [key: string]: AxiosInstance } = {};

  createInstance() {
    this.axiosInstance = axios.create({
      baseURL: getAPIUrl(),
      responseType: "application/json",
      headers: auth.getAuthorizationHeaders()
    });

    // recreate axios instance after token expires
    setTimeout(() => {
      this.createInstance();
    }, auth.tokenExpirationSecs * 1000);
  }

  getInstance(): AxiosInstance {
    if (!this.axiosInstance) {
      this.createInstance();
    }
    return (this.axiosInstance as AxiosInstance);
  }
}

export const axiosProxy = new AxiosProxy();
