import React from 'react';
import classNames from 'classnames';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

import { Tooltip, Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Visualiser.scss';

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
      <Tooltip
          PopperProps={{
            popperRef,
          }}
          open={open}
          enterTouchDelay={0}
          placement="top"
          title={value}
      >
        {children}
      </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function WaveSurferCanvas(props) {
  return (
      <React.Fragment>
        {<div className="visualiser__title">
        {props.showTitle && (
          <a
            className="textfield-bold"
            href={'#'}
            onClick={() => {
              props.playMedia(props.instanceName);
            }}
          >
            {props.title}
          </a>
        )}

        {props.score && (
          <span className="visualiser__stats">
            [Score: {props.score}, Type: {props.type}]
          </span>
        )}
      </div>}
        {!props.showTitle && (
            <span
                className="audio__button"
                onClick={() => {
                  props.playMedia(props.instanceName);
                }}
            >
          <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
            <defs>
              <rect id="path-1" x="0" y="-4.76063633e-13" width="343" height="106" />
              <filter x="-1.9%" y="-5.2%" width="103.8%" height="112.3%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                <feColorMatrix values="0 0 0 0 0.0823529412   0 0 0 0 0.133333333   0 0 0 0 0.196078431  0 0 0 0.08 0" type="matrix" in="shadowBlurOuter1" />
              </filter>
              <linearGradient x1="23%" y1="50%" x2="0%" y2="50%" id="linearGradient-3">
                <stop stopColor="#CAE8F7" stopOpacity="0" offset="0%" />
                <stop stopColor="#3BB6B4" stopOpacity="0.102" offset="100%" />
              </linearGradient>
              <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="linearGradient-4">
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#C86DD7" offset="100%" />
              </linearGradient>
            </defs>
            <g id="Operata-Review" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Logs-detail-Audio" transform="translate(-135.000000, -259.000000)">
                <g id="Group" transform="translate(126.000000, 287.000000)" />
                <g id="line-copy" transform="translate(131.000000, 224.000000)">
                  <g id="Group-9" transform="translate(0.000000, -0.000000)">
                    <g id="Rectangle_409-2" transform="translate(171.500000, 53.000000) rotate(180.000000) translate(-171.500000, -53.000000) ">
                      <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
                      <use fill="#FFFFFF" fillRule="evenodd" />
                    </g>
                    <rect id="Rectangle_410" fill="url(#linearGradient-3)" fillRule="nonzero" opacity="0.54" transform="translate(111.500000, 60.000000) rotate(180.000000) translate(-111.500000, -60.000000) " x="0" y="9.09494702e-13" width="223" height="120" />
                    <circle id="Ellipse_20-Copy-2" fill="url(#linearGradient-4)" fillRule="nonzero" cx="19.5" cy="50.5" r="15.5" />
                    <g id="00-General-/-01-Icons-/-01-Actions-/-01-Add-rounded" transform="translate(10.000000, 41.000000)">
                      <rect id="BG" x="0" y="0" width="20" height="20" />
                      <path d="M5.86350176,3.33333333 C6.1395328,3.33333333 6.41139653,3.39799556 6.65551417,3.52171186 L15.7615027,8.55885753 C16.5902916,8.97887919 16.9075618,9.96452213 16.4701454,10.7603527 C16.310665,11.0505095 16.063676,11.2876765 15.7615027,11.4408148 L6.65551417,16.4779605 C5.82672525,16.8979821 4.80026305,16.5933288 4.36284668,15.7974983 C4.23400681,15.5630885 4.16666667,15.302036 4.16666667,15.0369818 L4.16666667,4.96269049 C4.16666667,4.06282138 4.92636562,3.33333333 5.86350176,3.33333333 Z" id="color" fill="#FFFFFF" />
                    </g>
                  </g>
                </g>
                <g id="Group-7" transform="translate(135.000000, 259.000000)" />
              </g>
            </g>
          </svg>
        </span>
        )}
        <div id={'waveform_' + props.instanceName} className="visualiser__graph" />
        <div id={'waveform_' + props.instanceName + '_timeline'} className={classNames('visualiser__graph')} />
      </React.Fragment>
  );
}

WaveSurferCanvas.propTypes = {
  showTitle: PropTypes.bool,
  playMedia: PropTypes.func,
  instanceName: PropTypes.string,
  title: PropTypes.string,
  score: PropTypes.number,
  type: PropTypes.string,
};

class Visualiser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waveSurferWidth: this.props.width,
      waveSurferDisplay: 'none',
    };
    this.waveSurferInstances = {};

    this.sliderOnChange = this.sliderOnChange.bind(this);
  }

  async componentDidMount() {
    await this.createCanvases();

    setTimeout(() => {
      this.sliderOnChange(this.props.minZoom);
    }, 500);
  }

  // method is async because there were cases where it wouldn't finish creating the canvas before page renders
  async createCanvases() {
    await Object.keys(this.props.waveFiles).forEach((key) => {
      this.createWavesurferCanvas(key.toLowerCase(), this.props.waveFiles[key].url);
    });

    if (this.props.showRef) {
      await this.createWavesurferCanvas(this.props.referenceName, this.props.refFile);
    }
  }

  componentWillUnmount() {
    _.forEach(this.waveSurferInstances, function(instance) {
      instance.destroy();
    });
  }

  playMedia = (instanceName) => {
    this.waveSurferInstances[instanceName].playPause();
  };

  createWavesurferCanvas(instanceName, wavUrl) {
    this.waveSurferInstances[instanceName] = WaveSurfer.create({
      container: '#waveform_' + instanceName,
      waveColor: '#c86dd7',
      progressColor: '#3023ae',
      fillParent: true,
      scrollParent: true,
      hideScrollbar: false,
      minPxPerSec: this.props.minZoom,
      autocenter: false,
      height: this.props.height,
      plugins: [
        TimelinePlugin.create({
          container: '#waveform_' + instanceName + '_timeline',
        }),
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': '#000',
            color: '#fff',
            padding: '2px',
            'font-size': '10px',
          },
        }),
      ],
    });

    this.waveSurferInstances[instanceName].load(wavUrl);
  }

  sliderOnChange(zoom) {
    _.forEach(this.waveSurferInstances, function(instance) {
      instance.zoom(Number(zoom));
    });
  }

  render() {
    const agentResult = this.props.waveFiles['Agent'];
    const connectResult = this.props.waveFiles['Connect'];
    const customerResult = this.props.waveFiles['Customer'];
    const customerToConnectResult = this.props.waveFiles['Customer_to_Connect'];
    const connectToAgentResult = this.props.waveFiles['Connect_to_Agent'];
    const agentToConnectResult = this.props.waveFiles['Agent_to_Connect'];
    const connectToCustomerResult = this.props.waveFiles['Connect_to_Customer'];
    const showTitle = this.props.showTitle;
    const showRef = this.props.showRef;

    return (
        <div className="visualiser__wrapper">
          {<div className={classNames('visualiser__slider')}>
          <label className="textfield visualiser__label">Zoom In/Out</label>
          <Slider
            className="visualiser__range"
            ValueLabelComponent={ValueLabelComponent}
            aria-label="zoom wave surfer"
            defaultValue={this.props.minZoom}
            min={this.props.minZoom}
            max={this.props.maxZoom}
            onChange={(event, value) => {
              this.sliderOnChange(value);
            }}
          />
        </div>}

          {showRef && <WaveSurferCanvas instanceName={this.props.referenceName} title={'Play Reference WAV'} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} url={this.props.refFile} showTitle={showTitle} />}

          {agentResult && <WaveSurferCanvas className="visualiser__item" instanceName={'agent'} title={'Play Agent WAV'} score={agentResult.result.score} type={agentResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {connectResult && <WaveSurferCanvas className="visualiser__item" instanceName={'connect'} title={'Play Connect WAV'} score={connectResult.result.score} type={connectResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {customerResult && <WaveSurferCanvas className="visualiser__item" instanceName={'customer'} title={'Play Customer WAV'} score={customerResult.result.score} type={customerResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {customerToConnectResult && <WaveSurferCanvas className="visualiser__item" instanceName={'customer_to_connect'} title={'Play Customer To Connect WAV'} score={customerToConnectResult.result.score} type={customerToConnectResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {connectToAgentResult && <WaveSurferCanvas className="visualiser__item" instanceName={'connect_to_agent'} title={'Play Connect To Agent WAV'} score={connectToAgentResult.result.score} type={connectToAgentResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {agentToConnectResult && <WaveSurferCanvas className="visualiser__item" instanceName={'agent_to_connect'} title={'Play Agent To Connect WAV'} score={agentToConnectResult.result.score} type={agentToConnectResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}

          {connectToCustomerResult && <WaveSurferCanvas className="visualiser__item" instanceName={'connect_to_customer'} title={'Play Connect To Customer WAV'} score={connectToCustomerResult.result.score} type={connectToCustomerResult.result.type} playMedia={this.playMedia} width={this.props.width} display={this.state.waveSurferDisplay} showTitle={showTitle} />}
        </div>
    );
  }
}

Visualiser.propTypes = {
  showTitle: PropTypes.bool,
  playMedia: PropTypes.func,
  instanceName: PropTypes.string,
  title: PropTypes.string,
  score: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.number,
  minZoom: PropTypes.number,
  waveFiles: PropTypes.any,
  showRef: PropTypes.bool,
  height: PropTypes.number,
  maxZoom: PropTypes.number,
};

export default Visualiser;
