import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import QS from "./redux-query-sync";
import queryString from "qs";

function stateToString(state) {
  return queryString.stringify(state, { encode: false });
}

function stringToState(string) {
  return queryString.parse(string, {});
}

const PropsRoute = ({
  component,
  reduxPath,
  breadcrumb,
  store,
  action,
  ...rest
}) => {
  store.dispatch(action(breadcrumb));

  QS.ReduxQuerySync({
    store,
    params: {
      filter: {
        selector: state =>
          state.location[reduxPath] ? state.location[reduxPath].filter : {},
        action: value => ({
          type: "UPDATE_FILTER",
          payload: value,
          reduxPath
        }),
        stringToValue: string =>
          string && stringToState(string) ? stringToState(string) : {},
        valueToString: value =>
          value ? stateToString(value) : stateToString({}),
        defaultValue: {}
      }
    },
    initialTruth: "location",
    replaceState: false,
    reduxPath
  });

  return (
    <Route
      {...rest}
      render={routeProps => {
        const finalProps = Object.assign({}, routeProps, rest);
        return React.createElement(component, finalProps);
      }}
    />
  );
};

PropsRoute.propTypes = {
  component: PropTypes.node,
  breadcrumb: PropTypes.object,
  reduxPath: PropTypes.string,
  store: PropTypes.object,
  action: PropTypes.func
};

export default PropsRoute;
