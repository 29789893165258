import React from 'react';
import CampaignContext from '../CampaignContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MUIDataTable from '../../../Datatables';
import Pagination from '../../../Pagination/Pagination';

export default class StepData extends React.Component {
  render() {
    const options = {
      filter: false,
      search: false,
      selectableRows: 'none',
      rowsPerPage: 5,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 100, 500]}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
    };

    return (
      <CampaignContext.Consumer>
        {({ answerTreatment, data }) => {
          let columns = [];
          if (data && data.length > 0) {
            columns = Object.keys(data[0]).map((key, i) => {
              return {
                name: key,
                label: key,
              };
            });
          }

          return (
            <React.Fragment>
              {answerTreatment && (
                <div className="campaigns__grid">
                  <List>
                    <ListItem>
                      <ListItemText primary="Answer Treatment" secondary={answerTreatment} />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemText primary="Test Inputs" secondary={data.length} />
                    </ListItem>
                  </List>
                </div>
              )}

              <MUIDataTable data={data} options={options} columns={columns} />
            </React.Fragment>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}
