import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchEventSourceConfig,
  handlePostEventSource,
  deleteEventSource,
  resetStateEventSource,
  EVENT_SOURCE_CREATED,
  ERROR_EVENT_SOURCE_CREATED,
  EVENT_SOURCE_DELETED,
  RESET_EVENT_SOURCE_PAGE_STATE
} from "../../../actions/eventBridge";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Modal, MUIDataTable } from "@operata/ui-components";
import { showConfirmationDialog } from "../../../actions/dialog";
import CreateEventSource from "./CreateEventSource";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS
} from "../../../actions/snackbar";
import { formatDate } from "../../../utils/date";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import * as classes from "@operata/ui-components";
import Badge from "@material-ui/core/Badge";

let EventSource = class EventSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchEventSourceConfig(true);
  }

  componentDidUpdate() {
    if (this.props.status === RESET_EVENT_SOURCE_PAGE_STATE) {
      this.props.fetchEventSourceConfig(true);
    }
  }

  handleNewEventSource = () => {
    this.setState({ modalOpen: true });
  };

  handleDeleteEventSource(eventSource) {
    let { deleteEventSource, showConfirmationDialog } = this.props;
    showConfirmationDialog(
      "Delete Event Source",
      "Are you sure you want to delete this event source?",
      deleteEventSource,
      [eventSource],
      () => {},
      []
    );
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
    this.props.resetStateEventSource();
  };

  static getDerivedStateFromProps(props) {
    let { showSnackbarMessage } = props;
    let errorMessage;

    if (props.status === ERROR_EVENT_SOURCE_CREATED) {
      errorMessage =
        props.message === undefined ||
        JSON.stringify(props.message).length === 0
          ? "An error occurred while creating event source. Please try again."
          : JSON.stringify(props.message).includes(
              "at 'name' failed to satisfy constraint: Member must satisfy regular expression pattern:"
            )
            ? "Event source name should contain only the following characters (/[\\.\\-_A-Za-z0-9]+){2,}. Please check your name and try again."
            : JSON.stringify(props.message).includes(
                "Caller is not allowlisted for the event source partner namespace"
              )
              ? "Invalid AWS Account (Caller is not allowlisted for the event source partner namespace). Please check your account number and try again."
              : JSON.stringify(props.message).includes(
                  "ResourceAlreadyExistsException"
                )
                ? "Event source name already exists with given AWS account. Please try again with a unique name."
                : JSON.stringify(props.message);

      showSnackbarMessage(SNACKBAR_ERROR, "Error details - " + errorMessage);
    }

    if (props.status === EVENT_SOURCE_DELETED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Event source is deleted successfully."
      );

      props.resetStateEventSource();
    }

    if (props.status === EVENT_SOURCE_CREATED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Event source is created successfully."
      );
      return {
        modalOpen: false
      };
    }

    return null;
  }

  render() {
    let columns = [
      {
        name: "AWS Region"
      },

      {
        name: "Event Source Name"
      },

      {
        name: "AWS Account"
      },

      {
        name: "Status",
        options: {
          customBodyRender: value => {
            return value === "PENDING" ? (
              <Tooltip title="Event bus is not yet associated with event source. Please associate.">
                <Button className={classes.button}>
                  <Badge color="secondary" variant="dot">
                    {value}
                  </Badge>
                </Button>
              </Tooltip>
            ) : (
              <div> {value} </div>
            );
          }
        }
      },

      {
        name: "Last Triggered"
      }
    ];

    columns.push({
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: eventSource => {
          return (
            <div className="group__links">
              {this.props.data.length != 0 && (
                <React.Fragment>
                  <span
                    className="textfield-bold actions__link"
                    onClick={() => {
                      this.handleDeleteEventSource(eventSource);
                    }}
                  >
                    Delete
                  </span>
                </React.Fragment>
              )}
            </div>
          );
        }
      }
    });

    const options = {
      selectableRows: "none",
      filter: false,
      filterType: "dropdown",
      fixedHeader: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      pagination: false
    };

    return (
      <Grid container>
        <Grid container spacing={5} justify="flex-end">
          <Grid item xs={4}>
            <div>
              <>
                {" "}
                <button
                  className={"button"}
                  onClick={this.handleNewEventSource}
                >
                  <span className="button__circle">+</span>
                  New Event Source
                </button>
                <Modal
                  className="hidden"
                  title={"Create Event Source"}
                  isModalOpen={this.state.modalOpen}
                  onCloseModal={this.onCloseModal}
                  modalSize={"300px"}
                >
                  <CreateEventSource
                    handleCreateEventSource={this.props.handlePostEventSource}
                  />
                </Modal>{" "}
              </>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <MUIDataTable
              data={this.props.data.map(eventSource => {
                return [
                  eventSource.awsRegion,
                  eventSource.eventSourceName,
                  eventSource.accountId,
                  eventSource.status,
                  eventSource.lastTriggered
                    ? formatDate(eventSource.lastTriggered)
                    : "Never",
                  eventSource
                ];
              })}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const mapDispatchToProps = {
  fetchEventSourceConfig: fetchEventSourceConfig,
  handlePostEventSource: handlePostEventSource,
  showConfirmationDialog: showConfirmationDialog,
  deleteEventSource: deleteEventSource,
  resetStateEventSource: resetStateEventSource,
  showSnackbarMessage: showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.eventBridge.data,
  loading: state.eventBridge.loading,
  status: state.eventBridge.status,
  message: state.eventBridge.message
});

EventSource = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventSource);

EventSource.propTypes = {
  fetchEventSourceConfig: PropTypes.func,
  data: PropTypes.object,
  status: PropTypes.string,
  deleteEventSource: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  handlePostEventSource: PropTypes.func,
  resetStateEventSource: PropTypes.func,
  message: PropTypes.string
};

export default EventSource;
