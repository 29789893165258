import React from 'react';
import CampaignContext from '../CampaignContext';
import { AVAILABLE_CAMPAIGN_TYPES } from '../WizardConstants';

export class StepType extends React.Component {
  renderTypes(selectedType, setType) {
    return Object.keys(AVAILABLE_CAMPAIGN_TYPES).map((key) => {
      let { type, image, enabled } = AVAILABLE_CAMPAIGN_TYPES[key];
      let selected = key === selectedType ? 'selected' : '';
      return (
        <div
          className={`campaignType__cell ${enabled} ${selected}`}
          key={key}
          onClick={() => {
            if (enabled !== 'disabled') setType(key);
          }}
        >
          <div style={{ backgroundImage: `url(${image})` }} className="campaignType__logo" alt={type} />
          <p className="campaignType__name">{type}</p>
        </div>
      );
    });
  }

  render() {
    return <CampaignContext.Consumer>{({ type, setType }) => <ul className="auto-grid">{this.renderTypes(type, setType)}</ul>}</CampaignContext.Consumer>;
  }
}

export default StepType;
