import React, { useEffect, useState } from "react";
import InsightsDetails from "./InsightsDetails";
import { Filter, Time } from "@operata/ui-components";
import InsightsChart from "./InsightsChart";
import { getTimestampCriteria } from "../../../utils/datatables";
import { fetchLogs } from "../../../actions/heartbeat";
import { fetchConfig } from "../../../actions/config";
import { dispatchChangeFilter } from "../../../actions/location";
import { useDispatch, useSelector } from "react-redux";
import "./Insights.scss";
import {
  subscribeToPage,
  unsubscribeFromPage
} from "../../../websockets/socket";
import { HEARTBEAT_JOBS } from "../../../constants/push";
import { matchPath, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { QS } from "../../UNSAFE_route";

export const FINISHED_STATUSES = ["Completed", "Poor Call Quality"];

const Insights = props => {
  const dispatch = useDispatch();
  const { logs } = useSelector(state => state.heartbeat);
  const { config } = useSelector(state => state.config);
  let filterInsights = useSelector(
    state =>
      state.location["hbinsights"] && state.location["hbinsights"].filter
        ? state.location["hbinsights"].filter
        : {}
  );

  if (!filterInsights["timestamp"]) {
    filterInsights["timestamp"] = {
      dateRange: "time:(from:now-4h,to:now)",
      selectedRange: "Last 4 hours",
      selectedRangeDisplay: "Last 4 hours"
    };
  }
  if (!filterInsights["agentType"]) {
    filterInsights["agentType"] = [];
  }
  if (!filterInsights["targetPhoneNumber"]) {
    filterInsights["targetPhoneNumber"] = [];
  }
  if (!filterInsights["selectedTab"]) {
    filterInsights["selectedTab"] = 0;
  }
  if (!filterInsights["sortDirection"]) {
    filterInsights["sortDirection"] = {
      telephony: {
        colIndex: 0,
        order: null
      },
      cX: {
        colIndex: 0,
        order: null
      },
      aX: {
        colIndex: 0,
        order: null
      },
      networkPerformance: {
        colIndex: 0,
        order: null
      }
    };
  }
  if (!filterInsights["showColumns"]) {
    filterInsights["showColumns"] = {
      telephony: [
        { name: "jobId", display: "false" },
        { name: "toNumber", display: "true" },
        { name: "carrier", display: "true" },
        { name: "audioLatency", display: "true" },
        { name: "audioQuality", display: "true" },
        { name: "status", display: "true" },
        { name: "tags", display: "true" }
      ],
      cX: [
        { name: "jobId", display: "false" },
        { name: "timestamp", display: "true" },
        { name: "agentId", display: "true" },
        { name: "agentType", display: "true" },
        { name: "audioLatency", display: "true" },
        { name: "audioQuality", display: "true" },
        { name: "cxScore", display: "true" },
        { name: "tags", display: "true" }
      ],
      aX: [
        { name: "jobId", display: "false" },
        { name: "timestamp", display: "true" },
        { name: "agentId", display: "true" },
        { name: "agentType", display: "true" },
        { name: "audioLatency", display: "true" },
        { name: "audioQuality", display: "true" },
        { name: "axScore", display: "true" },
        { name: "tags", display: "true" }
      ],
      networkPerformance: [
        { name: "jobId", display: "false" },
        { name: "timestamp", display: "true" },
        { name: "agentId", display: "true" },
        { name: "agentType", display: "true" },
        { name: "rtt", display: "true" },
        { name: "jitter", display: "true" },
        { name: "packetLoss", display: "true" },
        { name: "mos", display: "true" },
        { name: "tags", display: "true" }
      ]
    };
  }

  if (!filterInsights["tags"]) {
    filterInsights["tags"] = {
      telephony: [],
      cX: [],
      aX: [],
      networkPerformance: []
    };
  }
  const [filters, setFilters] = useState(filterInsights);

  useEffect(() => {
      dispatch(fetchConfig("HEARTBEAT_TARGET_PHONE_NUMBERS", "array"));
    }, []);

  useEffect(
    () => {
      const timeCriteria = getTimestampCriteria(filters.timestamp.dateRange);
      const additionalCriteria = {
        status: FINISHED_STATUSES,
        agentType:
          filters.agentType &&
          filters.agentType.map(type => `${type.toUpperCase()}_AGENT`),
        targetPhoneNumber:
          filters.targetPhoneNumber &&
          filters.targetPhoneNumber.map(number => encodeURIComponent(number))
      };
      const criteria = Object.assign({}, timeCriteria, additionalCriteria);

      dispatch(fetchLogs(criteria));

      subscribeToPage(HEARTBEAT_JOBS, job => {
        if (FINISHED_STATUSES.includes(job.status)) {
          dispatch(fetchLogs(criteria));
        }
      });

      if (matchPath(window.location.pathname, props.match)) {
        QS.updateFromState("hbinsights");
      }

      return function cleanup() {
        unsubscribeFromPage(HEARTBEAT_JOBS);
      };
    },
    [filters]
  );

  const handleFilterReset = () => {
    setFilters({
      ...filters,
      agentType: [],
      targetPhoneNumber: []
    });
  };

  const handleFilterUpdate = (index, filterValue, fieldName) => {
    if (fieldName === "timestamp") {
      setFilters({
        ...filterInsights,
        timestamp: {
          dateRange: filterInsights.timestamp.dateRange,
          selectedRange: filterInsights.timestamp.selectedRange,
          selectedRangeDisplay: filterInsights.timestamp.selectedRangeDisplay
        }
      });

      dispatch(
        dispatchChangeFilter(
          {
            ...filterInsights
          },
          "hbinsights"
        )
      );

      if (matchPath(window.location.pathname, props.match)) {
        QS.updateFromState("hbinsights");
      }
    } else {
      setFilters({
        ...filterInsights,
        [fieldName]: filterValue
      });

      dispatch(
        dispatchChangeFilter(
          {
            ...filters,
            agentType:
              fieldName === "agentType" ? filterValue : filters.agentType,
            targetPhoneNumber:
              fieldName === "targetPhoneNumber"
                ? filterValue
                : filters.targetPhoneNumber
          },
          "hbinsights"
        )
      );
    }
  };

  for (let index = 0; index < filters.targetPhoneNumber.length; index++) {
    if (filters.targetPhoneNumber[index].charAt(0) === " ") {
      filters.targetPhoneNumber[index] = filters.targetPhoneNumber[
        index
      ].replace(filters.targetPhoneNumber[index].charAt(0), "+");
    }
  }

  return (
    <React.Fragment>
      <Filter
        filters={[
          {
            name: "timestamp",
            label: "Timestamp",
            type: "custom",
            // eslint-disable-next-line react/display-name
            display: (filteredData, onChange, index, filter) => {
              return (
                <Time
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterInsights = {
                      ...filterInsights,
                      timestamp: {
                        dateRange: dateRange,
                        selectedRange: selectedRange,
                        selectedRangeDisplay: selectedRangeDisplay
                      }
                    };
                    onChange(
                      {
                        dateRange,
                        selectedRange,
                        selectedRangeDisplay
                      },
                      index,
                      filter
                    );
                  }}
                  selectedRange={
                    filterInsights && filterInsights.timestamp.selectedRange
                  }
                  selectedRangeDisplay={
                    filterInsights &&
                    filterInsights.timestamp.selectedRangeDisplay
                  }
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            }
          },
          {
            label: "Agent Type",
            name: "agentType",
            options: ["Human", "Virtual"],
            type: "multiselect",
            filteredList: ["", filters.agentType, filters.targetPhoneNumber]
          },
          {
            label: "Target Phone Number",
            name: "targetPhoneNumber",
            options:
              config &&
              config.key === "HEARTBEAT_TARGET_PHONE_NUMBERS" &&
              config.value
                ? config.value
                : [],
            type: "multiselect",
            filteredList: ["", filters.agentType, filters.targetPhoneNumber]
          }
        ]}
        onFilterReset={handleFilterReset}
        onFilterUpdate={handleFilterUpdate}
      />

      <InsightsChart logs={logs} filters={filters} />
      <InsightsDetails
        props={props}
        filters={filters}
        logs={logs}
        filterInsights={filterInsights}
      />
    </React.Fragment>
  );
};

Insights.propTypes = {
  match: PropTypes.object
};

export default withRouter(Insights);
