import React from 'react';
import Chip from '@material-ui/core/Chip';

class Chips extends React.Component {
  render() {
    let parentProps = this.props;
    return <Chip label={this.props.label} className="chip" {...parentProps} />;
  }
}

export default Chips;
