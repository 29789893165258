import {
  CONFIG_CREATED,
  ERROR_CONFIG_CREATED,
  RESET_CONFIG_PAGE_STATE,
  CONFIG_RECEIVED,
  ERROR_CONFIG_RECEIVED
} from "../actions/config";

const initialState = {
  loading: false
};

const actions = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_RECEIVED:
      //TODO: modify the config to store all config values instead of replacing them
      var configs = state.configs || {};
      configs = action.config
        ? { ...configs, [action.config.key]: action.config.value }
        : {};
      var newstate = {
        ...state,
        configs,
        config: action.config,
        loading: false,
        status: CONFIG_RECEIVED
      };
      return newstate;
    case RESET_CONFIG_PAGE_STATE:
      return {
        ...state,
        loading: false,
        status: RESET_CONFIG_PAGE_STATE
      };
    case CONFIG_CREATED:
      return {
        ...state,
        loading: false,
        status: CONFIG_CREATED,
        config: action.config
      };
    case ERROR_CONFIG_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_CONFIG_CREATED,
        random: Math.random() * 10000
      };
    case ERROR_CONFIG_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_CONFIG_RECEIVED,
        random: Math.random() * 10000
      };
    default:
      return state;
  }
};

export default actions;
