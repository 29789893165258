import React, { useState } from "react";
import { ButtonRefactored as Button, InputSelect, Modal, Skeleton, Stack, Table, Typography } from "@operata/adagio";
import { DataRole } from "../../models/dataRole";
import { UserProfile } from "../../models/user";

type UpdateRoleModalProps = {
  onClose: () => void;
  onAddUser: (user: UserProfile) => void;
  onRemoveUser: (user: UserProfile) => void;
  role: DataRole;
  usersForRole: string[];
  users: UserProfile[];
};

export default function UpdateRoleModal({onClose, onAddUser, onRemoveUser, role, users, usersForRole}: UpdateRoleModalProps) {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const isSelectedUserValid = users.find((user) => user.id == selectedUser);

  const handleAddUser = () => {
    const user = users.find((user) => user.id == selectedUser);
    if (user) {
      onAddUser(user);
      setSelectedUser(null);
    }
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onClose={onClose}
        width="medium"
      >
        <Modal.Header border>Update Role - {role.name}</Modal.Header>
        <Modal.Content>
          <Stack direction="column" gap="medium" padding="medium" block>
            { !users.length ?
              <Stack gap={"small"}>
                <Skeleton loading width={265} height={20} />
                <Skeleton loading width={120} height={20} />
                <Skeleton loading width={265} height={20} />
              </Stack>
            : <>
                <Typography large>Add Team Member</Typography>
                <Stack direction={"row"} gap={"small"} block>
                  <InputSelect placeholder={"Select user to add"} selectedValue={selectedUser} setSelectedValue={setSelectedUser}>
                    {
                      users?.filter((user) => !usersForRole.includes(user.id))
                        .map((user) => (<InputSelect.Option key={user.id} value={user.id}>{user.name} ({user.email})</InputSelect.Option>))
                    }
                  </InputSelect>
                  <Button disabled={!isSelectedUserValid} onClick={() => handleAddUser()}>Add Team Member</Button>
                </Stack>
                <Typography large>Who has access</Typography>
                <UserRoleTable usersForRole={usersForRole} users={users} onRemove={onRemoveUser} />
              </>
            }
          </Stack>
        </Modal.Content>
      </Modal>
    </div>
  );
}

function UserRoleTable({ usersForRole, users, onRemove }: { usersForRole: string[], users: UserProfile[], onRemove: (user: UserProfile) => void}) {
  if (usersForRole.length === 0) {
    return (
      <Typography>No users have access to this role.</Typography>
    );
  }

  return (
    <Table>
      <thead>
      <Table.Row>
        <Table.Header width="auto" sortable>Name</Table.Header>
        <Table.Header width="auto">Email</Table.Header>
        <Table.Header width="200"></Table.Header>
      </Table.Row>
      </thead>
      <tbody>
      { usersForRole.map((userId) => {
        const user = users.find((u) => u.id === userId);
        return (
          <Table.Row key={userId}>
            <Table.Cell>{user?.name}</Table.Cell>
            <Table.Cell>{user?.email}</Table.Cell>
            <Table.Cell align={"right"}>
              <Stack direction="row" gap="4" justify={"end"}>
                <Button type="danger" onClick={() => user && onRemove(user)}>Remove</Button>
              </Stack>
            </Table.Cell>
          </Table.Row>
        );
      })}
      </tbody>
    </Table>
  );
}