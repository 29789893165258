import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Select, TextField } from "@operata/ui-components";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import PropTypes from "prop-types";

const ConditionRow = props => {
  const { index, condition, operator, onChange, onDelete, errors } = props;

  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <TextField
          id="outlined-required"
          error={errors.name}
          label={errors.name ? errors.name : ""}
          name="name"
          className="textField"
          onChange={event =>
            onChange(index, condition, {
              ...condition,
              name: event.target.value
            })
          }
          variant="outlined"
          value={condition.name}
        />
      </TableCell>
      <TableCell>
        <Select
          name="operator"
          value={operator}
          onChange={event =>
            onChange(index, condition, {
              ...condition,
              operator: event.target.value
            })
          }
        >
          <MenuItem value="<">Less Than</MenuItem>
          <MenuItem value=">">Greater Than</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          id="outlined-required"
          error={errors.threshold}
          label={errors.threshold ? errors.threshold : ""}
          name="threshold"
          className="textField"
          onChange={event => {
            onChange(index, condition, {
              ...condition,
              threshold: Number(event.target.value)
            });
          }}
          variant="outlined"
          value={condition.threshold}
          type={"number"}
        />
      </TableCell>
      <TableCell>
        <a href={"#"} onClick={() => onDelete(index)}>
          <DeleteIcon color={"secondary"} />
        </a>
      </TableCell>
    </TableRow>
  );
};

ConditionRow.propTypes = {
  condition: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  operator: PropTypes.string,
  errors: PropTypes.object
};

export default ConditionRow;
