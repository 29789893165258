import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../../auth/Auth";
import _ from "lodash";
import PropTypes from "prop-types";
import { getLocalStateObject } from "../../utils/deeplinks";

class Callback extends Component {
  constructor() {
    super();

    this.loadDefaultPage = this.loadDefaultPage.bind(this);
  }

  loadDefaultPage() {
    let localStateObject = getLocalStateObject();

    if (
      _.has(localStateObject, "redirectUrl") &&
      localStateObject.redirectUrl.length > 1
    ) {
      console.log(`Redirecting to: ` + localStateObject.redirectUrl);
      this.props.history.replace(localStateObject.redirectUrl);
    } else {
      this.props.history.replace("/" + auth.getCurrentGroupId() + "/dashboard");
    }
  }

  componentDidMount() {
    auth.handleAuthentication(this.loadDefaultPage);
  }

  render() {
    return <div />;
  }
}

Callback.propTypes = {
  history: PropTypes.object
};

export default withRouter(Callback);
