import React, { useState } from "react";
import {
  LinkTab,
  TabContainer,
  Tabs,
  TabsWrapper
} from "@operata/ui-components";
import Numbers from "./Numbers";
import CallTaggingConfig from "../CallTaggingConfig";

const GSMConfigs = () => {
  const [state, setState] = useState({
    selectedTab: 0
  });

  return (
    <TabsWrapper>
      <Tabs
        selectedTab={state.selectedTab}
        onChange={(e, val) => setState({ selectedTab: val })}
      >
        <LinkTab label="To Numbers" href="page1" />
        <LinkTab label="Tagging" href="page2" />
      </Tabs>
      <TabContainer selectedTab={state.selectedTab} index={0}>
        <Numbers />
      </TabContainer>
      <TabContainer selectedTab={state.selectedTab} index={1}>
        <CallTaggingConfig />
      </TabContainer>
    </TabsWrapper>
  );
};

export default GSMConfigs;
