import { axiosProxy } from "../axios/AxiosProxy";

export const CUSTOMERS_RECEIVED = "CUSTOMERS_RECEIVED";

export const customersReceived = customerList => ({
    type: CUSTOMERS_RECEIVED,
    customerList: customerList
  });


//redux-thunk
export const fetchCustomers = () => {
    return dispatch => {
        return getCustomers().then(resp => {
            dispatch(customersReceived(resp.data));
        });
    };
};

const getCustomers = () => {
    return axiosProxy.getInstance().get("/customer");
  };
  