import moment from "moment";

const displayDateFormat = "DD-MM-YYYY HH:mm:ss";

export const formatDate = (date, format = displayDateFormat) => {
  if (!moment(date).isValid()) {
    return "";
  }

  return moment(date).format(format);
};

// Compares two dates and returns a sort order
// Returns 1 if a is a date earlier than b, -1 otherwise
// An invalid date will have a lower order
export const sortDate = (a, b) => {
  const HIGHER_THAN = 1;
  const LOWER_THAN = -1;
  const dateA = moment(a, displayDateFormat);
  const dateB = moment(b, displayDateFormat);

  if (!dateA.isValid()) {
    return LOWER_THAN;
  } else if (!dateB.isValid()) {
    return HIGHER_THAN;
  }
  return dateA.isAfter(dateB) ? LOWER_THAN : HIGHER_THAN;
};

export const getDurationBetween = (a, b, unit = "seconds") => {
  const dateA = moment(a);
  const dateB = moment(b);

  if (!dateA.isValid() || !dateB.isValid()) {
    return 0;
  }

  return dateA.diff(dateB, unit);
};

// For Adagio date filter
export const convertTimeFilterToDate = timeFilter => {
  let date = new Date();

  if (timeFilter === "24h") {
    date = new Date(date - 24 * 60 * 60 * 1000);
  } else if (timeFilter === "7d") {
    date = new Date(date - 7 * 24 * 60 * 60 * 1000);
  } else if (timeFilter === "28d") {
    date = new Date(date - 28 * 24 * 60 * 60 * 1000);
  }

  return date;
};
