import React from 'react';
import MonitorContext from '../MonitorContext';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export class StepAgentRegions extends React.Component {
    render() {
        return (
            <MonitorContext.Consumer>
                {({
                      initAgentRegions,
                      setAgentRegions,
                }) => {
                    return (
                        <div className="grid__container workflow__summary">
                            <div className="grid__column grid__column--four">

                                {
                                    Object.keys(initAgentRegions).map((region) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={initAgentRegions[region]}
                                                    onChange={event => setAgentRegions(event.target.value)}
                                                    name="connectRegions"
                                                    value={region}
                                                />}
                                            label={region}
                                        />
                                    ))}

                            </div>
                        </div>
                    );
                }}
            </MonitorContext.Consumer>
        );
    }
}

export default StepAgentRegions;
