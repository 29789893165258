import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Wizard } from "@operata/ui-components";
import StepConditions from "./StepConditions";
import StepDetails from "./StepDetails";
import { validateRequireField } from "../../Generic/core-validations";

const TaggingConfigWizard = ({ config, onSave }) => {
  const [resetWizard, setResetWizard] = useState(false);
  const [name, setName] = useState(config.name || "");
  const [field, setField] = useState(config.field || "");
  const [conditions, setConditions] = useState(config.conditions || "");
  const [errorStates, setErrorStates] = useState({});

  useEffect(
    () => {
      setName(config.name);
      setField(config.field);
      setConditions(config.conditions || []);
      setResetWizard(false);

      if (_.isEmpty(config)) {
        setResetWizard(true);
      }
    },
    [config]
  );

  const getFormData = () => {
    return {
      id: config.id,
      name,
      field,
      conditions
    };
  };

  const validate = requiredFields => {
    const errors = {};

    validateRequireField(requiredFields, getFormData(), errors);
    errors.conditions = validateConditions();

    setErrorStates(errors);
    return errors;
  };

  const validateConditions = () => {
    let conditionErrors = [];

    conditions.forEach(condition => {
      const errors = {};
      validateRequireField(["name", "threshold"], condition, errors);
      conditionErrors.push(errors);
    });
    return conditionErrors;
  };

  const steps = [
    {
      content: (
        <StepDetails
          name={name}
          setName={setName}
          field={field}
          setField={setField}
          errors={errorStates}
        />
      ),
      label: "Details",
      onError: function noRefCheck() {},
      validate: () => {
        return validate(["name", "field"]);
      }
    },
    {
      content: (
        <StepConditions
          conditions={conditions}
          setConditions={setConditions}
          errors={errorStates}
        />
      ),
      label: "Conditions",
      onError: function noRefCheck() {},
      validate: () => {
        return validate([]);
      }
    }
  ];

  const handleSave = () => {
    onSave(getFormData());
    setResetWizard(!resetWizard);
  };

  return <Wizard onSave={handleSave} steps={steps} reset={resetWizard} />;
};

TaggingConfigWizard.propTypes = {
  config: PropTypes.object,
  onSave: PropTypes.func
};

export default TaggingConfigWizard;
