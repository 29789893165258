import { validateRequireField } from "../Generic/core-validations";

export const validate = values => {
  const errors = {};

  const requiredFields = [
    "name",
    "ccp",
    "region",
    "customer",
    "newCustomerName",
    "existingCustomerName",
    "ccaasProvider"
  ];

  validateRequireField(requiredFields, values, errors);

  return errors;
};
