import { axiosProxy } from "../../axios/AxiosProxy";

export async function getCallData(contactId, region) {
  const data = await getData(contactId, region);

  const call = data?.seconds?.length > 0
      ? data.seconds.sort(
          (x, y) => new Date(x.timestamp) - new Date(y.timestamp)
        )
      : null;
  const summary = hasHits(data?.summary)
    ? data?.summary?.hits?.hits[0]?._source
    : {};
  const logs = hasHits(data?.logs) ? mapCallData(data?.logs?.hits?.hits) : null;

  return { call, summary, logs };
}

async function getData(contactId, region) {
  try {
    const response = await axiosProxy
      .getInstance()
      .get(`/viscall2/call/${region}/${contactId}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching data", error);
    return null;
  }
}

function mapCallData(data) {
  if (!data) return null;

  data = data.map(e => {
    const source = { ...e._source };
    if (source.clientInfo) {
      delete source.clientInfo;
    }
    return source;
  });

  return data.sort((x, y) => new Date(x.timestamp) - new Date(y.timestamp));
}

function hasHits(data) {
  return data?.hits?.hits?.length > 0;
}

export function formatDuration(startTime, endTime) {
  if (!startTime || !endTime) return null;
  const durationMs = new Date(endTime) - new Date(startTime);
  const totalSeconds = Math.round(durationMs / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours ? `${hours}h ` : ""}${
    minutes ? `${minutes}m ` : ""
  }${seconds}s`;
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
