import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';

class TableHeadRow extends React.Component {
  static propTypes = {
    /** Extend the style applied to components */
    classes: PropTypes.object,
  };

  render() {
    return <TableRow className="table__row">{this.props.children}</TableRow>;
  }
}

export default TableHeadRow;
