import React from "react";
import Tabs from "@material-ui/core/Tabs";
import "../Badge/Badge.scss";
import LinkTab from "../Tabs/LinkTab";
import TabContainer from "../Tabs/TabContainer";
import WorkflowActionsHistory from "../Actions/WorkflowActionsHistory.jsx";
import AuditLogs from "../AuditLogs/AuditLogs";

class HistoryLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0
    };
  }

  componentDidMount() {}

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { tab } = this.state;
    let pages = [];

    pages.push("WorkflowActionsHistory");
    pages.push("AuditLogs");

    return (
      <React.Fragment>
        <div className="grid__container auto-height">
          <div className="grid__column grid__column--twelve">
            <div className="grid__container auto-height">
              {" "}
              <div className="grid__column--twelve">
                <div className="tabs__wrapper">
                  <Tabs
                    className="tabs__links"
                    value={tab}
                    onChange={this.handleTab}
                  >
                    <LinkTab
                      label="Workflow Actions"
                      href="page2"
                      badgeCount={this.state.resultsCount}
                    />
                    <LinkTab label="Audit Logs" href="page3" />
                  </Tabs>
                  {pages[tab] === "WorkflowActionsHistory" && (
                    <TabContainer>
                      <WorkflowActionsHistory />
                    </TabContainer>
                  )}
                  {pages[tab] === "AuditLogs" && (
                    <TabContainer>
                      <AuditLogs />
                    </TabContainer>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HistoryLists;
