import React from "react";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@operata/ui-components";
import Clock from 'react-live-clock';
import "./Status.scss";
import PropTypes from "prop-types";

function StatusHeader(props){

    const { index, group, onChange } = props;

        return(
            <div className="status__header__wrapper">
                <div className="status__header__groupSection">
                    <Select
                        name="groupSelect"
                        value={group}
                        onChange={event =>
                            onChange(index, group,{
                                ...group,
                                group: event.target.value
                            })
                        }
                        >
                        <MenuItem value="test">Test</MenuItem>
                    </Select>
                </div>
                <div className="status__header__timeSection">
                    <div className="worldTime__wrapper__Seattle">
                        <div className="worldTime__clock">
                            <AccessTimeIcon/>
                        </div>
                        <div className="worldTime__nameTime">
                            <div className="worldTime__name">
                                Seattle
                            </div>
                            <Clock className="worldTime__seattle" format={'HH:mm:ss'} ticking={true} timezone={'US/Pacific'} />
                        </div>
                    </div>
                    <div className="worldTime__wrapper__London">
                        <div className="worldTime__clock">
                            <AccessTimeIcon/>
                        </div>
                        <div className="worldTime__nameTime">
                            <div className="worldTime__name">
                                London
                            </div>
                            <Clock className="worldTime__london" format={'HH:mm:ss'} ticking={true} timezone={'Europe/London'} />
                        </div>
                    </div>
                    <div className="worldTime__wrapper__Sydney">
                        <div className="worldTime__clock">
                            <AccessTimeIcon/>
                        </div>
                        <div className="worldTime__nameTime">
                            <div className="worldTime__name">
                                Sydney
                            </div>
                            <Clock className="worldTime__sydney" format={'HH:mm:ss'} ticking={true} timezone={'Australia/Sydney'} />
                        </div>
                    </div>
                </div>
                <div className="status__header__alertSection">
                    <div className="alert__icon">
                        <ReportProblemIcon/>
                    </div>
                </div>
            </div>
        );
}

StatusHeader.propTypes = {
    index: PropTypes.number,
    group: PropTypes.object,
    onChange: PropTypes.func
}

export default StatusHeader;