import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";

export const TWELVE_MONTHS_APP_USAGE_RECEIVED =
  "TWELVE_MONTHS_APP_USAGE_RECEIVED";
export const ERROR_APP_USAGE_RETRIEVAL = "ERROR_APP_USAGE_RETRIEVAL";

export const showProgress = () => ({
  type: SHOW_PROGRESS
});

export const twelveMonthAppUsageFetched = data => ({
  type: TWELVE_MONTHS_APP_USAGE_RECEIVED,
  usageData: data
});

export const errorAppUsageRetrieval = () => ({
  type: ERROR_APP_USAGE_RETRIEVAL
});

export const fetchMonthlyAppUsage = () => {
  return dispatch => {
    dispatch(showProgress());
    return getMonthlyAppUsage()
      .then(resp => {
        dispatch(twelveMonthAppUsageFetched(resp.data));
      })
      .catch(() => {
        dispatch(errorAppUsageRetrieval());
      });
  };
};

export const getMonthlyAppUsage = () => {
  return axiosProxy.getInstance().get("/billing/usage");
};
