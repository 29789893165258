import Select from "@material-ui/core/Select";
import { OutlinedInput } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@operata/ui-components";

export const StepDetails = ({
  AWSRegion,
  setAWSRegion,
  errorAWSRegion,
  eventSourceName,
  setEventSourceName,
  errorEventSourceName,
  errorMessageEventSourceName,
  AWSAccount,
  setAWSAccount,
  errorAWSAccount,
  errorMessageAWSAccount,
  eventSNPattern
}) => {
  const availableAWSRegions = [
    "us-east-1",
    "us-east-2",
    "us-west-1",
    "us-west-2",
    "af-south-1",
    "ap-east-1",
    "ap-south-1",
    "ap-southeast-1",
    "ap-southeast-2",
    "ap-northeast-1",
    "ap-northeast-2",
    "ap-northeast-3",
    "ca-central-1",
    "eu-central-1",
    "eu-west-1",
    "eu-west-2",
    "eu-west-3",
    "eu-south-1",
    "eu-north-1",
    "me-south-1",
    "sa-east-1"
  ];

  return (
    <div className="grid__container workflow__summary">
      <div className="grid__column grid__column--four">
        <div className="workflow__input">
          <label htmlFor="business">AWS Region</label>
          <Select
            name="awsRegion"
            value={AWSRegion}
            variant="outlined"
            id="outlined-required"
            error={errorAWSRegion}
            onChange={event => setAWSRegion(event.target.value)}
            input={<OutlinedInput name="AWSRegion" id="schedule-helper" />}
          >
            {availableAWSRegions.map((region, i) => (
              <MenuItem key={availableAWSRegions[i]} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>

          <TextField
            label="Event Source Name Prefix"
            value={eventSNPattern}
            disabled
          />
          <TextField
            error={errorEventSourceName}
            label={
              errorMessageEventSourceName
                ? errorMessageEventSourceName
                : "Event Source Name"
            }
            id="outlined-required"
            name="eventSourceName"
            className="textField"
            onChange={event => setEventSourceName(event.target.value)}
            variant="outlined"
            value={eventSourceName}
            float="right"
          />

          <TextField
            error={errorAWSAccount}
            label={
              errorMessageAWSAccount ? errorMessageAWSAccount : "AWS Account"
            }
            id="outlined-required"
            name="awsAccount"
            className="textField"
            onChange={event => setAWSAccount(event.target.value)}
            variant="outlined"
            value={AWSAccount}
          />
        </div>
      </div>
    </div>
  );
};

StepDetails.propTypes = {
  AWSRegion: PropTypes.string,
  setAWSRegion: PropTypes.func,
  errorAWSRegion: PropTypes.string,
  eventSourceName: PropTypes.string,
  setEventSourceName: PropTypes.func,
  errorEventSourceName: PropTypes.string,
  errorMessageEventSourceName: PropTypes.string,
  AWSAccount: PropTypes.string,
  setAWSAccount: PropTypes.func,
  errorAWSAccount: PropTypes.string,
  errorMessageAWSAccount: PropTypes.string,
  eventSNPattern: PropTypes.string
};
