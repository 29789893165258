import { TextField } from "@operata/ui-components";
import React from "react";
import PropTypes from "prop-types";

const StepDetails = props => {
  const { name, setName, field, setField, errors } = props;

  return (
    <div className="grid__container workflow__summary">
      <div className="grid__column grid__column--four">
        <div className="workflow__input">
          <TextField
            error={errors.name}
            label={errors.name ? errors.name : "Name"}
            id="outlined-required"
            name="name"
            className="textField"
            onChange={event => setName(event.target.value)}
            variant="outlined"
            value={name}
          />
        </div>
        <div className="workflow__input">
          <TextField
            error={errors.field}
            label={errors.field ? errors.field : "Field"}
            id="outlined-required"
            name="field"
            className="textField"
            onChange={event => setField(event.target.value)}
            variant="outlined"
            value={field}
          />
        </div>
      </div>
    </div>
  );
};

StepDetails.propTypes = {
  field: PropTypes.string,
  name: PropTypes.string,
  setField: PropTypes.func,
  setName: PropTypes.func,
  errors: PropTypes.object
};

export default StepDetails;
