import React from "react";
import {InputSelect} from "@operata/adagio";
import { DataRole } from "../../models/dataRole";

type RoleSelectorProps = {
  roles: DataRole[];
  activeRoleId: number;
  updateRole: (roleId: number) => void;
}

export default function RoleSelector({ roles, activeRoleId, updateRole }: RoleSelectorProps) {
  const disabled = !roles?.length;
  const placeholder = disabled ? "No Roles Available" : undefined;
  return (
      <InputSelect placeholder={placeholder} disabled={disabled} selectedValue={activeRoleId} setSelectedValue={(r: string) => { updateRole(parseInt(r, 10)) }}>
          {
              roles?.map((role) => (<InputSelect.Option key={role.id} value={role.id}>{role.name}</InputSelect.Option>))
          }
      </InputSelect>
  );
}
