import axios from 'axios';
import { SHOW_PROGRESS } from './progress';

export const README_RECEIVED = 'README_RECEIVED';
export const SAMPLE_CODE_RECEIVED = 'SAMPLE_CODE_RECEIVED';

export const fetchReadmeActionRequested = () => ({
  type: SHOW_PROGRESS,
});

export const readmeReceived = readme => ({
  type: README_RECEIVED,
  readme: readme,
});

export const sampleCodeReceived = code => ({
  type: SAMPLE_CODE_RECEIVED,
  code: code,
});

//redux-thunk
export const fetchReadme = () => {
  return dispatch => {
    dispatch(fetchReadmeActionRequested());
    return getReadme().then(resp => {
      console.log(resp)
      dispatch(readmeReceived(resp.data));
    }).catch(err => {
      console.log('error loading readme', err)
    });
  };
};

export const fetchSampleCode = () => {
  return dispatch => {
    dispatch(fetchReadmeActionRequested());
    return getSampleCode().then(resp => {
      dispatch(sampleCodeReceived(resp.data));
    }).catch(err => {
      console.log('error loading the sample code', err)
    });
  };
};

export const getReadme = () => {
  return axios.get(process.env.REACT_APP_STATIC_JS_URL + 'README.js', { crossdomain: true,  headers: {
    accept: 'text/markdown'
  }});
};

export const getSampleCode = () => {
  return axios.get(process.env.REACT_APP_STATIC_JS_URL + 'operata_example_softphone.html', { crossdomain: true });
};


