import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import CampaignContext from '../CampaignContext';

export class StepNumber extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          fromNumber,
          setFromNumber,
          error_fromNumber,
          errorMessage_fromNumber,
          fromNumbers,
          toNumber,
          setToNumber,
          error_toNumber,
          errorMessage_toNumber,
          toNumbers,
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">

                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="fromNumber-helper">
                      {errorMessage_fromNumber ? errorMessage_fromNumber : 'From Number'}
                    </InputLabel>
                    <Select
                      name="fromNumber"
                      error={error_fromNumber}
                      variant="outlined"
                      value={fromNumber}
                      onChange={event => setFromNumber(event.target.value)}
                      input={<OutlinedInput name="fromNumber" id="fromNumber-helper" />}
                    >
                      {fromNumbers.map((number, i) => (
                        <MenuItem value={number}>{number}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="toNumber-helper">
                      {errorMessage_toNumber ? errorMessage_toNumber : 'To Number'}
                    </InputLabel>
                    <Select
                      name="toNumber"
                      error={error_toNumber}
                      variant="outlined"
                      value={toNumber}
                      onChange={event => setToNumber(event.target.value)}
                      input={<OutlinedInput name="toNumber" id="toNumber-helper" />}
                    >
                      {toNumbers.map((number, i) => (
                        <MenuItem value={number}>{number}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}

export default StepNumber;
