import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const EVENT_SOURCE_CREATED = "EVENT_SOURCE_CREATED";
export const ERROR_EVENT_SOURCE_CREATED = "ERROR_EVENT_SOURCE_CREATED";
export const EVENT_SOURCE_CONFIG_RECEIVED = "EVENT_SOURCE_CONFIG_RECEIVED";
export const EVENT_SOURCE_DELETED = "EVENT_SOURCE_DELETED";
export const RESET_EVENT_SOURCE_PAGE_STATE = "RESET_EVENT_SOURCE_PAGE_STATE";
export const ERROR_EVENT_SOURCE_FETCH = "ERROR_EVENT_SOURCE_FETCH";
export const ERROR_EVENT_SOURCE_DELETED = "ERROR_EVENT_SOURCE_DELETED";

export const eventSourceActionRequested = () => ({
  type: SHOW_PROGRESS
});

export const handlePostEventSource = eventSource => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    postEventSource(eventSource)
      .then(resp => {
        dispatch(eventSourceCreated(resp.data));
        dispatch(resetStateEventSource());
      })
      .catch(error => {
        if (
          error.response &&
          (error.response.status == 502 ||
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 500 ||
            error.response.status == 403 ||
            error.response.status == 503)
        ) {
          dispatch(errorEventSourceCreated(error.response.data));
        }
      });
  };
};

const postEventSource = eventSource => {
  return axiosProxy.getInstance().post("/users/eventBridge", eventSource);
};

export const eventSourceCreated = eventSource => ({
  type: EVENT_SOURCE_CREATED,
  eventSource: eventSource
});

export const errorEventSourceCreated = message => ({
  type: ERROR_EVENT_SOURCE_CREATED,
  message: message
});

export const errorEventSourceFetch = message => ({
  type: ERROR_EVENT_SOURCE_FETCH,
  message: message
});

export const errorEventSourceDeleted = message => ({
  type: ERROR_EVENT_SOURCE_DELETED,
  message: message
});

export const eventSourceConfigReceived = eventSourceConfig => ({
  type: EVENT_SOURCE_CONFIG_RECEIVED,
  eventSourceConfig: eventSourceConfig
});

const getEventSourceConfig = fetchStatus => {
  return axiosProxy
    .getInstance()
    .get("/users/eventBridge/" + fetchStatus.toString());
};

export const fetchEventSourceConfig = fetchStatus => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    return getEventSourceConfig(fetchStatus)
      .then(resp => {
        dispatch(eventSourceConfigReceived(resp.data));
      })
      .catch(error => {
        if (
          error.response &&
          (error.response.status == 502 ||
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 500 ||
            error.response.status == 403 ||
            error.response.status == 503)
        ) {
          dispatch(errorEventSourceFetch(error.response.data));
        }
      });
  };
};

export const eventSourceDeleted = () => ({
  type: EVENT_SOURCE_DELETED
});

export const deleteEventSource = eventSource => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    return delEventSource(eventSource[`eventSourceId`])
      .then(() => {
        dispatch(eventSourceDeleted());
      })
      .catch(error => {
        if (
          error.response &&
          (error.response.status == 502 ||
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 500 ||
            error.response.status == 403 ||
            error.response.status == 503)
        ) {
          dispatch(errorEventSourceDeleted(error.response.data));
        }
      });
  };
};

const delEventSource = id => {
  return axiosProxy.getInstance().delete("/users/eventBridge/" + id);
};

export const resetStateEventSource = () => ({
  type: RESET_EVENT_SOURCE_PAGE_STATE
});
