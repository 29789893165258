import { DIALOG_OPENED, DIALOG_CLOSED } from '../actions/dialog'

const dialog = (state = { 'status': '' }, action) => {
    switch (action.type) {
        case DIALOG_OPENED:
            return {
                ...state,
                'title': action.title,
                'message': action.message,
                'okCallback': action.okCallback,
                'okCallbackArgs': action.okCallbackArgs,
                'cancelCallback': action.cancelCallback,
                'cancelCallbackArgs': action.cancelCallbackArgs,
                'status': DIALOG_OPENED
            }
        case DIALOG_CLOSED:
            return { ...state, 'status': DIALOG_CLOSED }
        default:
            return state
    }
}

export default dialog