import {
  CSV_DOWNLOAD_REQUESTED,
  ERROR_CSV_DOWNLOAD,
  CSV_DATA_FETCHED
} from "../actions/extensionCoverage";

const extensionCoverage = (
  state = {
    loading: false,
    utilisationPeriod: "",
    tableRows: []
  },
  action
) => {
  switch (action.type) {
    case CSV_DATA_FETCHED:
      return Object.assign({}, state, {
        tableRows: action.payload,
        status: CSV_DATA_FETCHED
      });
    case CSV_DOWNLOAD_REQUESTED:
      return Object.assign({}, state, {
        csvData: action.payload,
        status: CSV_DOWNLOAD_REQUESTED
      });
    case ERROR_CSV_DOWNLOAD:
      return Object.assign({}, state, {
        status: ERROR_CSV_DOWNLOAD
      });
    default:
      return state;
  }
};

export default extensionCoverage;
