import CardAlternative from "../../../Card/CardAlternative";
import React from "react";
import * as PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";

function CX(props) {
  const { jobStats, calculateDegradation, customerToConnectReference } = props;
  let connect_to_dialler;
  let dialler_to_connect;
  let agent_to_connect;
  if (jobStats.polqaScores) {
    connect_to_dialler = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "connect_to_dialler"
    );
    agent_to_connect = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "agent_to_connect"
    );
    dialler_to_connect = jobStats.polqaScores.find(
      polqaScores => polqaScores.location === "dialler_to_connect"
    );
  }

  return (
    <>
      <CardAlternative
        title={
          "CX " +
          (_.has(jobStats, "job.cxScore")
            ? "(" + jobStats.job.cxScore + "/10)"
            : "")
        }
      />

      <div className="campaigns__grid">
        <List>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Quality"
              secondary={
                dialler_to_connect && _.has(dialler_to_connect, "score")
                  ? dialler_to_connect.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Quality"
              secondary={
                connect_to_dialler && _.has(connect_to_dialler, "score")
                  ? connect_to_dialler.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Loss"
              secondary={
                dialler_to_connect && _.has(dialler_to_connect, "score")
                  ? calculateDegradation(
                      customerToConnectReference,
                      dialler_to_connect.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Loss"
              secondary={
                agent_to_connect &&
                connect_to_dialler &&
                _.has(agent_to_connect, "score") &&
                _.has(connect_to_dialler, "score")
                  ? calculateDegradation(
                      agent_to_connect.score,
                      connect_to_dialler.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Echo Latency"
              secondary={
                jobStats &&
                jobStats.audioLatency &&
                jobStats.audioLatency.find(
                  audioLatency =>
                    audioLatency.recordingType === "echo" &&
                    audioLatency.averageLatencyMs
                )
                  ? jobStats.audioLatency.find(
                      audioLatency => audioLatency.recordingType === "echo"
                    ).averageLatencyMs + " ms"
                  : "N/A"
              }
            />
          </ListItem>
        </List>
      </div>
    </>
  );
}

CX.propTypes = {
  jobStats: PropTypes.object,
  calculateDegradation: PropTypes.func,
  customerToConnectReference: PropTypes.number
};

export default CX;
