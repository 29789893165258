import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import './Search.scss';
import SearchIcon from '@material-ui/icons/Search';

class Search extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  render() {
    return (
      <div className="search__wrapper">
        <div className="search__icon">
          <SearchIcon />
        </div>
        <InputBase placeholder="Search…" />
      </div>
    );
  }
}

export default Search;
