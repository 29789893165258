const initials = name => {
  if (!name?.trim()) return "";

  const words = name.trim().split(" ");

  if (words.length < 1) {
    return "";
  }

  if (words.length < 2) {
    return words[0].substring(0, 2).toUpperCase();
  }

  return (words[0].substring(0, 1) + words[1].substring(0, 1)).toUpperCase();
};

export default initials;
