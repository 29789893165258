import React from 'react';
import { createPortal } from "react-dom";
import classNames from 'classnames';
import Button from '../Button/Button';
import './Modal.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    // set initial state
    this.state = {
      isModalOpen: props.isModalOpen,
      isInnerModalOpen: false,
      disableBackdropClick: props.disableBackdropClick ? props.disableBackdropClick : false,
    };

    // bind functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isModalOpen: nextProps.isModalOpen,
    });
  }

  // close modal (set isModalOpen, true)
  closeModal() {
    let { onCloseModal } = this.props;
    this.setState({
      isModalOpen: false,
    });

    if (onCloseModal) {
      onCloseModal();
    }
  }

  // open modal (set isModalOpen, false)
  openModal() {
    let { onButtonClick } = this.props;
    this.setState({
      isModalOpen: true,
    });
    if (onButtonClick) {
      onButtonClick();
    }
  }

  // render app
  render() {
    return createPortal(
      <React.Fragment>
        <Button className={this.props.className} buttonText={this.props.buttonText} onClick={this.openModal} />

        <div className={classNames('modal__wrapper', { open: this.state.isModalOpen })}>
          <div
            className="modal__overlay"
            onClick={() => {
              if (!this.state.disableBackdropClick) this.closeModal();
            }}
          />

          <div
            className={classNames('modal__inner', {
              modal__small: this.props.modalSize === 'small',
            })}
          >
            <div className="modal__header">
              <h3>{this.props.title}</h3>
              <Button className="btnClose" buttonText={'X'} onClick={this.closeModal} />
            </div>
            <div className="modal__content">{this.props.children}</div>
          </div>
        </div>
      </React.Fragment>,
      document.getElementById('modal-root') || document.createElement('div')
    );
  }
}
export default Modal;
