import PropTypes from 'prop-types';
import React from 'react';
import ScatterChart from './ScatterChart';

const InsightsChart = ({ logs }) => {
  logs.sort(function(a, b) {
    if (a.jobId > b.jobId) {
      return 1;
    }
    return -1;
  });

  return (
    <div className="insights__scatter-chart">
      <ScatterChart
        exportable
        data={[
          {
            color: '#3200cc',
            data: logs.map((log) => [new Date(log.createdOn).getTime(), log.cxScore]),
            name: 'CX Score',
          },
          {
            color: '#834BC4',
            data: logs.map((log) => [new Date(log.createdOn).getTime(), log.axScore]),
            name: 'AX Score',
          },
          {
            color: '#C66CD6',
            data: logs.map((log) => [new Date(log.createdOn).getTime(), log.networkScore]),
            name: 'Network Score',
          },
        ]}
      />
    </div>
  );
};

export default InsightsChart;

InsightsChart.propTypes = {
  logs: PropTypes.array,
};
