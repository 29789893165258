import {
  JOB_INITIALISED,
  OUBOUND_CALL_INVOKED,
  INBOUND_CALL_INVOKED,
  OUBOUND_CALL_FAILED,
  INBOUND_CALL_FAILED,
  PERFORMANCE_RESULTS_RECEIVED,
  PERFORMANCE_HISTORY_RECEIVED,
  ERROR_PERFORMANCE_RESULTS_RETRIEVAL,
  ERROR_PERFORMANCE_HISTORY_RETRIEVAL,
  ERROR_POLQA_CALCULATION,
  RESET_POLQA_CALCULATION,
  ERROR_OBTAINING_POOL_NUMBER,
} from '../actions/performance';

const performance = (
  state = {
    loading: false,
    jobData: {},
    results: [],
    history: [],
    errorStatus: '',
    errorMessage: '',
    errorTimestamp: '',
  },
  action
) => {
  switch (action.type) {
    case JOB_INITIALISED:
      return Object.assign({}, state, { jobData: action.jobData, loading: false, status: JOB_INITIALISED });
    case OUBOUND_CALL_INVOKED:
      return Object.assign({}, state, { loading: false, status: OUBOUND_CALL_INVOKED });
    case INBOUND_CALL_INVOKED:
      return Object.assign({}, state, { loading: false, status: INBOUND_CALL_INVOKED });
    case OUBOUND_CALL_FAILED:
      return Object.assign({}, state, { loading: false, status: OUBOUND_CALL_FAILED });
    case INBOUND_CALL_FAILED:
      return Object.assign({}, state, { loading: false, status: INBOUND_CALL_FAILED });
    case PERFORMANCE_RESULTS_RECEIVED:
      return Object.assign({}, state, {
        results: action.results,
        loading: false,
        status: PERFORMANCE_RESULTS_RECEIVED,
      });
    case ERROR_PERFORMANCE_RESULTS_RETRIEVAL:
      return Object.assign({}, state, { loading: false, status: ERROR_PERFORMANCE_RESULTS_RETRIEVAL });
    case ERROR_OBTAINING_POOL_NUMBER:
      return Object.assign({}, state, { loading: false, status: ERROR_OBTAINING_POOL_NUMBER });
    case PERFORMANCE_HISTORY_RECEIVED:
      return Object.assign({}, state, {
        history: action.results,
        loading: false,
        status: PERFORMANCE_HISTORY_RECEIVED,
      });
    case ERROR_PERFORMANCE_HISTORY_RETRIEVAL:
      return Object.assign({}, state, { loading: false, status: ERROR_PERFORMANCE_HISTORY_RETRIEVAL });
    case ERROR_POLQA_CALCULATION:
      return Object.assign({}, state, {
        loading: false,
        errorStatus: ERROR_POLQA_CALCULATION,
        errorMessage: action.message,
        errorTimestamp: action.timestamp,
      });
    case RESET_POLQA_CALCULATION:
      return Object.assign({}, state, {
        loading: false,
        errorStatus: RESET_POLQA_CALCULATION,
        errorMessage: action.message,
        errorTimestamp: action.timestamp,
      });

    default:
      return state;
  }
};

export default performance;
