import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Search from "@material-ui/icons/FileCopyOutlined";
import Close from "@material-ui/icons/FileCopyOutlined";

import "./Group.scss";
import {
  fetchGroups,
  updateCanvas,
  CANVAS_ENABLED,
  ERROR_CANVAS_ENABLE
} from "../../actions/groups";
import {
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
  showSnackbarMessage
} from "../../actions/snackbar";

import {
  ButtonRefactored,
  Stack,
  Table,
  InputSearch,
  InputSelect,
  TableFilter,
  BrickCopy,
  Skeleton
} from "@operata/adagio";

import { showConfirmationDialog } from "../../actions/dialog";
import TablePagination from "./TablePagination";
import { createCSVDownload } from "../Datatables/utils";
import { sortData } from "../../utils/datatables";

const Paginate = ({ pageSize, page }) => array =>
  array.slice(pageSize * page, pageSize * (page + 1));

const downloadCSV = groups => {
  const columns = [
    {
      name: "Customer",
      download: true
    },
    {
      name: "Group",
      download: true
    },
    {
      name: "Region",
      download: true
    },
    {
      name: "Group Status",
      download: true
    }
  ];

  const data = groups.map(data => {
    return {
      data: [data.customerName, data.name, data.region, data.status]
    };
  });

  const options = {
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ","
    }
  };

  createCSVDownload(columns, data, options);
};

let GroupList = ({
  fetchGroups,
  showConfirmationDialog,
  updateCanvas,
  showSnackbarMessage,
  data,
  status,
  message,
  loading,
  history,
  type
}) => {
  data = data.map(data => {
    data.status = data.status || "Archived";

    return data;
  });

  const customers = [...new Set(data.map(data => data.customerName))].filter(
    a => a
  );

  const groupNames = [...new Set(data.map(data => data.name))].filter(a => a);

  const statuses = [...new Set(data.map(data => data.status))].filter(a => a);

  const [searchTerm, setSearchTerm] = useState("");
  const [customerFilter, setCustomerFilter] = useState(null);
  const [groupFilter, setGroupFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortKey, setSortKey] = useState("customerName");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortClick = key => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  useEffect(
    () => {
      fetchGroups(type);
      setSearchTerm("");
      setCustomerFilter(null);
      setGroupFilter(null);
      setStatusFilter(null);
      setPage(0);
    },
    [fetchGroups, type]
  );

  useEffect(
    () => {
      if (status === CANVAS_ENABLED) {
        showSnackbarMessage(SNACKBAR_SUCCESS, message);
      } else if (status === ERROR_CANVAS_ENABLE) {
        showSnackbarMessage(SNACKBAR_ERROR, message);
      }
    },
    [status, message, showSnackbarMessage]
  );

  let filteredGroups = data;

  filteredGroups = searchTerm
    ? filteredGroups.filter(
        data =>
          data?.customerName
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase()) ||
          data?.customerID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          data?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          data?.id?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          data.status.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : filteredGroups;

  filteredGroups = customerFilter
    ? filteredGroups.filter(data => data.customerName === customerFilter)
    : filteredGroups;
  filteredGroups = groupFilter
    ? filteredGroups.filter(data => data.name === groupFilter)
    : filteredGroups;
  filteredGroups = statusFilter
    ? filteredGroups.filter(data => data.status === statusFilter)
    : filteredGroups;

  const sortedGroups = filteredGroups.sort(sortData(sortKey, sortOrder));

  const paginatedGroups = Paginate({ pageSize, page })(sortedGroups);

  const handleUpdateCanvas = (groupId, groupName) => {
    const handleCancel = () => {};
    showConfirmationDialog(
      "Update Canvas",
      "Are you sure you want to update canvas for group " + groupName + "?",
      updateCanvas,
      [groupId],
      handleCancel,
      []
    );
  };

  return (
    <>
      <TableFilter
        search={
          <InputSearch
            value={searchTerm}
            placeholder="Search Table"
            iconBefore={<Search />}
            iconAfter={<Close />}
            onChange={event => setSearchTerm(event.target.value)}
            onClear={() => setSearchTerm("")}
          />
        }
        filters={
          <>
            <InputSelect
              placeholder="Customer"
              selectedValue={customerFilter}
              setSelectedValue={customerFilter => {
                setCustomerFilter(customerFilter);
                setPage(0);
              }}
            >
              {customers.map((customer, index) => (
                <InputSelect.Option key={index} value={customer} />
              ))}
            </InputSelect>
            <InputSelect
              placeholder="Group Name"
              selectedValue={groupFilter}
              setSelectedValue={groupFilter => {
                setGroupFilter(groupFilter);
                setPage(0);
              }}
            >
              {groupNames.map((group, index) => (
                <InputSelect.Option key={index} value={group} />
              ))}
            </InputSelect>
            <InputSelect
              placeholder="Status"
              selectedValue={statusFilter}
              setSelectedValue={statusFilter => {
                setStatusFilter(statusFilter);
                setPage(0);
              }}
            >
              {statuses.map((status, index) => (
                <InputSelect.Option key={index} value={status} />
              ))}
            </InputSelect>
            <ButtonRefactored
              size="medium"
              type="secondary"
              disabled={
                !searchTerm && !customerFilter && !groupFilter && !statusFilter
              }
              onClick={() => {
                setSearchTerm("");
                setCustomerFilter(null);
                setGroupFilter(null);
                setStatusFilter(null);
                setPage(0);
              }}
            >
              Reset
            </ButtonRefactored>
          </>
        }
        actions={
          <ButtonRefactored
            type="secondary"
            size="medium"
            disabled={!data.length}
            onClick={() => downloadCSV(data)}
            iconAfter={<Table.DownloadIcon />}
          >
            Download CSV
          </ButtonRefactored>
        }
      />
      <Table>
        <thead>
          <Table.Row>
            <Table.Header
              width="auto"
              sortable
              sortIcon={
                sortKey === "customerName" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("customerName")}
            >
              Customer
            </Table.Header>
            <Table.Header
              width="auto"
              sortable
              sortIcon={
                sortKey === "name" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("name")}
            >
              Group Name
            </Table.Header>
            <Table.Header
              align="center"
              width={250}
              sortable
              sortIcon={
                sortKey === "region" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("region")}
            >
              Region
            </Table.Header>
            <Table.Header
              width={250}
              align="center"
              sortable
              sortIcon={
                sortKey === "status" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("status")}
            >
              Status
            </Table.Header>
            <Table.Header width={0} />
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={16} />
                    <Skeleton loading width={265} height={16} />
                  </Stack>
                </Table.Cell>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={16} />
                    <Skeleton loading width={265} height={16} />
                  </Stack>
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="right">
                  <Stack direction="col" gap="4" display="inline">
                    <Skeleton loading width={50} height={32} />
                    <Skeleton loading width={135} height={32} />
                    <Skeleton loading width={125} height={32} />
                  </Stack>
                </Table.Cell>
              </Table.Row>
            ))
          ) : paginatedGroups.length ? (
            paginatedGroups.map((data, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  {data.customerName ? (
                    <BrickCopy
                      title={data.customerName}
                      onClick={() =>
                        navigator.clipboard.writeText(data.customerID)
                      }
                    >
                      {data.customerID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {data.id ? (
                    <BrickCopy
                      title={data.name}
                      onClick={() => navigator.clipboard.writeText(data.id)}
                    >
                      {data.id}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell align="center">{data.region}</Table.Cell>
                <Table.Cell align="center">{data.status}</Table.Cell>
                <Table.Cell align="right">
                  <Stack direction="col" gap="4" display="inline">
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() => history.push("/admin/group/" + data.id)}
                    >
                      Edit
                    </ButtonRefactored>
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() =>
                        (window.location =
                          "/admin/group/" + data.id + "/member/0")
                      }
                    >
                      Invite Teammate
                    </ButtonRefactored>
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() => handleUpdateCanvas(data.id, data.name)}
                    >
                      Update Canvas
                    </ButtonRefactored>
                  </Stack>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={4}>No data found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        count={filteredGroups.length}
      />
    </>
  );
};

GroupList.propTypes = {
  fetchGroups: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  updateCanvas: PropTypes.func,
  showSnackbarMessage: PropTypes.func,

  data: PropTypes.array,
  status: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,

  history: PropTypes.object.isRequired,

  type: PropTypes.string
};

const mapDispatchToProps = {
  fetchGroups,
  showConfirmationDialog,
  updateCanvas,
  showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.groups.data,
  status: state.groups.status,
  message: state.groups.message,
  loading: state.progress.loading
});

// eslint-disable-next-line no-class-assign
GroupList = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupList);

export default withRouter(GroupList);
