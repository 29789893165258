import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const RESET_TEST_DETAILS = "RESET_TEST_DETAILS";
export const ERROR_TESTS = "ERROR_TESTS";
export const TESTS_RECEIVED = "TESTS_RECEIVED";
export const TEST_DISABLED = "TEST_DISABLED";
export const ERROR_TEST_DISABLE = "ERROR_TEST_DISABLE";
export const TEST_CREATED = "TEST_CREATED";
export const ERROR_TEST_CREATED = "ERROR_TEST_CREATED";
export const SOURCE_NUMBERS_RECEIVED = "SOURCE_NUMBERS_RECEIVED";
export const ERROR_SOURCE_NUMBERS_RECEIVED = "ERROR_SOURCE_NUMBERS_RECEIVED";
export const NEW_TEST = "NEW_TEST";
export const RESET_TEST_PAGE_STATE = "RESET_TEST_PAGE_STATE";
export const ERROR_LOGS_RETRIEVAL = "ERROR_LOGS_RETRIEVAL";
export const LOGS_RECEIVED = "LOGS_RECEIVED";
export const LOG_UPDATED = "LOG_UPDATED";
export const JOBS_STATS_RECEIVED = "JOBS_STATS_RECEIVED";
export const ERROR_JOBS_STATS_RETRIEVAL = "ERROR_JOBS_STATS_RETRIEVAL";
export const JOB_STATS_REPORT_RECEIVED = "JOB_STATS_REPORT_RECEIVED";
export const ERROR_JOB_STATS_REPORT_RECEIVED =
  "ERROR_JOB_STATS_REPORT_RECEIVED";
export const JOB_INSIGHTS_RECEIVED = "JOB_INSIGHTS_RECEIVED";
export const ERROR_JOB_INSIGHTS_RECEIVED = "ERROR_JOB_INSIGHTS_RECEIVED";

export const backendCalled = () => ({
  type: SHOW_PROGRESS
});

export const resetState = () => ({
  type: RESET_TEST_DETAILS
});

export const errorTests = message => ({
  type: ERROR_TESTS,
  message: message
});

export const testsReceived = testDetails => ({
  type: TESTS_RECEIVED,
  tests: testDetails
});

export const testDisabled = id => ({
  type: TEST_DISABLED,
  id: id
});

export const errorTestDisable = message => ({
  type: ERROR_TEST_DISABLE,
  message: message
});

export const logsReceived = logs => ({
  type: LOGS_RECEIVED,
  logs: logs
});

export const errorLogsRetrieval = () => ({
  type: ERROR_LOGS_RETRIEVAL
});

export const logUpdated = log => ({
  type: LOG_UPDATED,
  log: log
});

export const jobsStatsReceived = (currentPeriodStats, prevPeriodStats) => ({
  type: JOBS_STATS_RECEIVED,
  currentPeriodStats,
  prevPeriodStats
});

export const errorJobsStatsRetrieval = () => ({
  type: ERROR_JOBS_STATS_RETRIEVAL
});

export const jobStatsReportReceived = job => ({
  type: JOB_STATS_REPORT_RECEIVED,
  job: job
});

export const errorJobStatsReportReceived = message => ({
  type: ERROR_JOB_STATS_REPORT_RECEIVED,
  message: message
});

export const jobInsightsReceived = insight => ({
  type: JOB_INSIGHTS_RECEIVED,
  insight: insight
});

export const errorJobInsightsReceived = message => ({
  type: ERROR_JOB_INSIGHTS_RECEIVED,
  message: message
});

//redux-thunk

export const fetchTests = criteria => {
  return dispatch => {
    dispatch(backendCalled());
    return getTestDetails(criteria)
      .then(resp => {
        dispatch(testsReceived(resp.data));
      })
      .catch(error => {
        dispatch(errorTests(error.message));
      });
  };
};

const getTestDetails = criteria => {
  return axiosProxy
    .getInstance()
    .get("/gsm/heartbeat?criteria=" + JSON.stringify(criteria));
};

export const disableTest = testId => {
  return dispatch => {
    dispatch(backendCalled());
    return disableTestApi(testId)
      .then(() => {
        dispatch(testDisabled(testId));
        dispatch(resetState());
      })
      .catch(() => {
        dispatch(
          errorTestDisable("An error occurred while disabling the test")
        );
        dispatch(resetState());
      });
  };
};

const disableTestApi = testId => {
  return axiosProxy.getInstance().delete("/gsm/heartbeat/" + testId);
};

export const resetStateTest = () => ({
  type: RESET_TEST_PAGE_STATE
});

export const testsTestRequested = () => ({
  type: SHOW_PROGRESS
});

export const openNewTest = () => ({
  type: NEW_TEST
});

export const testCreated = test => ({
  type: TEST_CREATED,
  test: test
});

export const errorTestCreated = message => ({
  type: ERROR_TEST_CREATED,
  message: message
});

export const sourceNumbersRetrieved = numbers => ({
  type: SOURCE_NUMBERS_RECEIVED,
  numbers
});

export const errorSourceNumbersRetrieved = message => ({
  type: ERROR_SOURCE_NUMBERS_RECEIVED,
  message: message
});

export const newTest = () => {
  return dispatch => {
    dispatch(openNewTest());
  };
};

export const createTest = test => {
  return dispatch => {
    dispatch(testsTestRequested());

    return createTestHandler(test)
      .then(resp => {
        dispatch(testCreated(resp.data));
      })
      .catch(error => {
        if (
          error.response &&
          (error.response.status === 502 ||
            error.response.status === 400 ||
            error.response.status === 404 ||
            error.response.status === 500 ||
            error.response.status === 403 ||
            error.response.status === 503)
        ) {
          dispatch(errorTestCreated(error.response.data));
          dispatch(resetStateTest());
        } else {
          dispatch(
            errorTestCreated("An error occurred while creating the test")
          );
          dispatch(resetStateTest());
        }
      });
  };
};

const createTestHandler = test => {
  return axiosProxy.getInstance().post("/gsm/heartbeat", test);
};

export const fetchSourceNumbers = () => {
  return dispatch => {
    return axiosProxy
      .getInstance()
      .get("/gsm/heartbeat/numbers/source")
      .then(resp => {
        dispatch(sourceNumbersRetrieved(resp.data));
      })
      .catch(error => {
        if (error.response) {
          dispatch(errorSourceNumbersRetrieved(error.response.data));
        }
      });
  };
};

export const resetTestsPageState = () => {
  return dispatch => {
    dispatch(resetStateTest());
  };
};

export const fetchLogs = criteria => {
  return dispatch => {
    dispatch(backendCalled());
    return getLogs(criteria)
      .then(resp => {
        dispatch(logsReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorLogsRetrieval("An error occurred while retrieving logs"));
      });
  };
};

const getLogs = criteria => {
  return axiosProxy
    .getInstance()
    .get("/gsm/heartbeat/jobs?criteria=" + JSON.stringify(criteria));
};

export const getJobStats = jobId => {
  return axiosProxy.getInstance().get(`/gsm/heartbeat/jobs/${jobId}/stats`);
};

export const fetchJobsAverages = (
  currentPeriodCriteria,
  prevPeriodCriteria
) => {
  const currentPeriodStats = getJobsStats(currentPeriodCriteria);
  const prevPeriodStats = getJobsStats(prevPeriodCriteria);

  return dispatch => {
    dispatch(backendCalled());

    return Promise.all([currentPeriodStats, prevPeriodStats])
      .then(([currentPeriod, prevPeriod]) => {
        dispatch(jobsStatsReceived(currentPeriod.data, prevPeriod.data));
      })
      .catch(() => {
        dispatch(
          errorJobsStatsRetrieval(
            "An error occurred while retrieving average scores"
          )
        );
      });
  };
};

const getJobsStats = criteria => {
  return axiosProxy
    .getInstance()
    .get("/gsm/heartbeat/jobs/stats?criteria=" + JSON.stringify(criteria));
};

export const fetchJobStats = jobId => {
  return dispatch => {
    dispatch(backendCalled());
    return getJobStats(jobId)
      .then(resp => {
        const jobStats = resp.data;
        dispatch(jobStatsReportReceived(jobStats));
      })
      .catch(() => {
        dispatch(
          errorJobStatsReportReceived(
            "An error occurred while retrieving Job Stats"
          )
        );
        dispatch(resetState());
      });
  };
};

export const fetchJobInsights = jobId => {
  return dispatch => {
    dispatch(backendCalled());
    return getJobInsights(jobId)
      .then(resp => {
        const jobInsights = resp.data;
        dispatch(jobInsightsReceived(jobInsights));
      })
      .catch(() => {
        dispatch(
          errorJobInsightsReceived(
            "An error occurred while retrieving Job Insights"
          )
        );
        dispatch(resetState());
      });
  };
};

const getJobInsights = jobId => {
  return axiosProxy
    .getInstance()
    .get("gsm/heartbeat/jobs/" + jobId + "/insights");
};
