import { io } from "socket.io-client";
import { auth } from "../auth/Auth";

let socket = null;

const establishConnection = () => {
  if (!socket || !socket.connected) {
    socket = io(
      `${process.env.REACT_APP_PUSH_SERVICE_URL}/${auth.getCurrentGroupId()}`
    );
  }
};

const subscribeToPage = (page, cb) => {
  establishConnection();

  socket.on("message", cb);
  socket.emit("subscribeToPage", page);
};

const unsubscribeFromPage = page => {
  if (!socket || !socket.connected) {
    return;
  }

  socket.removeAllListeners("message");
  socket.emit("unsubscribeFromPage", page);
};

export { subscribeToPage, unsubscribeFromPage };
