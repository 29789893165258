import React from "react";
import { connect } from "react-redux";
import "moment-timezone";
import PropTypes from "prop-types";
import { Button, Modal, Time, MUIDataTable } from "@operata/ui-components";
import { formatDate } from "../../../utils/date";
import { showConfirmationDialog } from "../../../actions/dialog";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_INFO
} from "../../../actions/snackbar";
import {
  ERROR_TESTS,
  TEST_DISABLED,
  fetchTests,
  disableTest,
  TEST_CREATED,
  resetTestsPageState,
  newTest,
  NEW_TEST,
  createTest
} from "../../../actions/heartbeat";
import { dispatchChangeFilter } from "../../../actions/location";
import Pagination from "../../Pagination/Pagination";
import CreateTestWizard from "./CreateTest/CreateTestWizard";
import { getTimestampCriteria } from "../../../utils/datatables";
import { throttle } from "lodash";
import { matchPath, withRouter } from "react-router-dom";
import { QS } from "../../UNSAFE_route";

let TestsList = class TestsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [[], [], []],
      filterDisplayTexts: [[], [], []],
      timestampFilterKey: 0,
      modalOpen: false,
      executed: false
    };
    this.fetchTestsThrottled = throttle(this.fetchTestsInternal, 2000);
  }

  componentDidMount() {
    const { filterTests } = this.props;

    this.props.fetchTests(
      getTimestampCriteria(filterTests.timestamp.dateRange)
    );
  }

  componentDidUpdate() {
    if (matchPath(window.location.pathname, this.props.match)) {
      QS.updateFromState("hbtestslist");
    }

    if (
      (this.props.status === TEST_CREATED ||
        this.props.status === TEST_DISABLED) &&
      this.state.executed === false
    ) {
      const { filterTests } = this.props;
      this.props.fetchTests(
        getTimestampCriteria(filterTests.timestamp.dateRange)
      );
      this.setState({ executed: true });
      if (this.props.status === TEST_DISABLED) {
        this.props.resetTestsPageState();
      }
    }
  }

  static getDerivedStateFromProps(props) {
    let newData = props.testData;

    let { showSnackbarMessage } = props;

    if (props.status === ERROR_TESTS) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving Tests List."
      );
    }
    if (props.status === TEST_DISABLED) {
      newData.map(item => {
        if (item.heartbeatId && item.heartbeatId === props.disabledId) {
          item.active = false;
        }
      });
      showSnackbarMessage(SNACKBAR_INFO, "Test is successfully disabled");
      return { tests: newData };
    }
    if (props.status === TEST_CREATED) {
      return { modalOpen: false };
    }
    if (props.status === NEW_TEST) {
      return { modalOpen: true };
    }

    return { executed: false };
  }

  fetchTestsInternal(criteria) {
    this.props.fetchTests(criteria);
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
    this.props.resetTestsPageState();
  };

  displayNextRun = item => {
    if (!item.active) {
      return "Disabled";
    }
    if (!item.schedule) {
      return "Not scheduled";
    }
    return item.schedule;
  };

  handleDisableTest(event, testId) {
    this.props.showConfirmationDialog(
      "Disable Test",
      "Are you sure you want to disable this Test?",
      this.props.disableTest,
      [testId],
      this.handleCancelDisableTest,
      []
    );
    event.preventDefault();
  }

  handleRestartTest(event, item) {
    let { createTest } = this.props;

    const newDescription = "[Retest] ";

    if (!item.description.includes(newDescription)) {
      item.description = newDescription + item.description;
    }

    let test = {
      agentIds: item.agentIds,
      targetPhoneNumber: item.targetPhoneNumber,
      sourcePhoneNumber: item.sourcePhoneNumber,
      description: item.description,
      agentType: item.agentType
    };
    createTest(test);
  }

  handleCancelDisableTest() {
    //do nothing
  }

  handleNewTest = () => {
    this.props.newTest();
  };

  render() {
    const data = this.props.testData;
    const { filterTests } = this.props;

    if (!filterTests["timestamp"]) {
      filterTests["timestamp"] = {
        dateRange: "time:(from:now-7d,to:now)",
        selectedRange: "Last 7 days",
        selectedRangeDisplay: "Last 7 days"
      };
      filterTests["filterList"] = {
        heartbeatID: null,
        agentType: null,
        agentID: null
      };
      filterTests["colIndex"] = 5;
      filterTests["order"] = "desc";
      filterTests["showColumns"] = {
        heartbeatID: true,
        agentType: true,
        agentID: true,
        createdOn: true,
        lastRun: true,
        nextSchedule: true,
        description: true,
        actions: true
      };
    }

    let sortDirectionForCols = {};
    sortDirectionForCols[filterTests.colIndex] = filterTests.order;

    const columns = [
      {
        name: "timestamp",
        label: "Timestamp",
        options: {
          showColumn: false,
          filter: true,
          filterType: "custom",
          customFilterListRender: value => {
            if (value.length > 0 && filterTests.timestamp) {
              return this.state.filterDisplayTexts[0];
            } else {
              return "";
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let filterDisplay = [];

              return (
                <Time
                  key={this.state.timestampFilterKey}
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterDisplay[index] = selectedRangeDisplay;
                    filterList[index] = dateRange;
                    this.setState({ filterDisplayTexts: filterDisplay });
                    filterList[index] = selectedRange;
                    filterTests["timestamp"] = {
                      dateRange: dateRange,
                      selectedRange: selectedRange,
                      selectedRangeDisplay: selectedRangeDisplay
                    };
                    onChange(dateRange, index, column);
                  }}
                  selectedRange={filterTests.timestamp.selectedRange}
                  selectedRangeDisplay={
                    filterTests.timestamp.selectedRangeDisplay
                  }
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
            logic: () => {
              return false;
            }
          },

          filterList: []
        }
      },
      {
        name: "Heartbeat ID",
        label: "Heartbeat ID",
        options: {
          filter: true,
          customFilterListRender: value => `Heartbeat ID: ${value}`,
          sort: true,
          customBodyRender: value => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[1]
            ? sortDirectionForCols[1]
            : null,
          filterList:
            filterTests.filterList && filterTests.filterList["heartbeatID"],
          display: filterTests.showColumns["heartbeatID"]
        }
      },
      {
        name: "Agent Type",
        label: "Agent Type",
        options: {
          filter: true,
          customFilterListRender: value => `Agent Type: ${value}`,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          },
          sortDirection: sortDirectionForCols[2]
            ? sortDirectionForCols[2]
            : null,
          filterList:
            filterTests.filterList && filterTests.filterList["agentType"],
          display: filterTests.showColumns["agentType"]
        }
      },
      {
        name: "Agent ID",
        label: "Agent ID",
        options: {
          filter: true,
          customFilterListRender: value => `Agent ID: ${value}`,
          sort: true,
          customBodyRender: value => {
            return (
              <div className="table__long-field">
                <div className="textfield">{value}</div>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[3]
            ? sortDirectionForCols[3]
            : null,
          filterList:
            filterTests.filterList && filterTests.filterList["agentID"],
          display: filterTests.showColumns["agentID"]
        }
      },
      {
        name: "Created On",
        label: "Created On",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{formatDate(value)}</div>;
          },
          sortDirection: sortDirectionForCols[4]
            ? sortDirectionForCols[4]
            : null,
          display: filterTests.showColumns["createdOn"]
        }
      },
      {
        name: "Last Run",
        label: "Last Run",
        options: {
          sortDirection: sortDirectionForCols[5]
            ? sortDirectionForCols[5]
            : null,
          filter: false,
          sort: true,
          customBodyRender: value => {
            //handling undefined values in last_run column so that they are not auto-formatted by formatDate() since formatDate() converts undefined dates to current dates
            value = value ? value : "";
            return <div className="textfield">{formatDate(value)}</div>;
          },
          display: filterTests.showColumns["lastRun"]
        }
      },
      {
        name: "Next Schedule",
        label: "Next Schedule",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          },
          sortDirection: sortDirectionForCols[6]
            ? sortDirectionForCols[6]
            : null,
          display: filterTests.showColumns["nextSchedule"]
        }
      },
      {
        name: "Description",
        label: "Description",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          },
          sortDirection: sortDirectionForCols[7]
            ? sortDirectionForCols[7]
            : null,
          display: filterTests.showColumns["description"]
        }
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: item => {
            return (
              <React.Fragment>
                {item.active && (
                  <span
                    id="actionSpan_Disable"
                    className="textfield-bold actions__link"
                    onClick={event => {
                      this.handleDisableTest(event, item.heartbeatId);
                    }}
                  >
                    Disable
                  </span>
                )}
                {!item.active && (
                  <span
                    id="actionSpan_Retest"
                    className="textfield-bold actions__link"
                    onClick={event => {
                      this.handleRestartTest(event, item);
                    }}
                  >
                    Retest
                  </span>
                )}
              </React.Fragment>
            );
          },
          display: filterTests.showColumns["actions"]
        }
      }
    ];

    const options = {
      selectableRows: "none",
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      onFilterChange: (column, filterList) => {
        if (column === "timestamp") {
          if (filterList[0].length <= 0) {
            filterTests["timestamp"] = {
              dateRange: "time:(from:now-7d,to:now)",
              selectedRange: "Last 7 days",
              selectedRangeDisplay: "Last 7 days"
            };
          }
          this.props.fetchTests(
            getTimestampCriteria(filterTests.timestamp.dateRange)
          );
        }

        this.props.dispatchChangeFilter(
          {
            ...filterTests,
            filterList: {
              heartbeatID: filterList[1],
              agentType: filterList[2],
              agentID: filterList[3]
            }
          },
          "hbtestslist"
        );
      },
      //including customSort as it would enable sorting of arrays such as Agent Ids
      customSort: (data, colIndex, order) => {
        if (
          colIndex !== filterTests.colIndex ||
          (colIndex === filterTests.colIndex && order !== filterTests.order)
        ) {
          this.props.dispatchChangeFilter(
            {
              ...filterTests,
              colIndex: colIndex,
              order: order
            },
            "hbtestslist"
          );
        }

        return data.sort((a, b) => {
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === "asc" ? 1 : -1)
          );
        });
      },
      onTableChange: (action, tableState) => {
        if (action === "columnViewChange") {
          this.props.dispatchChangeFilter(
            {
              ...filterTests,
              showColumns: {
                heartbeatID: tableState.columns[1].display,
                agentType: tableState.columns[2].display,
                agentID: tableState.columns[3].display,
                createdOn: tableState.columns[4].display,
                lastRun: tableState.columns[5].display,
                nextSchedule: tableState.columns[6].display,
                description: tableState.columns[7].display,
                actions: tableState.columns[8].display
              }
            },
            "hbtestslist"
          );
        }
      }
    };

    return (
      <>
        <Button
          className={"button button--right"}
          buttonText={"New Test"}
          onClick={this.handleNewTest}
          hidePlus={false}
        />
        <Modal
          className="hidden"
          title={"Create Test"}
          isModalOpen={this.state.modalOpen}
          onCloseModal={this.onCloseModal}
          modalSize={"300px"}
        >
          <CreateTestWizard />
        </Modal>

        <MUIDataTable
          data={data.map(item => {
            let agentType =
              item.agentType === "HUMAN_AGENT" ? "Human" : "Virtual";
            return [
              item.lastRun,
              item.heartbeatId,
              agentType,
              item.agentIds,
              item.createdOn,
              item.lastRun,
              this.displayNextRun(item),
              item.description,
              item
            ];
          })}
          columns={columns}
          options={options}
        />
      </>
    );
  }
};

TestsList.propTypes = {
  testData: PropTypes.array,
  status: PropTypes.string,
  showSnackbarMessage: PropTypes.func,
  actionPath: PropTypes.string,
  fetchTests: PropTypes.func,
  disableTest: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  disabledId: PropTypes.number,
  resetTestsPageState: PropTypes.func,
  newTest: PropTypes.func,
  createTest: PropTypes.func,
  dispatchChangeFilter: PropTypes.func,
  filterTests: PropTypes.object,
  match: PropTypes.object
};

const mapDispatchToProps = {
  fetchTests: fetchTests,
  showSnackbarMessage: showSnackbarMessage,
  disableTest: disableTest,
  showConfirmationDialog: showConfirmationDialog,
  resetTestsPageState: resetTestsPageState,
  newTest: newTest,
  createTest: createTest,
  dispatchChangeFilter: dispatchChangeFilter
};

const mapStateToProps = state => {
  function getFilterTests() {
    return state.location["hbtestslist"] && state.location["hbtestslist"].filter
      ? state.location["hbtestslist"].filter
      : {};
  }

  return {
    testData: state.heartbeat.tests || [],
    status: state.heartbeat.status,
    disabledId: state.heartbeat.disabledId,
    reduxPath: state.heartbeat.reduxPath,
    filterTests: getFilterTests()
  };
};

TestsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestsList);

export default withRouter(TestsList);
