import React from "react";
import "../Badge/Badge.scss";
import CallStatsLog from "./CallStatsLog";
import { Stack, PageHeading, Tabs, Section } from "@operata/adagio";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import AgentLogs from "./AgentLogs";

class ExplorerCalls extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { path } = this.props.match;

    return (
      <div className="group_page__padding">
        <PageHeading title="Calls and Logs" />
        <Section>
          <Stack direction="col" gap="24">
            <Tabs padding="8">
              <Tabs.Tab exact to={`${path}/calls`} label="Call Stats Log" />
              <Tabs.Tab exact to={`${path}/logs`} label="Softphone Logs" />
            </Tabs>
          </Stack>
          <Section.Content>
            <div style={{ minHeight: "50em" }}>
              <Switch>
                <Route path={`${path}/calls`}>
                  <CallStatsLog type="calls" path={`${path}/calls`} />
                </Route>
                <Route path={`${path}/logs`}>
                  <AgentLogs type="logs" path={`${path}/logs`} />
                </Route>
              </Switch>
            </div>
          </Section.Content>
        </Section>
      </div>
    );
  }
}

ExplorerCalls.propTypes = {
  match: PropTypes.object.isRequired
};

export default ExplorerCalls;
