import PropTypes from "prop-types";
import React from "react";
import Chip from "../../Chips/Chip";
import moment from "moment";
import InsightsTable from "./InsightsTable";
import { NavLink } from "react-router-dom";
import {
  COMPLETED_STATUS,
  CUSTOMER_EXPERIENCE_TAGS,
  getTagsWithoutCategory
} from "../../../constants/gsm";
import { createStatusLevelText, getStatusLevelFromTags } from "./utils";
import CustomerExperienceSneakPeek from "./SneakPeek/CustomerExperienceSneakPeek";

const CustomerExperience = ({ logs, filterInsights, props }) => {
  let sortDirectionForCols = {};
  sortDirectionForCols[filterInsights.sortDirection.cX.colIndex] =
    filterInsights.sortDirection.cX.order;

  const columns = [
    {
      name: "jobId",
      label: "Job ID",
      options: {
        display: filterInsights.showColumns.cX[0].display,
        sortDirection: sortDirectionForCols[0] ? sortDirectionForCols[0] : null
      }
    },
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: job => {
          return (
            <NavLink
              className="textfield-bold actions__link"
              to={`/gsm/heartbeat/jobs/${job.jobId}/report`}
            >
              {moment(job.createdOn).format("DD-MM-YYYY HH:mm:ss")}
            </NavLink>
          );
        },
        display: filterInsights.showColumns.cX[1].display,
        sortDirection: sortDirectionForCols[1] ? sortDirectionForCols[1] : null
      }
    },
    {
      name: "agentId",
      label: "Target Agent",
      options: {
        display: filterInsights.showColumns.cX[2].display,
        sortDirection: sortDirectionForCols[2] ? sortDirectionForCols[2] : null
      }
    },
    {
      name: "agentType",
      label: "Test Type",
      options: {
        display: filterInsights.showColumns.cX[3].display,
        sortDirection: sortDirectionForCols[3] ? sortDirectionForCols[3] : null
      }
    },
    {
      name: "audioLatency",
      label: "Audio Latency",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: ([tags, status]) => {
          const statusLevel = getStatusLevelFromTags(
            status,
            tags,
            CUSTOMER_EXPERIENCE_TAGS["High Audio Latency"],
            "Normal"
          );

          return createStatusLevelText(statusLevel);
        },
        display: filterInsights.showColumns.cX[4].display,
        sortDirection: sortDirectionForCols[4] ? sortDirectionForCols[4] : null
      }
    },
    {
      name: "audioQuality",
      label: "Audio Quality (IN/OUT)",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: ([tags, status]) => {
          const inboundQualityStatusLevel = getStatusLevelFromTags(
            status,
            tags,
            CUSTOMER_EXPERIENCE_TAGS["Poor Audio Quality"].filter(tag =>
              tag.includes("Inbound")
            )
          );
          const outboundQualityStatusLevel = getStatusLevelFromTags(
            status,
            tags,
            CUSTOMER_EXPERIENCE_TAGS["Poor Audio Quality"].filter(tag =>
              tag.includes("Outbound")
            )
          );

          return (
            <span>
              {createStatusLevelText(inboundQualityStatusLevel)}
              {"/"}
              {createStatusLevelText(outboundQualityStatusLevel)}
            </span>
          );
        },
        display: filterInsights.showColumns.cX[5].display,
        sortDirection: sortDirectionForCols[5] ? sortDirectionForCols[5] : null
      }
    },
    {
      name: "cxScore",
      label: "CX Score",
      options: {
        display: filterInsights.showColumns.cX[6].display,
        sortDirection: sortDirectionForCols[6] ? sortDirectionForCols[6] : null
      }
    },
    {
      name: "tags",
      label: "Insights",
      options: {
        customBodyRender: tags => {
          let chips = [];
          if (tags) {
            tags.forEach(tag => {
              chips.push(<Chip label={tag} key={tag} />);
            });
          }
          return chips;
        },
        display: filterInsights.showColumns.cX[7].display,
        sortDirection: sortDirectionForCols[7] ? sortDirectionForCols[7] : null
      }
    }
  ];

  return (
    <InsightsTable
      logs={logs}
      tags={CUSTOMER_EXPERIENCE_TAGS}
      columns={columns}
      dataToColumn={result => {
        return [
          result.jobId,
          result,
          result.agentId,
          result.agentType === "HUMAN_AGENT" ? "Human" : "Virtual",
          [result.tags, result.status],
          [result.tags, result.status],
          result.status !== COMPLETED_STATUS ? "-" : result.cxScore,
          result.tags
            ? result.tags.filter(tag =>
                getTagsWithoutCategory(CUSTOMER_EXPERIENCE_TAGS).includes(tag)
              )
            : []
        ];
      }}
      InsightsSneakPeek={CustomerExperienceSneakPeek}
      filterInsights={filterInsights}
      props={props}
    />
  );
};

CustomerExperience.propTypes = {
  logs: PropTypes.array,
  filterInsights: PropTypes.object,
  props: PropTypes.object
};

export default CustomerExperience;
