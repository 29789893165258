import React from "react";
import PropTypes from "prop-types";
export default function CallHeader({ call, hasResolved, actions }) {
  let contactId =
    call?.summary?.contact?.id?.current || call?.logs?.[0].contactId;
  return (
    <div className="call-header">
      <div className="call-header__title">
        <div className="call-header__label">Call Contact ID</div>
        <div className="call-header__id">
          {hasResolved ? contactId || "N/A" : ""}
        </div>
      </div>
      {actions && <div className="call-header__actions">{actions}</div>}
    </div>
  );
}

CallHeader.propTypes = {
  call: PropTypes.object,
  hasResolved: PropTypes.bool,
  actions: PropTypes.node
};
