import React from "react";
import MUIDataTable from "../Datatables";
import Pagination from "../Pagination/Pagination";
import { connect } from "react-redux";
import "./Actions.scss";
import "../ActionBar/ActionBar.scss";
import {
  fetchActionHistory,
  ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL
} from "../../actions/workflow";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../actions/snackbar";
import moment from "moment";
import Time from "../DateTime/TimeContainer";
import PropTypes from "prop-types";
import { getTimestampCriteria } from "../../utils/datatables";

let WorkflowActionsHistory = class WorkflowActionsHistory extends React.Component {
  state = {
    filters: [[], [], []],
    sortDirection: new Array(13),
    filterDisplayTexts: [[], [], []],
    timestampFilterKey: 0
  };

  componentDidMount() {
    this.props.fetchActionHistory(
      getTimestampCriteria("time:(from:now-15m,to:now)")
    );
  }

  static getDerivedStateFromProps(nextProps) {
    let { showSnackbarMessage } = nextProps;

    if (nextProps.status === ERROR_WORKFLOW_ACTIONS_HISTORY_RETRIEVAL) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving actions history."
      );
    }
  }

  render() {
    const columns = [
      {
        name: "timestamp",
        label: "Timestamp",
        options: {
          filter: true,
          filterType: "custom",
          customFilterListRender: value => {
            if (value.length > 0) {
              return this.state.filterDisplayTexts[0];
            } else {
              return "";
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let selectedRange = "Last 15 minutes";
              let selectedRangeDisplay = "Last 15 minutes";

              return (
                <Time
                  key={this.state.timestampFilterKey}
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterList[index] = dateRange;
                    //eslint-disable-next-line
                    this.state.filterDisplayTexts[index] = selectedRangeDisplay;
                    onChange(filterList[index], index, column);
                  }}
                  selectedRange={selectedRange}
                  selectedRangeDisplay={selectedRangeDisplay}
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
            logic: () => {
              return false;
            }
          },
          customBodyRender: timestamp => {
            let val = moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
            return val;
          },
          filterList: this.state.filters[0],
          sortDirection: this.state.sortDirection[0]
        }
      },
      {
        name: "Workflow",
        label: "Workflow",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Application",
        label: "Application",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Trigger",
        label: "Trigger",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Action",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Contact Id",
        label: "Contact Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      },
      {
        name: "Response",
        label: "Response",
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            return <span className="textfield">{value}</span>;
          }
        }
      }
    ];

    const options = {
      selectableRows: "none",
      filter: true,
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      searchText: " ",
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      onFilterChange: (column, filterList) => {
        if (column === "timestamp") {
          this.props.fetchActionHistory(getTimestampCriteria(filterList[0]));
        }
      }
    };

    return (
      <MUIDataTable
        data={this.props.data.map(item => {
          let history = item._source;
          return [
            history.timestamp,
            history.workflowName,
            history.application,
            history.trigger,
            history.action,
            history.contactId,
            history.agentName,
            history.response
          ];
        })}
        columns={columns}
        options={options}
      />
    );
  }
};

WorkflowActionsHistory.propTypes = {
  fetchActionHistory: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  status: PropTypes.string
};

const mapDispatchToProps = {
  fetchActionHistory: fetchActionHistory,
  showSnackbarMessage: showSnackbarMessage
};

const mapStateToProps = state => ({
  data: state.workflow.history,
  loading: state.workflow.loading,
  status: state.workflow.status
});

WorkflowActionsHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowActionsHistory);

export default WorkflowActionsHistory;
