import React from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import * as PropTypes from "prop-types";

function AudioLatency(props) {
  let latencies = {};

  props.audioLatency.map(item => {
    latencies[item.recordingType] = item;
  });

  return (
    <div className="audio__quality__visualiser__wrapper">
      <div className="audio__quality__visualiser__inner__wrapper">
        <div className="audio__quality__audioLatency__wrapper">
          <div className="audio__quality__audioLatency__wrapper__inner">
            <div className="audioLatency__inner__column">
              <div className="title__circle">Customer</div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="arrows">
                <ArrowRightAltIcon />
                <ArrowRightAltIcon className="arrows__reversed" />
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="audioLatency__inner__column__latencyCircle">
                {latencies["echo"] && latencies["connect"]
                  ? latencies["echo"].averageLatencyMs -
                    latencies["connect"].averageLatencyMs +
                    "ms"
                  : "Unavailable"}
              </div>
              <div className="audioLatency__inner__column__regional">
                Regional Average: Unavailable
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="arrows">
                <ArrowRightAltIcon />
                <ArrowRightAltIcon className="arrows__reversed" />
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="title__circle">CCaaS</div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="arrows">
                <ArrowRightAltIcon />
                <ArrowRightAltIcon className="arrows__reversed" />
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="audioLatency__inner__column__latencyCircle">
                {latencies["connect"]
                  ? latencies["connect"].averageLatencyMs + "ms"
                  : "Unavailable"}
              </div>
              <div className="audioLatency__inner__column__regional">
                Regional Average: Unavailable
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="arrows">
                <ArrowRightAltIcon />
                <ArrowRightAltIcon className="arrows__reversed" />
              </div>
            </div>
            <div className="audioLatency__inner__column">
              <div className="title__circle">Agent</div>
            </div>
          </div>
          <div className="audio__quality__audioLatency__footer">
            <div className="echo__latency__wrapper">
              <div className="echo__latency__title">Echo Latency</div>
              <div className="echo__latency__value">
                {latencies["echo"]
                  ? latencies["echo"].averageLatencyMs + "ms"
                  : "Unavailable"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AudioLatency.propTypes = {
  audioLatency: PropTypes.object
};

export default AudioLatency;
