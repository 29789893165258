export const DESCRIPTION_STEP = "Description";
export const AGENT_TYPE_STEP = "Agent Type";
export const TARGET_AGENTS_STEP = "Target Agent(s)";
export const SCHEDULE_STEP = "Schedule";
export const HUMAN_AGENT = "HUMAN_AGENT";
export const VIRTUAL_AGENT = "VIRTUAL_AGENT";

export const outboundReferenceAudioFile =
  "https://s3-ap-southeast-2.amazonaws.com/op.reference.samples/12s_IRS_48k.wav";
export const inboundReferenceAudioFile =
  "https://s3-ap-southeast-2.amazonaws.com/op.reference.samples/EN_MF_flat_08k_HRF_GSM.wav";

export const POLQA_MAX_SCORES = {
  POLQA: 4.5,
  "POLQA SWB": 4.8
};
