import PropTypes from "prop-types";
import React from "react";
import ChipInput from "material-ui-chip-input";


const RedactionConfig = ({ label, value }) => {
  let type = value.type || "";
  let configs = value.fields;


  return(
  <>
    <div className={"card__title h5"}>CTR Redaction Type</div>
    <span id="redactionType">{type}</span>
    <br/>
    {!configs ? 'Redaction config not found' : 
      <ChipInput
        className="tags__wrapper"
        label={label}
        placeholder="Type and press enter to add"
        value={configs}
    />
    }
  </>);
    
};

RedactionConfig.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object
};

export default RedactionConfig;