import React from "react";
import "./users.scss";
const success = require("../../shared/images/success.gif");

const AccountActivated = () => {
  const imgStyle = {
    maxWidth: "100%",
    height: "auto",
    position: "relative",
    zIndex: 2
  };

  return (
    <div
      style={{
        height: "100vh",
        background: "white",
        fontFamily: "'Karla', sans-serif"
      }}
    >
      <svg className="svg" style={{ position: "absolute" }}>
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0.01,0.572 l0.049,-0.253 L0.177,0.12,0.233,0.083 l0.036,0.059 L0.311,0.262 l0.033,-0.014,0.045,-0.008,0.049,-0.086,0.034,0.02,0.037,-0.021,0.073,0.023 L0.67,0.04 l0.064,-0.031 L0.878,0.196,0.973,0.339 v0.069 l0.037,0.204,-0.02,0.093,-0.042,0.019,-0.051,0,0.005,0.066,0.021,0.045,-0.048,0.115,-0.099,-0.015,-0.111,-0.009,-0.138,0.033,-0.084,0.049 L0.374,0.953 l-0.018,-0.023 s-0.011,0.036,-0.014,0.041 s-0.036,0.003,-0.036,0.003 l-0.04,-0.018,-0.054,0.019 L0.186,0.906 l-0.045,-0.058,-0.054,0.046 L0.03,0.914,0.01,0.762" />
        </clipPath>
      </svg>
      <div
        className="account__activation-container"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          height: "100%",
          overflow: "hidden"
        }}
      >
        <div
          className="account__activation-column"
          style={{
            background:
              "url('https://static.operata.io/operata-assets/operata-login-images/934869e0e4b44cbfb69164ab16b2ab17.webp') no-repeat center center",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "30px",
            textAlign: "left",
            height: "100%"
          }}
        >
          <div
            className="logo"
            style={{
              position: "absolute",
              top: "40px",
              left: "60px"
            }}
          >
            <img
              style={{
                display: "inline-block",
                height: "40.7812px",
                maxWidth: "100%",
                width: "160px"
              }}
              src="https://static.operata.io/operata-assets/operata-login-images/logo.svg"
            />
          </div>
          <div
            className="image-container"
            style={{
              position: "relative"
            }}
          >
            <div
              className="account__activation-image"
              style={{
                height: "100%",
                width: "100%",
                textAlign: "center"
              }}
            >
              <div
                className="shape"
                style={{
                  margin: "auto",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0
                }}
              >
                <img
                  style={imgStyle}
                  src="https://static.operata.io/operata-assets/operata-login-images/ee73956a6c275483b295d77b9abf8090.webp"
                />
              </div>
              <img
                style={imgStyle}
                src="https://static.operata.io/operata-assets/operata-login-images/7ecc7229a3917932876f3ebcdd92afe4.png"
              />
              <div
                className="hero-bg"
                style={{
                  width: "100%",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  backgroundImage:
                    "url(https://static.operata.io/operata-assets/operata-login-images/hero_bg-texture.webp)",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  "-webkit-clip-path": "url(#my-clip-path)",
                  clipPath: "url(#my-clip-path)"
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="account__activation"
          style={{
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "auto",
            padding: "30px"
          }}
        >
          <div
            className="account__activation__inner"
            style={{ margin: "0 auto" }}
          >
            <h1
              style={{
                color: "rgb(55, 43, 97)",
                display: "block",
                fontFamily:
                  "Karla, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
                fontSize: "44.8px",
                fontWeight: 400,
                letterSpacing: "-1.4784px",
                lineHeight: "51.52px"
              }}
            >
              Account Activated
            </h1>
            <img
              style={{ marginTop: "20px", marginBottom: "20px" }}
              className={"animate__zoomIn"}
              src={success}
              alt="success icon"
            />
            <h2
              style={{
                color: "rgb(107, 62, 255)",
                display: "block",
                fontFamily:
                  "Karla, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
                fontSize: "18px",
                fontWeight: 400,
                letterSpacing: "-0.7128px",
                lineHeight: "29.7px",
                outlineColor: "rgb(107, 62, 255)"
              }}
            >
              You will receive an email with instructions to get up and running!
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountActivated;
