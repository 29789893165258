import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TimeChart } from "@operata/ui-components";
import { fetchCallStatDetails } from "../../../actions/callstats";
import "../Gsm.scss";
import NetworkSummary from "./NetworkSummary";
import NetworkBrowserDetails from "./NetworkBrowserDetails";
import SignallingEvents from "./SignallingEvents";

const AgentNetworkSoftphoneTab = props => {
  const contactId = props.job && props.job.callContactId;
  const softphoneSummary = props.softphoneSummary;
  const networkPerformanceMetrics =
    props.softphoneSummary && props.softphoneSummary.networkPerformanceMetrics;
  const softphoneEvents = props.softphoneEvents;

  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(fetchCallStatDetails(contactId, null));
    },
    [contactId]
  );

  const { details } = useSelector(state => state.callstats);

  let rttData = [],
    mosData = [],
    packetLossData = [],
    jitterData = [];

  details.forEach(item => {
    let time = new Date(item.timestamp).getTime();
    rttData.push([time, item.rtt]);
    mosData.push([time, item.mos]);
    packetLossData.push([time, item.packetsLost]);
    jitterData.push([time, item.jitter]);
  });

  let perSecondData = {
    title: "",
    data: [
      {
        name: "RTT",
        color: "#e72989",
        data: rttData
      },
      {
        name: "MOS",
        color: "#3200cc",
        data: mosData
      },
      {
        name: "Packet Loss (%)",
        color: "#FF9655",
        data: packetLossData
      },
      {
        name: "Jitter (ms)",
        color: "#50B432",
        data: jitterData
      }
    ],
    exportable: true,
    chart: {
      borderColor: "#ccc",
      borderWidth: 2,
      borderRadius: 5
    }
  };

  return (
    <div>
      <div className="rowDiv-callReport-agentNetwork">
        <div className="rowDiv-agentNetwork-left">
          <div className="label-display">Per Second Network Stats</div>
          {details.length > 0 ? (
            <TimeChart {...perSecondData} />
          ) : (
            <div className="rowDiv-perSecond-highcharts-display">
              No data received
            </div>
          )}
        </div>
        <div className="rowDiv-agentNetwork-right">
          <div className="label-display">Network Summary</div>
          <div>
            <NetworkSummary metrics={networkPerformanceMetrics} />
          </div>
        </div>
      </div>
      <div className="rowDiv-callReport-agentNetwork">
        <div className="rowDiv-agentNetwork-left">
          <div className="label-display">Signaling and events</div>
          {softphoneEvents ? (
            <SignallingEvents softphoneEvents={softphoneEvents} />
          ) : (
            <div>No data received</div>
          )}
        </div>
      </div>
      <div className="rowDiv-callReport-agentNetwork">
        <div className="rowDiv-callReport-agentNetwork">
          <div className="label-display">Network & Browser Details</div>
          <div>
            {softphoneSummary && (
              <NetworkBrowserDetails
                isp={softphoneSummary.agentISP}
                lanIP={softphoneSummary.agentLocalIPAddress}
                wanIP={softphoneSummary.agentExternalIPAddress}
                browser={softphoneSummary.browser}
                country={softphoneSummary.agentCountry}
                region={softphoneSummary.agentRegion}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AgentNetworkSoftphoneTab.propTypes = {
  job: PropTypes.object,
  softphoneSummary: PropTypes.object,
  softphoneEvents: PropTypes.object
};

export default AgentNetworkSoftphoneTab;
