import { AnyAction, Reducer } from "redux";
import {
    BILLING_LOGS_RECEIVED,
    BILLING_RECEIVED,
    MARKETPLACE_DATA_RECEIVED,
    BILLING_SUBSCRIPTIONS_RECEIVED,
    ERROR_MARKETPLACE_BILLING,
    ERROR_BILLING_SUBSCRIPTIONS
} from "../actions/billing";

export type BillingState = {
    loading: boolean;
    billingData: Array<any>;
    billingAuditLogs: Array<any>;
    marketplaceData: Array<any>;
    status?: string;
    subscriptions: Array<any>;
    message: string;
  }

const billing: Reducer<BillingState, AnyAction> = (
    state: BillingState = {
        loading: false,
        billingData: [],
        billingAuditLogs: [],
        marketplaceData: [],
        subscriptions: [],
        message: "",
        status: ""
    },
    action: AnyAction
  ) => {
    switch (action.type) {
        case BILLING_RECEIVED:
            return {
            ...state,
            loading: false,
            billingData: action.billing
            };
        case BILLING_LOGS_RECEIVED:
            return {
            ...state,
            loading: false,
            billingAuditLogs: action.data
            };
        case MARKETPLACE_DATA_RECEIVED:
            return {
            ...state,
            loading: false,
            marketplaceData: action.marketplaceData
            };
        case BILLING_SUBSCRIPTIONS_RECEIVED:
            return {
            ...state,
            loading: false,
            subscriptions: action.subscriptions
            };
        case ERROR_MARKETPLACE_BILLING:
            return {
                ...state,
                loading: false,
                status: ERROR_MARKETPLACE_BILLING,
                message: action.message
            };
        case ERROR_BILLING_SUBSCRIPTIONS:
            return {
                ...state,
                loading: false,
                status: ERROR_BILLING_SUBSCRIPTIONS,
                message: action.message
            };
        default:
            return state;
    }
};

export default billing;