import React from 'react';
import './Button.scss';
import { connect } from 'react-redux';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.disableButton();
    if (this.props.onClick){
      this.props.onClick();
    }
    this.enableButton();
  }

  enableButton() {
    let intervalRef = setInterval(() => {
      if (!this.props.loading) {
        if (this.refs.defButton) {
          this.refs.defButton.removeAttribute('disabled', 'disabled');
          this.setState({ disabled: false });
        }
        clearInterval(intervalRef);
      }
    }, 100);
  }

  disableButton() {
    if (this.props.asyncButton) {
      this.refs.defButton.setAttribute('disabled', 'disabled');
      this.setState({ disabled: true });
    }
  }

  render() {
    let { className, buttonText, hidePlus } = this.props;
    return (
      <button ref="defButton" className={className} onClick={this.handleClick}>
        {!hidePlus && <span className="button__circle">+</span>}
        {!this.state.disabled && buttonText}
        {this.state.disabled && 'Submitting ... '}
      </button>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  loading: state.progress ? state.progress.loading : false,
});

Button = connect(
  mapStateToProps,
  mapDispatchToProps
)(Button);

export default Button;
