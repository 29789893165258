import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { OutlinedInput, FormControl, InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import { validateRequireField } from "../../Generic/core-validations";

class ActionConfigCreateEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventSource: "",
      type: props.actionType
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    let { prevState } = this.props;
    if (prevState.type === this.state.type) {
      this.setState(prevState);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let eventSourceSelected;

    this.props.eventSources.forEach(source => {
      let eventSourceArr = source.eventSourceName.split("/");
      let eventSourceName = eventSourceArr[eventSourceArr.length - 1];

      if (eventSourceName === value) {
        eventSourceSelected = source;
      }
    });

    this.setState({ [name]: value });

    this.props.handleAddCondition(
      "ebSource",
      eventSourceSelected.eventSourceName
    );
    this.props.handleAddCondition("ebRegion", eventSourceSelected.awsRegion);
  }

  handleAddAction() {
    let requiredFields = ["eventSource"];

    let errors = {};
    validateRequireField(requiredFields, this.state, errors, this);

    if (!errors["hasErrors"]) {
      const action = Object.assign({}, this.state, {
        actionId: "Event_Source",
        displayText: ""
      });

      this.props.handleAddAction(action);
    }

    return errors;
  }

  render() {
    const { eventSources } = this.props;

    return (
      <div className="workflow__input">
        <FormControl variant="outlined">
          <InputLabel htmlFor="eventSources-helper">
            {this.state.errorMessage_eventSource
              ? this.state.errorMessage_eventSource
              : "Event Source Name"}
          </InputLabel>
          <Select
            id="eventbridgeDropdown"
            name="eventSource"
            error={this.state.error_eventSource}
            variant="outlined"
            value={this.state.eventSource}
            onChange={event => this.handleChange(event)}
            input={
              <OutlinedInput name="eventSource" id="eventSources-helper" />
            }
          >
            {eventSources
              ? eventSources.map(source => {
                  let eventSourceArr = source.eventSourceName.split("/");
                  let eventSourceName =
                    eventSourceArr[eventSourceArr.length - 1];
                  return (
                    <MenuItem
                      value={eventSourceName}
                      key={source.eventSourceId}
                    >
                      {eventSourceName}
                    </MenuItem>
                  );
                })
              : "No event sources configured"}
          </Select>
        </FormControl>
      </div>
    );
  }
}

ActionConfigCreateEvent.propTypes = {
  actionType: PropTypes.string,
  handleAddAction: PropTypes.func,
  handleAddCondition: PropTypes.func,
  onRef: PropTypes.func,
  eventSources: PropTypes.array,
  legacy: PropTypes.string,
  prevState: PropTypes.object
};

export default ActionConfigCreateEvent;
