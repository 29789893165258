import { SHOW_PROGRESS } from "../actions/progress";
import { DIALOG_CLOSED } from "../actions/dialog";
import { SNACKBAR_CLOSED } from "../actions/snackbar";
import { PATH_CHANGED } from "../actions/location";
import { BREADCRUMB_CHANGE_PATH } from "../actions/breadcrumb";
import { WINDOW_RESIZED } from "../actions/window";

const progress = (state = { loading: false }, action) => {
  if (action.type === SHOW_PROGRESS) {
    const returnState = { ...state, loading: true };
    return returnState;
  } else if (
    action.type &&
    action.type.indexOf("@@") < 0 &&
    action.type !== DIALOG_CLOSED &&
    action.type !== SNACKBAR_CLOSED &&
    action.type !== PATH_CHANGED &&
    action.type !== BREADCRUMB_CHANGE_PATH &&
    action.type !== WINDOW_RESIZED
  ) {
    return { ...state, loading: false };
  } else {
    return state;
  }
};

export default progress;
