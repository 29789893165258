import React, { useEffect, useState } from "react";
import "./Transcript.css";
import { Button } from "@operata/adagio";
import { ReactComponent as Sparkle } from "./sparkle.svg";
import PropTypes from "prop-types";
import { auth } from "../../auth/Auth";
import CloseIcon from "@material-ui/icons/Close";

function Transcript(props) {
  const regionalUrl = process.env.REACT_APP_API_URL.replace(
    ".operata.io",
    auth.getRegionalUrlPrefix() + ".operata.io"
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const [chat, setChat] = useState([[]]);
  const [chatIndex, setChatIndex] = useState(0);
  const [transcript, setTranscript] = useState("");

  const [completedTyping, setCompletedTyping] = useState(false);
  const [displayResponse, setDisplayResponse] = useState("");

  useEffect(
    () => {
      if (chatIndex < chat.length) {
        const timeout = setTimeout(() => {
          setTranscript(transcript => transcript + " " + chat[chatIndex]);
          setChatIndex(prevIndex => prevIndex + 1);
        }, 60);

        return () => clearTimeout(timeout);
      } else {
        setDisplayResponse("");
        setCompletedTyping(true);
      }
    },
    [chat, chatIndex, transcript]
  );

  useEffect(
    () => {
      setTranscript("");
      setDisplayResponse("");
      setIsLoaded(false);

      fetch(
        `${regionalUrl}/garvis/ask?prompt=${encodeURIComponent(props.prompt)}`,
        {
          method: "GET",
          headers: {
            ...auth.getAuthorizationHeaders(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          setIsLoaded(true);
          setCompletedTyping(false);
          setChatIndex(0);
          setChat(result.response.split(" "));
        })
        .catch(() => {
          setIsLoaded(true);
          setCompletedTyping(false);
          setChatIndex(0);
          setChat("Sorry, there was a problem! Try again.".split(" "));
        });
    },
    [regionalUrl, props.prompt]
  );

  return (
    <div className="qc">
      <div className="qq">
        <div className="fade-in">{props.prompt}</div>
        <div className="qq-action">
          <Button type="tertiary" size="small" onClick={props.onClose}>
            <Button.Icon>
              <CloseIcon />
            </Button.Icon>
          </Button>
        </div>
      </div>
      <div className="qa">
        <div className="qq-icon">
          <Sparkle />
        </div>
        <div className="qq-content">
          {!isLoaded && (
            <span className="loading fade-in">
              <div className="lds-dual-ring" />
            </span>
          )}
          {transcript && <span className="captions_">{transcript}</span>}
          {!completedTyping && (
            <span className="captions_">{displayResponse}</span>
          )}
          {completedTyping && transcript.length > 3 && false && <div />}
        </div>
        <div className="qq-action" />
      </div>
    </div>
  );
}

Transcript.propTypes = {
  prompt: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default Transcript;
