import React from "react";
import MUIDataTable from "../Datatables";
import Pagination from "../Pagination/Pagination";
import Chip from "../Chips/Chip";
import { connect } from "react-redux";
import "./Campaigns.scss";
import "../ActionBar/ActionBar.scss";
import { fetchCampaignRuns, fetchCampaign } from "../../actions/campaign";
import { showSnackbarMessage } from "../../actions/snackbar";
import { updateDynamicBreadcrumb } from "../../actions/breadcrumb";
import { formatDate, sortDate } from "../../utils/date";
import { getRunStatus } from "./dataUtil";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { prefixedPath } from "../../utils/deeplinks";

export const RUN_ON_COLUMN = 3;

class CampaignHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelOpen: false,
      modalTitle: ""
    };
  }

  componentDidMount() {
    const campaignId = this.props.match.params.campaignId;
    this.props.fetchCampaign(campaignId);
    this.props.fetchCampaignRuns(campaignId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newCampaign = nextProps.campaign;

    if (newCampaign) {
      this.props.updateDynamicBreadcrumb({
        campaignName: newCampaign.name
      });
    }
  }

  render() {
    const columns = [
      {
        name: "ID",
        label: "ID",
        options: {
          display: false,
          filter: false,
          sort: true,
          searchable: false,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Description",
        label: "Description",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            // const { name, description, executeOn } = value;

            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Tags",
        label: "Tags",
        options: {
          filter: true,
          customFilterListRender: value => `Tag: ${value}`,
          sort: true,
          customBodyRender: tags => {
            let chips = [];
            if (tags) {
              tags.forEach(tag => {
                chips.push(<Chip label={tag} key={tag} />);
              });
            }
            return chips;
          }
        }
      },
      {
        name: "Run On",
        label: "Run On",
        options: {
          filter: false,
          sort: true,
          sortDirection: "desc",
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Cancelled At",
        label: "Cancelled At",
        options: {
          filter: false,
          sort: true,
          searchable: false,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },
      {
        name: "Status",
        label: "Status",
        options: {
          filter: true,
          customFilterListRender: value => `Status: ${value}`,
          sort: true,
          customBodyRender: value => {
            return [<Chip label={value} key={value} />];
          }
        }
      },
      {
        name: "Outcome",
        label: "Outcome",
        options: {
          filter: false,
          sort: true,
          searchable: false,
          customBodyRender: value => {
            return <div className="textfield">{value}</div>;
          }
        }
      },

      {
        name: "Actions",
        options: {
          filter: false,
          download: false,
          searchable: false,
          customBodyRender: item => {
            return (
              <React.Fragment>
                <NavLink
                  className="textfield-bold actions__link"
                  to={{
                    pathname: prefixedPath(
                      "/campaign/view/" + item.campaignId + "/" + item.id
                    ),
                    campaign: item
                  }}
                >
                  View
                </NavLink>
              </React.Fragment>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: true,
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      searchText: "",
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          const aData =
            a.data[colIndex] === null || typeof a.data[colIndex] === "undefined"
              ? ""
              : a.data[colIndex];
          const bData =
            b.data[colIndex] === null || typeof b.data[colIndex] === "undefined"
              ? ""
              : b.data[colIndex];

          if (colIndex === RUN_ON_COLUMN) {
            return sortDate(aData, bData) * (order === "desc" ? 1 : -1);
          }
          return (
            (typeof aData.localeCompare === "function"
              ? aData.localeCompare(bData)
              : aData - bData) * (order === "asc" ? 1 : -1)
          );
        });
      }
    };

    return (
      <React.Fragment>
        {this.props.data && (
          <MUIDataTable
            data={this.props.data.map(item => {
              return [
                item.id,
                item.description,
                item.tags,
                formatDate(item.createdOn),
                formatDate(item.canceledAt),
                getRunStatus(item.canceledAt, item.isComplete),
                item.outcome,
                item
              ];
            })}
            columns={columns}
            options={options}
          />
        )}
      </React.Fragment>
    );
  }
}

CampaignHistory.propTypes = {
  fetchCampaign: PropTypes.func,
  fetchCampaignRuns: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  updateDynamicBreadcrumb: PropTypes.func,

  match: PropTypes.object,
  data: PropTypes.array,
  campaign: PropTypes.object
};

const mapDispatchToProps = {
  fetchCampaign: fetchCampaign,
  fetchCampaignRuns: fetchCampaignRuns,
  showSnackbarMessage: showSnackbarMessage,
  updateDynamicBreadcrumb: updateDynamicBreadcrumb
};

const mapStateToProps = state => ({
  data: state.campaign.runs,
  loading: state.campaign.loading,
  status: state.campaign.status,
  campaign: state.campaign.campaign,
  random: state.campaign.random
});

// eslint-disable-next-line no-class-assign
CampaignHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignHistory);

export default CampaignHistory;
