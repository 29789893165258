import {
  setHasErrors,
  updateComponentState,
  validateCCP,
  validateRequireField,
  validateURL
} from "../Generic/core-validations";
import _ from "lodash";
import { validateConfig } from "../../actions/config";

function validateNonEmptyURLValue(field, values, errors, component) {
  if (values[field] && values[field].length > 0) {
    validateURL(field, values, errors, component);
  }
}

function validateNonEmptyNumberRange(
  field,
  min,
  max,
  values,
  errors,
  component
) {
  if (values && values[field] && values[field].length > 0) {
    validateNumberRange(field, min, max, values, errors, component);
  }
}

function validateNumberRange(field, min, max, values, errors, component) {
  let val = _.toNumber(values[field]);

  if (!_.isNumber(val) || !(val >= min && val <= max)) {
    errors[field] = "Value must be between " + min + " and " + max;
  }

  updateComponentState(component, errors, field);
  setHasErrors(errors);
}

function validateNonEmptyInteger(field, values, errors, component) {
  if (values && values[field] && values[field].length > 0) {
    validateInteger(field, values, errors, component);
  }
}

function validateInteger(field, values, errors, component) {
  let val = _.toNumber(values[field]);

  if (_.isNaN(val) && !_.isInteger(val)) {
    errors[field] = "This value must be an integer";
  }

  updateComponentState(component, errors, field);
  setHasErrors(errors);
}

async function validateAllowlistedURLs(field, values, errors, component) {
  let val = values[field];

  if (
    val !== undefined &&
    isCorrectlyFormattedSoftphoneURL(val, errors, field)
  ) {
    try {
      await validateConfig({
        key: "SOFTPHONE_WHITELISTED_ORIGINS",
        value: val
      });
    } catch (e) {
      if (e.response.status === 406) {
        errors[field] = e.response.data;
      }
    }
  }

  updateComponentState(component, errors, field);
  setHasErrors(errors);
}

export const isCorrectlyFormattedSoftphoneURL = (domainUrls, errors, field) => {
  domainUrls.forEach(domainUrl => {
    if (errors[field]) return;

    let domainUrlParts = domainUrl.match(/((https|http):\/\/)(.*)/);

    if (domainUrlParts !== null) {
      const protocol = domainUrlParts[1];
      const domain = domainUrlParts[2];
      const rest = domainUrlParts[3];

      if (
        !protocol ||
        (protocol !== "https://" && protocol !== "http://") ||
        !domain ||
        rest === "/"
      ) {
        errors[field] = "Softphone domain " + domainUrl + " is invalid.";
      }
    } else {
      errors[field] = "Softphone domain " + domainUrl + " is invalid.";
    }
  });

  return errors[field] ? false : true;
};

export const validate = async (values, requiredFields, component) => {
  const errors = {};

  validateCCP("AWS_CONNECT_CCP", values, errors, component);

  await validateAllowlistedURLs(
    "SOFTPHONE_WHITELISTED_ORIGINS",
    values,
    errors,
    component
  );

  validateRequireField(requiredFields, values, errors, component);

  validateNonEmptyURLValue(
    "SOFTPHONE_OPERATA_API_SERVER",
    values,
    errors,
    component
  );

  validateNonEmptyURLValue(
    "SOFTPHONE_OPERATA_STATIC_SERVER",
    values,
    errors,
    component
  );

  validateNonEmptyURLValue(
    "SOFTPHONE_SSO_LOGIN_URL",
    values,
    errors,
    component
  );

  validateNonEmptyNumberRange(
    "low_mos",
    1,
    5,
    values["AGENT_ALERT_THRESHOLD_MOS"],
    errors,
    component
  );

  validateNonEmptyNumberRange(
    "very_low_mos",
    1,
    5,
    values["AGENT_ALERT_THRESHOLD_MOS"],
    errors,
    component
  );

  validateNonEmptyInteger(
    "seconds",
    values["AGENT_ALERT_THRESHOLD_MOS"],
    errors,
    component
  );

  validateNonEmptyNumberRange(
    "low_performance",
    0,
    99,
    values["AGENT_ALERT_COMPUTER_PERFORMANCE"],
    errors,
    component
  );

  validateNonEmptyNumberRange(
    "very_low_performance",
    0,
    99,
    values["AGENT_ALERT_COMPUTER_PERFORMANCE"],
    errors,
    component
  );

  validateNonEmptyInteger(
    "seconds",
    values["AGENT_ALERT_COMPUTER_PERFORMANCE"],
    errors,
    component
  );

  return errors;
};
