import PropTypes from "prop-types";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { validate } from "./ActionConfigEmailValidation";

class ActionConfigEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionId: "",
      type: props.actionType,
      subject: "",
      toEmail: "",
      body: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    let { prevState } = this.props;
    if (prevState.type === this.state.type) {
      this.setState(prevState);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.handleAddAction(this.state);
      }
    );
  }

  handleAddAction() {
    let requiredFields = ["toEmail"];
    if (this.props.legacy) {
      requiredFields.push("subject", "body");
    }

    let errors = validate(this.state, requiredFields, this);
    if (!errors["hasErrors"]) {
      const action = Object.assign({}, this.state, {
        actionId: "Send_Email",
        displayText: "Agent Notification"
      });

      this.props.handleAddAction(action);
    }

    return errors;
  }

  render() {
    return (
      <React.Fragment>
        <div className="form__control">
          <TextField
            error={this.state.error_toEmail}
            label={
              this.state.errorMessage_toEmail
                ? this.state.errorMessage_toEmail
                : "To Email"
            }
            id="outlined-required"
            name="toEmail"
            className="textField"
            onChange={event => this.handleChange(event)}
            variant="outlined"
            value={this.state.toEmail}
          />
        </div>

        {this.props.legacy && (
          <div className="form__control">
            <TextField
              error={this.state.error_subject}
              label={
                this.state.errorMessage_subject
                  ? this.state.errorMessage_subject
                  : "Subject"
              }
              id="outlined-required"
              name="subject"
              className="textField"
              onChange={event => this.handleChange(event)}
              variant="outlined"
              value={this.state.subject}
            />
          </div>
        )}

        {this.props.legacy && (
          <div className="form__control">
            <FormControl>
              <TextField
                error={this.state.error_body}
                label={
                  this.state.errorMessage_body
                    ? this.state.errorMessage_body
                    : "Body"
                }
                id="outlined-required"
                name="body"
                className="textField"
                onChange={event => this.handleChange(event)}
                variant="outlined"
                multiline={true}
                rows="3"
                value={this.state.body}
              />
            </FormControl>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ActionConfigEmail.propTypes = {
  actionType: PropTypes.string,
  handleAddAction: PropTypes.func,
  legacy: PropTypes.bool,
  onRef: PropTypes.func,
  prevState: PropTypes.object
};

export default ActionConfigEmail;
