import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";
import { showProgress } from "./config";

export const JOB_DETAILS_RECEIVED = "JOB_DETAILS_RECEIVED";
export const JOB_INSIGHTS_RECEIVED = "JOB_INSIGHTS_RECEIVED";
export const ERROR_JOB_DETAILS_RECEIVED = "ERROR_JOB_DETAILS_RECEIVED";
export const RESET_JOB_DETAILS = "RESET_JOB_DETAILS";
export const ERROR_MONITORS = "ERROR_MONITORS";
export const MONITORS_RECEIVED = "MONITORS_RECEIVED";
export const TO_NUMBERS_RECEIVED = "TO_NUMBERS_RECEIVED";
export const ERROR_TO_NUMBERS_RECEIVED = "ERROR_TO_NUMBERS_RECEIVED";
export const TO_NUMBERS_SAVED = "TO_NUMBERS_SAVED";
export const ERROR_TO_NUMBERS_SAVED = "ERROR_TO_NUMBERS_SAVED";
export const MONITOR_DISABLED = "MONITOR_DISABLED";
export const ERROR_MONITOR_DISABLE = "ERROR_MONITOR_DISABLE";
export const MONITOR_CREATED = "MONITOR_CREATED";
export const ERROR_MONITOR_CREATED = "ERROR_MONITOR_CREATED";
export const NEW_MONITOR = "NEW_MONITOR";
export const RESET_MONITOR_PAGE_STATE = "RESET_MONITOR_PAGE_STATE";

export const backendCalled = () => ({
  type: SHOW_PROGRESS
});

export const jobDetailsReceived = job => ({
  type: JOB_DETAILS_RECEIVED,
  job: job
});

export const jobInsightsReceived = insights => ({
  type: JOB_INSIGHTS_RECEIVED,
  insights: insights
});

export const errorJobDetailsReceived = message => ({
  type: ERROR_JOB_DETAILS_RECEIVED,
  message: message
});

export const resetState = () => ({
  type: RESET_JOB_DETAILS
});

export const errorMonitors = message => ({
  type: ERROR_MONITORS,
  message: message
});

export const monitorsReceived = monitorDetails => ({
  type: MONITORS_RECEIVED,
  monitors: monitorDetails
});

export const toNumbersReceived = numbers => ({
  type: TO_NUMBERS_RECEIVED,
  numbers: numbers
});

export const errorToNumbersReceived = message => ({
  type: ERROR_TO_NUMBERS_RECEIVED,
  message: message
});

export const toNumbersSaved = message => ({
  type: TO_NUMBERS_SAVED,
  message: message
});

export const errorToNumbersSaved = message => ({
  type: ERROR_TO_NUMBERS_SAVED,
  message: message
});

export const monitorDisabled = id => ({
  type: MONITOR_DISABLED,
  id: id
});

export const errorMonitorDisable = message => ({
  type: ERROR_MONITOR_DISABLE,
  message: message
});

//redux-thunk
export const fetchJobDetails = jobId => {
  return dispatch => {
    dispatch(backendCalled());
    return getJobDetails(jobId)
      .then(resp => {
        const job = resp.data;
        console.log("dispatching jobDetailsReceived..");
        dispatch(jobDetailsReceived(job));
      })
      .catch(() => {
        dispatch(
          errorJobDetailsReceived(
            "An error occurred while retrieving Job Details"
          )
        );
        dispatch(resetState());
      });
  };
};

const getJobDetails = jobId => {
  return axiosProxy.getInstance().get("gsm/monitors/jobs/" + jobId + "/stats");
};

export const fetchInsights = jobId => {
  return dispatch => {
    dispatch(backendCalled());
    return getInsights(jobId)
      .then(resp => {
        const insights = resp.data;
        console.log("dispatching insights received..");
        dispatch(jobInsightsReceived(insights));
      })
      .catch(() => {
        dispatch(
          errorJobDetailsReceived(
            "An error occurred while retrieving Job Insights"
          )
        );
        dispatch(resetState());
      });
  };
};

const getInsights = jobId => {
  return axiosProxy
    .getInstance()
    .get("gsm/monitors/jobs/" + jobId + "/insights");
};

/// Logs ///
export const LOGS_RECEIVED = "LOGS_RECEIVED";
export const ERROR_LOGS_RETRIEVAL = "ERROR_LOGS_RETRIEVAL";
export const JOBS_STATS_RECEIVED = "JOBS_STATS_RECEIVED";
export const ERROR_JOBS_STATS_RETRIEVAL = "ERROR_JOBS_STATS_RETRIEVAL";

export const logsActionRequested = () => ({
  type: SHOW_PROGRESS
});

export const logsReceived = logs => ({
  type: LOGS_RECEIVED,
  logs: logs
});

export const errorLogsRetrieval = () => ({
  type: ERROR_LOGS_RETRIEVAL
});

export const jobsStatsReceived = (currentPeriodStats, prevPeriodStats) => ({
  type: JOBS_STATS_RECEIVED,
  currentPeriodStats,
  prevPeriodStats
});

export const errorJobsStatsRetrieval = () => ({
  type: ERROR_JOBS_STATS_RETRIEVAL
});

export const fetchJobsAverages = (
  currentPeriodCriteria,
  prevPeriodCriteria
) => {
  const currentPeriodStats = getJobsStats(currentPeriodCriteria);
  const prevPeriodStats = getJobsStats(prevPeriodCriteria);

  return dispatch => {
    dispatch(logsActionRequested());

    return Promise.all([currentPeriodStats, prevPeriodStats])
      .then(([currentPeriod, prevPeriod]) => {
        dispatch(jobsStatsReceived(currentPeriod.data, prevPeriod.data));
      })
      .catch(() => {
        dispatch(
          errorJobsStatsRetrieval(
            "An error occurred while retrieving average scores"
          )
        );
      });
  };
};

const getJobsStats = criteria => {
  return axiosProxy
    .getInstance()
    .get("/gsm/monitors/jobs/stats?criteria=" + JSON.stringify(criteria));
};

export const fetchLogs = criteria => {
  return dispatch => {
    dispatch(logsActionRequested());
    return getLogs(criteria)
      .then(resp => {
        dispatch(logsReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorLogsRetrieval("An error occurred while retrieving logs"));
      });
  };
};

const getLogs = criteria => {
  return axiosProxy
    .getInstance()
    .get("/gsm/monitors/jobs?criteria=" + JSON.stringify(criteria));
};

export const fetchMonitors = criteria => {
  return dispatch => {
    dispatch(backendCalled());
    return getMonitorDetails(criteria)
      .then(resp => {
        dispatch(monitorsReceived(resp.data));
      })
      .catch(error => {
        dispatch(errorMonitors(error.message));
      });
  };
};

const getMonitorDetails = criteria => {
  return axiosProxy
    .getInstance()
    .get("gsm/monitors?criteria=" + JSON.stringify(criteria));
};

export const fetchToNumbersConfig = () => {
  return dispatch => {
    dispatch(backendCalled());
    return getToNumbers()
      .then(resp => {
        dispatch(toNumbersReceived(resp.data));
      })
      .catch(error => {
        dispatch(errorToNumbersReceived(error.message));
      });
  };
};

export const saveToNumbersConfig = values => {
  return dispatch => {
    dispatch(showProgress());

    return putToNumbers(values)
      .then(resp => {
        dispatch(toNumbersSaved(resp.data));
      })
      .catch(() => {
        dispatch(
          errorToNumbersSaved("An error occurred while setting the config")
        );
        dispatch(resetState());
      });
  };
};

const putToNumbers = value => {
  return axiosProxy
    .getInstance()
    .put("gsm/connect/numbers", JSON.stringify(value));
};

const getToNumbers = () => {
  return axiosProxy.getInstance().get("gsm/connect/numbers");
};

export const disableMonitor = monitorId => {
  return dispatch => {
    dispatch(backendCalled());
    return disableMonitorApi(monitorId)
      .then(() => {
        dispatch(monitorDisabled(monitorId));
        dispatch(resetState());
      })
      .catch(() => {
        dispatch(
          errorMonitorDisable("An error occurred while disabling the monitor")
        );
        dispatch(resetState());
      });
  };
};

const disableMonitorApi = monitorId => {
  return axiosProxy.getInstance().delete("gsm/monitors/" + monitorId);
};

export const resetStateMonitor = () => ({
  type: RESET_MONITOR_PAGE_STATE
});

export const monitorsMonitorRequested = () => ({
  type: SHOW_PROGRESS
});

export const openNewMonitor = () => ({
  type: NEW_MONITOR
});

export const monitorCreated = monitor => ({
  type: MONITOR_CREATED,
  monitor: monitor
});

export const errorMonitorCreated = message => ({
  type: ERROR_MONITOR_CREATED,
  message: message
});

export const newMonitor = () => {
  return dispatch => {
    dispatch(openNewMonitor());
  };
};

export const createMonitor = monitor => {
  return dispatch => {
    dispatch(monitorsMonitorRequested());

    return createMonitorHandler(monitor)
      .then(resp => {
        dispatch(monitorCreated(resp.data));
      })
      .catch(error => {
        if (error.response && error.response.status == 400) {
          dispatch(errorMonitorCreated(error.response.data));
          dispatch(resetStateMonitor());
        } else {
          dispatch(
            errorMonitorCreated("An error occurred while creating the monitor")
          );
          dispatch(resetStateMonitor());
        }
      });
  };
};

const createMonitorHandler = monitor => {
  return axiosProxy.getInstance().post("/gsm/monitors", monitor);
};

export const resetMonitorsPageState = () => {
  return dispatch => {
    dispatch(resetStateMonitor());
  };
};
