import {
  ERROR_JOB_DETAILS_RECEIVED,
  JOB_DETAILS_RECEIVED,
  JOB_INSIGHTS_RECEIVED,
  RESET_JOB_DETAILS,
  MONITORS_RECEIVED,
  TO_NUMBERS_RECEIVED,
  ERROR_MONITORS,
  MONITOR_DISABLED,
  LOGS_RECEIVED,
  RESET_MONITOR_PAGE_STATE,
  MONITOR_CREATED,
  ERROR_MONITOR_CREATED,
  NEW_MONITOR,
  ERROR_LOGS_RETRIEVAL,
  JOBS_STATS_RECEIVED,
  ERROR_JOBS_STATS_RETRIEVAL
} from "../actions/gsm";

const gsm = (
  state = {
    loading: false,
    job: {},
    insights: {},
    message: "",
    monitors: [],
    logs: [],
    numbers: []
  },
  gsm
) => {
  switch (gsm.type) {
    case JOB_DETAILS_RECEIVED:
      return Object.assign({}, state, {
        job: gsm.job,
        loading: false,
        status: JOB_DETAILS_RECEIVED
      });
    case JOB_INSIGHTS_RECEIVED:
      return Object.assign({}, state, {
        insights: gsm.insights,
        loading: false,
        status: JOB_INSIGHTS_RECEIVED
      });
    case ERROR_JOB_DETAILS_RECEIVED:
      return Object.assign({}, state, {
        message: gsm.message,
        loading: false,
        status: ERROR_JOB_DETAILS_RECEIVED
      });
    case RESET_JOB_DETAILS:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_JOB_DETAILS
      });
    case LOGS_RECEIVED:
      return Object.assign({}, state, {
        logs: gsm.logs,
        loading: false,
        status: LOGS_RECEIVED
      });
    case ERROR_LOGS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_LOGS_RETRIEVAL
      });
    case JOBS_STATS_RECEIVED:
      return Object.assign({}, state, {
        currentPeriodStats: gsm.currentPeriodStats,
        prevPeriodStats: gsm.prevPeriodStats,
        loading: false,
        status: JOBS_STATS_RECEIVED
      });
    case ERROR_JOBS_STATS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_JOBS_STATS_RETRIEVAL
      });
    case MONITORS_RECEIVED:
      return Object.assign({}, state, {
        monitors: gsm.monitors,
        loading: false,
        status: MONITORS_RECEIVED
      });
    case ERROR_MONITORS:
      return Object.assign({}, state, {
        message: gsm.message,
        loading: false,
        status: ERROR_MONITORS
      });
    case TO_NUMBERS_RECEIVED:
      return Object.assign({}, state, {
        numbers: gsm.numbers,
        loading: false,
        status: TO_NUMBERS_RECEIVED
      });
    case MONITOR_DISABLED:
      return Object.assign({}, state, {
        loading: false,
        status: MONITOR_DISABLED
      });
    case RESET_MONITOR_PAGE_STATE:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_MONITOR_PAGE_STATE
      });
    case MONITOR_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: MONITOR_CREATED
      });
    case NEW_MONITOR:
      return Object.assign({}, state, {
        loading: false,
        status: NEW_MONITOR,
        monitorData: {}
      });
    case ERROR_MONITOR_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_MONITOR_CREATED,
        errorMsg: gsm.message
      };
    default:
      return state;
  }
};

export default gsm;
