
const Crosshair = (function() {
    "use strict";
  
    /**
     * Creates the crosshair
     *
     * @constructor
     */
  
    var crosshair = function(opt_options) {
      this.canvas_ = document.createElement("canvas");
      this.elementx_ = document.createElement("div");
      this.elementdata_ = document.createElement("div");
      this.isactive_ = false;
      opt_options = opt_options || {};
      this.direction_ = opt_options.direction || null;
    };
  
    crosshair.prototype.toString = function() {
      return "Crosshair Plugin";
    };
  
    /**
     * @param {Dygraph} g Graph instance.
     * @return {object.<string, function(ev)>} Mapping of event names to callbacks.
     */
    crosshair.prototype.activate = function(g) {
      g.graphDiv.appendChild(this.canvas_);
      g.graphDiv.appendChild(this.elementx_);
      g.graphDiv.appendChild(this.elementdata_);

      g.graphDiv.addEventListener('mousemove', (event) => {
        let mousePos = { x: event.clientX, y: event.clientY };
        
        if (mousePos.x > event.target.getBoundingClientRect().left && 
            mousePos.x < event.target.getBoundingClientRect().right &&
            mousePos.y < event.target.getBoundingClientRect().bottom &&
            mousePos.y > event.target.getBoundingClientRect().top) {
                this.isactive_ = true;
        } else {
            this.isactive_ = false;
        }
      });
      
      return {
        select: this.select,
        deselect: this.deselect
      };
    };
  
    crosshair.prototype.select = function(e) {
      if (this.direction_ === null) {
        return;
      }
 

      let width = e.dygraph.width_;
      let height = e.dygraph.height_;

 
      const dpr = window.devicePixelRatio || 2;
            
      if (this.canvas_.width !== width * dpr || this.canvas_.height !== height * dpr) {
        this.canvas_.width = width * dpr; 
        this.canvas_.height = height * dpr; 
      }

      this.canvas_.style.width = width + "px";
      this.canvas_.style.height = height + "px";
    
      
      var ctx = this.canvas_.getContext("2d");
      ctx.clearRect(0, 0, width*dpr, height*dpr);
      ctx.strokeStyle = "rgba(0, 0, 0,0.95)";
      ctx.beginPath();
  
      var canvasx = Math.floor(e.dygraph.selPoints_[0].canvasx) + 0.5; // crisper rendering
  
      if (this.direction_ === "vertical" || this.direction_ === "both") {
        ctx.moveTo(canvasx*dpr, 0);
        ctx.lineTo(canvasx*dpr, height*dpr);
      }
  
      if (this.direction_ === "horizontal" || this.direction_ === "both") {
        for (var i = 0; i < e.dygraph.selPoints_.length; i++) {
          var canvasy = Math.floor(e.dygraph.selPoints_[i].canvasy) + 0.5; // crisper rendering
          ctx.moveTo(0, canvasy);
          ctx.lineTo(width, canvasy);
        }
      }
  
      const dispVal = new Date(e.dygraph.selPoints_[0].xval).toISOString().substring(11, 19);

      ctx.stroke();
      ctx.closePath();


    if (this.isactive_) {

        // display time
        this.elementx_.setAttribute("style", `z-index:5; position: absolute; padding: 2px 4px; color: white; background-color: black;`);
        this.elementx_.innerHTML = `<div>${dispVal}</div>`;
        const elementx_size = { width: this.elementx_.offsetWidth, height: this.elementx_.offsetHeight };
        if (canvasx-(elementx_size.width/2) < e.dygraph.plotter_.area.x) {
            this.elementx_.style.left = `${e.dygraph.plotter_.area.x}px`;
        } else if (canvasx+(elementx_size.width/2) > e.dygraph.plotter_.area.x+e.dygraph.plotter_.area.w) {
            this.elementx_.style.left = `${(e.dygraph.plotter_.area.x+e.dygraph.plotter_.area.w)-elementx_size.width}px`;
        } else {
            this.elementx_.style.left = `${canvasx-(elementx_size.width/2)}px`;
        }
        this.elementx_.style.top = `${height-elementx_size.height}px`;

        // set as the correct dygraph colour index
        this.elementdata_.setAttribute("style", `z-index:5; position: absolute; text-align:left`);
        this.elementdata_.innerHTML = `<div>` 
                                    + e.dygraph.selPoints_.map((d,i)=>{ return `<div style="color:white; padding: 4px 8px;"><span style="padding: 2px 5px; background-color:${e.dygraph.attributes_.user_.colors[i]}">${d.name}</span><span style="padding: 2px 5px; background-color:rgba(0,0,0,0.6); border-right: 1px solid white; border-left: 1px solid white;">${d.yval}<span></div>` }).join('') 
                                    + `</div>`;

        const elementdata_size = { width: this.elementdata_.offsetWidth, height: this.elementdata_.offsetHeight };
        if (canvasx < ((e.dygraph.plotter_.area.x+e.dygraph.plotter_.area.w)/2)) {
            this.elementdata_.style.left = `${canvasx+20}px`;
        } else {
            this.elementdata_.style.left = `${(canvasx-elementdata_size.width-20)}px`;
        } 
        this.elementdata_.style.top = `${(height/2)-(elementdata_size.height/2)}px`;
    }

    };
  
    crosshair.prototype.deselect = function(e) {
      var ctx = this.canvas_.getContext("2d");
      ctx.clearRect(0, 0, this.canvas_.width, this.canvas_.height);
      this.elementx_.setAttribute("style", "display:none");
      this.elementdata_.setAttribute("style", "display:none");
      this.isactive_ = false;
    };
  
    crosshair.prototype.destroy = function() {
      this.canvas_ = null;
      this.elementx_ = null;
      this.elementdata_ = null;
      this.isactive_ = false;
    };
  
    return crosshair;
  })();
  
  export default Crosshair;
  