export const UPDATE_FILTER = "UPDATE_FILTER";
export const PATH_CHANGED = "PATH_CHANGED";

export const changeFilter = (filter, reduxPath) => ({
  type: UPDATE_FILTER,
  payload: filter,
  reduxPath
});

export const dispatchChangeFilter = (filter, reduxPath) => {
  return dispatch => {
    dispatch(changeFilter(filter, reduxPath));
  };
};

export const pathChanged = path => ({
  type: PATH_CHANGED,
  path: path
});
