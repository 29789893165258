import {
  CALLSTATS_RECEIVED,
  CALLSTAT_DETAILS_RECEIVED,
  ERROR_CALLSTATS_RETRIEVAL,
  RESET_STATE_CALL_STATS
} from "../actions/callstats";

const performance = (
  state = {
    loading: false,
    log: [],
    logRecCount: 0,
    details: [],
    detailsRecCount: 0
  },
  action
) => {
  switch (action.type) {
    case CALLSTATS_RECEIVED:
      return Object.assign({}, state, {
        log: action.log,
        logRecCount: action.recordCount,
        loading: false,
        status: CALLSTATS_RECEIVED
      });
    case CALLSTAT_DETAILS_RECEIVED:
      return Object.assign({}, state, {
        details: action.log,
        detailsRecCount: action.recordCount,
        loading: false,
        status: CALLSTAT_DETAILS_RECEIVED
      });
    case ERROR_CALLSTATS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_CALLSTATS_RETRIEVAL
      });
    case RESET_STATE_CALL_STATS:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_STATE_CALL_STATS
      });
    default:
      return state;
  }
};

export default performance;
