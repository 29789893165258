export const DASHBOARD_CHANGE_FILTER = 'DASHBOARD_CHANGE_FILTER';
export const DASHBOARD_CHANGE_TAB = 'DASHBOARD_CHANGE_TAB';

export const filterDashboard = (filter, selectedRange, selectedRangeDisplay, selectedRefreshRate, autoRefresh) => ({
  type: DASHBOARD_CHANGE_FILTER,
  filter,
  selectedRange,
  selectedRangeDisplay,
  selectedRefreshRate,
  autoRefresh
});

export const changeTab = tab => ({
  type: DASHBOARD_CHANGE_TAB,
  tab
});
