import {
  ERROR_GROUP_DASHBOARD,
  GROUP_DASHBOARD_RECEIVED
} from "../actions/groups";

const homepage = (
  state = {
    loading: false,
    dashboard: {
      charts: {}
    }
  },
  action
) => {
  switch (action.type) {
    case GROUP_DASHBOARD_RECEIVED:
      return {
        ...state,
        charts: action.data?.charts,
        loading: false,
        status: GROUP_DASHBOARD_RECEIVED
      };
    case ERROR_GROUP_DASHBOARD:
      return {
        ...state,
        loading: false,
        status: ERROR_GROUP_DASHBOARD
      };
    default:
      return state;
  }
};

export default homepage;
