import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getJobStats } from "../../../../actions/heartbeat";
import { NavLink } from "react-router-dom";
import { formatDate, getDurationBetween } from "../../../../utils/date";
import { findAudioLatencyResult } from "../../utils";
import {
  getPOLQAScore,
  getPOLQAMaxScore,
  calculateAudioQualityLoss,
  NOT_AVAILABLE
} from "./utils";

const AgentExperienceSneakPeek = ({ jobId }) => {
  const inboundLeg = "connect_to_agent";
  const outboundLeg = "agent_to_connect";
  const inboundRef = "dialler_to_connect";
  const outboundRef = "POLQA SWB";

  const [data, setData] = useState({
    job: {},
    softphoneEvents: {}
  });

  useEffect(
    () => {
      getJobStats(jobId)
        .then(({ data }) => setData(data))
        .catch(err => console.log(err));
    },
    [jobId]
  );

  return (
    <>
      <TableRow className="tablerow__expanded">
        <TableCell colSpan={3}>Start:</TableCell>
        <TableCell>
          {data.softphoneEvents
            ? formatDate(data.softphoneEvents.connected, "HH:mm:ss")
            : ""}
        </TableCell>
        <TableCell colSpan={3}>Report:</TableCell>
        <TableCell>
          <NavLink
            className="textfield-bold actions__link"
            to={`/gsm/heartbeat/jobs/${jobId}/report`}
          >
            Link
          </NavLink>
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={3}>Test Duration:</TableCell>
        <TableCell>
          {data.softphoneEvents && data.softphoneEvents.ended
            ? getDurationBetween(
                data.softphoneEvents.ended.timestamp,
                data.softphoneEvents.connected
              )
            : NOT_AVAILABLE}
          s
        </TableCell>
        <TableCell colSpan={3}>Job ID:</TableCell>
        <TableCell>{jobId}</TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={3}>Date:</TableCell>
        <TableCell>{formatDate(data.job.createdOn, "DD/MM/YYYY")}</TableCell>
        <TableCell colSpan={3}>Connect Latency:</TableCell>
        <TableCell>
          {data.audioLatency &&
          findAudioLatencyResult(data.audioLatency, "connect")
            ? findAudioLatencyResult(data.audioLatency, "connect")
                .averageLatencyMs
            : NOT_AVAILABLE}
          ms
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={3}>Inbound Audio Quality Score:</TableCell>
        <TableCell>
          {getPOLQAScore(data.polqaScores, inboundLeg)}/
          {getPOLQAMaxScore(data.polqaScores, inboundRef)}
        </TableCell>
        <TableCell colSpan={3}>Inbound Quality Loss:</TableCell>
        <TableCell>
          {calculateAudioQualityLoss(
            getPOLQAMaxScore(data.polqaScores, inboundRef),
            getPOLQAScore(data.polqaScores, inboundLeg)
          )}
          %
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={3}>Outbound Audio Quality Score:</TableCell>
        <TableCell>
          {getPOLQAScore(data.polqaScores, outboundLeg)}/
          {getPOLQAMaxScore(data.polqaScores, outboundRef)}
        </TableCell>
        <TableCell colSpan={3}>Outbound Quality Loss:</TableCell>
        <TableCell>
          {calculateAudioQualityLoss(
            getPOLQAMaxScore(data.polqaScores, outboundRef),
            getPOLQAScore(data.polqaScores, outboundLeg)
          )}
          %
        </TableCell>
      </TableRow>
    </>
  );
};

export default AgentExperienceSneakPeek;

AgentExperienceSneakPeek.propTypes = {
  jobId: PropTypes.string
};
