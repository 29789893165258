import {
  DASHBOARD_CHANGE_FILTER,
  DASHBOARD_CHANGE_TAB
} from "../actions/dashboard";
import { UPDATE_FILTER } from "../actions/location";

const dashboard = (
  state = {
    filter: "refreshInterval:(pause:!f,value:10000)",
    selectedRange: "Last 15 minutes",
    selectedRangeDisplay: "Last 15 minutes",
    selectedRefreshRate: "5000",
    autoRefresh: true,
    tab: "dashboard"
  },
  action
) => {
  switch (action.type) {
    case DASHBOARD_CHANGE_FILTER: {
      let {
        filter,
        selectedRange,
        selectedRangeDisplay,
        selectedRefreshRate,
        autoRefresh
      } = action;
      return Object.assign({}, state, {
        filter,
        selectedRange,
        selectedRangeDisplay,
        selectedRefreshRate,
        autoRefresh
      });
    }
    case DASHBOARD_CHANGE_TAB:
      return Object.assign({}, state, { tab: action.tab });
    case UPDATE_FILTER:
      return Object.assign({}, state, action.payload, {
        reduxPath: action.reduxPath,
        status: UPDATE_FILTER
      });
    default:
      return state;
  }
};

export default dashboard;
