import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";
import { auth } from "../auth/Auth";

export const CSV_DOWNLOAD_REQUESTED = "CSV_DOWNLOAD_REQUESTED";
export const CSV_DATA_FETCHED = "CSV_DATA_FETCHED";
export const ERROR_CSV_DOWNLOAD = "ERROR_CSV_DOWNLOAD";

const tableRows = [
  {
    filter: "All",
    description: {
      heading: "All agents",
      description: "All agents seen by Operata, agent and cloud collected data."
    }
  },

  {
    filter: "NoExtension",
    description: {
      heading: "Agents without agent collected data",
      description: "No data from the agent collector - probably not installed on the browser used for calls."
    }
  },

  {
    filter: "LowCoverage",
    description: {
      heading: "Agents with low agent collector coverage",
      description:
        "20% or more calls seen in cloud data, but with no data from the agent collector - " +
        "probably not installed on the browser used for calls."
    }
  }
];

export const showProgress = () => ({
  type: SHOW_PROGRESS
});

export const agentDataCsvDownloadRequested = data => ({
  type: CSV_DOWNLOAD_REQUESTED,
  payload: data
});

export const agentDataCsvDataFetched = data => ({
  type: CSV_DATA_FETCHED,
  payload: data
});

export const errorCsvDownload = () => ({
  type: ERROR_CSV_DOWNLOAD
});

export const downloadCsv = utilisationPeriod => {
  return dispatch => {
    dispatch(showProgress());

    for (let i = 0; i < tableRows.length; i++) {
      axiosProxy
        .getInstance()
        .get(
          "/billing/usage/download/" +
            tableRows[i].filter +
            "/" +
            utilisationPeriod,
          {
            responseType: "text/plain",
            headers: {
              "Content-Type": "application/csv"
            }
          }
        )
        .then(resp => {
          const recCount = resp.headers["recordcount"];
          tableRows[i]["agents"] = recCount;
          tableRows[i]["data"] = {
            csv: resp.data,
            filename:
              auth
                .getCurrentGroupName()
                .replaceAll(" ", "-")
                .toLowerCase() +
              "-coverage-" +
              tableRows[i].filter.toLowerCase() +
              "-" +
              utilisationPeriod.split("-")[1] +
              ".csv"
          };

          if (tableRows[0].data && tableRows[1].data && tableRows[2].data) {
            dispatch(agentDataCsvDataFetched(tableRows));
          }
        })
        .catch(error => {
          if (error.response) {
            dispatch(errorCsvDownload(error.response.data));
          }
        });
    }
  };
};
