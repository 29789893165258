import React from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import 'regenerator-runtime/runtime';

import CampaignContext from '../CampaignContext';
import _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';

export class StepData extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          answerTreatment,
          setAnswerTreatment,
          error_answerTreatment,
          errorMessage_answerTreatment,
          setAnswerTreatmentData,
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
              <div className="workflow__input">
                  <Tooltip
                    placement="right"
                    title={
                      <span style={{fontSize: '12px'}}>{"Valid characters are digits (0-9) or 'w', '#', and '*'."} 
                        <br/><br/>{"Wait groups can be specified using w{x}, where x is the number of seconds to wait."}
                        <br/><br/>{"Templated data can be specified using {$Key}, where Key is the header value in the uploaded CSV."}</span>
                    }
                    arrow
                  >
                    <TextField
                      error={error_answerTreatment}
                      label={errorMessage_answerTreatment ? errorMessage_answerTreatment : 'Answer Treatment'}
                      id="outlined-required"
                      name="description"
                      className="textField"
                      onChange={event => setAnswerTreatment(event.target.value)}
                      variant="outlined"
                      value={answerTreatment}
                    />
                  </Tooltip>
                </div>

                <div className="workflow__input">
                  <DropzoneArea
                    onChange={setAnswerTreatmentData}
                    acceptedFiles={['.csv']}
                    filesLimit={1}
                    dropzoneText={'Drag and drop a csv file here or click.'}
                    showFileNames={true}
                    dropzoneClass={'campaigns__file_upload'}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}
