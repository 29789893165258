import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./store/store";
import { auth } from "./auth/Auth";
import "@operata/ui-components/dist/bundle.css";
import { MainCSS, theme } from "@operata/ui-components";

// Temporary comment to force cache reload
// Remove this next time to force cache reload again :)

render(
  <Provider store={store}>
    <MainCSS />
    <ThemeProvider theme={theme}>
      <App auth={auth} />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
