import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const BILLING_RECEIVED = "BILLING_RECEIVED";
export const ERROR_BILLING_LOGS = "ERROR_BILLING_LOGS";
export const BILLING_LOGS_RECEIVED = "BILLING_LOGS_RECEIVED";
export const MARKETPLACE_DATA_RECEIVED = "MARKETPLACE_DATA_RECEIVED";
export const ERROR_MARKETPLACE_BILLING = "ERROR_MARKETPLACE_BILLING";
export const BILLING_SUBSCRIPTIONS_RECEIVED = "BILLING_SUBSCRIPTIONS_RECEIVED";
export const ERROR_BILLING_SUBSCRIPTIONS = "ERROR_BILLING_SUBSCRIPTIONS";

export const billingActionRequested = () => ({
    type: SHOW_PROGRESS
  });

export const billingReceived = billingDetails => ({
    type: BILLING_RECEIVED,
    billing: billingDetails
});

export const errorBillingDetails = () => ({
    type: ERROR_BILLING_LOGS,
    message: "Error fetching billing details"
});  

export const billingAuditLogsReceived = auditLogs => ({
    type: BILLING_LOGS_RECEIVED,
    data: auditLogs
});

export const marketplaceDataReceived = data => ({
    type: MARKETPLACE_DATA_RECEIVED,
    marketplaceData: data
});

export const errorMarketplaceData = () => ({
    type: ERROR_MARKETPLACE_BILLING,
    message: "Error fetching marketplace data"
}); 

export const billingSubscriptionsReceived = data => ({
    type: BILLING_SUBSCRIPTIONS_RECEIVED,
    subscriptions: data
});

export const errorBillingSubscriptions = () => ({
    type: ERROR_BILLING_SUBSCRIPTIONS,
    message: "Error fetching marketplace data"
});  

export const fetchGroupBilling = criteria => {
    return dispatch => {
        dispatch(billingActionRequested());
        return getBillingDetails(criteria)
        .then(resp => {
            dispatch(billingReceived(resp.data));
        })
        .catch(() => {
            dispatch(errorBillingDetails());
        });
    };
};
  
export const fetchBillingAuditLogs = criteria => {
    return dispatch => {
        dispatch(billingActionRequested());
        return getBillingAuditLogs(criteria)
        .then(resp => {
            dispatch(billingAuditLogsReceived(resp.data));
        })
        .catch(() => {
            dispatch(errorBillingDetails());
        });
    };
};

export const fetchMarketplaceData = () => {
    return dispatch => {
        dispatch(billingActionRequested());
        return getMonthlyBillingData()
        .then(resp => {
            dispatch(marketplaceDataReceived(resp.data));
        })
        .catch(() => {
            dispatch(errorMarketplaceData());
        });
    };
}

const getBillingDetails = criteria => {
    return axiosProxy
        .getInstance()
        .get("/billing?criteria=" + JSON.stringify(criteria));
};
  
const getBillingAuditLogs = () => {
    return axiosProxy.getInstance().get("/marketplace/billingAuditLogs");
};

const getMonthlyBillingData = () => {
    return axiosProxy.getInstance().get("/marketplace/customerMarketplaceBilling");
};
