import auth0 from "auth0-js";
import store from "../store/store";
import {
  accessTokenChanged,
  loadUserProfileByEmail,
  logoutFromBI,
  switchGroup
} from "../actions/auth";
import jwtDecode from "jwt-decode";
import { featureToggles } from "./FearureToggles";
import _ from "lodash";
import { getCurrentURLPath } from "../utils/deeplinks";
import { setFeatureToggleConstants } from "../components/Actions/WizardConstants";

export const USER_ROLE_USER = "user:user";
export const USER_ROLE_ADMIN = "user:admin";
export const USER_ROLE_SUPER = "user:super";

const PUBLIC_ROUTES = ["subscription", "subscriptionsuccess", "activated"];

class Auth {
  accessToken;
  idToken;
  userProfile;
  picture;
  currentGroupId;
  roles = [];
  tokenExpirationSecs;
  initialLoad = true;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN_LOGIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: window.location.origin + "/callback",
    responseType: "token id_token",
    scope: "openid profile email read:messages"
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getCurrentGroupId = this.getCurrentGroupId.bind(this);
    this.getAuthorizationHeaders = this.getAuthorizationHeaders.bind(this);
    this.switchGroup = this.switchGroup.bind(this);
    this.getCurrentRegion = this.getCurrentRegion.bind(this);
    this.getRegionalUrlPrefix = this.getRegionalUrlPrefix.bind(this);
  }

  login() {
    const localStateObject = {
      redirectUrl: getCurrentURLPath()
    };

    if (!localStateObject.redirectUrl.startsWith("/callback")) {
      localStorage.setItem("state", JSON.stringify(localStateObject));
    }

    logoutFromBI();

    //The lock needs to be told about our previous login error here in order to
    //display the flash message after the user is redirected back to the login page
    let loginError = localStorage.getItem("loginError");
    if (loginError) {
      localStorage.removeItem("loginError");
    }

    this.auth0.authorize({ login_error: loginError });
  }

  handleAuthentication(callback) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.log(err);
        this.logout("There was an error logging in.");
      }
      callback();
    });
  }

  getUserProfile() {
    return this.userProfile;
  }

  setUserProfile(userProfile) {
    this.userProfile = userProfile;
  }

  getAuthorizationHeaders() {
    let headers = {
      Authorization: `Bearer ` + this.accessToken,
      GroupID: this.getCurrentGroupId()
    };
    return headers;
  }

  getPicture() {
    return this.picture;
  }

  switchGroup(groupId, requestedUrl) {
    let userProfile = this.getUserProfile();
    userProfile.currentGroupId = groupId;
    logoutFromBI();
    store.dispatch(switchGroup(userProfile, requestedUrl));
  }

  getCurrentGroupId() {
    return this.currentGroupId;
  }

  getCurrentGroupName() {
    let groupName = "";
    let currentGroupId = this.getCurrentGroupId();
    this.getUserProfile().groups.map(group => {
      if (group.id === currentGroupId) {
        groupName = group.name;
      }
    });

    return groupName;
  }

  getCurrentRegion() {
    let groupRegion = "ap-southeast-2";
    let currentGroupId = this.getCurrentGroupId();
    if (this.getUserProfile() && this.getUserProfile().groups) {
      this.getUserProfile().groups.map(group => {
        if (group.id === currentGroupId && group.region?.length > 0) {
          groupRegion = group.region;
        }
      });
    }
    return groupRegion;
  }

  getRegionalUrlPrefix() {
    if (this.getCurrentRegion() === "ap-southeast-2") {
      return "";
    }
    return "-" + this.getCurrentRegion();
  }

  hasPermission(inRoles) {
    let permitted = false;
    inRoles.forEach(inRole => {
      this.roles.forEach(role => {
        if (inRole === "*" || role === inRole) {
          permitted = true;
        }
      });
    });

    return permitted;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem("isLoggedIn", "true");

    // Set the time that the Access Token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem("expiresAt", expiresAt);
    this.tokenExpirationSecs = authResult.expiresIn;

    // refresh session 10 minutes before token expiration
    setTimeout(() => {
      console.log("renewing auth session");
      this.renewSession();
    }, (authResult.expiresIn - 10 * 60) * 1000);

    this.accessToken = authResult.accessToken;
    store.dispatch(accessTokenChanged(this.accessToken));
    let accessPayload = jwtDecode(this.accessToken);

    this.picture = authResult.idTokenPayload.picture;
    this.currentGroupId = accessPayload["https://app.operata.io/group"];

    this.setEnabledFeatures(
      accessPayload["https://app.operata.io/enabledFearures"]
    );

    setFeatureToggleConstants();

    let permissions = accessPayload["permissions"];

    permissions.forEach(permission => {
      this.roles.push(permission);
    });

    if (this.initialLoad) {
      setTimeout(() => {
        store.dispatch(loadUserProfileByEmail());
      }, 400);
    }
    this.initialLoad = false;
  }

  setEnabledFeatures(enabledFeatures) {
    if (enabledFeatures && enabledFeatures.length > 0) {
      enabledFeatures.map(feature => {
        if (feature.group === this.getCurrentGroupId()) {
          featureToggles.addFeatureToggle(feature.feature, true);
        }
      });
    }
  }

  renewSession() {
    this.auth0.checkSession(
      { response_mode: "web_message" },
      (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        } else if (err) {
          console.log(err);
          this.login();
        }
      }
    );
  }

  logout(err) {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;

    // loginError should only exist in storage if we want to show a flash message on the lock screen
    localStorage.removeItem("loginError");
    if (err) {
      localStorage.setItem("loginError", err);
    }

    localStorage.setItem("expiresAt", 0);

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem("isLoggedIn");

    logoutFromBI();

    this.auth0.logout({
      returnTo: window.location.origin
    });
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = localStorage.getItem("expiresAt");
    return new Date().getTime() < expiresAt;
  }

  isPublicRoute() {
    const path = window.location.pathname.split("/")[1];
    return _.indexOf(PUBLIC_ROUTES, path) >= 0;
  }

  getRenewedProfile() {
    this.auth0.client.userInfo(this.accessToken, function(err, profile) {
      if (profile) {
        store.dispatch(loadUserProfileByEmail(profile.email));
      }
    });
  }
}

export let auth = new Auth();
