import React, { useState } from "react";
import { Wizard } from "@operata/ui-components";
import { StepDetails } from "./WizardContent/StepDetails";
import { StepVerify } from "./WizardContent/StepVerify";
import { auth } from "../../../auth/Auth";

export const CreateEventSource = args => {
  let [AWSRegion, setAWSRegion] = useState("");
  const [errorAWSRegion, setErrorAWSRegion] = useState(false);
  const [errorMessageAWSRegion, setErrorMessageAWSRegion] = useState("");
  let [eventSourceName, setEventSourceName] = useState("");
  const [errorEventSourceName, setErrorEventSourceName] = useState(false);
  const [
    errorMessageEventSourceName,
    setErrorMessageEventSourceName
  ] = useState("");
  let [AWSAccount, setAWSAccount] = useState("");
  const [errorAWSAccount, setErrorAWSAccount] = useState(false);
  const [errorMessageAWSAccount, setErrorMessageAWSAccount] = useState("");
  let eventSNPattern =
    "aws.partner/operata.com/" + auth.getCurrentGroupId() + "/";

  const steps = [
    {
      label: "Details",
      content: (
        <StepDetails
          AWSRegion={AWSRegion}
          setAWSRegion={setAWSRegion}
          errorAWSRegion={errorAWSRegion}
          errorMessageAWSRegion={errorMessageAWSRegion}
          eventSourceName={eventSourceName}
          setEventSourceName={setEventSourceName}
          errorEventSourceName={errorEventSourceName}
          errorMessageEventSourceName={errorMessageEventSourceName}
          AWSAccount={AWSAccount}
          setAWSAccount={setAWSAccount}
          errorAWSAccount={errorAWSAccount}
          errorMessageAWSAccount={errorMessageAWSAccount}
          eventSNPattern={eventSNPattern}
        />
      ),
      validate: () => {
        let errors = {};
        if (!AWSRegion || AWSRegion.length === 0) {
          errors.hasErrors = true;
          setErrorAWSRegion(true);
          setErrorMessageAWSRegion("Required (AWS Region)");
        }
        if (!eventSourceName || eventSourceName.length === 0) {
          errors.hasErrors = true;
          setErrorEventSourceName(true);
          setErrorMessageEventSourceName("Required (Event Source Name)");
        }
        if (!AWSAccount || AWSAccount.length === 0) {
          errors.hasErrors = true;
          setErrorAWSAccount(true);
          setErrorMessageAWSAccount("Required (AWS Account)");
        } else if (AWSAccount.length != 12) {
          errors.hasErrors = true;
          setErrorAWSAccount(true);
          setErrorMessageAWSAccount("Number of digits is not 12");
        }
        return errors;
      }
    },
    {
      label: "Verify",
      content: (
        <StepVerify
          AWSRegion={AWSRegion}
          setAWSRegion={setAWSRegion}
          eventSourceName={eventSourceName}
          AWSAccount={AWSAccount}
          eventSNPattern={eventSNPattern}
        />
      ),
      validate: () => {
        let errors = {};
        return errors;
      }
    }
  ];

  const handleSave = () => {
    let eventSource = {
      awsRegion: AWSRegion,
      eventSourceName: eventSNPattern + eventSourceName,
      accountId: AWSAccount
    };
    args.handleCreateEventSource(eventSource);
  };

  return <Wizard steps={steps} onSave={handleSave} />;
};

export default CreateEventSource;
