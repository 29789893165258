export const DIALOG_OPENED = 'DIALOG_OPENED';
export const DIALOG_CLOSED = 'DIALOG_CLOSED';

export const showConfirmationDialog = (title, message, okCallback, okCallbackArgs, cancelCallback, cancelCallbackArgs) => ({
    type: DIALOG_OPENED,
    'title': title,
    'message': message,
    'okCallback': okCallback,
    'okCallbackArgs': okCallbackArgs,
    'cancelCallback': cancelCallback,
    'cancelCallbackArgs': cancelCallbackArgs,
});

export const closeDialog = () => ({
    type: DIALOG_CLOSED
});