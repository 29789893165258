import React from "react";
import TextField from "@material-ui/core/TextField";
import TestContext from "../TestContext";
import { DESCRIPTION_STEP } from "../../../../../constants/heartbeat";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export class StepDescription extends React.Component {
  render() {
    return (
      <TestContext.Consumer>
        {({
          description,
          setDescription,
          error_description,
          errorMessage_description,
          sourcePhoneNumber,
          error_sourcePhoneNumber,
          errorMessage_sourcePhoneNumber,
          setSourcePhoneNumber,
          sourcePhoneNumbers,
          targetPhoneNumber,
          error_targetPhoneNumber,
          errorMessage_targetPhoneNumber,
          setTargetPhoneNumber,
          targetPhoneNumbers
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="output-helper">
                      {errorMessage_sourcePhoneNumber
                        ? errorMessage_sourcePhoneNumber
                        : "From Number"}
                    </InputLabel>
                    <Select
                      name="sourcePhoneNumber"
                      error={error_sourcePhoneNumber}
                      variant="outlined"
                      value={sourcePhoneNumber}
                      onChange={event =>
                        setSourcePhoneNumber(event.target.value)
                      }
                      input={
                        <OutlinedInput
                          name="sourcePhoneNumber"
                          id="sourcePhoneNumber-helper"
                        />
                      }
                    >
                      {sourcePhoneNumbers.length > 0 &&
                        sourcePhoneNumbers.map((number, i) => (
                          <MenuItem
                            key={"sourcePhoneNumber" + i}
                            value={number}
                          >
                            {number}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="output-helper">
                      {errorMessage_targetPhoneNumber
                        ? errorMessage_targetPhoneNumber
                        : "To Number"}
                    </InputLabel>
                    <Select
                      name="targetPhoneNumber"
                      error={error_targetPhoneNumber}
                      variant="outlined"
                      value={targetPhoneNumber}
                      onChange={event =>
                        setTargetPhoneNumber(event.target.value)
                      }
                      input={
                        <OutlinedInput
                          name="targetPhoneNumber"
                          id="targetPhoneNumber-helper"
                        />
                      }
                    >
                      {targetPhoneNumbers.length > 0 &&
                        targetPhoneNumbers.map((number, i) => (
                          <MenuItem
                            key={"targetPhoneNumber" + i}
                            value={number}
                          >
                            {number}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="workflow__input">
                  <TextField
                    error={error_description}
                    label={
                      errorMessage_description
                        ? errorMessage_description
                        : DESCRIPTION_STEP
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event => setDescription(event.target.value)}
                    variant="outlined"
                    multiline={true}
                    rows="3"
                    value={description}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </TestContext.Consumer>
    );
  }
}

export default StepDescription;
