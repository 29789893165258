import React, { useState } from "react";
import { TabContainer } from "@operata/ui-components";
import EventSource from "./EventSource";

const EventBridge = () => {
  const [state] = useState({
    selectedTab: 0
  });

  return (
    <TabContainer selectedTab={state.selectedTab} index={0}>
      <EventSource />
    </TabContainer>
  );
};

export default EventBridge;
