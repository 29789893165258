import React from "react";
import { withStyles } from "@material-ui/core/styles";

const defaultTitleStyles = {
    titleContainer: {
        marginBottom: "20px",
        textAlign: "left",
        color: "#3e3f42",
    },
    titleHeading: {
        fontWeight: "bold",
        letterSpacing: "-0.3px",
        fontSize: "15px"
    },
    titleDescription: {
        letterSpacing: "-0.5px",
        fontSize: "14px"
    }
};

class TableTitle extends React.Component {

    render() {
        const {
            classes,
            title
        } = this.props;

        return (
            <React.Fragment>
                <div className={classes.titleContainer}>
                    <div className={classes.titleHeading}>{title.heading}</div>
                    <div className={classes.titleDescription}>{title.description}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultTitleStyles, { name: "MUIDataTableTitle" })(
    TableTitle
);
