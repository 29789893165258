import React from "react";
import { closeGroupSwitch, OPEN_GROUP_SWITCH } from "../../actions/groups";
import { auth } from "../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
import { GroupSwitch, Modal } from "@operata/ui-components";

const GroupSwitchModalWrapper = () => {
  const dispatch = useDispatch();
  let isSwitchGroup = useSelector(state => {
    return state.groups.groupSwitchState === OPEN_GROUP_SWITCH;
  });

  const handleCloseGroupSwitch = () => {
    dispatch(closeGroupSwitch());
  };

  const handleSwitchGroup = groupId => {
    auth.switchGroup(groupId);
  };

  return (
    <Modal
      className="hidden"
      title={"Switch Groups"}
      isModalOpen={isSwitchGroup}
      onCloseModal={handleCloseGroupSwitch}
      modalSize="small"
    >
      <GroupSwitch
        currentGroupId={auth.getCurrentGroupId()}
        groups={auth.getUserProfile().groups}
        switchGroupAction={handleSwitchGroup}
      />
    </Modal>
  );
};

export default GroupSwitchModalWrapper;
