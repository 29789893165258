import React from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

class TableSearch extends React.Component {
  handleTextChange = event => {
    const { onSearchChange } = this.props.options;

    if (onSearchChange) {
      onSearchChange(event.target.value);
    }

    this.props.onSearch(event.target.value);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  render() {
    const { options, searchText } = this.props;

    return (
      <div className="search__wrapper" ref={el => (this.rootRef = el)}>
        <TextField
          className="search__input"
          autoFocus={true}
          InputProps={{ 'aria-label': options.textLabels.toolbar.search }}
          value={searchText || ''}
          onChange={this.handleTextChange}
          fullWidth={true}
          inputRef={el => (this.searchField = el)}
        />
        <SearchIcon className="search__icon" />
      </div>
    );
  }
}

export default TableSearch;
