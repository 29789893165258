import React from 'react';
import TextField from '@material-ui/core/TextField';
import CampaignContext from '../CampaignContext';
import ChipInput from 'material-ui-chip-input';
import _ from 'lodash';

export class StepName extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          name,
          setCampaignName,
          error_name,
          errorMessage_name,
          description,
          setDescription,
          error_description,
          errorMessage_description,
          tags,
          setTags,
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="workflow__input">
                  <TextField
                    error={error_name}
                    label={errorMessage_name ? errorMessage_name : 'Campaign Name'}
                    id="outlined-required"
                    name="name"
                    className="textField"
                    onChange={event => setCampaignName(event.target.value)}
                    variant="outlined"
                    value={name}
                  />
                </div>

                <div className="workflow__input">
                  <TextField
                    error={error_description}
                    label={errorMessage_description ? errorMessage_description : 'Campaign Description'}
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event => setDescription(event.target.value)}
                    variant="outlined"
                    multiline={true}
                    rows="3"
                    value={description}
                  />
                </div>

                <div className="workflow__input">
                  <ChipInput
                    className="tags__wrapper"
                    label="Add Tags"
                    placeholder="Type and press enter to add tags"
                    value={!tags || typeof tags === 'string' || tags instanceof String ? [] : tags}
                    onAdd={newTag => setTags(_.union(tags, [newTag]))}
                    onDelete={deltedTag => setTags(_.without(tags, deltedTag))}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}

export default StepName;
