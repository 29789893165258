import React from "react";
import Button from "../Button/Button";
import Search from "../Search/Search";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepApplication from "./WizardContent/StepApplication";
import StepTrigger from "./WizardContent/StepTrigger";
import StepAction from "./WizardContent/StepAction";
import StepActionConfig from "./WizardContent/StepActionConfig";
import { StepSave } from "./WizardContent/StepSave";
import WorkflowContext from "./WorkflowContext";
import {
  createOrUpdateWorkflow,
  EDIT_WORKFLOW,
  ERROR_WORKFLOW_CREATED,
  NEW_WORKFLOW
} from "../../actions/workflow";
import { showConfirmationDialog } from "../../actions/dialog";
import { connect } from "react-redux";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../actions/snackbar";
import { validate } from "./validation";
import "../Wizard/Wizard.scss";
import {
  AVAILABLE_ACTIONS,
  AVAILABLE_APPLICATIONS,
  AVAILABLE_TRIGGERS
} from "./WizardConstants";
import { auth } from "../../auth/Auth";
import PropTypes from "prop-types";
import { fetchEventSourceConfig } from "../../actions/eventBridge";

function getSteps() {
  return ["Application", "Trigger", "Action", "Configure", "Details"];
}

export const getAvailableTriggers = () => {
  const availableTriggers = Object.entries(AVAILABLE_TRIGGERS).filter(
    ([, trigger]) =>
      !trigger.permittedTo || auth.hasPermission(trigger.permittedTo)
  );
  return Object.fromEntries(availableTriggers);
};
const eventbridgeTriggerSteps = [
  "eb-summary-data",
  "aws-connect-agent-issue-reported",
  "heartbeat-telephony"
];
class Wizard extends React.Component {
  setWorkflowName(workflowName) {
    this.setState({ workflowName });
  }

  setTags(tags) {
    this.setState({ tags });
  }

  setTrigger(trigger) {
    this.setState({ trigger });
  }

  setActions(actions) {
    this.setState({ actions });
  }

  setAction(action) {
    this.setState({ action });
  }

  setApplication(application) {
    this.setState({ application });
  }

  setTriggerThreshold(field, threshold) {
    let triggerThreshold = this.state.triggerThreshold;
    triggerThreshold[field] = threshold;
    this.setState({ triggerThreshold });
  }

  setContext(target) {
    this.setState({ [target.name]: target.value });
  }

  handleSave() {
    let { createOrUpdateWorkflow } = this.props;
    let workflow = {
      workflowId: this.state.workflowId,
      application: this.state.application,
      trigger: this.state.trigger,
      actions: this.state.actions,
      className: this.state.className,
      workflowName: this.state.workflowName,
      tags: this.state.tags,
      triggerThreshold: this.state.triggerThreshold
    };

    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      const useWatchdogApi =
        AVAILABLE_TRIGGERS[this.state.trigger].watchdogWatcher || false;
      createOrUpdateWorkflow(workflow, useWatchdogApi);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.open) {
      return {
        application: "",
        trigger: "",
        action: "",
        actions: [],
        tags: [],
        activeStep: 0,
        triggerThreshold: {},
        savedScenario: "",
        eventSources: [],
        workflowId: "-1",
        workflowName: ""
      };
    }
    if (
      state.activeStep === 2 &&
      eventbridgeTriggerSteps.includes(state.trigger) &&
      state.eventBridgeConfigStatus !== "EVENT_SOURCE_CONFIG_RECEIVED"
    ) {
      props.fetchEventSourceConfig(false);
      return {
        ...state,
        eventBridgeConfigStatus: props.eventBridgeConfigStatus
      };
    } else if (props.status === ERROR_WORKFLOW_CREATED) {
      props.showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while creating the workflow."
      );
      return null;
    } else if (
      (props.status === NEW_WORKFLOW || props.status === EDIT_WORKFLOW) &&
      state.application === ""
    ) {
      state.setFormState(props);
      return null;
    } else if (
      props.eventBridgeConfigStatus === "EVENT_SOURCE_CONFIG_RECEIVED"
    ) {
      if (state.eventSources.length === 0) {
        return {
          ...state,
          eventSources: props.eventSources,
          application: state.application
        };
      } else {
        props.eventSources.forEach((_, index) => {
          if (
            props.eventSources[index].eventSourceId !==
            state.eventSources[index].eventSourceId
          ) {
            return {
              ...state,
              eventSources: props.eventSources,
              application: state.application
            };
          }
        });
      }
    }
    return null;
  }

  constructor() {
    super();

    this.getStepContent = this.getStepContent.bind(this);
    this.setWorkflowName = this.setWorkflowName.bind(this);
    this.setTags = this.setTags.bind(this);
    this.setTrigger = this.setTrigger.bind(this);

    this.setAction = this.setAction.bind(this);
    this.setActions = this.setActions.bind(this);
    this.setApplication = this.setApplication.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setFormSate = this.setFormSate.bind(this);
    this.setTriggerThreshold = this.setTriggerThreshold.bind(this);
    this.setContext = this.setContext.bind(this);

    const availableTriggers = getAvailableTriggers();

    this.initFormState = {
      workflowId: "-1",
      activeStep: 0,
      application: "",
      setApplication: this.setApplication,
      workflowName: "",
      setWorkflowName: this.setWorkflowName,
      tags: [],
      setTags: this.setTags,
      trigger: "",
      setTrigger: this.setTrigger,

      availableTriggers: availableTriggers,
      action: "",
      setAction: this.setAction,
      actions: [],
      setActions: this.setActions,
      availableActions: AVAILABLE_ACTIONS,
      availableApplications: AVAILABLE_APPLICATIONS,
      triggerThreshold: {},
      savedScenario: "",
      setTriggerThreshold: this.setTriggerThreshold,
      setContext: this.setContext,
      eventSources: [],
      setFormState: this.setFormSate
    };

    this.state = this.initFormState;

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.validateCurrentStep = this.validateCurrentStep.bind(this);
  }

  setFormSate(props) {
    let { data } = props;

    if (data && data.id) {
      // this stuff is temporary while we are in transition period of Elastic watchers -> watchdog watchers
      // it is to prevent updating of one type of watcher to another type (eg. can't update a watchdog watcher to become an elastic watcher)
      const availableTriggers = Object.entries(AVAILABLE_TRIGGERS).filter(
        ([, trigger]) =>
          (!trigger.permittedTo || auth.hasPermission(trigger.permittedTo)) &&
          trigger.watchdogWatcher ===
            AVAILABLE_TRIGGERS[data.type].watchdogWatcher
      );
      const availableApplications = Object.entries(
        AVAILABLE_APPLICATIONS
      ).filter(([key]) => {
        const available = Object.values(availableTriggers).filter(
          ([, trigger]) => trigger.application === key
        );
        return available.length > 0;
      });

      this.setState({
        workflowId: data.id,
        application: data.application,
        workflowName: data.name,
        tags:
          !data.tags ||
          typeof data.tags === "string" ||
          data.tags instanceof String
            ? []
            : data.tags,
        trigger: data.type,
        triggerThreshold: this.getTriggerThreshold(data),
        action: data.actions[0].type,
        actions: data.actions,
        availableTriggers: Object.fromEntries(availableTriggers),
        availableApplications: Object.fromEntries(availableApplications)
      });
    }
  }

  getTriggerThreshold(metadata) {
    if (typeof metadata.triggerThreshold === "string") {
      try {
        return JSON.parse(metadata.triggerThreshold);
      } catch (e) {
        return {};
      }
    }
    return metadata.triggerThreshold;
  }

  showValidationError() {
    let { showSnackbarMessage } = this.props;
    switch (this.state.activeStep) {
      case 0:
        showSnackbarMessage(SNACKBAR_ERROR, "Please select an Application");
        return;
      case 1:
        showSnackbarMessage(SNACKBAR_ERROR, "Please select a Trigger");
        return;
      case 2:
        showSnackbarMessage(SNACKBAR_ERROR, "Please select an Action");
        return;
    }
  }

  validateCurrentStep() {
    let errors = {};

    switch (this.state.activeStep) {
      case 0:
        return (errors = validate(this.state, ["application"], this));
      case 1:
        return (errors = validate(this.state, ["trigger"], this));
      case 2:
        return (errors = validate(this.state, ["action"], this));
      case 3:
        this.actionConfig
          .getCurrentTriggerConditionObjects()
          .map(conditionObj => {
            errors = conditionObj.handleAddCondition();
          });
        errors = errors["hasErrors"]
          ? {
              ...this.actionConfig.getCurrentActionObject().handleAddAction(),
              ...errors
            }
          : this.actionConfig.getCurrentActionObject().handleAddAction();

        return errors;
      case 4:
        return (errors = validate(this.state, ["workflowName"], this));
    }

    return errors;
  }

  renderSearch() {
    if (this.displaySearch === "true") {
      return (
        <div className="search__container">
          <Search />
        </div>
      );
    }
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <StepApplication
            renderSearch={this.renderSearch}
            displaySearch={"true"}
          />
        );
      case 1:
        return <StepTrigger />;
      case 2:
        return (
          <StepAction renderSearch={this.renderSearch} displaySearch={"true"} />
        );
      case 3:
        return (
          <StepActionConfig
            onRef={ref => (this.actionConfig = ref)}
            eventSources={this.state.eventSources || []}
          />
        );
      case 4:
        return <StepSave />;
      default:
        return "Unknown step";
    }
  }

  handleNext() {
    const { activeStep } = this.state;
    let stepErrors = this.validateCurrentStep();

    if (!stepErrors["hasErrors"]) {
      this.setState({
        activeStep: activeStep + 1
      });
    } else {
      this.showValidationError();
    }
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  }

  handleReset() {
    this.setState({
      activeStep: 0
    });
  }

  render() {
    const steps = getSteps();

    const { activeStep } = this.state;
    const displayPrev = this.state.activeStep > 0;
    const displayNext = this.state.activeStep < steps.length - 1;
    const displaySave = this.state.activeStep === steps.length - 1;

    return (
      <div className="wizard__wrapper">
        <Stepper activeStep={activeStep} className="wizard__progress">
          {steps.map((label, index) => {
            const labelProps = {};
            if (index === activeStep) {
              labelProps.icon = (
                <div className="step step__active">{label}</div>
              );
            }
            if (index > activeStep) {
              labelProps.icon = (
                <div className="step step__future">{label}</div>
              );
            }
            if (index < activeStep) {
              labelProps.icon = <div className="step step__past">{label}</div>;
            }
            return (
              <Step key={label}>
                <StepLabel icon={index + 1} {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        <div className="wizard__content">
          <WorkflowContext.Provider value={this.state}>
            {this.getStepContent(this.state.activeStep)}
          </WorkflowContext.Provider>
        </div>

        <div className="wizard__btn-container">
          {displayPrev && (
            <Button
              onClick={this.handleBack}
              buttonText={"Previous"}
              className={"btnColoured"}
            />
          )}
          {displayNext && (
            <Button
              className={"btnSolid"}
              buttonText={"Next"}
              onClick={this.handleNext}
            />
          )}
          {displaySave && (
            <Button
              asyncButton={true}
              buttonText={"Create"}
              className={"btnSolid"}
              onClick={this.handleSave}
            />
          )}
        </div>
      </div>
    );
  }
}

Wizard.propTypes = {
  createOrUpdateWorkflow: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string,
  application: PropTypes.string,
  fetchEventSourceConfig: PropTypes.func,
  eventBridgeConfigStatus: PropTypes.string,
  eventSources: PropTypes.array,
  open: PropTypes.bool
};

const mapDispatchToProps = {
  createOrUpdateWorkflow: createOrUpdateWorkflow,
  showConfirmationDialog: showConfirmationDialog,
  showSnackbarMessage: showSnackbarMessage,
  fetchEventSourceConfig: fetchEventSourceConfig
};

const mapStateToProps = state => ({
  status: state.workflow.status,
  data: state.workflow.workflowData,
  eventSources: state.eventBridge.data,
  eventBridgeConfigStatus: state.eventBridge.status
});

// eslint-disable-next-line no-class-assign
Wizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wizard);

export default Wizard;
