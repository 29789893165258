import { BREADCRUMB_CHANGE_PATH, BREADCRUMB_UPDATE_DYNAMIC } from '../actions/breadcrumb'

const breadcrumb = (state = { 'path': [] }, action) => {
    switch (action.type) {
        case BREADCRUMB_CHANGE_PATH:
            return {
                ...state,
                'path': action.path
            }
        case BREADCRUMB_UPDATE_DYNAMIC:
            const updated = state.path.map(crumb => {
                if (crumb.dynamic) {
                    let title = crumb.name;
                    let path = crumb.path;
                    Object.keys(action.pathData).forEach(key => {
                        title = title.replace('${'+key+'}', action.pathData[key]);
                        if(path) {
                            path = path.replace('${'+key+'}', action.pathData[key]);
                        }
                    });
                    return Object.assign({}, crumb, {displayName: title, displayPath: path});
                }
                return crumb;
            });
            return {
                ...state,
                'path': updated
            }
        default:
            return state
    }
}

export default breadcrumb