import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Select
} from "@material-ui/core";

import TestContext from "../TestContext";
import CardAlternative from "../../../../Card/CardAlternative";
import {
  TARGET_AGENTS_STEP,
  VIRTUAL_AGENT
} from "../../../../../constants/heartbeat";

export class StepAgentIds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualAgents: []
    };
  }

  setVirtualAgentSelected(selectedName, setAgentIdFunc) {
    this.setState({
      virtualAgents: selectedName
    });
    var agentIds = selectedName.join(",");
    setAgentIdFunc(agentIds);
  }

  render() {
    return (
      <TestContext.Consumer>
        {({ agentIds, setAgentIds, error_agentIds, errorMessage_agentIds }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="workflow__input">
                  {this.props.agentType === VIRTUAL_AGENT ? (
                    this.props.virtualAgentNames.length === 0 ? (
                      <CardAlternative description="No Virtual Agents configured for this group" />
                    ) : (
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="virtualAgentId-helper">
                          {errorMessage_agentIds
                            ? errorMessage_agentIds
                            : TARGET_AGENTS_STEP}
                        </InputLabel>
                        <Select
                          name="virtualAgentIds"
                          labelId="demo-mutiple-checkbox-label"
                          id="outlined-required"
                          multiple
                          value={this.state.virtualAgents}
                          error={errorMessage_agentIds}
                          onChange={event =>
                            this.setVirtualAgentSelected(
                              event.target.value,
                              setAgentIds
                            )
                          }
                          input={
                            <OutlinedInput
                              name="virtualAgentIds"
                              id="virtualAgentId-helper"
                            />
                          }
                          renderValue={selected => selected.join(",")}
                        >
                          {this.props.virtualAgentNames.map(name => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={this.state.virtualAgents.includes(
                                  name
                                )}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                  ) : (
                    <TextField
                      error={error_agentIds}
                      label={
                        errorMessage_agentIds
                          ? errorMessage_agentIds
                          : TARGET_AGENTS_STEP + "(comma separated)"
                      }
                      id="outlined-required"
                      name="agentIds"
                      className="textField"
                      onChange={event => setAgentIds(event.target.value)}
                      variant="outlined"
                      multiline={true}
                      rows="3"
                      value={agentIds}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </TestContext.Consumer>
    );
  }
}

StepAgentIds.propTypes = {
  virtualAgentNames: PropTypes.array,
  agentType: PropTypes.string,
  setAgentIds: PropTypes.func
};

export default StepAgentIds;
