import {
  validateRequireField,
  validateNumeric
} from "../Generic/core-validations";

export const validate = (values, requiredFields, component) => {
  const errors = {};

  validateRequireField(requiredFields, values, errors, component);

  return errors;
};

export const validateNumericField = (field, values, component) => {
  const errors = {};

  validateNumeric(field, values, errors, component);

  return errors;
};
