import React from "react";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlineOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";

export const ALERT_INFO = "info";
export const ALERT_SUCCESS = "success";
export const ALERT_ERROR = "error";
export const ALERT_WARNING = "warning";

const contentStyles = () => ({
  root: {
    borderRadius: "8px",
    padding: "0 16px"
  },
  icon: {
    "align-items": "center"
  },
  standardSuccess: {
    backgroundColor: "#E8F7ED",
    border: "1px solid #52C176",
    "& $icon": {
      color: "#242931"
    }
  },
  standardError: {
    backgroundColor: "#F4D7DA",
    border: "1px solid #C83745",
    "& $icon": {
      color: "#242931"
    }
  },
  standardInfo: {
    backgroundColor: "#F4F0FF",
    border: "1px solid #5824FF",
    "& $icon": {
      color: "#242931"
    }
  },
  standardWarning: {
    backgroundColor: "#FEEEE5",
    border: "1px solid #F46E23",
    "& $icon": {
      color: "#242931"
    }
  },
  message: {
    padding: "18px 0"
  },
  action: {
    "margin-right": "0"
  }
});

const iconMapping = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />
};

function Alert(props) {
  const { closeText = "Close", onClose, ...others } = props;
  const action = onClose ? (
    <IconButton
      aria-label={closeText}
      title={closeText}
      className="closeButton"
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  ) : null;
  return (
    <MuiAlert
      elevation={0}
      iconMapping={iconMapping}
      action={action}
      {...others}
    />
  );
}

Alert.propTypes = {
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  severity: PropTypes.oneOf([
    ALERT_SUCCESS,
    ALERT_WARNING,
    ALERT_ERROR,
    ALERT_INFO
  ]).isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(contentStyles)(Alert);
