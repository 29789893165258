import React from "react";
import PropTypes from "prop-types";
import "./Card.scss";

class CardAlternative extends React.Component {
  render() {
    return (
      <React.Fragment>
        {(this.props.title || this.props.description) && (
          <div className="card card--alternative background--mystic">
            {this.props.title && (
              <div className="card__title h3">{this.props.title}</div>
            )}
            {this.props.description && (
              <p className="card__description">{this.props.description}</p>
            )}
            {this.props.children}
          </div>
        )}
      </React.Fragment>
    );
  }
}

CardAlternative.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element
};

export default CardAlternative;
