import * as PropTypes from "prop-types";
import React, { useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import Visualiser from "../../../Performance/Visualiser";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { POLQA_MAX_SCORES } from "../../../../constants/gsm";

const customerToConnectReference = POLQA_MAX_SCORES["POLQA"];
const agentToConnectReference = POLQA_MAX_SCORES["POLQA SWB"];
const outboundReferenceAudioFile =
  "https://s3-ap-southeast-2.amazonaws.com/op.reference.samples/12s_IRS_48k.wav";
const inboundReferenceAudioFile =
  "https://s3-ap-southeast-2.amazonaws.com/op.reference.samples/EN_MF_flat_08k_HRF_GSM.wav";
let referenceScore;
let referenceFile;
let audioQualityData = [];
let visualisers = [];

const callQualityTags = [
  {
    Hop: "Customer_to_Connect",
    AmberWarning: "Poor inbound call quality",
    RedWarning: "Very Poor inbound call quality"
  },
  {
    Hop: "Connect_to_Agent",
    AmberWarning: "Poor call quality inbound to agent",
    RedWarning: "Very Poor call quality inbound to agent"
  },
  {
    Hop: "Agent_to_Connect",
    AmberWarning: "Poor call quality outbound from Agent",
    RedWarning: "Very Poor call quality outbound from Agent"
  },
  {
    Hop: "Connect_to_Customer",
    AmberWarning: "Poor outbound call quality",
    RedWarning: "Very Poor outbound call quality"
  }
];

function AudioQualityVisualiser(props) {
  const [isShownRight, setIsShownRight] = useState(false);
  const [isShownMiddle, setIsShownMiddle] = useState(false);
  const [isShownRef, setIsShownRef] = useState(false);

  let waveFiles = {};

  // Assign the data to objects based on whether it is outbound or inbound
  if (props.isInbound) {
    referenceScore = customerToConnectReference;
    referenceFile = inboundReferenceAudioFile;

    visualisers = [
      { Title: "Customer", MapName: "Inbound_Reference" },
      { Title: "CCaaS", MapName: "Customer_to_Connect" },
      { Title: "Agent", MapName: "Connect_to_Agent" }
    ];

    audioQualityData = [
      props.visualiserFilesMap["Customer_to_Connect"],
      props.visualiserFilesMap["Connect_to_Agent"]
    ];
  } else {
    referenceScore = agentToConnectReference;
    referenceFile = outboundReferenceAudioFile;

    visualisers = [
      { Title: "Agent", MapName: "Outbound_Reference" },
      { Title: "CCaaS", MapName: "Agent_to_Connect" },
      { Title: "Customer", MapName: "Connect_to_Customer" }
    ];

    audioQualityData = [
      props.visualiserFilesMap["Agent_to_Connect"],
      props.visualiserFilesMap["Connect_to_Customer"]
    ];
  }

  function getLossClass(location) {
    const locationCompare = location.toUpperCase();
    let className = "stat__circle";
    if (props.tags) {
      props.tags.map(tag => {
        if (
          tag.toUpperCase() ===
          callQualityTags
            .find(({ Hop }) => Hop.toUpperCase() === locationCompare)
            .AmberWarning.toUpperCase()
        ) {
          className = "stat__circle yellow";
        } else if (
          tag.toUpperCase() ===
          callQualityTags
            .find(({ Hop }) => Hop.toUpperCase() === locationCompare)
            .RedWarning.toUpperCase()
        ) {
          className = "stat__circle red";
        }
      });
    }

    return className;
  }

  function calculateDegradation(reference, score) {
    let percentage = ((reference - score) / reference) * 100;

    return percentage.toFixed(1);
  }

  const firstResultScore =
    (audioQualityData[0] && audioQualityData[0].result.score) || 0;
  const secondResultScore =
    (audioQualityData[1] && audioQualityData[1].result.score) || 0;
  const visualiserMapName = visualisers[2].MapName || "";
  const firstAudioQualityData = audioQualityData[1] || {};

  return (
    <div className="audio__quality__visualiser__wrapper">
      <div className="audio__quality__visualiser__inner__wrapper">
        <div className="columnDiv__visualiser">
          <div className="columnDiv__visualiser__header">
            <div className="visualiser__name">
              {(visualisers[0] && visualisers[0].Title) || ""}
            </div>
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShownRef(true)}
              onMouseLeave={() => setIsShownRef(false)}
            />
            {isShownRef && (
              <div className="info__box">POLQA Score: {referenceScore}</div>
            )}
          </div>
          <div className="columnDiv__visualiser__main">
            {waveFiles && (
              <Visualiser
                waveFiles={{}}
                minZoom={20}
                maxZoom={100}
                width={325}
                height={64}
                showTitle={false}
                showRef={true}
                refFile={referenceFile}
                referenceName={(visualisers[0] && visualisers[0].MapName) || ""}
              />
            )}
          </div>
        </div>
        <div className="columnDiv__loss">
          <ArrowRightAltIcon />
          <div
            className={getLossClass(
              (visualisers[1] && visualisers[1].MapName) || ""
            )}
          >
            <p>{calculateDegradation(referenceScore, firstResultScore)}%</p>
            <p>Loss</p>
          </div>
          <ArrowRightAltIcon />
        </div>
        <div className="columnDiv__visualiser">
          <div className="columnDiv__visualiser__header">
            <div className="visualiser__name">
              {(visualisers[1] && visualisers[1].Title) || ""}
            </div>
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShownMiddle(true)}
              onMouseLeave={() => setIsShownMiddle(false)}
            />
            {isShownMiddle && (
              <div className="info__box">POLQA Score: {firstResultScore}</div>
            )}
          </div>
          {audioQualityData[0] && (
            <Visualiser
              waveFiles={{ [visualisers[1].MapName]: audioQualityData[0] }}
              minZoom={20}
              maxZoom={100}
              width={325}
              height={64}
              showRef={false}
            />
          )}
        </div>
        <div className="columnDiv__loss">
          <ArrowRightAltIcon />
          <div
            className={getLossClass(
              (visualisers[2] && visualisers[2].MapName) || ""
            )}
          >
            <p>{calculateDegradation(firstResultScore, secondResultScore)}%</p>
            <p>Loss</p>
          </div>
          <ArrowRightAltIcon />
        </div>
        <div className="columnDiv__visualiser">
          <div className="columnDiv__visualiser__header">
            <div className="visualiser__name">
              {(visualisers[2] && visualisers[2].Title) || ""}
            </div>
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShownRight(true)}
              onMouseLeave={() => setIsShownRight(false)}
            />
            {isShownRight && (
              <div className="info__box">POLQA Score: {secondResultScore}</div>
            )}
          </div>
          {audioQualityData[1] && (
            <Visualiser
              waveFiles={{ [visualiserMapName]: firstAudioQualityData }}
              minZoom={20}
              maxZoom={100}
              width={325}
              height={64}
              showRef={false}
            />
          )}
        </div>
      </div>
      <div className="globalAverage__wrapper">
        <div className="globalAverage__customerToConnect">
          Global Average: Unavailable
        </div>
        <div className="globalAverage__connectToAgent">
          Global Average: Unavailable
        </div>
      </div>
    </div>
  );
}

AudioQualityVisualiser.propTypes = {
  visualiserFilesMap: PropTypes.object,
  Customer_to_Connect: PropTypes.object,
  Connect_to_Agent: PropTypes.object,
  Agent_to_Connect: PropTypes.object,
  Connect_to_Customer: PropTypes.object,
  isInbound: PropTypes.bool,
  tags: PropTypes.array
};

export default AudioQualityVisualiser;
