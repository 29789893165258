import React from "react";
import * as PropTypes from "prop-types";
import Chip from "../../../Chips/Chip";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

function TopInsights(props) {
  const { tags } = props;
  return (
    <>
      {tags ? (
        tags.map((tag, i) => (
          <ListItem key={"listItem" + i}>
            <Chip label={tag} key={i} />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary={"N/A"} />
        </ListItem>
      )}
    </>
  );
}

TopInsights.propTypes = { tags: PropTypes.array };

export default TopInsights;
