import React from "react";
import NavTabs from "../Tabs/Tabs";
import { NavLink } from "react-router-dom";
import "../Button/Button.scss";
import { auth } from "../../auth/Auth";

function Users() {
  return (
    <React.Fragment>
      <NavLink
        className="button button--right"
        to={"/admin/group/" + auth.getCurrentGroupId() + "/member/0"}
        href={"#"}
      >
        <span className="button__circle">+</span>
        TeamMate
      </NavLink>

      <NavTabs />
    </React.Fragment>
  );
}

export default Users;
