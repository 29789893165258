import {
  DATA_ROLE_USER_ADDED, DATA_ROLE_USER_REMOVED,
  DATA_ROLE_USERS_RECEIVED, ERROR_DATA_ROLE_USER_ADDED, ERROR_DATA_ROLE_USER_REMOVED, ERROR_DATA_ROLE_USERS_RECEIVED,
  ERROR_PLAYBOOKS_RECEIVED, ERROR_ROLE_CREATED,
  ERROR_ROLES_RECEIVED, ERROR_USER_ROLES_RECEIVED,
  PLAYBOOKS_RECEIVED, ROLE_CREATED,
  ROLES_RECEIVED, USER_ROLES_RECEIVED
} from "../actions/playbooks";
import { EmbeddedDashboard, DataRole } from "../models/dataRole";
import { AnyAction, Reducer } from "redux";

export type PlaybooksState = {
  loading: boolean;
  roles: DataRole[];
  userRoles: DataRole[];
  usersForRole: Record<number, string[]>;
  playbooks: EmbeddedDashboard[];
  status?: string;
}

const playbooks: Reducer<PlaybooksState, AnyAction> = (
  state: PlaybooksState = {
    loading: false,
    roles: [],
    userRoles: [],
    playbooks: [],
    usersForRole: {}
  } as PlaybooksState,
  action: AnyAction
): PlaybooksState => {
  switch (action.type) {
    case ROLES_RECEIVED:
      return {
        ...state,
        roles: action.roles,
        loading: false,
        status: ROLES_RECEIVED
      };
    case ERROR_ROLES_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_ROLES_RECEIVED
      };
    case USER_ROLES_RECEIVED:
      return {
        ...state,
        userRoles: action.roles,
        loading: false,
        status: USER_ROLES_RECEIVED
      };
    case ERROR_USER_ROLES_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_USER_ROLES_RECEIVED
      };
    case ROLE_CREATED:
      return {
        ...state,
        loading: false,
        status: ROLE_CREATED
      };
    case ERROR_ROLE_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_ROLE_CREATED
      };
    case DATA_ROLE_USERS_RECEIVED:
      return {
        ...state,
        loading: false,
        status: DATA_ROLE_USERS_RECEIVED,
        usersForRole: {
          ...state.usersForRole,
          [action.dataRoleId]: action.users
        }
      };
    case ERROR_DATA_ROLE_USERS_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_DATA_ROLE_USERS_RECEIVED
      };
    case DATA_ROLE_USER_ADDED:
      return {
        ...state,
        loading: false,
        status: DATA_ROLE_USER_ADDED,
        usersForRole: {
          ...state.usersForRole,
          [action.dataRoleId]: [...(state.usersForRole[action.dataRoleId]?.filter(u => u !== action.user) || []), action.user]
        }
      };
    case ERROR_DATA_ROLE_USER_ADDED:
      return {
        ...state,
        loading: false,
        status: ERROR_DATA_ROLE_USER_ADDED
      };
    case DATA_ROLE_USER_REMOVED:
      return {
        ...state,
        loading: false,
        status: DATA_ROLE_USER_REMOVED,
        usersForRole: {
          ...state.usersForRole,
          [action.dataRoleId]: (state.usersForRole[action.dataRoleId] || []).filter((user) => user !== action.user)
        }
      };
    case ERROR_DATA_ROLE_USER_REMOVED:
      return {
        ...state,
        loading: false,
        status: ERROR_DATA_ROLE_USER_REMOVED
      };
    case PLAYBOOKS_RECEIVED:
      return {
        ...state,
        playbooks: action.playbooks,
        loading: false,
        status: PLAYBOOKS_RECEIVED
      };
    case ERROR_PLAYBOOKS_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_PLAYBOOKS_RECEIVED
      };
    default:
      return state;
  }
};

export default playbooks;
