import React from "react";
import MonitorContext from "../MonitorContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

export class StepSchedule extends React.Component {
  render() {
    return (
      <MonitorContext.Consumer>
        {({ schedule, setSchedule, error_schedule, errorMessage_schedule }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="workflow__input">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="schedule-helper">
                      {errorMessage_schedule
                        ? errorMessage_schedule
                        : "Schedule"}
                    </InputLabel>
                    <Select
                      name="schedule"
                      value={schedule}
                      variant="outlined"
                      id="outlined-required"
                      error={error_schedule}
                      onChange={event => setSchedule(event.target.value)}
                      input={
                        <OutlinedInput name="schedule" id="schedule-helper" />
                      }
                    >
                      <MenuItem value="0m">Run Once</MenuItem>
                      <MenuItem value="30m">Every 30m</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          );
        }}
      </MonitorContext.Consumer>
    );
  }
}

export default StepSchedule;
