import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getJobStats } from "../../../../actions/heartbeat";
import { NavLink } from "react-router-dom";
import { formatDate, getDurationBetween } from "../../../../utils/date";
import { NOT_AVAILABLE } from "./utils";

const NetworkPerformanceSneakPeek = ({ jobId }) => {
  const [data, setData] = useState({
    job: {},
    softphoneEvents: {}
  });

  useEffect(
    () => {
      getJobStats(jobId)
        .then(({ data }) => setData(data))
        .catch(err => console.log(err));
    },
    [jobId]
  );

  return (
    <>
      <TableRow className="tablerow__expanded">
        <TableCell colSpan={2}>Start:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneEvents
            ? formatDate(data.softphoneEvents.connected, "HH:mm:ss")
            : ""}
        </TableCell>
        <TableCell colSpan={2}>Report:</TableCell>
        <TableCell colSpan={2}>
          <NavLink
            className="textfield-bold actions__link"
            to={`/gsm/heartbeat/jobs/${jobId}/report`}
          >
            Link
          </NavLink>
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={2}>Test Duration:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneEvents && data.softphoneEvents.ended
            ? getDurationBetween(
                data.softphoneEvents.ended.timestamp,
                data.softphoneEvents.connected
              )
            : NOT_AVAILABLE}
          s
        </TableCell>
        <TableCell colSpan={2}>Date:</TableCell>
        <TableCell colSpan={2}>
          {formatDate(data.job.createdOn, "DD/MM/YYYY")}
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={2}>Jitter:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneSummary &&
          data.softphoneSummary.networkPerformanceMetrics ? (
            <span>
              {"Avg: " +
                data.softphoneSummary.networkPerformanceMetrics.jitter.avg}{" "}
              <br />
              {"Min: " +
                data.softphoneSummary.networkPerformanceMetrics.jitter.min}{" "}
              <br />
              {"Max: " +
                data.softphoneSummary.networkPerformanceMetrics.jitter.max}
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </TableCell>
        <TableCell colSpan={2}>Packet Loss:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneSummary &&
          data.softphoneSummary.networkPerformanceMetrics ? (
            <span>
              {"Avg: " +
                data.softphoneSummary.networkPerformanceMetrics.packetLoss
                  .avg}{" "}
              <br />
              {"Min: " +
                data.softphoneSummary.networkPerformanceMetrics.packetLoss
                  .min}{" "}
              <br />
              {"Max: " +
                data.softphoneSummary.networkPerformanceMetrics.packetLoss.max}
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </TableCell>
      </TableRow>

      <TableRow className="tablerow__expanded">
        <TableCell colSpan={2}>RTT:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneSummary &&
          data.softphoneSummary.networkPerformanceMetrics ? (
            <span>
              {"Avg: " +
                data.softphoneSummary.networkPerformanceMetrics.rtt.avg}{" "}
              <br />
              {"Min: " +
                data.softphoneSummary.networkPerformanceMetrics.rtt.min}{" "}
              <br />
              {"Max: " +
                data.softphoneSummary.networkPerformanceMetrics.rtt.max}
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </TableCell>
        <TableCell colSpan={2}>MOS:</TableCell>
        <TableCell colSpan={2}>
          {data.softphoneSummary &&
          data.softphoneSummary.networkPerformanceMetrics ? (
            <span>
              {"Avg: " +
                data.softphoneSummary.networkPerformanceMetrics.mos.avg.toFixed(
                  2
                )}{" "}
              <br />
              {"Min: " +
                data.softphoneSummary.networkPerformanceMetrics.mos.min.toFixed(
                  2
                )}{" "}
              <br />
              {"Max: " +
                data.softphoneSummary.networkPerformanceMetrics.mos.max.toFixed(
                  2
                )}
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default NetworkPerformanceSneakPeek;

NetworkPerformanceSneakPeek.propTypes = {
  jobId: PropTypes.string
};
