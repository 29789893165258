import React from "react";
import PropTypes from "prop-types";

import Toggle from "../Toggle/Toggle";
import DateTimePicker from "./Wrapper";
import { getRanges } from "./lib/utils/TimeFunctionUtils";

import "./Time.scss";

const DISPLAY_DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";
const ES_QUERY_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerOpen: "",
      selectedRange: props.selectedRange,
      selectedRangeDisplay: props.selectedRangeDisplay,
      selectedCriteria: "",
      selectedRefreshRate: props.selectedRefreshRate,
      startTime: "",
      endTime: "",
      fromTime: "",
      toTime: "",
      autoRefresh: props.autoRefresh,
      showAutoRefresh:
        props.showAutoRefresh !== undefined ? props.showAutoRefresh : true,
      pickerRightAligned:
        props.pickerRightAligned !== undefined
          ? props.pickerRightAligned
          : false
    };

    this.dateRangeSelected = this.dateRangeSelected.bind(this);
    this.handleAutoRefreshToggle = this.handleAutoRefreshToggle.bind(this);
  }

  dateRangeSelected(selectedRange, startTime, endTime) {
    let selectedTimeCriteria = "()";

    this.setState({ selectedRange: selectedRange });
    if (selectedRange === "Custom Range") {
      let startTimeFormattedForDisplay = startTime.format(DISPLAY_DATE_FORMAT);
      let endTimeFormattedForDisplay = endTime.format(DISPLAY_DATE_FORMAT);
      selectedTimeCriteria =
        "(from:'" +
        startTime.format(ES_QUERY_DATE_FORMAT) +
        "',to:'" +
        endTime.format(ES_QUERY_DATE_FORMAT) +
        "')";
      this.setState({
        startTime: startTime,
        endTime: endTime,
        fromTime: startTime.format(ES_QUERY_DATE_FORMAT),
        toTime: endTime.format(ES_QUERY_DATE_FORMAT),
        selectedRangeDisplay:
          startTimeFormattedForDisplay === endTimeFormattedForDisplay
            ? startTimeFormattedForDisplay
            : startTimeFormattedForDisplay + " - " + endTimeFormattedForDisplay,
        selectedCriteria: selectedTimeCriteria,
        selectedRange: selectedRange,
        pickerOpen: ""
      });
    } else {
      let ranges = getRanges(new Date());
      selectedTimeCriteria = ranges[selectedRange][2];
      this.setState({
        startTime: startTime,
        endTime: endTime,
        selectedRangeDisplay: selectedRange,
        selectedRange: selectedRange,
        selectedCriteria: selectedTimeCriteria,
        fromTime: ranges[selectedRange][4],
        toTime: ranges[selectedRange][5],
        pickerOpen: ""
      });
    }

    setTimeout(() => {
      this.doFilterCallback();
    }, 100);
  }

  handleOpen = () => {
    this.setState({ pickerOpen: "open" });
  };

  handleClose = () => {
    this.setState({ pickerOpen: "" });
  };

  handleAutoRefreshToggle = checked => {
    this.setState({ autoRefresh: checked }); //silent
    setTimeout(() => {
      this.doFilterCallback();
    }, 100);
  };

  doFilterCallback() {
    let { filterCallback } = this.props;
    let kibanaCriteria =
      (this.state.autoRefresh
        ? ",refreshInterval:(pause:!f,value:10000)"
        : "") +
      (this.state.selectedCriteria !== ""
        ? ",time:" + this.state.selectedCriteria
        : "");

    if (filterCallback) {
      let {
        selectedRange,
        selectedRangeDisplay,
        selectedRefreshRate,
        autoRefresh,
        fromTime,
        toTime
      } = this.state;

      filterCallback(
        kibanaCriteria.substring(1),
        selectedRange,
        selectedRangeDisplay,
        selectedRefreshRate,
        autoRefresh,
        fromTime,
        toTime
      );
    }
  }

  render() {
    return (
      <div className="time__container">
        <div className="time__actions">
          <span onClick={this.handleOpen} className="time__link time__results">
            {this.state.selectedRangeDisplay}
          </span>
          {this.state.showAutoRefresh && (
            <Toggle
              checked={this.state.autoRefresh}
              onChange={this.handleAutoRefreshToggle}
              labelPlacement={"end"}
              className={"time__link"}
              text={"Auto Refresh"}
            />
          )}
        </div>

        <DateTimePicker
          rightAlign={this.state.pickerRightAligned}
          dateRangeSelected={this.dateRangeSelected}
          pickerOpen={this.state.pickerOpen}
          currentRange={this.state.selectedRange}
          currentStartTime={this.state.startTime}
          currentEndTime={this.state.endTime}
        />
      </div>
    );
  }
}

DateTime.propTypes = {
  selectedRange: PropTypes.string,
  selectedRangeDisplay: PropTypes.string,
  selectedRefreshRate: PropTypes.string,
  autoRefresh: PropTypes.bool,
  showAutoRefresh: PropTypes.bool,
  pickerRightAligned: PropTypes.bool,
  filterCallback: PropTypes.func
};

export default DateTime;
