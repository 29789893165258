import {
  GROUP_RECEIVED,
  GROUP_CREATED,
  GROUP_UPDATED,
  ERROR_GROUP_CREATED,
  GROUP_CONFIG_RECEIVED,
  ERROR_GROUP_CONFIG_RECEIVED,
  CLEAR_STATE
} from "../actions/groups";

const groups = (state = { loading: false }, action) => {
  switch (action.type) {
    case GROUP_RECEIVED:
      return Object.assign({}, state, {
        data: action.group,
        loading: false,
        status: GROUP_RECEIVED
      });
    case GROUP_CREATED:
      return Object.assign({}, state, {
        data: action.group,
        loading: false,
        status: GROUP_CREATED
      });
    case ERROR_GROUP_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_GROUP_CREATED,
        message: action.message
      });
    case GROUP_UPDATED:
      return Object.assign({}, state, {
        data: action.group,
        loading: false,
        status: GROUP_UPDATED
      });
    case GROUP_CONFIG_RECEIVED:
      return Object.assign({}, state, {
        config: action.config,
        loading: false,
        status: GROUP_CONFIG_RECEIVED
      });
    case ERROR_GROUP_CONFIG_RECEIVED:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_GROUP_CONFIG_RECEIVED
      });
    case CLEAR_STATE:
      return {
        ...state,
        loading: false,
        status: "",
        message: "",
        data: {}
      };
    default:
      return Object.assign({}, state);
  }
};

export default groups;
