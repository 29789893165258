import React from "react";
import PropTypes from "prop-types";
import { convertTimeFilterToDate } from "../../utils/date";
import { auth } from "../../auth/Auth";

const CanvasDashboard = ({ sliceId, timeFilter }) => {
  timeFilter = convertTimeFilterToDate(timeFilter).toISOString();

  if (!sliceId) {
    return <></>;
  }

  const iframeSrc =
    process.env.REACT_APP_BI_URL.replace(
      ".operata.io",
      auth.getRegionalUrlPrefix() + ".operata.io"
    ) +
    `/superset/explore/?slice_id=${sliceId}&standalone=1&from=${timeFilter}`;

  return (
    <iframe
      id="dashboard_iframe"
      title="canvas"
      content="upgrade-insecure-requests"
      src={iframeSrc}
      width="100%"
      height="100%"
    />
  );
};

CanvasDashboard.propTypes = {
  timeFilter: PropTypes.string.isRequired,
  sliceId: PropTypes.string
};

export default CanvasDashboard;
