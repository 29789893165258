import React from "react";
import PropTypes from "prop-types";

const NetworkSummary = props => {
    const networkMetrics = props.metrics;
    const columns = [{label:'',value:''}, {label:'Min', value:'min'}, {label:'Max', value:'max'}, {label:'Avg', value:'avg'}, {label:'Global 99th', value:'global'}];
    const rows = [{label:'', value:''}, {label:'Jitter',value:'jitter'},{label:'Packet Loss', value:'packetLoss'},{label:'MOS',value:'mos'}, {label:'RTT',value:'rtt'}];

    return (
        <div>
            {
                rows.map((row, rowIndex) => {
                    return (<div key={row.value} className="greyBorder">
                        {
                            rowIndex === 0
                            ? 
                            columns.map(col => <div key={col.value} className="grid-col-networkSummary">{col.label}</div>)
                            :
                            columns.map((col, colIndex) => {
                                let value = col.value;
                                let valueOf = row.value;
                                let labelOf = row.label;
                                let displayValue = networkMetrics && networkMetrics[valueOf] && networkMetrics[valueOf][value] || 0;
                                return (<div key={col.value} className="grid-col-networkSummary">
                                    {
                                        colIndex === 0 
                                        ?
                                        labelOf
                                        :
                                        parseFloat(displayValue.toFixed(2))
                                    }
                                </div>)
                            })
                        }
                    </div>)
                })
            }
        </div>
    )
}

NetworkSummary.propTypes = {
    metrics: PropTypes.object
}
export default NetworkSummary;