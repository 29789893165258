import React from "react";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";

import CampaignContext from "../CampaignContext";

export class StepSave extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          totalCalls,
          handleTotalCallsChange,
          error_totalCalls,
          errorMessage_totalCalls,
          noAnswerTimeout,
          setNoAnswerTimeout,
          error_noAnswerTimeout,
          errorMessage_noAnswerTimeout,
          callDuration,
          setCallDuration,
          error_callDuration,
          errorMessage_callDuration,
          callsPerSecond,
          handleCallsPerSecondChange,
          error_callsPerSecond,
          errorMessage_callsPerSecond
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                {(totalCalls > 300 || callsPerSecond > 6) && (
                  <Alert severity="warning">
                    Please gain AWS approval before running this campaign -{" "}
                    <a href="https://help.operata.io/en/articles/3992442-aws-simulate-campaign-approval">
                      details here
                    </a>
                  </Alert>
                )}

                <div className="workflow__input">
                  <TextField
                    error={error_totalCalls}
                    label={
                      errorMessage_totalCalls
                        ? errorMessage_totalCalls
                        : "Total Calls"
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event =>
                      handleTotalCallsChange(event.target.value)
                    }
                    variant="outlined"
                    value={totalCalls}
                  />
                </div>

                <div className="workflow__input">
                  <TextField
                    error={error_callsPerSecond}
                    label={
                      errorMessage_callsPerSecond
                        ? errorMessage_callsPerSecond
                        : "Calls Per Second"
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event =>
                      handleCallsPerSecondChange(event.target.value)
                    }
                    variant="outlined"
                    value={callsPerSecond}
                  />
                </div>

                <div className="workflow__input">
                  <TextField
                    error={error_callDuration}
                    label={
                      errorMessage_callDuration
                        ? errorMessage_callDuration
                        : "Call Duration"
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event => setCallDuration(event.target.value)}
                    variant="outlined"
                    value={callDuration}
                  />
                </div>

                <div className="workflow__input">
                  <TextField
                    error={error_noAnswerTimeout}
                    label={
                      errorMessage_noAnswerTimeout
                        ? errorMessage_noAnswerTimeout
                        : "No Answer Timeout"
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={event => setNoAnswerTimeout(event.target.value)}
                    variant="outlined"
                    value={noAnswerTimeout}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}
