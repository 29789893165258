import {
  CAMPAIGNS_RECEIVED,
  CAMPAIGN_TOGGLED,
  CAMPAIGN_CANCELLED,
  CAMPAIGN_CREATED,
  ERROR_CAMPAIGN_CREATED,
  CAMPAIGN_REMOVED,
  EDIT_CAMPAIGN,
  NEW_CAMPAIGN,
  CAMPAIGN_UPDATED,
  CAMPAIGNS_RUNS_RECEIVED,
  CAMPAIGNS_RUN_RECEIVED,
  CAMPAIGNS_CALL_LOG_RECEIVED,
  RESET_CAMPAIGN_PAGE_STATE,
  CAMPAIGN_RECEIVED,
  CAMPAIGN_STARTED,
  ERROR_CAMPAIGN_STARTED,
  CAMPAIGN_STOPPED,
  ERROR_CAMPAIGN_REMOVED,
} from '../actions/campaign';

const actions = (state = { loading: false, data: [], runs: [], callLog: []}, action) => {
  switch (action.type) {
    case CAMPAIGNS_RECEIVED:
      return Object.assign({}, state, { data: action.campaigns, loading: false, status: CAMPAIGNS_RECEIVED });
    case CAMPAIGN_RECEIVED:
      return Object.assign({}, state, { campaign: action.campaign, loading: false, status: CAMPAIGN_RECEIVED });
    case CAMPAIGNS_RUNS_RECEIVED:
      return Object.assign({}, state, { runs: action.campaignRuns, loading: false, status: CAMPAIGNS_RUNS_RECEIVED });
    case CAMPAIGNS_RUN_RECEIVED:
      return Object.assign({}, state, {
        campaignRun: action.campaignRun,
        loading: false,
        status: CAMPAIGNS_RUN_RECEIVED,
      });
    case CAMPAIGNS_CALL_LOG_RECEIVED:
      return Object.assign({}, state, { callLog: action.callLog, campaignRun: action.campaignRun, loading: false, status: CAMPAIGNS_CALL_LOG_RECEIVED });
    case CAMPAIGN_TOGGLED:
      return Object.assign(
        {},
        state,
        state.data.map((campaign, i) => {
          if (campaign._id === action.campaignId) {
            return Object.assign({}, campaign, { _source: { status: { state: { active: action.enabled } } } });
          } else {
            return campaign;
          }
        })
      );

    case RESET_CAMPAIGN_PAGE_STATE:
      return Object.assign({}, state, { loading: false, status: RESET_CAMPAIGN_PAGE_STATE });
    case CAMPAIGN_STOPPED:
      return Object.assign({}, state, { loading: false, status: CAMPAIGN_STOPPED });
    case CAMPAIGN_REMOVED:
      return Object.assign({}, state, { loading: false, status: CAMPAIGN_REMOVED });
    case CAMPAIGN_CREATED:
      return Object.assign({}, state, { loading: false, status: CAMPAIGN_CREATED });
    case CAMPAIGN_STARTED:
      return Object.assign({}, state, { run: action.run, loading: false, status: CAMPAIGN_STARTED });
    case CAMPAIGN_UPDATED:
      return Object.assign({}, state, { loading: false, status: CAMPAIGN_UPDATED });
    case NEW_CAMPAIGN:
      return Object.assign({}, state, { loading: false, status: NEW_CAMPAIGN, campaignData: {} });
    case EDIT_CAMPAIGN:
      return Object.assign({}, state, {
        loading: false,
        status: EDIT_CAMPAIGN,
        campaignData: action.data,
        random: Math.random() * 10000,
      });
    case ERROR_CAMPAIGN_STARTED:
      return {
        ...state,
        loading: false,
        status: ERROR_CAMPAIGN_STARTED,
        random: Math.random() * 10000,
      };
    case ERROR_CAMPAIGN_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_CAMPAIGN_CREATED,
        errorMsg: action.message,
        random: Math.random() * 10000,
      };
      case ERROR_CAMPAIGN_REMOVED:
        return {
          ...state,
          loading: false,
          status: ERROR_CAMPAIGN_REMOVED,
          random: Math.random() * 10000,
        };
    default:
      return state;
  }
};

export default actions;
