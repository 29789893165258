import { TextField } from "@operata/ui-components";
import React from "react";
import PropTypes from "prop-types";

export const StepVerify = ({
  AWSRegion,
  eventSourceName,
  AWSAccount,
  eventSNPattern
}) => {
  return (
    <div className="grid__container workflow__summary">
      <div className="grid__column grid__column--four">
        <div className="workflow__input">
          <label htmlFor="business">AWS Region</label>
          <TextField
            id="outlined-required"
            name="AWSRegion"
            className="textField"
            variant="outlined"
            value={AWSRegion}
            disabled="disabled"
          />

          <TextField
            label="Event Source Name Prefix"
            value={eventSNPattern}
            disabled
          />
          <label htmlFor="business">Event Source Name</label>
          <TextField
            id="outlined-required"
            name="eventSourceName"
            className="textField"
            variant="outlined"
            value={eventSourceName}
            disabled="disabled"
          />

          <label htmlFor="business">AWS Account</label>
          <TextField
            id="outlined-required"
            name="AWSAccount"
            className="textField"
            variant="outlined"
            value={AWSAccount}
            disabled="disabled"
          />
        </div>
      </div>
    </div>
  );
};

StepVerify.propTypes = {
  AWSRegion: PropTypes.string,
  eventSourceName: PropTypes.string,
  AWSAccount: PropTypes.string,
  eventSNPattern: PropTypes.string
};
