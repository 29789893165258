import React from "react";
import PropTypes from "prop-types";
import store from "../../store/store";
import { windowResized } from "../../actions/window";
import { withRouter } from "react-router-dom";

class MainContent extends React.Component {
  constructor(props) {
    super(props);
    this.mainContent = React.createRef();
    this.storeMainContentWidth = this.storeMainContentWidth.bind(this);
  }

  storeMainContentWidth() {
    const mainContentWidth = this.mainContent.current.offsetWidth;
    const paddingLeft = window
      .getComputedStyle(this.mainContent.current, null)
      .getPropertyValue("padding-left");
    const paddingRight = window
      .getComputedStyle(this.mainContent.current, null)
      .getPropertyValue("padding-right");

    store.dispatch(
      windowResized(
        mainContentWidth -
          parseInt(paddingLeft, 10) -
          parseInt(paddingRight, 10)
      )
    );
  }

  componentDidMount() {
    this.storeMainContentWidth();
    window.addEventListener("resize", this.storeMainContentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.storeMainContentWidth);
  }

  render() {
    const isSupersetOpen = this.props.location.pathname.includes("/canvas");
    return (
      <div
        ref={this.mainContent}
        className={`main__content ${isSupersetOpen ? "superset__open" : ""}`}
      >
        {this.props.children}
      </div>
    );
  }
}

MainContent.propTypes = {
  isSupersetOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(MainContent);
